import React, { ChangeEvent, useRef, useState } from 'react'

import CropImageModal from '@components/Shared/CropImageModal'
import PartynerIcon from '@components/Shared/PartynerIcon'
import PictureUploadDto from '@dtos/PictureUploadDto'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, CardBody } from '@nextui-org/react'
import { v4 as uuidv4 } from 'uuid'

interface EditPicturesAddProps {
  onUploadPicture: (pictures: PictureUploadDto[]) => void
  existingPicturesCount: number
}

const EditPicturesAdd = ({
  onUploadPicture,
  existingPicturesCount
}: EditPicturesAddProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [croppingImage, setCroppingImage] = useState<
    PictureUploadDto | undefined
  >(undefined)
  const [uploadDto, setUploadDto] = useState<PictureUploadDto[]>([])
  const [croppingIndex, setCroppingIndex] = useState(0)

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files)
      const uploadDtoPromises = files.map((file, index) => {
        return new Promise<PictureUploadDto>((resolve) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            const image = reader.result as string
            resolve({
              id: uuidv4(),
              base64image: image,
              imageType: '',
              order: existingPicturesCount + 1 + index
            })
          }
          reader.readAsDataURL(file)
        })
      })

      const uploadDto = await Promise.all(uploadDtoPromises)

      if (uploadDto.length > 0) {
        setUploadDto(uploadDto)
        setCroppingIndex(0)
        setCroppingImage(uploadDto[0])
      }

      event.target.value = ''
    }
  }

  const handleCropSave = (croppedImage: string) => {
    const match = croppedImage.match(/^data:image\/([a-zA-Z0-9]+);base64,/)
    const type = match ? match[1].toLowerCase() : ''
    const newState: PictureUploadDto[] = [...uploadDto]
    newState[croppingIndex] = {
      base64image: croppedImage,
      imageType: type,
      id: croppingImage?.id,
      order: croppingImage?.order
    }

    if (croppingIndex < uploadDto.length - 1) {
      setUploadDto(newState)
      setCroppingImage(uploadDto[croppingIndex + 1])
      setCroppingIndex((prevIndex) => prevIndex + 1)
    } else {
      setCroppingImage(undefined)
      setCroppingIndex(0)
      setUploadDto([])

      onUploadPicture(newState)
    }
  }

  return (
    <>
      <CropImageModal
        imageSrc={croppingImage?.base64image}
        aspectRatio={1}
        onClose={() => setCroppingImage(undefined)}
        onSave={handleCropSave}
      />
      <Card
        isFooterBlurred
        radius="lg"
        className="size-[200px] min-h-[200px] border-none"
        fullWidth={false}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleFileChange}
          multiple
        />
        <CardBody className="flex items-center justify-center bg-success-100/20 align-middle">
          <PartynerIcon
            backgroundColor="transparent"
            frameColor="transparent"
            idleScale={90}
            onClick={() => handleButtonClick()}
          >
            <FontAwesomeIcon
              role="button"
              icon={faPlusCircle}
              className="text-success-600"
              size="5x"
            />
          </PartynerIcon>
        </CardBody>
      </Card>
    </>
  )
}

export default EditPicturesAdd
