import React, { useEffect, useState } from 'react'

import { AvailableCountries } from '@configuration/Constants'
import { useTheme } from '@configuration/ThemeContext'
import GenreDto from '@dtos/GenreDto'
import {
  faFilter,
  faFilterCircleXmark
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useLocalizedStringHelpers from '@hooks/useLocalizedStringHelpers'
import useTranslate from '@hooks/useTranslate'
import {
  Accordion,
  AccordionItem,
  Button,
  Input,
  Select,
  SelectItem,
  Tooltip
} from '@nextui-org/react'
import useGenreService from '@services/Genre'
import { RootState } from '@store'
import { useSelector } from 'react-redux'

interface ArtistsTableFilterProps {
  onApplyFilter: (
    names: string[],
    genres: string[],
    countries: string[]
  ) => void
  onClearFilter: () => void
}

const ArtistsTableFilter = ({
  onApplyFilter,
  onClearFilter
}: ArtistsTableFilterProps) => {
  const artistsFilterInMemory = useSelector(
    (state: RootState) => state.artistsFilter
  )
  const { getGenreList } = useGenreService()
  const { getLocalizedStringValue, selectedLanguage } =
    useLocalizedStringHelpers()
  const translate = useTranslate('components.artists.artists_table.filter')
  const countriesTranslation = useTranslate('countries')
  const { isDarkMode } = useTheme()

  const [nameInputValue, setNameInputValue] = useState(
    artistsFilterInMemory.namesFilter
      ? artistsFilterInMemory.namesFilter.join(';')
      : ''
  )
  const [genreList, setGenreList] = useState<GenreDto[]>([])
  const [namesFilter, setNamesFilter] = useState<string[]>(
    artistsFilterInMemory.namesFilter ?? []
  )
  const [genresFilter, setGenresFilter] = useState<string[]>(
    artistsFilterInMemory.genresFilter ?? []
  )
  const [countriesFilter, setCountriesFilter] = useState<string[]>(
    artistsFilterInMemory.countriesFilter ?? []
  )

  useEffect(() => {
    getGenreList(1, 1000, selectedLanguage).then((response) => {
      if (response) setGenreList(response.items)
    })
  }, [])

  const normalizeNamesFilter = (value: string) => {
    setNameInputValue(value)
    if (value === '') setNamesFilter([])
    else setNamesFilter(value.split(';'))
  }

  const clearFilter = () => {
    setNamesFilter([])
    setGenresFilter([])
    setCountriesFilter([])

    setNameInputValue('')

    onClearFilter()
  }

  return (
    <Accordion variant="shadow" isCompact={false}>
      <AccordionItem
        key="filters"
        title={translate('title')}
        startContent={
          <FontAwesomeIcon icon={faFilter} className="text-foreground-500" />
        }
        classNames={{
          title: 'text-sm font-semibold text-foreground-500'
        }}
      >
        <div className="flex flex-col items-center gap-3 pb-3">
          <div className="flex w-full flex-col justify-between gap-3 md:flex-row">
            <Tooltip
              size="sm"
              placement="bottom"
              color="foreground"
              content={translate('name_tooltip')}
            >
              <Input
                value={nameInputValue}
                label={translate('name')}
                id="artistNameFilter"
                name="artistNameFilter"
                classNames={{ label: 'z-0' }}
                onValueChange={normalizeNamesFilter}
              />
            </Tooltip>

            <Select
              selectedKeys={genresFilter}
              items={genreList}
              label={translate('genre')}
              selectionMode="multiple"
              classNames={{
                label: 'z-0',
                popoverContent: `${isDarkMode ? 'text-foreground dark' : ''}`
              }}
              onChange={(e) => setGenresFilter(e.target.value.split(','))}
            >
              {(genre) => (
                <SelectItem key={`${genre.id}`}>
                  {getLocalizedStringValue(genre.name)}
                </SelectItem>
              )}
            </Select>
            <Select
              selectedKeys={countriesFilter}
              label={translate('country')}
              selectionMode="multiple"
              classNames={{
                label: 'z-0',
                popoverContent: `${isDarkMode ? 'text-foreground dark' : ''}`
              }}
              onChange={(e) => setCountriesFilter(e.target.value.split(','))}
            >
              {AvailableCountries.sort((a, b) =>
                countriesTranslation(a.abbreviation).localeCompare(
                  countriesTranslation(b.abbreviation)
                )
              ).map((country) => (
                <SelectItem
                  key={country.databaseSelector}
                  value={country.databaseSelector}
                >
                  {countriesTranslation(country.abbreviation)}
                </SelectItem>
              ))}
            </Select>
          </div>
          <div className="flex flex-wrap justify-center gap-3">
            <Button
              variant="solid"
              color="success"
              onClick={() =>
                onApplyFilter(namesFilter, genresFilter, countriesFilter)
              }
              isDisabled={
                nameInputValue.length === 0 &&
                genresFilter.length === 0 &&
                countriesFilter.length === 0
              }
              startContent={<FontAwesomeIcon icon={faFilter} />}
            >
              {translate('apply_filters')}
            </Button>
            <Button
              variant="solid"
              color="warning"
              onClick={clearFilter}
              isDisabled={
                nameInputValue.length === 0 &&
                genresFilter.length === 0 &&
                countriesFilter.length === 0
              }
              startContent={<FontAwesomeIcon icon={faFilterCircleXmark} />}
            >
              {translate('clear_filters')}
            </Button>
          </div>
        </div>
      </AccordionItem>
    </Accordion>
  )
}

export default ArtistsTableFilter
