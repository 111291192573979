import React from 'react'

import InputField from '@components/Shared/InputField'
import { AvailableSocialNetworks } from '@configuration/Constants'
import { useTheme } from '@configuration/ThemeContext'
import {
  faGlobe,
  faGripLines,
  faTrashCan,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import UpdateSocialNetworkListFormValues from '@interfaces/Forms/Shared/UpdateSocialNetworkListFormValues'
import { Button, Card, CardBody, Tooltip } from '@nextui-org/react'
import { Draggable } from 'react-beautiful-dnd'

interface EditSocialNetworkListDraggableProps {
  values: UpdateSocialNetworkListFormValues
  onRemoveLink: (id: string) => void
}

const EditSocialNetworkListDraggable = ({
  values,
  onRemoveLink
}: EditSocialNetworkListDraggableProps) => {
  const socialNetworkTranslate = useTranslate('social_networks')
  const { isDarkMode } = useTheme()
  const getIcon = (name: string): IconDefinition => {
    return (
      AvailableSocialNetworks.find((asn) => asn.name === name)?.icon ?? faGlobe
    )
  }

  return (
    <div className="flex flex-col gap-2">
      {values
        .links!.sort((a, b) => a.order - b.order)
        .map((value, index) => (
          <Draggable key={value.id} draggableId={value.id!} index={index}>
            {(provided) => (
              <Card
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={{
                  ...provided.draggableProps.style
                }}
                className="flex gap-2"
              >
                <CardBody>
                  <div className="flex items-center gap-2">
                    <InputField
                      id={`links[${index}].link`}
                      name={`links[${index}].link`}
                      fullWidth
                      startContent={
                        <Tooltip
                          className={isDarkMode ? 'text-foreground dark' : ''}
                          content={socialNetworkTranslate(value.name)}
                        >
                          <FontAwesomeIcon icon={getIcon(value.name)} />
                        </Tooltip>
                      }
                    />
                    <Button
                      isIconOnly
                      size="sm"
                      variant="bordered"
                      color="danger"
                      onClick={() => onRemoveLink(value.id!)}
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </Button>
                    <Button
                      isIconOnly
                      size="sm"
                      variant="bordered"
                      {...provided.dragHandleProps}
                    >
                      <FontAwesomeIcon icon={faGripLines} />
                    </Button>
                  </div>
                </CardBody>
              </Card>
            )}
          </Draggable>
        ))}
    </div>
  )
}

export default EditSocialNetworkListDraggable
