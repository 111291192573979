export enum VenuesFilterActionTypes {
  SET_VENUES_FILTER = 'SET_VENUES_FILTER',
  CLEAR_VENUES_FILTER = 'CLEAR_VENUES_FILTER'
}

export const setVenuesFilterAction = (
  page: number,
  pageSize: number,
  nameFilter: string[],
  streetFilter: string[],
  neighborhoodFilter: string[],
  postalCodeFilter: string[],
  cityFilter: string[],
  stateFilter: string[],
  countryFilter: string[]
) => ({
  type: VenuesFilterActionTypes.SET_VENUES_FILTER as const,
  payload: {
    page,
    pageSize,
    nameFilter,
    streetFilter,
    neighborhoodFilter,
    postalCodeFilter,
    cityFilter,
    stateFilter,
    countryFilter
  }
})

export const clearVenuesFilterAction = () => ({
  type: VenuesFilterActionTypes.CLEAR_VENUES_FILTER as const
})

export type VenuesFilterAction = ReturnType<
  typeof setVenuesFilterAction | typeof clearVenuesFilterAction
>
