export enum ProducersFilterActionTypes {
  SET_PRODUCERS_FILTER = 'SET_PRODUCERS_FILTER',
  CLEAR_PRODUCERS_FILTER = 'CLEAR_PRODUCERS_FILTER'
}

export const setProducersFilterAction = (
  page: number,
  pageSize: number,
  nameFilter: string[]
) => ({
  type: ProducersFilterActionTypes.SET_PRODUCERS_FILTER as const,
  payload: {
    page,
    pageSize,
    nameFilter
  }
})

export const clearProducersFilterAction = () => ({
  type: ProducersFilterActionTypes.CLEAR_PRODUCERS_FILTER as const
})

export type ProducersFilterAction = ReturnType<
  typeof setProducersFilterAction | typeof clearProducersFilterAction
>
