import React, { Ref, useEffect, useState } from 'react'

import { setIsLoadingAction } from '@actions/Global'
import EditEventVenueDropdown from '@components/Events/EditEvent/EditEventVenueDropdown'
import VenueRadioSelector from '@components/Events/VenueRadioSelector'
import FramedImage from '@components/Shared/FramedImage/FramedImage'
import PartynerIcon from '@components/Shared/PartynerIcon'
import {
  VenueVisibility,
  GetVenueVisibilityValue
} from '@configuration/Constants'
import EventDto from '@dtos/EventDto'
import VenueDto from '@dtos/VenueDto'
import { EventVenueFormValidationSchema } from '@formSchemas/Event'
import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useStringHelpers from '@hooks/useStringHelpers'
import useTranslate from '@hooks/useTranslate'
import UpdateEventVenue from '@interfaces/Forms/Event/UpdateEventVenue'
import { parseDate } from '@internationalized/date'
import {
  Card,
  CardBody,
  DatePicker,
  DateValue,
  RadioGroup,
  Skeleton
} from '@nextui-org/react'
import { I18nProvider } from '@react-aria/i18n'
import useEventService from '@services/Event'
import useVenueService from '@services/Venue'
import { RootState } from '@store'
import { Formik, FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

interface EditEventVenueFormProps {
  values: UpdateEventVenue
  formRef: Ref<FormikProps<UpdateEventVenue>>
  formKey: number
  onSaveForm: (event: EventDto) => void
}

const EditEventVenueForm = ({
  values,
  formRef,
  formKey,
  onSaveForm
}: EditEventVenueFormProps) => {
  const state = useSelector((state: RootState) => state.global)
  const translate = useTranslate('components.events.edit_event')
  const errorsTranslate = useTranslate('errors')
  const dispatch = useDispatch()

  const {
    updateEventVenue,
    updateEventVenueVisibility,
    updateEventVenueReleaseDate
  } = useEventService()
  const { getVenueById } = useVenueService()
  const { getAddressMainInfo, getAddressAddons, getAddressCountry } =
    useStringHelpers()

  const [isLoading, setIsLoading] = useState(false)
  const [venueVisibility, setVenueVisibility] = useState(
    VenueVisibility.VISIBLE
  )
  const [venueReleaseDate, setVenueReleaseDate] = useState<
    DateValue | undefined | null
  >(undefined)
  const [selectedVenue, setSelectedVenue] = useState<VenueDto | undefined>(
    undefined
  )

  useEffect(() => {
    if (!values.venueId) {
      setSelectedVenue(undefined)
      return
    }

    setIsLoading(true)
    getVenueById(values.venueId)
      .then((venue) => {
        if (venue) {
          setSelectedVenue(venue)
          switch (values.venueVisibility) {
            case 0:
              setVenueVisibility(VenueVisibility.VISIBLE)
              break
            case 1:
              setVenueVisibility(VenueVisibility.HIDDEN)
              break
            case 2: {
              setVenueVisibility(VenueVisibility.SCHEDULED)
              if (values.venueReleaseDate) {
                setVenueReleaseDate(
                  parseDate(values.venueReleaseDate.toString().substring(0, 10))
                )
              }
            }
          }
        }
      })
      .finally(() => setIsLoading(false))
  }, [values.venueId])

  const handleError = () => {
    dispatch(setIsLoadingAction(false))
    toast.error(errorsTranslate('general'))
  }

  const saveForm = async (form: UpdateEventVenue) => {
    if (form.eventId) {
      const updatedEvent = await updateEventVenue(form.eventId, form.venueId)
      if (!updatedEvent) return handleError()

      const updatedEventVenueVisibility = await updateEventVenueVisibility(
        form.eventId,
        form.venueVisibility
      )
      if (!updatedEventVenueVisibility) return handleError()

      if (
        form.venueVisibility !==
        GetVenueVisibilityValue(VenueVisibility.SCHEDULED)
      ) {
        form.venueReleaseDate = undefined
      }

      const updatedEventVenueReleaseDate = await updateEventVenueReleaseDate(
        form.eventId,
        form.venueReleaseDate
      )
      if (!updatedEventVenueReleaseDate) return handleError()

      onSaveForm(updatedEventVenueReleaseDate)
    }
  }

  const changeVenue = (
    form: FormikProps<UpdateEventVenue>,
    venueId: string
  ) => {
    if (!venueId) {
      setSelectedVenue(undefined)
      return
    }

    setIsLoading(true)
    getVenueById(venueId)
      .then((venue) => {
        if (venue) {
          setSelectedVenue(venue)
          form.setFieldValue('venueId', venueId)
        }
      })
      .finally(() => setIsLoading(false))
  }

  const changeVenueVisibility = (
    form: FormikProps<UpdateEventVenue>,
    value: string
  ) => {
    form.setFieldValue('venueVisibility', GetVenueVisibilityValue(value))
    setVenueVisibility(value as VenueVisibility)

    if (value !== VenueVisibility.SCHEDULED) setVenueReleaseDate(null)
  }

  const changeVenueReleaseDate = (
    form: FormikProps<UpdateEventVenue>,
    value: DateValue
  ) => {
    form.setFieldValue(
      'venueReleaseDate',
      value === null
        ? undefined
        : new Date(
            Date.UTC(value.year, value.month - 1, value.day)
          ).toISOString()
    )
    setVenueReleaseDate(value)
  }

  return (
    <Card fullWidth>
      <Formik
        innerRef={formRef}
        key={formKey}
        initialValues={values}
        onSubmit={async (values) => await saveForm(values)}
        validationSchema={EventVenueFormValidationSchema}
      >
        {(props: FormikProps<UpdateEventVenue>) => (
          <form onSubmit={props.handleSubmit} noValidate className="h-full">
            <CardBody className="h-full">
              <div className="flex h-full flex-col items-center gap-4 lg:flex-row">
                <div className="min-h-[150px]">
                  {!isLoading && !selectedVenue && (
                    <div className="size-[150px]">
                      <PartynerIcon
                        roundSize="rounded-3xl"
                        backgroundColor="hsl(var(--nextui-content2))"
                        frameColor="transparent"
                      >
                        <FontAwesomeIcon
                          icon={faMapLocationDot}
                          size="4x"
                          className="text-foreground-300"
                        />
                      </PartynerIcon>
                    </div>
                  )}
                  {!isLoading && selectedVenue && (
                    <FramedImage
                      size={150}
                      src={selectedVenue?.logoUrl}
                      showFrame={false}
                    />
                  )}
                  {isLoading && (
                    <Skeleton className="size-[150px] rounded-3xl" />
                  )}
                </div>
                <div className="flex size-full flex-col gap-6 lg:gap-3">
                  <EditEventVenueDropdown
                    onSelectVenue={(value) => changeVenue(props, value)}
                    selectedVenue={props.values.venueId}
                  />
                  <div className="grid size-full grid-cols-12 justify-between gap-6 lg:flex-row lg:gap-2">
                    <div className="col-span-12 flex size-full flex-col items-center gap-6 lg:col-span-5 lg:flex-row">
                      {!isLoading && !selectedVenue && (
                        <div className="flex size-full items-center justify-center text-sm text-foreground-300 lg:justify-start">
                          {translate('select_venue_to_display_details')}
                        </div>
                      )}
                      {!isLoading && selectedVenue && (
                        <div className="flex flex-col gap-0 text-center text-sm lg:text-left">
                          <span className="text-foreground subpixel-antialiased">
                            {getAddressMainInfo(selectedVenue.address)}
                          </span>
                          <span className="text-foreground subpixel-antialiased">
                            {getAddressAddons(selectedVenue.address)}
                          </span>
                          <span className="text-foreground subpixel-antialiased">
                            {getAddressCountry(selectedVenue.address)}
                          </span>
                        </div>
                      )}
                      {isLoading && (
                        <div className="mt-1 flex flex-col items-center gap-1 text-sm lg:items-start">
                          <Skeleton className="h-3.5 w-80 rounded-full" />
                          <Skeleton className="h-3.5 w-64 rounded-full" />
                          <Skeleton className="h-3.5 w-52 rounded-full" />
                        </div>
                      )}
                    </div>
                    <div className="col-span-12 flex size-full grow justify-center lg:col-span-7 lg:justify-end">
                      <RadioGroup
                        value={venueVisibility}
                        onValueChange={(value) =>
                          changeVenueVisibility(props, value)
                        }
                        classNames={{
                          wrapper: 'flex flex-row justify-center'
                        }}
                      >
                        <VenueRadioSelector
                          onSelect={(value) =>
                            changeVenueVisibility(props, value)
                          }
                          content={translate('show_venue')}
                          value={VenueVisibility.VISIBLE}
                        />
                        <VenueRadioSelector
                          onSelect={(value) =>
                            changeVenueVisibility(props, value)
                          }
                          content={translate('hide_venue')}
                          value={VenueVisibility.HIDDEN}
                        />
                        <VenueRadioSelector
                          onSelect={(value) =>
                            changeVenueVisibility(props, value)
                          }
                          value={VenueVisibility.SCHEDULED}
                        >
                          <I18nProvider locale={state.language}>
                            <DatePicker
                              showMonthAndYearPickers
                              value={venueReleaseDate}
                              onChange={(value) =>
                                changeVenueReleaseDate(props, value)
                              }
                              isDisabled={
                                venueVisibility !== VenueVisibility.SCHEDULED
                              }
                              variant="underlined"
                              size="sm"
                              label={translate('schedule_venue_release')}
                            />
                          </I18nProvider>
                        </VenueRadioSelector>
                      </RadioGroup>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </form>
        )}
      </Formik>
    </Card>
  )
}

export default EditEventVenueForm
