import React, { useState } from 'react'

import useTranslate from '@hooks/useTranslate'
import { Button, Input } from '@nextui-org/react'

interface ConfirmDeleteArtistModalBodyInputProps {
  name?: string
  onConfirmDeleteArtist: () => void
}

const ConfirmDeleteArtistModalBodyInput = ({
  name,
  onConfirmDeleteArtist
}: ConfirmDeleteArtistModalBodyInputProps) => {
  const translate = useTranslate('components.artists.remove_artist')
  const [nameMatch, setNameMatch] = useState('')
  return (
    <>
      <span className="text-sm">{translate('type_artist_name')}</span>
      <Input
        variant="bordered"
        onValueChange={(value) => setNameMatch(value)}
      />
      <Button
        variant="flat"
        color="danger"
        isDisabled={nameMatch !== name}
        onClick={onConfirmDeleteArtist}
      >
        {translate('remove_artist')}
      </Button>
    </>
  )
}

export default ConfirmDeleteArtistModalBodyInput
