import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'

import SoundCloudTrackInfoDto from '@dtos/SoundCloudTrackInfoDto'
import {
  faGripLines,
  faPause,
  faPlay,
  faTrashCan
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useSoundCloudHelpers from '@hooks/useSoundCloudHelpers'
import {
  Button,
  Card,
  CardBody,
  Image,
  Slider,
  SliderValue,
  Tooltip
} from '@nextui-org/react'
import useSoundCloudService from '@services/SoundCloud'
import { DraggableProvided } from 'react-beautiful-dnd'

interface EditArtistSoundCloudPlayerProps {
  trackId: string
  draggableProvided: DraggableProvided
  onRemoveLink: () => void
  onPlay: () => void
}

export type EditArtistSoundCloudPlayerRef = {
  pausePlayer: () => void
}

const EditArtistSoundCloudPlayer = forwardRef<
  EditArtistSoundCloudPlayerRef,
  EditArtistSoundCloudPlayerProps
>(
  (
    {
      trackId,
      draggableProvided,
      onRemoveLink,
      onPlay
    }: EditArtistSoundCloudPlayerProps,
    editArtistSoundCloudPlayerRef
  ) => {
    const { getTrackInfo } = useSoundCloudService()
    const audioRef = useRef<HTMLMediaElement | null>(null)
    const [trackData, setTrackData] = useState<SoundCloudTrackInfoDto | null>(
      null
    )
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const { generateAudioBuffer } = useSoundCloudHelpers()

    useEffect(() => {
      getTrackInfo(trackId).then(async (response) => {
        if (response && audioRef.current) {
          setTrackData(response)
          generateAudioBuffer(response, audioRef)
        }
      })
    }, [trackId])

    useEffect(() => {
      if (audioRef.current) {
        const handleLoadedMetadata = () => {
          if (audioRef.current) {
            setDuration(audioRef.current.duration)
          }
        }

        const handleTimeUpdate = () => {
          if (audioRef.current) {
            setCurrentTime(audioRef.current.currentTime)
          }
        }

        const handleEnded = () => {
          setIsPlaying(false)
          setCurrentTime(0)
          if (audioRef.current) {
            audioRef.current.pause()
            audioRef.current.currentTime = 0
          }
        }

        audioRef.current.addEventListener(
          'loadedmetadata',
          handleLoadedMetadata
        )
        audioRef.current.addEventListener('timeupdate', handleTimeUpdate)
        audioRef.current.addEventListener('ended', handleEnded)

        return () => {
          if (audioRef.current) {
            audioRef.current.removeEventListener(
              'loadedmetadata',
              handleLoadedMetadata
            )
            audioRef.current.removeEventListener('timeupdate', handleTimeUpdate)
            audioRef.current.removeEventListener('ended', handleEnded)
          }
        }
      }
    }, [trackData])

    const handleSliderChange = (value: SliderValue) => {
      if (audioRef.current) {
        audioRef.current.currentTime = value as number
      }
    }

    const play = () => {
      if (audioRef.current) {
        setIsPlaying(true)
        audioRef.current.play()
        onPlay()
      }
    }

    const pause = () => {
      if (audioRef.current) {
        setIsPlaying(false)
        audioRef.current.pause()
      }
    }

    useImperativeHandle(editArtistSoundCloudPlayerRef, () => ({
      pausePlayer: pause
    }))

    const formatTime = (seconds: number): string => {
      const roundedSeconds = Math.floor(seconds)
      const minutes = Math.floor(roundedSeconds / 60)
      const remainingSeconds = roundedSeconds % 60

      return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`
    }

    return (
      <Card fullWidth>
        <CardBody>
          <div className="flex w-full gap-3">
            <Image
              src={trackData?.artwork_url}
              alt={trackData?.title}
              width={100}
              className="rounded-md"
            />
            <div className="flex w-full flex-col gap-2">
              <div className="flex w-full justify-between">
                <div className="flex flex-col">
                  <span className="max-w-[150px] truncate text-small font-semibold text-foreground-500 md:max-w-[250px] md:text-lg lg:max-w-fit">
                    {trackData?.title}
                  </span>
                  <span className="max-w-[150px] truncate text-tiny font-semibold text-foreground-500 md:max-w-[250px] lg:max-w-fit">
                    {trackData?.user.username}
                  </span>
                </div>
                <div className="flex gap-2">
                  <Button
                    isIconOnly
                    size="sm"
                    variant="bordered"
                    color="danger"
                    onClick={() => onRemoveLink()}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </Button>
                  <Button
                    isIconOnly
                    size="sm"
                    variant="bordered"
                    {...draggableProvided.dragHandleProps}
                  >
                    <FontAwesomeIcon icon={faGripLines} />
                  </Button>
                </div>
              </div>

              <div className="flex grow items-center justify-start gap-3">
                <Button
                  onClick={() => (isPlaying ? pause() : play())}
                  isIconOnly
                  radius="full"
                  color="primary"
                  size="sm"
                >
                  <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
                </Button>
                <Slider
                  className="px-2"
                  minValue={0}
                  maxValue={duration}
                  value={currentTime}
                  onChange={handleSliderChange}
                  color="primary"
                  size="sm"
                  renderThumb={(props) => (
                    <Tooltip
                      placement="top"
                      size="sm"
                      content={formatTime(currentTime)}
                    >
                      <div {...props}></div>
                    </Tooltip>
                  )}
                />
                <span className="min-w-[50px] text-small">
                  {formatTime(duration - currentTime)}
                </span>
              </div>
            </div>
            <audio ref={audioRef} />
          </div>
        </CardBody>
      </Card>
    )
  }
)
EditArtistSoundCloudPlayer.displayName = 'EditArtistSoundCloudPlayer'

export default EditArtistSoundCloudPlayer
