import { GlobalActions, GlobalActionTypes } from '@actions/Global'
import UserDto from '@dtos/UserDto'

export interface GlobalState {
  loggedUser?: UserDto
  isLoading: boolean
  isLoadingUser: boolean
  theme: string
  language: string
  producer?: string
}

const initialGlobalState: GlobalState = {
  loggedUser: undefined,
  isLoading: false,
  isLoadingUser: false,
  theme: 'partyner',
  language: 'en',
  producer: undefined
}

const GlobalReducers = (
  state: GlobalState = initialGlobalState,
  action: GlobalActions
) => {
  switch (action.type) {
    case GlobalActionTypes.SET_LOGGED_USER:
      return {
        ...state,
        loggedUser: action.payload
      }
    case GlobalActionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload
      }
    case GlobalActionTypes.SET_IS_LOADING_USER:
      return {
        ...state,
        isLoadingUser: action.payload
      }
    case GlobalActionTypes.SET_THEME:
      return {
        ...state,
        theme: action.payload
      }
    case GlobalActionTypes.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload
      }
    case GlobalActionTypes.SET_PRODUCER:
      return {
        ...state,
        producer: action.payload
      }
    default:
      return state
  }
}

export default GlobalReducers
