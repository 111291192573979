import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const reactPlugin = new ReactPlugin()
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey:
      process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: {}
    },
    enableAutoRouteTracking: true
  }
})

appInsights.loadAppInsights()

export { appInsights, reactPlugin }
