import React, { ReactNode } from 'react'

import { AvailableSocialNetworks } from '@configuration/Constants'
import useTranslate from '@hooks/useTranslate'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger
} from '@nextui-org/react'

interface EditSocialNetworkListDropdownProps {
  onAddSocialNetwork: (name: string) => void
  children: ReactNode
}

const EditSocialNetworkListDropdown = ({
  onAddSocialNetwork,
  children
}: EditSocialNetworkListDropdownProps) => {
  const translate = useTranslate('social_networks')

  return (
    <Dropdown className="dark" placement="bottom-end">
      <DropdownTrigger>{children}</DropdownTrigger>
      <DropdownMenu variant="flat">
        {AvailableSocialNetworks.sort((a, b) =>
          a.name.localeCompare(b.name)
        ).map((network) => (
          <DropdownItem
            key={network.name}
            className="text-foreground"
            color="default"
            onPress={() => onAddSocialNetwork(network.name)}
          >
            {translate(network.name)}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default EditSocialNetworkListDropdown
