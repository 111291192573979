import React from 'react'

import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if (process.env.REACT_APP_IGNORE_ARIA_WARNINGS === 'true') {
  const originalWarn = console.warn

  console.warn = (...args: any[]) => {
    if (typeof args[0] === 'string' && args[0].includes('aria')) return
    originalWarn(...args)
  }
}

root.render(
  <BrowserRouter>
    <div className="h-screen w-full overflow-y-auto">
      <App />
    </div>
  </BrowserRouter>
)
