import React from 'react'

import LocalizedStringDto from '@dtos/LocalizedStringDto'
import useLocalizedStringHelpers from '@hooks/useLocalizedStringHelpers'

interface GenresTableColumnNameProps {
  name: LocalizedStringDto[]
}

const GenresTableColumnName = ({ name }: GenresTableColumnNameProps) => {
  const { getLocalizedStringValue } = useLocalizedStringHelpers()

  return <span className="font-semibold">{getLocalizedStringValue(name)}</span>
}

export default GenresTableColumnName
