import React, { forwardRef, useImperativeHandle, useState } from 'react'

import FaceIcon from '@assets/Icons/FaceIcon'
import PartynerIcon from '@components/Shared/PartynerIcon'
import SignInInput from '@components/SignIn/SignInInput'
import SignInDto from '@dtos/SignInDto'
import {
  SignInFormInitialValues,
  SignInFormValidationSchema
} from '@formSchemas/SignIn'
import { faSignIn } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import { Button, Checkbox } from '@nextui-org/react'
import { useAuth } from '@security/AuthContext'
import { Formik, FormikProps } from 'formik'

interface SignInNewUserCardProps {
  onSelectNewUser: () => void
}

export type SignInNewUserCardRef = {
  deselectCard: () => void
  selectCard: () => void
}

const SignInNewUserCard = forwardRef<
  SignInNewUserCardRef,
  SignInNewUserCardProps
>(({ onSelectNewUser }: SignInNewUserCardProps, signInNewUserCardRef) => {
  const { login } = useAuth()
  const translate = useTranslate('components.sign_in')

  const [isCardSelected, setIsCardSelected] = useState(false)
  const [rememberUser, setRememberUser] = useState(true)

  const selectNewUser = () => {
    setIsCardSelected(true)
    onSelectNewUser()
  }

  const handleLogin = async (signInDto: SignInDto) => {
    await login(rememberUser, signInDto)
  }

  useImperativeHandle(signInNewUserCardRef, () => ({
    deselectCard: () => setIsCardSelected(false),
    selectCard: () => setIsCardSelected(true)
  }))

  return (
    <Formik
      initialValues={SignInFormInitialValues}
      validationSchema={SignInFormValidationSchema}
      onSubmit={handleLogin}
    >
      {(props: FormikProps<SignInDto>) => (
        <form onSubmit={props.handleSubmit} noValidate>
          <div className="relative z-10 flex h-full w-[300px] flex-col items-center justify-center gap-3">
            <div className="flex aspect-square w-36 items-center justify-center">
              <PartynerIcon
                idleOpacity={isCardSelected ? 100 : 70}
                idleScale={isCardSelected ? 100 : 95}
                onClick={selectNewUser}
              >
                <FaceIcon fill="#FFFFFF" />
              </PartynerIcon>
            </div>
            <div className="flex flex-col items-center justify-center">
              <span className="font-partyner text-2xl text-white">
                {translate('sign_in')}
              </span>
              <span className="text-tiny">&nbsp;</span>
            </div>
            <div
              className={`absolute top-full flex w-full flex-col items-center justify-center gap-2 transition-all duration-500 ease-in-out ${
                isCardSelected
                  ? 'translate-y-0 opacity-95'
                  : 'translate-y-8 opacity-0'
              }`}
            >
              <SignInInput
                id="email"
                name="email"
                fullWidth
                type="email"
                radius="full"
                placeholder={translate('email')}
                classNames={{
                  label: 'text-black/50',
                  input: [
                    'bg-transparent',
                    'text-black/90',
                    'placeholder:text-default-700/50'
                  ],
                  innerWrapper: 'bg-transparent',
                  inputWrapper: [
                    'hover:bg-transparent',
                    'shadow-xl',
                    'bg-default-200/70',
                    'backdrop-blur-xl',
                    'backdrop-saturate-200',
                    'focus-within:!bg-default-200/70',
                    'hover:!bg-default-200/70',
                    '!cursor-text'
                  ]
                }}
              />
              <SignInInput
                id="password"
                name="password"
                fullWidth
                type="password"
                radius="full"
                placeholder={translate('password_new_user')}
                classNames={{
                  label: 'text-black/50',
                  input: [
                    'bg-transparent',
                    'text-black/90',
                    'placeholder:text-default-700/50'
                  ],
                  innerWrapper: 'bg-transparent',
                  inputWrapper: [
                    'hover:bg-transparent',
                    'shadow-xl',
                    'bg-default-200/70',
                    'backdrop-blur-xl',
                    'backdrop-saturate-200',
                    'focus-within:!bg-default-200/70',
                    'hover:!bg-default-200/70',
                    '!cursor-text'
                  ]
                }}
              />
              <div className="flex w-full items-center justify-center gap-3">
                <div
                  className="flex w-full cursor-pointer items-center justify-start rounded-full bg-default-200/70 p-2.5 align-middle "
                  onClick={() => setRememberUser((prevState) => !prevState)}
                >
                  <Checkbox
                    isSelected={rememberUser}
                    onChange={() => setRememberUser((prevState) => !prevState)}
                  />
                  <span className="text-tiny font-light text-black/70">
                    {translate('remember_me')}
                  </span>
                </div>
                <Button
                  className="bg-primary shadow-xl backdrop-blur-xl backdrop-saturate-200"
                  isIconOnly
                  radius="full"
                  type="submit"
                >
                  <FontAwesomeIcon icon={faSignIn} className="text-white" />
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
})

SignInNewUserCard.displayName = 'SignInNewUserCard'

export default SignInNewUserCard
