import {
  EventsFilterAction,
  EventsFilterActionTypes
} from '@actions/EventsFilter'

export interface EventsFilterState {
  page?: number
  pageSize?: number
  nameFilter?: string[]
}

const initialEventsFilterState: EventsFilterState = {
  page: undefined,
  pageSize: undefined,
  nameFilter: undefined
}

const EventsFilterReducer = (
  state: EventsFilterState = initialEventsFilterState,
  action: EventsFilterAction
) => {
  switch (action.type) {
    case EventsFilterActionTypes.SET_EVENTS_FILTER:
      return {
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        nameFilter: action.payload.nameFilter
      }
    case EventsFilterActionTypes.CLEAR_EVENTS_FILTER:
      return initialEventsFilterState
    default:
      return state
  }
}

export default EventsFilterReducer
