import React from 'react'

import defaultAvatar from '@assets/Images/default_artist_avatar.png'
import defaultAvatarDark from '@assets/Images/default_artist_avatar_dark.png'
import ConfirmDeleteArtistModalBodyAvatar from '@components/Artists/ConfirmDeleteArtistModal/ConfirmDeleteArtistModalBodyAvatar'
import ConfirmDeleteArtistModalBodyInput from '@components/Artists/ConfirmDeleteArtistModal/ConfirmDeleteArtistModalBodyInput'
import { useTheme } from '@configuration/ThemeContext'
import ArtistDto from '@dtos/ArtistDto'
import { ModalBody } from '@nextui-org/react'

interface ConfirmDeleteArtistModalBodyProps {
  artist?: ArtistDto
  onConfirmDeleteArtist: () => void
}

const ConfirmDeleteArtistModalBody = ({
  artist,
  onConfirmDeleteArtist
}: ConfirmDeleteArtistModalBodyProps) => {
  const { isDarkMode } = useTheme()
  return (
    <ModalBody>
      <div className="flex flex-col items-center gap-3">
        <ConfirmDeleteArtistModalBodyAvatar
          avatarUrl={
            artist?.avatarUrl
              ? artist.avatarUrl
              : isDarkMode
                ? defaultAvatarDark
                : defaultAvatar
          }
          name={artist?.name}
        />
        <ConfirmDeleteArtistModalBodyInput
          name={artist?.name}
          onConfirmDeleteArtist={onConfirmDeleteArtist}
        />
      </div>
    </ModalBody>
  )
}

export default ConfirmDeleteArtistModalBody
