import LocalizedStringDto from '@dtos/LocalizedStringDto'
import { useTranslation } from 'react-i18next'

const useLocalizedStringHelpers = () => {
  const selectedLanguage = useTranslation().i18n.language

  const getLocalizedStringValue = (items: LocalizedStringDto[]): string => {
    return (
      items.find((item) => item.culture === selectedLanguage)?.value ??
      items.find((item) => item.culture === 'en')!.value
    )
  }

  return { getLocalizedStringValue, selectedLanguage }
}

export default useLocalizedStringHelpers
