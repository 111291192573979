import React from 'react'

import EditArtistSoundCloudTrackValidation from '@components/Artists/EditArtist/SoundCloudTracks/EditArtistSoundCloudTrackValidation'
import UpdateSoundCloudTracks from '@interfaces/Forms/Artist/UpdateSoundCloudTracks'
import { Modal, ModalBody, ModalContent } from '@nextui-org/react'
import { FormikProps } from 'formik'
import { v4 as uuidv4 } from 'uuid'

interface EditArtistSoundCloudTrackModalProps {
  isOpen: boolean
  form: FormikProps<UpdateSoundCloudTracks>
  onClose: () => void
}

const EditArtistSoundCloudTrackModal = ({
  isOpen,
  form,
  onClose
}: EditArtistSoundCloudTrackModalProps) => {
  const checkValidation = (
    isValid: boolean,
    extractedId: string | undefined
  ) => {
    if (isValid && extractedId && form.values.tracks) {
      form.setFieldValue('tracks', [
        ...form.values.tracks,
        {
          id: uuidv4(),
          trackId: extractedId,
          order: form.values.tracks.length + 1,
          isNew: true
        }
      ])
      onClose()
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalBody>
          <div className="flex">
            <EditArtistSoundCloudTrackValidation onValidate={checkValidation} />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default EditArtistSoundCloudTrackModal
