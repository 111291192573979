import CreateUpdateGenreDto from '@dtos/CreateUpdateGenreDto'
import GenreDto from '@dtos/GenreDto'
import PaginatedListDto from '@dtos/PaginatedListDto'
import useRootApiService from '@services/RootApi'

const useGenreService = () => {
  const { Get, Delete, Post, Put } = useRootApiService()

  const getGenreList = async (
    pageIndex: number,
    pageSize: number,
    culture: string,
    namesFilter?: string[]
  ): Promise<PaginatedListDto<GenreDto> | undefined> => {
    let params: any = { pageIndex, pageSize, culture }

    if (namesFilter && namesFilter.length > 0)
      params = { ...params, nameFilter: namesFilter }

    const response = await Get<PaginatedListDto<GenreDto>>('genre', params)

    return response.data
  }

  const getGenreById = async (
    genreId: string
  ): Promise<GenreDto | undefined> => {
    const response = await Get<GenreDto>(`/genre/${genreId}`)

    return response.data
  }

  const getGenreByName = async (
    genreName: string,
    culture: string
  ): Promise<GenreDto[] | undefined> => {
    const response = await Get<GenreDto[]>(`/genre/name`, {
      genreName,
      culture
    })

    return response.data
  }

  const deleteGenre = async (genreId: string): Promise<boolean> => {
    const response = await Delete(`/genre/${genreId}`)

    return response.success
  }

  const createGenre = async (
    genre: CreateUpdateGenreDto
  ): Promise<GenreDto | undefined> => {
    const response = await Post<GenreDto, CreateUpdateGenreDto>('/genre', genre)

    return response.data
  }

  const updateGenre = async (
    genreId: string,
    genre: CreateUpdateGenreDto
  ): Promise<GenreDto | undefined> => {
    const response = await Put<GenreDto, CreateUpdateGenreDto>(
      `/genre/${genreId}`,
      genre
    )

    return response.data
  }

  return {
    getGenreList,
    getGenreById,
    getGenreByName,
    deleteGenre,
    createGenre,
    updateGenre
  }
}

export default useGenreService
