import React from 'react'

import useTranslate from '@hooks/useTranslate'
import {
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger
} from '@nextui-org/react'

interface FormFooterProps {
  showCancelButton: boolean
  onSelectCancel: () => void
  onSelectSave: () => void
  onGoBack: () => void
}

const FormFooter = ({
  showCancelButton,
  onSelectCancel,
  onSelectSave,
  onGoBack
}: FormFooterProps) => {
  const translate = useTranslate('components.shared.form_footer')
  return (
    <Card fullWidth>
      <CardBody className="flex flex-col justify-end gap-3 align-middle lg:flex-row">
        {showCancelButton && (
          <Dropdown className="dark" placement="top">
            <DropdownTrigger>
              <Button size="lg" color="danger" variant="flat">
                {translate('cancel_changes')}
              </Button>
            </DropdownTrigger>
            <DropdownMenu variant="flat">
              <DropdownSection title={translate('cancel_changes_confirmation')}>
                <DropdownItem key="no" className="text-success" color="success">
                  {translate('no')}
                </DropdownItem>
                <DropdownItem
                  key="yes"
                  className="text-danger"
                  color="danger"
                  onPress={onSelectCancel}
                >
                  {translate('yes')}
                </DropdownItem>
              </DropdownSection>
            </DropdownMenu>
          </Dropdown>
        )}
        <Button size="lg" color="success" variant="flat" onClick={onSelectSave}>
          {translate('save_changes')}
        </Button>
        <div className="flex lg:absolute lg:left-3">
          <Button
            className="w-full"
            size="lg"
            variant="flat"
            onClick={onGoBack}
          >
            {translate('go_back')}
          </Button>
        </div>
      </CardBody>
    </Card>
  )
}

export default FormFooter
