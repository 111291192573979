import React from 'react'

interface EventIconProps {
  fill?: string
}

const EventIcon = ({ fill }: EventIconProps) => {
  return (
    <div className="flex size-1/2 items-center justify-center align-middle">
      <svg
        fill={fill ?? 'currentColor'}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 140.02 145.9"
        width="100%"
        height="100%"
      >
        <path d="M92.58,35.35l29.92-17.59c3.23-1.4,6.67,1.66,5.75,5.07l-17.9,30.64c.16.23.3.29.57.34,6.67,1.14,13.94,1.45,20.71,2.31,2.91.37,7.81.25,8.32,3.98.3,2.19-.43,3.43-2.14,4.66-7.39,5.32-17.26,9.18-24.81,14.39-.23.16-.43.32-.6.53l22.78,21.42c2.24,3.1.09,6.74-3.52,6.96l-32.71-4.11c.18,2.38.43,4.78.71,7.15,1.05,8.94,2.53,17.9,3.11,26.87-1.06,3.3-4.85,4.31-7.39,1.91l-20.93-22.25-14.96,26.05c-1.99,3.24-6.63,2.86-7.83-.76l-3.07-27.35-29.99,17.52c-3.45,1.7-7.14-1.53-6.06-5.15l17.82-30.6-27.35-3.07c-3.48-1.1-4.04-5.49-1.21-7.6,7.37-4.38,14.93-8.43,22.33-12.76,1.05-.62,2.66-1.44,3.57-2.12.2-.15.42-.22.39-.54L5.53,49.82c-2.38-3.26.59-7.42,4.44-6.72l32.25,3.87-1.83-25.86c-.85-4.49,3.29-7.87,7.15-4.87,5.06,4.65,9.93,9.53,14.96,14.21.77.72,2.37,2.36,3.08,2.85.19.13.23.32.57.22L82.59,2c2.31-3.44,7.31-2.19,7.88,1.85.28,1.95.14,4.33.29,6.32.65,8.39,1.13,16.8,1.82,25.19Z" />
      </svg>
    </div>
  )
}

export default EventIcon
