import React, { useEffect, useState } from 'react'

import { setRootBreadcrumbAction } from '@actions/Breadcrumb'
import {
  clearEventsFilterAction,
  setEventsFilterAction
} from '@actions/EventsFilter'
import EventsTable from '@components/Events/EventsTable/EventsTable'
import { RootBreadcrumbConstants } from '@configuration/Constants'
import EventDto from '@dtos/EventDto'
import useEventService from '@services/Event'
import { RootState } from '@store'
import { useDispatch, useSelector } from 'react-redux'

const Events = () => {
  const dispatch = useDispatch()
  const { getEventList } = useEventService()

  const eventsFilterInMemory = useSelector(
    (state: RootState) => state.eventsFilter
  )
  const globalState = useSelector((state: RootState) => state.global)

  const [eventList, setEventList] = useState<EventDto[]>([])
  const [eventToDelete, setEventToDelete] = useState<EventDto | undefined>(
    undefined
  )

  const [totalPages, setTotalPages] = useState<number>(0)
  const [selectedPage, setSelectedPage] = useState<number>(
    eventsFilterInMemory.page ?? 1
  )
  const [pageSize, setPageSize] = useState<number>(
    eventsFilterInMemory.pageSize ?? 10
  )
  const [totalItems, setTotalItems] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)

  const [nameFilter, setNameFilter] = useState<string[]>(
    eventsFilterInMemory.nameFilter ?? []
  )

  useEffect(() => {
    dispatch(setRootBreadcrumbAction(RootBreadcrumbConstants.EVENTS))
  }, [])

  useEffect(() => {
    loadTable()
  }, [selectedPage, pageSize, nameFilter, globalState.producer])

  const loadTable = () => {
    setIsLoading(true)
    getEventList(selectedPage, pageSize, nameFilter).then((result) => {
      if (result) {
        setEventList(result.items)
        setTotalPages(result.totalPages)
        setTotalItems(result.totalItems)
      }
      setIsLoading(false)
    })
  }

  const closeDeleteEventModal = (reloadTable: boolean) => {
    setEventToDelete(undefined)
    if (reloadTable) loadTable()
  }

  const applyFilter = (nameFilter: string[]) => {
    setSelectedPage(1)
    setNameFilter(nameFilter)
    dispatch(setEventsFilterAction(1, pageSize, nameFilter))
  }

  const clearFilter = () => {
    if (nameFilter.length > 0) {
      setNameFilter([])
      setSelectedPage(1)
      dispatch(clearEventsFilterAction())
    }
  }

  const changePage = (page: number) => {
    dispatch(setEventsFilterAction(page, pageSize, nameFilter))
    setSelectedPage(page)
  }

  const changePageSize = (pageSize: number) => {
    dispatch(setEventsFilterAction(selectedPage, pageSize, nameFilter))
    setPageSize(pageSize)
  }

  return (
    <>
      <EventsTable
        eventList={eventList}
        totalPages={totalPages}
        selectedPage={selectedPage}
        totalItems={totalItems}
        pageSize={pageSize}
        isLoading={isLoading}
        onSelectDeleteEvent={setEventToDelete}
        onChangePage={changePage}
        onChangePageSize={changePageSize}
        onApplyFilter={applyFilter}
        onClearFilter={clearFilter}
      />
    </>
  )
}

export default Events
