import React, { useState } from 'react'

import LogoTicketP from '@assets/VisualElements/LogoTicketP'
import MainMenuItem from '@components/MainMenu/MainMenuItem'
import ProducerSelector from '@components/MainMenu/ProducerSelector'
import UserMenu from '@components/MainMenu/UserMenu/UserMenu'
import {
  MainMenuProducerItems,
  MainMenuGeneralItems
} from '@configuration/Constants'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider } from '@nextui-org/react'
import { RootState } from '@store'
import { useSelector } from 'react-redux'

const TopMenu = () => {
  const state = useSelector((state: RootState) => state.global)

  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div
      className={`default-bg-top h-screen w-full overflow-hidden transition-all duration-250 ease-in-out ${
        isExpanded ? 'max-h-screen' : 'max-h-[70px]'
      }`}
    >
      <div className="flex min-h-full flex-col justify-between bg-black/40">
        <div>
          <div className="flex h-[70px] w-full items-center justify-between">
            <div className="flex min-h-full flex-row items-center justify-start gap-0">
              <div className="size-[70px] p-3">
                <LogoTicketP fill="hsl(var(--nextui-white))" />
              </div>
              <ProducerSelector />
            </div>
            <div
              className="cursor-pointer pr-6"
              onClick={() => setIsExpanded((prevState) => !prevState)}
            >
              <FontAwesomeIcon className="text-white" size="xl" icon={faBars} />
            </div>
          </div>

          {state.producer && (
            <>
              <div className="flex w-full flex-col gap-0.5 px-4">
                {MainMenuProducerItems.map((item, index) => (
                  <MainMenuItem
                    key={index}
                    menuOption={item}
                    selectItem={() => setIsExpanded(false)}
                  />
                ))}
              </div>
              <div className="w-full px-4 py-1">
                <Divider className="bg-white/30" />
              </div>
            </>
          )}

          <div className="flex w-full flex-col gap-0.5 px-4">
            {MainMenuGeneralItems.map((item, index) => (
              <MainMenuItem
                key={index}
                menuOption={item}
                selectItem={() => setIsExpanded(false)}
              />
            ))}
          </div>
        </div>

        <div className="flex w-full flex-col gap-1 px-4 pb-24 pt-1">
          <Divider className="bg-white/30" />
          <UserMenu />
        </div>
      </div>
    </div>
  )
}

export default TopMenu
