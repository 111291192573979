import React from 'react'

import PartynerIcon from '@components/Shared/PartynerIcon'
import useTranslate from '@hooks/useTranslate'
import MainMenuOption from '@interfaces/MainMenuOption'
import { RootState } from '@store'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

interface MainMenuItemProps {
  menuOption: MainMenuOption
  selectItem?: () => void
}

const MainMenuItem = ({ menuOption, selectItem }: MainMenuItemProps) => {
  const translate = useTranslate('main_menu')
  const navigate = useNavigate()
  const breadcrumbs = useSelector((state: RootState) => state.breadcrumbs)

  const isActive = breadcrumbs.breadcrumbs[0]?.path === menuOption.destination

  return (
    <div
      className={`flex w-full cursor-pointer items-center justify-start gap-3 rounded-lg p-2 text-white ${isActive ? ' bg-white/30' : 'hover:bg-white/10'}`}
      onClick={() => {
        navigate(menuOption.destination)
        if (selectItem) {
          selectItem()
        }
      }}
    >
      <div className="flex size-9 items-center justify-center gap-2">
        <PartynerIcon
          frameColor="hsl(var(--nextui-white))"
          backgroundColor="transparent"
          roundSize="rounded"
        >
          {menuOption.icon}
        </PartynerIcon>
      </div>
      <div className="text-sm">{translate(menuOption.text)}</div>
    </div>
  )
}

export default MainMenuItem
