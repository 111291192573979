import { LocalStorageConstants } from '@configuration/Constants'
import AvailableProducerDto from '@dtos/AvailableProducerDto'
import SignInDto from '@dtos/SignInDto'
import TokenResponseDto from '@dtos/TokenResponseDto'
import UserDto from '@dtos/UserDto'
import ValidateTokenResponseDto from '@dtos/ValidateTokenResponseDto'
import useRootApiService from '@services/RootApi'

interface UpdateProducerTokenModel {
  authToken: string
  refreshToken: string
}

const useAuthService = () => {
  const { Get, Post, PostWithoutRefreshToken } = useRootApiService()

  const signInUser = async (
    signInDto: SignInDto
  ): Promise<TokenResponseDto | undefined> => {
    const response = await PostWithoutRefreshToken<TokenResponseDto, SignInDto>(
      '/user/sign-in',
      signInDto
    )

    return response.data
  }

  const updateTokenWithProducer = async (
    producerId: string
  ): Promise<TokenResponseDto | undefined> => {
    const authToken = localStorage.getItem(LocalStorageConstants.AUTH_TOKEN)
    const refreshToken = localStorage.getItem(
      LocalStorageConstants.REFRESH_TOKEN
    )

    if (!authToken || !refreshToken) return undefined

    const tokenModel: UpdateProducerTokenModel = {
      authToken,
      refreshToken
    }

    const response = await Post<TokenResponseDto, UpdateProducerTokenModel>(
      `/user/update-producer/${producerId}`,
      tokenModel
    )

    return response.data
  }

  const getUserProfile = async (): Promise<UserDto | undefined> => {
    const response = await Get<UserDto>('/user')

    return response.data
  }

  const getAvailableProducers = async (): Promise<
    AvailableProducerDto[] | undefined
  > => {
    const response = await Get<AvailableProducerDto[]>('/user/producers')

    return response.data
  }

  const validateToken = async (
    token: string
  ): Promise<ValidateTokenResponseDto | undefined> => {
    const response = await Post<ValidateTokenResponseDto, undefined>(
      '/token/validate',
      undefined,
      token
    )

    return response.data
  }

  return {
    signInUser,
    getUserProfile,
    getAvailableProducers,
    updateTokenWithProducer,
    validateToken
  }
}

export default useAuthService
