import {
  ProducersFilterAction,
  ProducersFilterActionTypes
} from '@actions/ProducersFilter'

export interface ProducersFilterState {
  page?: number
  pageSize?: number
  nameFilter?: string[]
}

const initialProducersFilterState: ProducersFilterState = {
  page: undefined,
  pageSize: undefined,
  nameFilter: undefined
}

const ProducersFilterReducer = (
  state: ProducersFilterState = initialProducersFilterState,
  action: ProducersFilterAction
) => {
  switch (action.type) {
    case ProducersFilterActionTypes.SET_PRODUCERS_FILTER:
      return {
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        nameFilter: action.payload.nameFilter
      }
    case ProducersFilterActionTypes.CLEAR_PRODUCERS_FILTER:
      return initialProducersFilterState
    default:
      return state
  }
}

export default ProducersFilterReducer
