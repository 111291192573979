import React, { Key, ReactNode } from 'react'

import PaginatedTableFooter from '@components/Shared/PaginatedTable/PaginatedTableFooter'
import PaginatedTableHeader from '@components/Shared/PaginatedTable/PaginatedTableHeader'
import PaginatedTableLoadingContent from '@components/Shared/PaginatedTable/PaginatedTableLoadingContent'
import useTranslate from '@hooks/useTranslate'
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow
} from '@nextui-org/react'

export interface PaginatedTableColumns {
  name: string
  uid: string
}

interface HasId {
  id: string
}

interface PaginatedTableProps<T extends HasId> {
  items: T[]
  columns: PaginatedTableColumns[]
  cellRenderer: (item: T, key: Key) => ReactNode
  totalPages: number
  selectedPage: number
  pageSize: number
  totalItems: number
  isLoading: boolean
  onChangePage: (page: number) => void
  onChangePageSize: (size: number) => void
  title: string
  filter?: ReactNode
  loadingContent?: ReactNode
  emptyContent?: ReactNode
  headerEndContent?: ReactNode
}

const PaginatedTable = <T extends HasId>({
  items,
  columns,
  cellRenderer,
  totalPages,
  selectedPage,
  pageSize,
  totalItems,
  isLoading,
  onChangePage,
  onChangePageSize,
  title,
  filter,
  loadingContent,
  emptyContent,
  headerEndContent
}: PaginatedTableProps<T>) => {
  const translate = useTranslate('components.shared.paginated_table')
  return (
    <Table
      fullWidth
      isStriped
      topContent={
        <PaginatedTableHeader
          title={title}
          totalItems={totalItems}
          endContent={headerEndContent}
          filter={filter}
        />
      }
      bottomContent={
        <PaginatedTableFooter
          totalPages={totalPages}
          selectedPage={selectedPage}
          pageSize={pageSize}
          onChangePage={(page) => onChangePage(page)}
          onChangePageSize={(size) => onChangePageSize(size)}
        />
      }
    >
      <TableHeader columns={columns}>
        {(column) => (
          <TableColumn
            key={column.uid}
            align={column.uid === 'actions' ? 'end' : 'start'}
          >
            {column.uid === 'actions' ? '' : column.name}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody
        isLoading={isLoading}
        loadingContent={loadingContent ?? <PaginatedTableLoadingContent />}
        items={items}
        emptyContent={
          emptyContent ?? (
            <div className="flex justify-center">{translate('empty_text')}</div>
          )
        }
      >
        {(item) => (
          <TableRow key={item.id}>
            {(columnKey) => (
              <TableCell>{cellRenderer(item, columnKey)}</TableCell>
            )}
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

export default PaginatedTable
