import React from 'react'

import defaultAvatar from '@assets/Images/default_artist_avatar.png'
import defaultAvatarDark from '@assets/Images/default_artist_avatar_dark.png'
import ConfirmDeleteVenueModalBodyInput from '@components/Venues/ConfirmDeleteVenueModal/ConfirmDeleteVenueModalBodyInput'
import ConfirmDeleteVenueModalBodyLogo from '@components/Venues/ConfirmDeleteVenueModal/ConfirmDeleteVenueModalBodyLogo'
import { useTheme } from '@configuration/ThemeContext'
import VenueDto from '@dtos/VenueDto'
import { ModalBody } from '@nextui-org/react'

interface ConfirmDeleteVenueModalBodyProps {
  venue?: VenueDto
  onConfirmDeleteVenue: () => void
}

const ConfirmDeleteVenueModalBody = ({
  venue,
  onConfirmDeleteVenue
}: ConfirmDeleteVenueModalBodyProps) => {
  const { isDarkMode } = useTheme()
  return (
    <ModalBody>
      <div className="flex flex-col items-center gap-3">
        <ConfirmDeleteVenueModalBodyLogo
          logoUrl={
            venue?.logoUrl
              ? venue.logoUrl
              : isDarkMode
                ? defaultAvatarDark
                : defaultAvatar
          }
          name={venue?.name}
        />
        <ConfirmDeleteVenueModalBodyInput
          name={venue?.name}
          onConfirmDeleteVenue={onConfirmDeleteVenue}
        />
      </div>
    </ModalBody>
  )
}

export default ConfirmDeleteVenueModalBody
