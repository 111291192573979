import React, { useEffect, useRef, useState } from 'react'

import { setRootBreadcrumbAction } from '@actions/Breadcrumb'
import { setIsLoadingAction } from '@actions/Global'
import EditProducerMainInfoForm from '@components/Producers/EditProducer/Forms/EditProducerMainInfoForm'
import FormFooter from '@components/Shared/FormFooter'
import EditPicturesListForm from '@components/Shared/Forms/EditPicutreListForm'
import EditSocialNetworkListForm from '@components/Shared/Forms/EditSocialNetworkListForm'
import { RootBreadcrumbConstants } from '@configuration/Constants'
import PictureDto from '@dtos/PictureDto'
import ProducerDto from '@dtos/ProducerDto'
import { ProducerMainInfoFormInitialValues } from '@formSchemas/Producer'
import {
  UpdatePictureListFormValuesInitialValues,
  UpdateSocialNetworkListFormValuesInitialValues
} from '@formSchemas/Shared'
import usePageTitleTranslation from '@hooks/usePageTitleTranslation'
import useTranslate from '@hooks/useTranslate'
import CreateUpdateProducerMainInfo from '@interfaces/Forms/Producer/CreateUpdateProducerMainInfo'
import UpdatePictureListFormValues from '@interfaces/Forms/Shared/UpdatePictureListFormValues'
import UpdateSocialNetworkListFormValues from '@interfaces/Forms/Shared/UpdateSocialNetworkListFormValues'
import useProducerService from '@services/Producer'
import { FormikProps } from 'formik'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const EditProducer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const translate = useTranslate('components.producers.edit_producer')

  const { id } = useParams<{ id: string | undefined }>()
  const { getProducerById } = useProducerService()
  const { getPageTitle } = usePageTitleTranslation()
  const { updateProducerPhotoBatch, updateProducerSocialNetworkListBatch } =
    useProducerService()

  const [isSavingData, setIsSavingData] = useState(false)
  const [producer, setProducer] = useState<ProducerDto | undefined>(undefined)
  const [producerPictures, setProducerPictures] = useState<
    PictureDto[] | undefined
  >(undefined)

  const [mainInfoFormKey, setMainInfoFormKey] = useState(0)
  const [socialNetworkListFormKey, setSocialNetworkListFormKey] = useState(0)
  const [picturesFormKey, setPicturesFormKey] = useState(0)

  const [mainInfoFormValues, setMainInfoFormValues] =
    useState<CreateUpdateProducerMainInfo>(ProducerMainInfoFormInitialValues)
  const [socialNetworkListFormValues, setSocialNetworkListFormValues] =
    useState<UpdateSocialNetworkListFormValues>(
      UpdateSocialNetworkListFormValuesInitialValues
    )
  const [picturesFormValues, setPicturesFormValues] =
    useState<UpdatePictureListFormValues>(
      UpdatePictureListFormValuesInitialValues
    )

  const mainInfoFormRef =
    useRef<FormikProps<CreateUpdateProducerMainInfo>>(null)
  const picturesFormRef = useRef<FormikProps<UpdatePictureListFormValues>>(null)
  const socialNetworkListFormRef =
    useRef<FormikProps<UpdateSocialNetworkListFormValues>>(null)

  const loadProducer = async (id: string) => {
    dispatch(setIsLoadingAction(true))
    const response = await getProducerById(id)
    if (response) {
      setBreadcrumbs(response)
      setProducer(response)
    } else {
      navigate('/not-found')
      dispatch(setIsLoadingAction(false))
    }
  }

  const setBreadcrumbs = (breadcrumbData?: ProducerDto) => {
    if (breadcrumbData) {
      dispatch(
        setRootBreadcrumbAction(RootBreadcrumbConstants.PRODUCERS, [
          {
            path: `/producers/${breadcrumbData.id}`,
            title: breadcrumbData.name
          }
        ])
      )
    } else {
      dispatch(
        setRootBreadcrumbAction(RootBreadcrumbConstants.PRODUCERS, [
          { path: '/producers/add', title: getPageTitle('add_producer') }
        ])
      )
    }
  }

  const saveMainForm = async () => {
    if (mainInfoFormRef.current) {
      await mainInfoFormRef.current.setFieldValue('producerId', id)
      await mainInfoFormRef.current.validateForm()
    }

    setTimeout(async () => {
      if (mainInfoFormRef.current && mainInfoFormRef.current.isValid) {
        dispatch(setIsLoadingAction(true))
        setIsSavingData(true)
        await mainInfoFormRef.current.submitForm()
      }
    }, 0)
  }

  const saveSocialNetworksForm = async (updatedProducer: ProducerDto) => {
    if (socialNetworkListFormRef.current) {
      await socialNetworkListFormRef.current.setFieldValue(
        'entityId',
        updatedProducer.id
      )
      setTimeout(async () => {
        await socialNetworkListFormRef.current!.submitForm()
      }, 0)
    }
  }

  const savePictureForm = async (updatedProducer: ProducerDto) => {
    if (picturesFormRef.current) {
      await picturesFormRef.current.setFieldValue(
        'entityId',
        updatedProducer.id
      )
      setTimeout(async () => {
        await picturesFormRef.current!.submitForm()
      }, 0)
    }
  }

  const finishSavingForms = (updatedProducer: ProducerDto) => {
    if (updatedProducer) {
      setIsSavingData(false)
      setProducer(updatedProducer)
      navigate(`/producers/${updatedProducer.id}`, { replace: true })
      toast.success(translate('data_saved'))
    }
  }

  useEffect(() => {
    if (id) loadProducer(id)
    setBreadcrumbs()
  }, [id])

  useEffect(() => {
    if (!isSavingData && producer) {
      setMainInfoFormValues({
        name: producer.name,
        description: producer.description,
        logo: { base64image: '', imageType: '' },
        producerId: producer.id
      })
      setPicturesFormValues({
        pictures: [],
        entityId: producer.id
      })
      setSocialNetworkListFormValues({
        links: producer.socialNetworkLinks,
        entityId: producer.id
      })
      setProducerPictures(producer.photos)
    }
    setMainInfoFormKey((prevKey) => prevKey + 1)
    setSocialNetworkListFormKey((prevKey) => prevKey + 1)
    setPicturesFormKey((prevKey) => prevKey + 1)
    dispatch(setIsLoadingAction(false))
  }, [producer])

  return (
    <div className="flex grow flex-col gap-3">
      <EditProducerMainInfoForm
        values={mainInfoFormValues}
        formRef={mainInfoFormRef}
        formKey={mainInfoFormKey}
        originalLogoUrl={producer?.logoUrl}
        onSaveForm={async (values) => await saveSocialNetworksForm(values)}
      />
      <EditSocialNetworkListForm
        values={socialNetworkListFormValues}
        formRef={socialNetworkListFormRef}
        formKey={socialNetworkListFormKey}
        updateSocialNetworkService={updateProducerSocialNetworkListBatch}
        onSaveForm={async (values) => await savePictureForm(values)}
      />
      <EditPicturesListForm
        values={picturesFormValues}
        formRef={picturesFormRef}
        formKey={picturesFormKey}
        onSaveForm={finishSavingForms}
        existingPictures={producerPictures}
        updatePicturesService={updateProducerPhotoBatch}
      />
      <FormFooter
        showCancelButton={!!id}
        onSelectCancel={() => loadProducer(id!)}
        onSelectSave={async () => await saveMainForm()}
        onGoBack={() => navigate('/producers')}
      />
    </div>
  )
}

export default EditProducer
