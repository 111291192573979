import {
  VenuesFilterAction,
  VenuesFilterActionTypes
} from '@actions/VenuesFilter'

export interface VenuesFilterState {
  page?: number
  pageSize?: number
  nameFilter?: string[]
  streetFilter?: string[]
  neighborhoodFilter?: string[]
  postalCodeFilter?: string[]
  cityFilter?: string[]
  stateFilter?: string[]
  countryFilter?: string[]
}

const initialVenuesFilterState: VenuesFilterState = {
  page: undefined,
  pageSize: undefined,
  nameFilter: undefined,
  streetFilter: undefined,
  neighborhoodFilter: undefined,
  postalCodeFilter: undefined,
  cityFilter: undefined,
  stateFilter: undefined,
  countryFilter: undefined
}

const VenuesFilterReducer = (
  state: VenuesFilterState = initialVenuesFilterState,
  action: VenuesFilterAction
) => {
  switch (action.type) {
    case VenuesFilterActionTypes.SET_VENUES_FILTER:
      return {
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        nameFilter: action.payload.nameFilter,
        streetFilter: action.payload.streetFilter,
        neighborhoodFilter: action.payload.neighborhoodFilter,
        postalCodeFilter: action.payload.postalCodeFilter,
        cityFilter: action.payload.cityFilter,
        stateFilter: action.payload.stateFilter,
        countryFilter: action.payload.countryFilter
      }
    case VenuesFilterActionTypes.CLEAR_VENUES_FILTER:
      return initialVenuesFilterState
    default:
      return state
  }
}

export default VenuesFilterReducer
