import React from 'react'

import { setIsLoadingAction } from '@actions/Global'
import ConfirmDeleteArtistModalBody from '@components/Artists/ConfirmDeleteArtistModal/ConfirmDeleteArtistModalBody'
import ConfirmDeleteArtistModalHeader from '@components/Artists/ConfirmDeleteArtistModal/ConfirmDeleteArtistModalHeader'
import { useTheme } from '@configuration/ThemeContext'
import ArtistDto from '@dtos/ArtistDto'
import useTranslate from '@hooks/useTranslate'
import { Modal, ModalContent } from '@nextui-org/react'
import useArtistService from '@services/Artist'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

interface ConfirmDeleteArtistModalProps {
  artist?: ArtistDto
  onClose: (reloadList: boolean) => void
}

const ConfirmDeleteArtistModal = ({
  artist,
  onClose
}: ConfirmDeleteArtistModalProps) => {
  const { isDarkMode } = useTheme()
  const { deleteArtist } = useArtistService()
  const dispatch = useDispatch()
  const translate = useTranslate('components.artists.remove_artist')

  const removeArtist = async () => {
    if (!artist) return

    dispatch(setIsLoadingAction(true))
    const response = await deleteArtist(artist.id)

    if (response) {
      toast.success(translate('artist_removed'))
    } else {
      toast.error(translate('error_on_remove'))
    }
    dispatch(setIsLoadingAction(false))

    onClose(true)
  }

  return (
    <Modal
      isOpen={!!artist}
      onClose={() => onClose(false)}
      backdrop={'blur'}
      isDismissable={true}
      size="sm"
      classNames={{
        header:
          'flex flex-col gap-1text-center justify-center font-semibold text-2xl text-danger',
        base: `${isDarkMode ? 'dark text-foreground' : ''} text-center`
      }}
    >
      <ModalContent>
        <ConfirmDeleteArtistModalHeader />
        <ConfirmDeleteArtistModalBody
          artist={artist}
          onConfirmDeleteArtist={async () => await removeArtist()}
        />
      </ModalContent>
    </Modal>
  )
}

export default ConfirmDeleteArtistModal
