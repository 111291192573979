import React, { Ref } from 'react'

import { setIsLoadingAction } from '@actions/Global'
import EditEventLineupItem from '@components/Events/EditEvent/Lineup/EditEventLineupItem'
import CreateUpdateLineupArtistDto from '@dtos/CreateUpdateLineupArtistDto'
import LineupDto from '@dtos/LineupDto'
import { EventLineupFormValidationSchema } from '@formSchemas/Event'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import CreateUpdateEventLineup from '@interfaces/Forms/Event/CreateUpdateEventLineup'
import { Button, Card, CardBody, CardHeader } from '@nextui-org/react'
import useEventService from '@services/Event'
import useLineupService from '@services/Lineup'
import { Formik, FormikProps } from 'formik'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'

interface EditEventLineupFormProps {
  values: CreateUpdateEventLineup
  formRef: Ref<FormikProps<CreateUpdateEventLineup>>
  formKey: number
  onSaveForm: (eventId: string) => void
}

const EditEventLineupForm = ({
  values,
  formRef,
  formKey,
  onSaveForm
}: EditEventLineupFormProps) => {
  const dispatch = useDispatch()
  const translate = useTranslate('components.events.edit_event')
  const errorsTranslate = useTranslate('errors')

  const { createLineup, updateLineup, updateLineupArtists } = useLineupService()
  const { updateEventLineups } = useEventService()

  const handleError = () => {
    dispatch(setIsLoadingAction(false))
    toast.error(errorsTranslate('general'))
  }

  const saveForm = async (form: CreateUpdateEventLineup) => {
    const lineupIds: string[] = []
    await Promise.all(
      form.lineup.map(async (lineupForm) => {
        let response: LineupDto | undefined
        if (lineupForm.isNew) {
          response = await createLineup({ date: lineupForm.date! })
          if (!response) return handleError()
        } else {
          response = await updateLineup(lineupForm.id, {
            date: lineupForm.date!
          })
          if (!response) return handleError()
        }

        const artistsResponse = await updateLineupArtists(
          response.id,
          lineupForm.artists
        )
        if (!artistsResponse) return handleError()

        lineupIds.push(response.id)
      })
    )

    const eventResponse = await updateEventLineups(form.eventId!, lineupIds)
    if (!eventResponse) return handleError()

    if (form.eventId) {
      onSaveForm(form.eventId)
    }
  }

  const removeArtist = (
    form: FormikProps<CreateUpdateEventLineup>,
    lineupIndex: number,
    artistId: string
  ) => {
    const lineup = form.values.lineup[lineupIndex]
    lineup.artists = lineup.artists.filter(
      (artist) => artist.artistId !== artistId
    )
    form.setFieldValue(`lineup[${lineupIndex}]`, lineup)
  }

  const addArtist = (
    form: FormikProps<CreateUpdateEventLineup>,
    lineupIndex: number,
    artistId: string,
    order: number
  ) => {
    const artist = {
      artistId: artistId,
      order: order
    }

    const updatedArtists = [...form.values.lineup[lineupIndex].artists, artist]

    const updatedLineup = {
      ...form.values.lineup[lineupIndex],
      artists: updatedArtists
    }

    const updatedLineups = form.values.lineup.map((lineup, idx) =>
      idx === lineupIndex ? updatedLineup : lineup
    )

    form.setFieldValue('lineup', updatedLineups)
  }

  const changeLineupDate = (
    form: FormikProps<CreateUpdateEventLineup>,
    lineupIndex: number,
    date: string | undefined
  ) => form.setFieldValue(`lineup[${lineupIndex}].date`, date)

  const addLineup = (form: FormikProps<CreateUpdateEventLineup>) => {
    form.setFieldValue('lineup', [
      ...form.values.lineup,
      { id: uuidv4(), isNew: true, artists: [] }
    ])
  }

  const removeLineup = (
    form: FormikProps<CreateUpdateEventLineup>,
    lineupId: string
  ) => {
    form.setFieldValue('lineup', [
      ...form.values.lineup.filter((lineup) => lineup.id !== lineupId)
    ])
  }

  const reorderArtists = (
    form: FormikProps<CreateUpdateEventLineup>,
    lineupIndex: number,
    artists: CreateUpdateLineupArtistDto[]
  ) => {
    const newOrder: CreateUpdateLineupArtistDto[] = []

    artists.map((artist) =>
      newOrder.push({
        artistId: artist.artistId,
        time: artist.time,
        order: artist.order
      })
    )
    form.setFieldValue(`lineup[${lineupIndex}].artists`, newOrder)
  }

  return (
    <Formik
      innerRef={formRef}
      key={formKey}
      initialValues={values}
      onSubmit={async (values) => await saveForm(values)}
      validationSchema={EventLineupFormValidationSchema}
    >
      {(props: FormikProps<CreateUpdateEventLineup>) => (
        <form onSubmit={props.handleSubmit} noValidate>
          <Card fullWidth>
            <CardHeader className="flex w-full justify-between">
              <div className="pl-2 text-lg font-bold text-foreground-500">
                {translate('lineup')}
              </div>
              <div className="flex justify-end gap-3">
                <Button
                  isIconOnly
                  size="sm"
                  radius="full"
                  color="success"
                  onClick={() => addLineup(props)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <div className="flex size-full flex-col justify-center gap-4 align-middle">
                {props.values.lineup.length === 0 && (
                  <div className="mb-3 flex w-full justify-center text-small opacity-40">
                    {translate('no_lineup_added')}
                  </div>
                )}
                {props.values.lineup.map((lineup, index) => (
                  <EditEventLineupItem
                    key={lineup.id}
                    lineup={lineup}
                    onRemoveArtist={(artistId) =>
                      removeArtist(props, index, artistId)
                    }
                    onAddArtist={(artistId, order) =>
                      addArtist(props, index, artistId, order)
                    }
                    onChangeLineupDate={(date) =>
                      changeLineupDate(props, index, date)
                    }
                    onRemoveLineup={() => removeLineup(props, lineup.id)}
                    onReorderArtists={(artists) =>
                      reorderArtists(props, index, artists)
                    }
                  />
                ))}
              </div>
            </CardBody>
          </Card>
        </form>
      )}
    </Formik>
  )
}

export default EditEventLineupForm
