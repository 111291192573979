import React from 'react'

import { setIsLoadingAction } from '@actions/Global'
import ConfirmDeleteVenueModalBody from '@components/Venues/ConfirmDeleteVenueModal/ConfirmDeleteVenueModalBody'
import ConfirmDeleteVenueModalHeader from '@components/Venues/ConfirmDeleteVenueModal/ConfirmDeleteVenueModalHeader'
import { useTheme } from '@configuration/ThemeContext'
import VenueDto from '@dtos/VenueDto'
import useTranslate from '@hooks/useTranslate'
import { Modal, ModalContent } from '@nextui-org/react'
import useVenueService from '@services/Venue'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

interface ConfirmDeleteVenueModalProps {
  venue?: VenueDto
  onClose: (reloadList: boolean) => void
}

const ConfirmDeleteVenueModal = ({
  venue,
  onClose
}: ConfirmDeleteVenueModalProps) => {
  const { isDarkMode } = useTheme()
  const { deleteVenue } = useVenueService()
  const dispatch = useDispatch()
  const translate = useTranslate('components.venues.remove_venue')

  const removeVenue = async () => {
    if (!venue) return

    dispatch(setIsLoadingAction(true))
    const response = await deleteVenue(venue.id)

    if (response) {
      toast.success(translate('venue_removed'))
    } else {
      toast.error(translate('error_on_remove'))
    }
    dispatch(setIsLoadingAction(false))

    onClose(true)
  }

  return (
    <Modal
      isOpen={!!venue}
      onClose={() => onClose(false)}
      backdrop={'blur'}
      isDismissable={true}
      size="sm"
      classNames={{
        header:
          'flex flex-col gap-1text-center justify-center font-semibold text-2xl text-danger',
        base: `${isDarkMode ? 'dark text-foreground' : ''} text-center`
      }}
    >
      <ModalContent>
        <ConfirmDeleteVenueModalHeader />
        <ConfirmDeleteVenueModalBody
          venue={venue}
          onConfirmDeleteVenue={async () => await removeVenue()}
        />
      </ModalContent>
    </Modal>
  )
}

export default ConfirmDeleteVenueModal
