import React, { useEffect, useState } from 'react'

import { setRootBreadcrumbAction } from '@actions/Breadcrumb'
import {
  clearVenuesFilterAction,
  setVenuesFilterAction
} from '@actions/VenuesFilter'
import ConfirmDeleteVenueModal from '@components/Venues/ConfirmDeleteVenueModal/ConfirmDeleteVenueModal'
import VenuesTable from '@components/Venues/VenuesTable/VenuesTable'
import { RootBreadcrumbConstants } from '@configuration/Constants'
import VenueDto from '@dtos/VenueDto'
import useVenueService from '@services/Venue'
import { RootState } from '@store'
import { useDispatch, useSelector } from 'react-redux'

const Venues = () => {
  const dispatch = useDispatch()
  const { getVenueList } = useVenueService()
  const venuesFilterInMemory = useSelector(
    (state: RootState) => state.venuesFilter
  )
  const [venueList, setVenueList] = useState<VenueDto[]>([])
  const [venueToDelete, setVenueToDelete] = useState<VenueDto | undefined>(
    undefined
  )

  const [totalPages, setTotalPages] = useState<number>(0)
  const [selectedPage, setSelectedPage] = useState<number>(
    venuesFilterInMemory.page ?? 1
  )
  const [pageSize, setPageSize] = useState<number>(
    venuesFilterInMemory.pageSize ?? 10
  )
  const [totalItems, setTotalItems] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)

  const [nameFilter, setNameFilter] = useState<string[]>(
    venuesFilterInMemory.nameFilter ?? []
  )

  const [streetFilter, setStreetFilter] = useState<string[]>(
    venuesFilterInMemory.streetFilter ?? []
  )

  const [neighborhoodFilter, setNeighborhoodFilter] = useState<string[]>(
    venuesFilterInMemory.neighborhoodFilter ?? []
  )

  const [postalCodeFilter, setPostalCodeFilter] = useState<string[]>(
    venuesFilterInMemory.postalCodeFilter ?? []
  )

  const [cityFilter, setCityFilter] = useState<string[]>(
    venuesFilterInMemory.cityFilter ?? []
  )

  const [stateFilter, setStateFilter] = useState<string[]>(
    venuesFilterInMemory.stateFilter ?? []
  )

  const [countryFilter, setCountryFilter] = useState<string[]>(
    venuesFilterInMemory.countryFilter ?? []
  )

  useEffect(() => {
    dispatch(setRootBreadcrumbAction(RootBreadcrumbConstants.VENUES))
  }, [])

  useEffect(() => {
    loadTable()
  }, [
    selectedPage,
    pageSize,
    nameFilter,
    streetFilter,
    neighborhoodFilter,
    postalCodeFilter,
    cityFilter,
    countryFilter
  ])

  const loadTable = () => {
    setIsLoading(true)
    getVenueList(
      selectedPage,
      pageSize,
      nameFilter,
      streetFilter,
      neighborhoodFilter,
      postalCodeFilter,
      cityFilter,
      stateFilter,
      countryFilter
    ).then((result) => {
      if (result) {
        setVenueList(result.items)
        setTotalPages(result.totalPages)
        setTotalItems(result.totalItems)
      }
      setIsLoading(false)
    })
  }

  const closeDeleteVenueModal = (reloadTable: boolean) => {
    setVenueToDelete(undefined)
    if (reloadTable) loadTable()
  }

  const applyFilter = (
    nameFilter: string[],
    streetFilter: string[],
    neighborhoodFilter: string[],
    postalCodeFilter: string[],
    cityFilter: string[],
    stateFilter: string[],
    countryFilter: string[]
  ) => {
    setSelectedPage(1)
    setNameFilter(nameFilter)
    setStreetFilter(streetFilter)
    setNeighborhoodFilter(neighborhoodFilter)
    setPostalCodeFilter(postalCodeFilter)
    setCityFilter(cityFilter)
    setStateFilter(stateFilter)
    setCountryFilter(countryFilter)
    dispatch(
      setVenuesFilterAction(
        1,
        pageSize,
        nameFilter,
        streetFilter,
        neighborhoodFilter,
        postalCodeFilter,
        cityFilter,
        stateFilter,
        countryFilter
      )
    )
  }

  const clearFilter = () => {
    if (
      nameFilter.length > 0 ||
      streetFilter.length > 0 ||
      neighborhoodFilter.length > 0 ||
      postalCodeFilter.length > 0 ||
      cityFilter.length > 0 ||
      stateFilter.length > 0 ||
      countryFilter.length > 0
    ) {
      setNameFilter([])
      setStreetFilter([])
      setNeighborhoodFilter([])
      setPostalCodeFilter([])
      setCityFilter([])
      setStateFilter([])
      setCountryFilter([])
      setSelectedPage(1)
      dispatch(clearVenuesFilterAction())
    }
  }

  const changePage = (page: number) => {
    dispatch(
      setVenuesFilterAction(
        page,
        pageSize,
        nameFilter,
        streetFilter,
        neighborhoodFilter,
        postalCodeFilter,
        cityFilter,
        stateFilter,
        countryFilter
      )
    )
    setSelectedPage(page)
  }

  const changePageSize = (pageSize: number) => {
    dispatch(
      setVenuesFilterAction(
        selectedPage,
        pageSize,
        nameFilter,
        streetFilter,
        neighborhoodFilter,
        postalCodeFilter,
        cityFilter,
        stateFilter,
        countryFilter
      )
    )
    setPageSize(pageSize)
  }

  return (
    <>
      <ConfirmDeleteVenueModal
        venue={venueToDelete}
        onClose={(reloadTable) => closeDeleteVenueModal(reloadTable)}
      />
      <VenuesTable
        venueList={venueList}
        totalPages={totalPages}
        selectedPage={selectedPage}
        totalItems={totalItems}
        pageSize={pageSize}
        isLoading={isLoading}
        onSelectDeleteVenue={setVenueToDelete}
        onChangePage={changePage}
        onChangePageSize={changePageSize}
        onApplyFilter={applyFilter}
        onClearFilter={clearFilter}
      />
    </>
  )
}

export default Venues
