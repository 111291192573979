import React, { Ref } from 'react'

import { setIsLoadingAction } from '@actions/Global'
import ChangeableAvatar from '@components/Shared/ChangeableAvatar'
import CountrySelector from '@components/Shared/CountrySelector'
import InputField from '@components/Shared/InputField'
import LocalizableTextArea from '@components/Shared/LocalizableTextArea/LocalizableTextArea'
import ArtistDto from '@dtos/ArtistDto'
import CreateUpdateArtistDto from '@dtos/CreateUpdateArtistDto'
import PictureUploadDto from '@dtos/PictureUploadDto'
import { ArtistMainInfoFormValidationSchema } from '@formSchemas/Artist'
import { LocalizedStringInitialValues } from '@formSchemas/Shared'
import useTranslate from '@hooks/useTranslate'
import CreateUpdateArtistMainInfo from '@interfaces/Forms/Artist/CreateUpdateArtistMainInfo'
import { Card, CardBody, CardHeader } from '@nextui-org/react'
import useArtistService from '@services/Artist'
import { Formik, FormikProps } from 'formik'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

interface EditArtistMainInfoFormProps {
  values: CreateUpdateArtistMainInfo
  formRef: Ref<FormikProps<CreateUpdateArtistMainInfo>>
  formKey: number
  onSaveForm: (artist: ArtistDto) => void
  originalAvatarUrl?: string
}

const EditArtistMainInfoForm = ({
  values,
  formRef,
  formKey,
  onSaveForm,
  originalAvatarUrl
}: EditArtistMainInfoFormProps) => {
  const translate = useTranslate('components.artists.edit_artist')
  const errorsTranslate = useTranslate('errors')
  const dispatch = useDispatch()

  const { createArtist, updateArtist, updateArtistAvatar } = useArtistService()

  const changeArtistAvatar = (
    formikProps: FormikProps<CreateUpdateArtistMainInfo>,
    avatar: PictureUploadDto | null
  ) => formikProps.setFieldValue('avatar', avatar)

  const changeArtistCountry = (
    formikProps: FormikProps<CreateUpdateArtistMainInfo>,
    country: string
  ) => formikProps.setFieldValue('country', country)

  const addArtistDescription = (
    formikProps: FormikProps<CreateUpdateArtistMainInfo>,
    culture: string
  ) => {
    if (
      !formikProps.values.description
        .map((description) => description.culture)
        .includes(culture)
    ) {
      formikProps.setFieldValue('description', [
        ...formikProps.values.description,
        { ...LocalizedStringInitialValues(culture) }
      ])
    }
  }

  const removeArtistDescription = (
    formikProps: FormikProps<CreateUpdateArtistMainInfo>,
    culture: string
  ) => {
    if (
      formikProps.values.description
        .map((description) => description.culture)
        .includes(culture)
    ) {
      formikProps.setFieldValue('description', [
        ...formikProps.values.description.filter(
          (description) => description.culture !== culture
        )
      ])
    }
  }

  const handleError = () => {
    dispatch(setIsLoadingAction(false))
    toast.error(errorsTranslate('general'))
  }

  const saveForm = async (form: CreateUpdateArtistMainInfo) => {
    const artistDto: CreateUpdateArtistDto = {
      name: form.name,
      description: form.description,
      country: form.country
    }

    artistDto.description.forEach((description) => (description.id = undefined))

    let response

    if (form.artistId) {
      response = await updateArtist(form.artistId, artistDto)
    } else {
      response = await createArtist(artistDto)
    }

    if (!response) return handleError()

    if (form.avatar) {
      const avatarResponse = await saveAvatar(response.id, form.avatar)
      if (!avatarResponse) return handleError()
      return onSaveForm(avatarResponse)
    }

    return onSaveForm(response)
  }

  const saveAvatar = async (
    artistId: string,
    avatar: PictureUploadDto
  ): Promise<ArtistDto | undefined> => {
    const dataIndex = avatar.base64image.indexOf(',')
    avatar.base64image = avatar.base64image.substring(dataIndex + 1)
    const avatarResponse = await updateArtistAvatar(artistId, avatar)
    if (avatarResponse) return avatarResponse
  }

  return (
    <Card fullWidth>
      <CardHeader>
        <div className="pl-2 text-lg font-semibold text-foreground-500">
          {translate('main_info')}
        </div>
      </CardHeader>
      <Formik
        innerRef={formRef}
        key={formKey}
        initialValues={values}
        onSubmit={async (values) => await saveForm(values)}
        validationSchema={ArtistMainInfoFormValidationSchema}
      >
        {(props: FormikProps<CreateUpdateArtistMainInfo>) => (
          <form onSubmit={props.handleSubmit} noValidate>
            <CardBody>
              <div className="flex flex-col items-center gap-2 lg:flex-row">
                <ChangeableAvatar
                  onChangeAvatar={(avatar) => changeArtistAvatar(props, avatar)}
                  name={props.values.name}
                  originalAvatarUrl={originalAvatarUrl ?? ''}
                />
                <div className="flex w-full grow flex-col gap-3">
                  <div className="flex w-full grow flex-col gap-3 lg:flex-row">
                    <InputField
                      id="name"
                      name="name"
                      type="text"
                      label={translate('name_placeholder')}
                    />
                    <CountrySelector
                      multiSelect={false}
                      selectedValues={[props.values.country]}
                      onSingleSelect={(country) =>
                        changeArtistCountry(props, country)
                      }
                    />
                  </div>
                  <LocalizableTextArea
                    fieldName={'description'}
                    label={translate('description')}
                    placeholder={translate('description')}
                    values={props.values.description}
                    onAddValue={(language) =>
                      addArtistDescription(props, language)
                    }
                    onRemoveValue={(language) =>
                      removeArtistDescription(props, language)
                    }
                  />
                </div>
              </div>
            </CardBody>
          </form>
        )}
      </Formik>
    </Card>
  )
}

export default EditArtistMainInfoForm
