import React from 'react'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import { Button, CardHeader } from '@nextui-org/react'

import EditSocialNetworkListDropdown from './EditSocialNetworkListDropdown'

interface EditSocialNetworkListHeaderProps {
  onAddSocialNetwork: (name: string) => void
}

const EditSocialNetworkListHeader = ({
  onAddSocialNetwork
}: EditSocialNetworkListHeaderProps) => {
  const translate = useTranslate('components.shared.edit_social_network_list')

  return (
    <CardHeader className="flex w-full justify-between">
      <div className="pl-2 text-lg font-semibold text-foreground-500">
        {translate('social_networks')}
      </div>
      <div className="flex justify-end gap-3">
        <EditSocialNetworkListDropdown onAddSocialNetwork={onAddSocialNetwork}>
          <Button isIconOnly size="sm" radius="full" color="success">
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </EditSocialNetworkListDropdown>
      </div>
    </CardHeader>
  )
}

export default EditSocialNetworkListHeader
