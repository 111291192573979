import React from 'react'

interface StarIconProps {
  fill?: string
  fullSize?: boolean
}

const StarIcon = ({ fill, fullSize }: StarIconProps) => {
  return (
    <div
      className={`flex size-1/2 items-center justify-center align-middle ${fullSize ? 'size-full' : 'size-1/2'}`}
    >
      <svg
        fill={fill ?? 'currentColor'}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="36.16 30.32 63.02 71.73"
        width="100%"
        height="100%"
      >
        <path
          d="M56.4,86.64h0c-.45,0-.81-.37-.81-.81-.02-3.74-.14-6.78-.37-9.12-.24-2.51-.73-4.93-1.44-7.24-.75-2.32-1.81-4.21-3.18-5.68-2.97-2.97-7.52-4.52-13.64-4.64-.44,0-.8-.36-.8-.8h0c0-.43.35-.78.78-.8,5.03-.24,8.8-1.06,11.34-2.44,2.66-1.45,4.53-3.91,5.6-7.37.72-2.25,1.18-4.59,1.39-7.02.2-2.28.3-5.48.32-9.58,0-.45.36-.82.81-.82h0c.44,0,.8.37.81.81.03,5.86.35,10.66.98,14.41.39,2.32,1.03,4.13,1.91,5.43,1.47,2.25,3.47,3.92,6,4.99,2.38,1.01,5.62,1.55,9.74,1.61.44,0,.81.36.81.8h0c0,.43-.34.78-.77.81-5.03.31-8.8,1.2-11.32,2.67-2.64,1.55-4.52,4.12-5.63,7.71-.72,2.41-1.18,4.8-1.39,7.15-.2,2.2-.3,5.24-.32,9.12,0,.45-.36.81-.81.81ZM71.94,83.74v-.02c0-.44.36-.8.8-.81,2.63-.06,4.73-.41,6.32-1.07,1.75-.72,3.01-1.92,3.79-3.62.78-1.66,1.3-3.35,1.54-5.07.22-1.57.35-3.82.36-6.75,0-.45.36-.81.81-.81h0c.45,0,.8.37.81.81.02,2.93.14,5.18.36,6.75.24,1.71.76,3.4,1.54,5.07.78,1.7,2.05,2.9,3.79,3.62,1.59.65,3.69,1.01,6.32,1.07.44,0,.8.36.8.81v.02c0,.44-.36.79-.8.8-5.25.14-8.59,1.74-10.02,4.79-.82,1.63-1.35,3.34-1.62,5.14-.24,1.64-.37,3.9-.39,6.77,0,.44-.36.81-.81.81h0c-.44,0-.8-.37-.81-.81-.02-2.84-.14-5.09-.36-6.75-.24-1.81-.76-3.53-1.54-5.16-.85-1.7-2.15-2.92-3.92-3.67-1.6-.68-3.66-1.05-6.2-1.12-.44-.01-.8-.36-.8-.81Z"
          transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 2.220446049250313e-16, -1.7763568394002505e-15)"
        />
      </svg>
    </div>
  )
}

export default StarIcon
