import UserDto from '@dtos/UserDto'

export enum GlobalActionTypes {
  SET_LOGGED_USER = 'SET_LOGGED_USER',
  SET_IS_LOADING = 'SET_IS_LOADING',
  SET_IS_LOADING_USER = 'SET_IS_LOADING_USER',
  SET_THEME = 'SET_THEME',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_PRODUCER = 'SET_PRODUCER'
}

export const setLoggedUserAction = (user: UserDto | undefined) => ({
  type: GlobalActionTypes.SET_LOGGED_USER as const,
  payload: user
})

export const setIsLoadingAction = (isLoading: boolean) => ({
  type: GlobalActionTypes.SET_IS_LOADING as const,
  payload: isLoading
})

export const setIsLoadingUserAction = (isLoadingUser: boolean) => ({
  type: GlobalActionTypes.SET_IS_LOADING_USER as const,
  payload: isLoadingUser
})

export const setThemeAction = (theme: string) => ({
  type: GlobalActionTypes.SET_THEME as const,
  payload: theme
})

export const setLanguageAction = (language: string) => ({
  type: GlobalActionTypes.SET_LANGUAGE as const,
  payload: language
})

export const setProducerAction = (producer: string) => ({
  type: GlobalActionTypes.SET_PRODUCER as const,
  payload: producer
})

export type GlobalActions = ReturnType<
  | typeof setLoggedUserAction
  | typeof setIsLoadingAction
  | typeof setIsLoadingUserAction
  | typeof setThemeAction
  | typeof setLanguageAction
  | typeof setProducerAction
>
