export enum GenresFilterActionTypes {
  SET_GENRE_FILTER = 'SET_GENRE_FILTER',
  CLEAR_GENRE_FILTER = 'CLEAR_GENRE_FILTER'
}

export const setGenresFilterAction = (
  page: number,
  pageSize: number,
  namesFilter: string[]
) => ({
  type: GenresFilterActionTypes.SET_GENRE_FILTER as const,
  payload: { page, pageSize, namesFilter }
})

export const clearGenresFilterAction = () => ({
  type: GenresFilterActionTypes.CLEAR_GENRE_FILTER as const
})

export type GenresFilterAction = ReturnType<
  typeof setGenresFilterAction | typeof clearGenresFilterAction
>
