import React, { useEffect, useState } from 'react'

import ArtistDto from '@dtos/ArtistDto'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, CardFooter, Image, Skeleton } from '@nextui-org/react'
import useArtistService from '@services/Artist'

interface EditEventLineupArtistProps {
  artistId: string
  buttonAction: (artistId: string) => void
  buttonIcon: IconDefinition
}

const EditEventLineupArtistCard = ({
  artistId,
  buttonAction,
  buttonIcon
}: EditEventLineupArtistProps) => {
  const { getArtistById } = useArtistService()

  const [artist, setArtist] = useState<ArtistDto | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    getArtistById(artistId)
      .then((response) => {
        if (response) setArtist(response)
      })
      .then(() => setIsLoading(false))
  }, [artistId])

  if (isLoading || !artist)
    return (
      <Skeleton className="size-[200px] min-h-[200px] min-w-[200px] rounded-3xl" />
    )

  return (
    <Card
      isFooterBlurred
      className="min-h-[200px] min-w-[200px] border-none"
      radius="lg"
    >
      <Image
        alt={artist.name}
        className="object-cover"
        height={200}
        src={artist.avatarUrl}
        width={200}
      />
      <CardFooter
        className="absolute bottom-1 z-10 ml-1 w-[calc(100%_-_8px)] justify-between overflow-hidden rounded-large border-1 py-1 shadow-small before:rounded-xl before:bg-white/10"
        style={{
          borderColor: 'rgba(255, 255, 255, 0.2)'
        }}
      >
        <p
          className="text-tiny"
          style={{
            color: 'rgba(255, 255, 255, 0.8)'
          }}
        >
          {artist.name}
        </p>
        <Button
          className="bg-black/20 text-tiny"
          color="default"
          radius="lg"
          size="sm"
          variant="flat"
          isIconOnly
          onClick={() => buttonAction(artist.id)}
          style={{
            color: 'rgba(255, 255, 255, 0.8)'
          }}
        >
          <FontAwesomeIcon icon={buttonIcon} />
        </Button>
      </CardFooter>
    </Card>
  )
}

export default EditEventLineupArtistCard
