import { useEffect, useState } from 'react'

import i18n from '../i18n'

const useTranslate = (rootNamespace?: string) => {
  const [language, setLanguage] = useState(i18n.language)

  useEffect(() => {
    const handleLanguageChange = (lng: string) => {
      setLanguage(lng)
    }

    i18n.on('languageChanged', handleLanguageChange)

    return () => {
      i18n.off('languageChanged', handleLanguageChange)
    }
  }, [language])

  return (selector: string, args?: any): string => {
    const result = i18n.t(
      `${rootNamespace ?? ''}${rootNamespace ? '.' : ''}${selector}`,
      { ...args }
    )
    return result.toString()
  }
}

export default useTranslate
