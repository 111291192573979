import React, { useState } from 'react'

import { useTheme } from '@configuration/ThemeContext'
import useTranslate from '@hooks/useTranslate'
import {
  Pagination,
  Select,
  SelectItem,
  SharedSelection
} from '@nextui-org/react'

interface PaginatedTableFooter {
  totalPages: number
  selectedPage: number
  pageSize: number
  onChangePage: (page: number) => void
  onChangePageSize: (size: number) => void
}

const PaginatedTableFooter = ({
  totalPages,
  selectedPage,
  pageSize,
  onChangePage,
  onChangePageSize
}: PaginatedTableFooter) => {
  const translate = useTranslate('components.shared.paginated_table')
  const { isDarkMode } = useTheme()
  const [lastSelection, setLastSelection] = useState(pageSize.toString())

  const changePageSize = (size: SharedSelection) => {
    if (size.currentKey) {
      const newPageSize = parseInt(size.currentKey)
      setLastSelection(size.currentKey)
      onChangePageSize(newPageSize)
    }
  }

  return (
    <div className="flex size-full max-h-full flex-col items-center justify-center gap-3 md:flex-row">
      <Pagination
        isCompact
        showControls
        color="default"
        total={totalPages}
        page={selectedPage}
        onChange={(page) => onChangePage(page)}
        initialPage={1}
        siblings={10}
        size="sm"
      />
      <Select
        classNames={{
          value: 'text-tiny !text-foreground-500'
        }}
        size="sm"
        className="max-w-[150px] md:absolute md:right-3"
        selectedKeys={[lastSelection]}
        onSelectionChange={changePageSize}
        popoverProps={{
          placement: 'top',
          className: `w-fit ${isDarkMode ? 'dark text-foreground' : ''}`
        }}
      >
        <SelectItem key={'10'}>
          {translate('items_per_page', { quantity: '10' })}
        </SelectItem>
        <SelectItem key={'25'}>
          {translate('items_per_page', { quantity: '25' })}
        </SelectItem>
        <SelectItem key={'50'}>
          {translate('items_per_page', { quantity: '50' })}
        </SelectItem>
        <SelectItem key={'100'}>
          {translate('items_per_page', { quantity: '100' })}
        </SelectItem>
      </Select>
    </div>
  )
}

export default PaginatedTableFooter
