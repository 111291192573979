import React from 'react'

import defaultAvatar from '@assets/Images/default_artist_avatar.png'
import defaultAvatarDark from '@assets/Images/default_artist_avatar_dark.png'
import ConfirmDeleteProducerModalBodyInput from '@components/Producers/ConfirmDeleteProducerModal/ConfirmDeleteProducerModalBodyInput'
import ConfirmDeleteProducerModalBodyLogo from '@components/Producers/ConfirmDeleteProducerModal/ConfirmDeleteProducerModalBodyLogo'
import { useTheme } from '@configuration/ThemeContext'
import ProducerDto from '@dtos/ProducerDto'
import { ModalBody } from '@nextui-org/react'

interface ConfirmDeleteProducerModalBodyProps {
  producer?: ProducerDto
  onConfirmDeleteProducer: () => void
}

const ConfirmDeleteProducerModalBody = ({
  producer,
  onConfirmDeleteProducer
}: ConfirmDeleteProducerModalBodyProps) => {
  const { isDarkMode } = useTheme()
  return (
    <ModalBody>
      <div className="flex flex-col items-center gap-3">
        <ConfirmDeleteProducerModalBodyLogo
          logoUrl={
            producer?.logoUrl
              ? producer.logoUrl
              : isDarkMode
                ? defaultAvatarDark
                : defaultAvatar
          }
          name={producer?.name}
        />
        <ConfirmDeleteProducerModalBodyInput
          name={producer?.name}
          onConfirmDeleteProducer={onConfirmDeleteProducer}
        />
      </div>
    </ModalBody>
  )
}

export default ConfirmDeleteProducerModalBody
