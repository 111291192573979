import React, { Key, useState } from 'react'

import { useTheme } from '@configuration/ThemeContext'
import GenreDto from '@dtos/GenreDto'
import useLocalizedStringHelpers from '@hooks/useLocalizedStringHelpers'
import useTranslate from '@hooks/useTranslate'
import { Autocomplete, AutocompleteItem } from '@nextui-org/react'
import useGenreService from '@services/Genre'
import { useTranslation } from 'react-i18next'

interface EditArtistGenresDropdownProps {
  addedGenreIds: string[]
  onAddGenre: (genreId: string, displayName: string) => void
}

const EditArtistGenresDropdown = ({
  addedGenreIds,
  onAddGenre
}: EditArtistGenresDropdownProps) => {
  const translate = useTranslate('components.artists.edit_artist')
  const selectedLanguage = useTranslation().i18n.language
  const [inputValue, setInputValue] = useState('')
  const [genresMatch, setGenresMatch] = useState<GenreDto[]>([])
  const [hasError, setHasError] = useState<boolean>(false)
  const { getLocalizedStringValue } = useLocalizedStringHelpers()
  const { isDarkMode } = useTheme()
  const { getGenreByName } = useGenreService()

  const changeValue = (value: string) => {
    setHasError(false)
    if (value.trim().length > 0) {
      getGenreByName(value, selectedLanguage).then((response) =>
        setGenresMatch(
          response?.filter((genre) => !addedGenreIds.includes(genre.id)) ?? []
        )
      )
    } else {
      setGenresMatch([])
    }
    setInputValue(value)
  }

  const addGenreFromDropdown = (genreKey: Key | null) => {
    if (!genreKey) return

    const genre = (genreKey as string).split('|||')

    if (genreIsValid(genre[0])) {
      onAddGenre(genre[0], genre[1])
      setInputValue('')
      setGenresMatch([])
    }
  }

  const genreIsValid = (genre: string): boolean => {
    if (addedGenreIds.includes(genre)) {
      setHasError(true)
      return false
    }

    return true
  }

  return (
    <Autocomplete
      isInvalid={hasError}
      isClearable={false}
      errorMessage={translate('genre_already_exists')}
      label={translate('genre_placeholder')}
      size="sm"
      radius="full"
      onValueChange={(value) => changeValue(value)}
      classNames={{
        selectorButton: 'hidden',
        popoverContent: `${isDarkMode ? 'text-foreground dark' : ''}`
      }}
      inputValue={inputValue}
      onSelectionChange={addGenreFromDropdown}
      listboxProps={{
        emptyContent: translate('no_genres_found')
      }}
    >
      {genresMatch &&
        genresMatch.map((genre) => (
          <AutocompleteItem
            key={`${genre.id}|||${getLocalizedStringValue(genre.name)}`}
          >
            {getLocalizedStringValue(genre.name)}
          </AutocompleteItem>
        ))}
    </Autocomplete>
  )
}

export default EditArtistGenresDropdown
