import React from 'react'

import MainMenu from '@components/MainMenu/MainMenu'
import TopMenu from '@components/MainMenu/TopMenu/TopMenu'
import { useAuth } from '@security/AuthContext'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoutes = () => {
  const { isAuthenticated } = useAuth()

  return !isAuthenticated ? (
    <Navigate to="/signin" />
  ) : (
    <div className="default-bg min-h-screen">
      <div className="z-0 min-h-screen bg-background/90">
        <div className="flex flex-col gap-3 md:flex-row">
          <div className="sticky top-0 z-40 w-full shadow-xl md:hidden">
            <TopMenu />
          </div>
          <div className="sticky left-0 top-0 hidden h-screen min-h-screen min-w-[325px] max-w-[325px] md:flex">
            <MainMenu />
          </div>
          <div className="grow px-4 md:pl-0">
            <div className="py-4">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivateRoutes
