import React, { useCallback } from 'react'

import ArtistsTableFilter from '@components/Artists/ArtistsTable/ArtistsTableFilter'
import ArtistsTableColumnActions from '@components/Artists/ArtistsTable/Columns/ArtistsTableColumnActions'
import ArtistsTableColumnCountry from '@components/Artists/ArtistsTable/Columns/ArtistsTableColumnCountry'
import ArtistsTableColumnGenre from '@components/Artists/ArtistsTable/Columns/ArtistsTableColumnGenre'
import ArtistsTableColumnName from '@components/Artists/ArtistsTable/Columns/ArtistsTableColumnName'
import PaginatedTable from '@components/Shared/PaginatedTable/PaginatedTable'
import ArtistDto from '@dtos/ArtistDto'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMediaQueryHelpers from '@hooks/useMediaQueryHelpers'
import useTranslate from '@hooks/useTranslate'
import { Button, Tooltip } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'

enum columnUid {
  name = 'name',
  country = 'country',
  genres = 'genres',
  actions = 'actions'
}

interface ArtistsTableProps {
  artistList: ArtistDto[]
  totalPages: number
  selectedPage: number
  totalItems: number
  pageSize: number
  isLoading: boolean
  onSelectDeleteArtist: (artist: ArtistDto) => void
  onChangePage: (page: number) => void
  onChangePageSize: (size: number) => void
  onApplyFilter: (
    names: string[],
    genres: string[],
    countries: string[]
  ) => void
  onClearFilter: () => void
}

const ArtistsTable = ({
  artistList,
  totalPages,
  selectedPage,
  totalItems,
  pageSize,
  isLoading,
  onSelectDeleteArtist,
  onChangePage,
  onChangePageSize,
  onApplyFilter,
  onClearFilter
}: ArtistsTableProps) => {
  const translate = useTranslate('components.artists.artists_table')
  const navigate = useNavigate()
  const { isMedium } = useMediaQueryHelpers()

  const columns = [
    { name: translate('column_names.name'), uid: columnUid.name }
  ]

  if (!isMedium) {
    columns.push({
      name: translate('column_names.country'),
      uid: columnUid.country
    })
  }

  columns.push({
    name: translate('column_names.genres'),
    uid: columnUid.genres
  })
  columns.push({
    name: translate('column_names.actions'),
    uid: columnUid.actions
  })

  const renderCell = useCallback(
    (artist: ArtistDto, columnKey: React.Key) => {
      switch (columnKey) {
        case columnUid.name:
          return (
            <ArtistsTableColumnName
              name={artist.name}
              avatarUrl={artist.avatarUrl}
              artistId={artist.id}
              country={artist.country}
            />
          )
        case columnUid.country:
          return <ArtistsTableColumnCountry country={artist.country} />
        case columnUid.genres:
          return <ArtistsTableColumnGenre genres={artist.genres} />
        case columnUid.actions:
          return (
            <ArtistsTableColumnActions
              artistId={artist.id}
              onSelectDeleteArtist={() => onSelectDeleteArtist(artist)}
            />
          )
      }
    },
    [artistList]
  )

  return (
    <PaginatedTable
      items={artistList}
      columns={columns}
      cellRenderer={renderCell}
      totalPages={totalPages}
      selectedPage={selectedPage}
      pageSize={pageSize}
      totalItems={totalItems}
      isLoading={isLoading}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      emptyContent={translate('no_artists_found')}
      title={translate('title')}
      headerEndContent={
        <Tooltip color="success" content={translate('actions.add')}>
          <Button
            isIconOnly
            radius="full"
            color="success"
            size="sm"
            onClick={() => navigate('/artists/add')}
          >
            <FontAwesomeIcon icon={faPlus} size="lg" />
          </Button>
        </Tooltip>
      }
      filter={
        <ArtistsTableFilter
          onApplyFilter={onApplyFilter}
          onClearFilter={onClearFilter}
        />
      }
    />
  )
}

export default ArtistsTable
