import React, { useState } from 'react'

import { setLanguageAction } from '@actions/Global'
import { LocalStorageConstants } from '@configuration/Constants'
import useTranslate from '@hooks/useTranslate'
import { Select, SelectItem, SharedSelection } from '@nextui-org/react'
import { RootState } from '@store'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

const UserMenuLanguageSelector = () => {
  const languagesTranslation = useTranslate('languages')
  const { i18n } = useTranslation()
  const supportedLanguages = i18n.options.supportedLngs
  const dispatch = useDispatch()
  const [selectedLanguage, setSelectedLanguage] = useState(
    useSelector((state: RootState) => state.global.language)
  )

  const setLanguage = (selection: SharedSelection) => {
    if (selection.currentKey) {
      const language = selection.currentKey as string
      i18n.changeLanguage(language)
      dispatch(setLanguageAction(language))
      localStorage.setItem(LocalStorageConstants.LANGUAGE, language)
      setSelectedLanguage(language)
    }
  }

  if (!supportedLanguages) return <></>

  return (
    <Select
      onSelectionChange={setLanguage}
      selectedKeys={[selectedLanguage]}
      size="sm"
    >
      {supportedLanguages
        .filter((lng) => lng !== 'cimode')
        .map((lng) => (
          <SelectItem key={lng} value={lng}>
            {languagesTranslation(lng)}
          </SelectItem>
        ))}
    </Select>
  )
}

export default UserMenuLanguageSelector
