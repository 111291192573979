import React, { useEffect, useState } from 'react'

import {
  clearArtistsFilterAction,
  setArtistsFilterAction
} from '@actions/ArtistsFilter'
import { setRootBreadcrumbAction } from '@actions/Breadcrumb'
import ArtistsTable from '@components/Artists/ArtistsTable/ArtistsTable'
import ConfirmDeleteArtistModal from '@components/Artists/ConfirmDeleteArtistModal/ConfirmDeleteArtistModal'
import { RootBreadcrumbConstants } from '@configuration/Constants'
import ArtistDto from '@dtos/ArtistDto'
import useArtistService from '@services/Artist'
import { RootState } from '@store'
import { useDispatch, useSelector } from 'react-redux'

const Artists = () => {
  const dispatch = useDispatch()
  const { getArtistList } = useArtistService()
  const artistsFilterInMemory = useSelector(
    (state: RootState) => state.artistsFilter
  )
  const [artistList, setArtistList] = useState<ArtistDto[]>([])
  const [totalPages, setTotalPages] = useState<number>(0)
  const [selectedPage, setSelectedPage] = useState<number>(
    artistsFilterInMemory.page ?? 1
  )
  const [pageSize, setPageSize] = useState<number>(
    artistsFilterInMemory.pageSize ?? 10
  )
  const [totalItems, setTotalItems] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)
  const [namesFilter, setNamesFilter] = useState<string[]>(
    artistsFilterInMemory.namesFilter ?? []
  )
  const [genresFilter, setGenresFilter] = useState<string[]>(
    artistsFilterInMemory.genresFilter ?? []
  )
  const [countriesFilter, setCountrtiesFilter] = useState<string[]>(
    artistsFilterInMemory.countriesFilter ?? []
  )
  const [artistToDelete, setArtistToDelete] = useState<ArtistDto | undefined>(
    undefined
  )

  useEffect(() => {
    dispatch(setRootBreadcrumbAction(RootBreadcrumbConstants.ARTISTS))
  }, [])

  useEffect(() => {
    loadTable()
  }, [selectedPage, pageSize, namesFilter, genresFilter, countriesFilter])

  const loadTable = () => {
    setIsLoading(true)
    getArtistList(
      selectedPage,
      pageSize,
      namesFilter,
      genresFilter,
      countriesFilter
    ).then((result) => {
      if (result) {
        setArtistList(result.items)
        setTotalPages(result.totalPages)
        setTotalItems(result.totalItems)
      }
      setIsLoading(false)
    })
  }

  const closeDeleteArtistModal = (reloadTable: boolean) => {
    setArtistToDelete(undefined)
    if (reloadTable) loadTable()
  }

  const applyFilter = (
    namesFilter: string[],
    genresFilter: string[],
    countriesFilter: string[]
  ) => {
    setSelectedPage(1)
    setNamesFilter(namesFilter)
    setGenresFilter(genresFilter)
    setCountrtiesFilter(countriesFilter)
    dispatch(
      setArtistsFilterAction(
        1,
        pageSize,
        namesFilter,
        genresFilter,
        countriesFilter
      )
    )
  }

  const clearFilter = () => {
    if (
      namesFilter.length > 0 ||
      genresFilter.length > 0 ||
      countriesFilter.length > 0
    ) {
      setNamesFilter([])
      setGenresFilter([])
      setCountrtiesFilter([])
      setSelectedPage(1)
      dispatch(clearArtistsFilterAction())
    }
  }

  const changePage = (page: number) => {
    dispatch(
      setArtistsFilterAction(
        page,
        pageSize,
        namesFilter,
        genresFilter,
        countriesFilter
      )
    )
    setSelectedPage(page)
  }

  const changePageSize = (pageSize: number) => {
    dispatch(
      setArtistsFilterAction(
        selectedPage,
        pageSize,
        namesFilter,
        genresFilter,
        countriesFilter
      )
    )
    setPageSize(pageSize)
  }

  return (
    <>
      <ConfirmDeleteArtistModal
        artist={artistToDelete}
        onClose={(reloadTable) => closeDeleteArtistModal(reloadTable)}
      />
      <ArtistsTable
        artistList={artistList}
        totalPages={totalPages}
        selectedPage={selectedPage}
        totalItems={totalItems}
        pageSize={pageSize}
        isLoading={isLoading}
        onSelectDeleteArtist={setArtistToDelete}
        onChangePage={changePage}
        onChangePageSize={changePageSize}
        onApplyFilter={applyFilter}
        onClearFilter={clearFilter}
      />
    </>
  )
}

export default Artists
