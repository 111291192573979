import React from 'react'

interface TicketFrameProps {
  stroke?: string
}

const TicketFrame = ({ stroke }: TicketFrameProps) => {
  return (
    <div className="flex size-full items-center justify-center align-middle saturate-200">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 137 142"
        width="100%"
        height="100%"
      >
        <path
          fill="none"
          stroke={stroke ?? 'currentColor'}
          strokeMiterlimit={2}
          strokeWidth="2px"
          d="M 124.735 65.69 C 127.505 63.38 129.035 59.89 129.035 56.27 L 129.035 21.46 C 129.035 14.52 123.405 8.9 116.465 8.9 L 20.745 8.9 C 13.815 8.9 8.185 14.52 8.185 21.46 L 8.185 56.3 C 8.185 59.9 9.695 63.38 12.465 65.67 C 13.965 66.91 14.915 68.78 14.915 70.87 C 14.915 72.96 13.965 74.83 12.465 76.06 C 9.695 78.35 8.185 81.84 8.185 85.44 L 8.185 120.28 C 8.185 127.22 13.815 132.84 20.745 132.84 L 116.465 132.84 C 123.405 132.84 129.035 127.21 129.035 120.28 L 129.035 85.47 C 129.035 81.85 127.505 78.36 124.725 76.05 C 123.245 74.81 122.305 72.95 122.305 70.87 C 122.305 68.79 123.245 66.93 124.735 65.69 Z"
        />
      </svg>
    </div>
  )
}

export default TicketFrame
