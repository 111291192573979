import React, { useEffect, useState } from 'react'

import EditPicturesListAdd from '@components/Shared/EditPicturesList/EditPicturesListAdd'
import EditPicturesListPreview from '@components/Shared/EditPicturesList/EditPicturesListPreview'
import PictureDto from '@dtos/PictureDto'
import PictureUploadDto from '@dtos/PictureUploadDto'
import useMediaQueryHelpers from '@hooks/useMediaQueryHelpers'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

interface EditPicturesListProps {
  pictureList: PictureDto[]
  onAddPictures: (pictures: PictureUploadDto[]) => void
  onRemove: (picture: string) => void
  onCancelRemove: (picture: string) => void
  onRemoveNewPicture: (picture: string) => void
  picturesToRemoveList: string[]
  onReorderPictures: (pictures: PictureDto[]) => void
  showFrame?: boolean
}

const EditPicturesList = ({
  pictureList,
  onAddPictures,
  onRemove,
  onCancelRemove,
  onRemoveNewPicture,
  picturesToRemoveList,
  onReorderPictures,
  showFrame = true
}: EditPicturesListProps) => {
  const [pictures, setPictures] = useState<PictureDto[]>(
    pictureList ? [...pictureList] : []
  )

  const { isSmall } = useMediaQueryHelpers()
  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return

    const reorderedImages = [...pictures]
    const [movedImage] = reorderedImages.splice(result.source.index, 1)
    reorderedImages.splice(result.destination.index, 0, movedImage)

    reorderedImages.map((pic, index) => (pic.order = index + 1))

    setPictures(reorderedImages)
    onReorderPictures(reorderedImages)
  }

  useEffect(() => {
    setPictures(pictureList)
  }, [pictureList])

  return (
    <div className="flex flex-wrap justify-center gap-3 lg:justify-start">
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable
          droppableId="pictureList"
          direction={isSmall ? 'vertical' : 'horizontal'}
        >
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="flex flex-wrap justify-center gap-3 lg:justify-start"
            >
              {pictures.map((picture, index) => (
                <Draggable
                  key={picture.id!}
                  draggableId={picture.id!}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style
                      }}
                    >
                      <EditPicturesListPreview
                        key={index}
                        picture={picture.url}
                        pictureId={picture.id!}
                        isNewPicture={picture.newPicture ?? false}
                        showFrame={showFrame}
                        setToRemove={picturesToRemoveList.includes(picture.id!)}
                        onRemove={(picToRemove) =>
                          picture.newPicture
                            ? onRemoveNewPicture(picToRemove)
                            : onRemove(picToRemove)
                        }
                        onCancelRemove={(picToCancelRemove) =>
                          !picture.newPicture
                            ? onCancelRemove(picToCancelRemove)
                            : undefined
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              <EditPicturesListAdd
                onUploadPicture={onAddPictures}
                existingPicturesCount={pictureList.length}
              />
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default EditPicturesList
