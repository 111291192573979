import React, { Dispatch, SetStateAction, useState } from 'react'

import CountrySelector from '@components/Shared/CountrySelector'
import {
  faFilter,
  faFilterCircleXmark
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import {
  Accordion,
  AccordionItem,
  Button,
  Input,
  Tooltip
} from '@nextui-org/react'
import { RootState } from '@store'
import { useSelector } from 'react-redux'

interface VenuesTableFilterProps {
  onApplyFilter: (
    name: string[],
    street: string[],
    neighborhood: string[],
    postalCode: string[],
    city: string[],
    state: string[],
    country: string[]
  ) => void
  onClearFilter: () => void
}

interface StringFilterHelper {
  field: string
  setInputValue: Dispatch<SetStateAction<string>>
  setFilter: Dispatch<SetStateAction<string[]>>
}

const VenuesTableFilter = ({
  onApplyFilter,
  onClearFilter
}: VenuesTableFilterProps) => {
  const venuesFilterInMemory = useSelector(
    (state: RootState) => state.venuesFilter
  )
  const translate = useTranslate('components.venues.venues_table.filter')

  const [nameInputValue, setNameInputValue] = useState(
    venuesFilterInMemory.nameFilter
      ? venuesFilterInMemory.nameFilter.join(';')
      : ''
  )

  const [streetInputValue, setStreetInputValue] = useState(
    venuesFilterInMemory.streetFilter
      ? venuesFilterInMemory.streetFilter.join(';')
      : ''
  )

  const [neighborhoodInputValue, setNeighborhoodInputValue] = useState(
    venuesFilterInMemory.neighborhoodFilter
      ? venuesFilterInMemory.neighborhoodFilter.join(';')
      : ''
  )

  const [postalCodeInputValue, setPostalCodeInputValue] = useState(
    venuesFilterInMemory.postalCodeFilter
      ? venuesFilterInMemory.postalCodeFilter.join(';')
      : ''
  )

  const [cityInputValue, setCityInputValue] = useState(
    venuesFilterInMemory.cityFilter
      ? venuesFilterInMemory.cityFilter.join(';')
      : ''
  )

  const [stateInputValue, setStateInputValue] = useState(
    venuesFilterInMemory.stateFilter
      ? venuesFilterInMemory.stateFilter.join(';')
      : ''
  )

  const [nameFilter, setNameFilter] = useState<string[]>(
    venuesFilterInMemory.nameFilter ?? []
  )

  const [streetFilter, setStreetFilter] = useState<string[]>(
    venuesFilterInMemory.streetFilter ?? []
  )

  const [neighborhoodFilter, setNeighborhoodFilter] = useState<string[]>(
    venuesFilterInMemory.neighborhoodFilter ?? []
  )

  const [postalCodeFilter, setPostalCodeFilter] = useState<string[]>(
    venuesFilterInMemory.postalCodeFilter ?? []
  )

  const [cityFilter, setCityFilter] = useState<string[]>(
    venuesFilterInMemory.cityFilter ?? []
  )

  const [stateFilter, setStateFilter] = useState<string[]>(
    venuesFilterInMemory.stateFilter ?? []
  )

  const [countryFilter, setCountryFilter] = useState<string[]>(
    venuesFilterInMemory.countryFilter ?? []
  )

  const stringFilters: StringFilterHelper[] = [
    {
      field: 'name',
      setInputValue: setNameInputValue,
      setFilter: setNameFilter
    },
    {
      field: 'street',
      setInputValue: setStreetInputValue,
      setFilter: setStreetFilter
    },
    {
      field: 'neighborhood',
      setInputValue: setNeighborhoodInputValue,
      setFilter: setNeighborhoodFilter
    },
    {
      field: 'postalCode',
      setInputValue: setPostalCodeInputValue,
      setFilter: setPostalCodeFilter
    },
    {
      field: 'city',
      setInputValue: setCityInputValue,
      setFilter: setCityFilter
    },
    {
      field: 'state',
      setInputValue: setStateInputValue,
      setFilter: setStateFilter
    }
  ]

  const normalizeFilterValue = (field: string, value: string) => {
    const filter = stringFilters.find((f) => f.field === field)
    if (filter) {
      filter.setInputValue(value)
      filter.setFilter(value === '' ? [] : value.split(';'))
    }
  }

  const clearFilter = () => {
    Object.values(stringFilters).forEach(({ setInputValue, setFilter }) => {
      setInputValue('')
      setFilter([])
    })

    setCountryFilter([])
    onClearFilter()
  }

  return (
    <Accordion variant="shadow" isCompact={false}>
      <AccordionItem
        key="filters"
        title={translate('title')}
        startContent={
          <FontAwesomeIcon icon={faFilter} className="text-foreground-500" />
        }
        classNames={{
          title: 'text-sm font-semibold text-foreground-500'
        }}
      >
        <div className="flex flex-col items-center gap-3 pb-3">
          <Tooltip
            size="sm"
            placement="bottom"
            color="foreground"
            content={translate('name_tooltip')}
          >
            <Input
              value={nameInputValue}
              label={translate('name')}
              id="venueNameFilter"
              name="venueNameFilter"
              classNames={{ label: 'z-0' }}
              onValueChange={(value) => normalizeFilterValue('name', value)}
            />
          </Tooltip>
          <div className="flex w-full flex-col justify-between gap-3 md:flex-row">
            <Tooltip
              size="sm"
              placement="bottom"
              color="foreground"
              content={translate('street_tooltip')}
            >
              <Input
                value={streetInputValue}
                label={translate('street')}
                id="venueStreetFilter"
                name="venueStreetFilter"
                classNames={{ label: 'z-0' }}
                onValueChange={(value) => normalizeFilterValue('street', value)}
              />
            </Tooltip>
            <Tooltip
              size="sm"
              placement="bottom"
              color="foreground"
              content={translate('neighborhood_tooltip')}
            >
              <Input
                value={neighborhoodInputValue}
                label={translate('neighborhood')}
                id="venueNeighborhoodFilter"
                name="venueNeighborhoodFilter"
                classNames={{ label: 'z-0' }}
                onValueChange={(value) =>
                  normalizeFilterValue('neighborhood', value)
                }
              />
            </Tooltip>
            <Tooltip
              size="sm"
              placement="bottom"
              color="foreground"
              content={translate('postalCode_tooltip')}
            >
              <Input
                value={postalCodeInputValue}
                label={translate('postalCode')}
                id="venuePostalCodeFilter"
                name="venuePostalCodeFilter"
                classNames={{ label: 'z-0' }}
                onValueChange={(value) =>
                  normalizeFilterValue('postalCode', value)
                }
              />
            </Tooltip>
          </div>
          <div className="flex w-full flex-col justify-between gap-3 md:flex-row">
            <Tooltip
              size="sm"
              placement="bottom"
              color="foreground"
              content={translate('city_tooltip')}
            >
              <Input
                value={cityInputValue}
                label={translate('city')}
                id="venueCityFilter"
                name="venueCityFilter"
                classNames={{ label: 'z-0' }}
                onValueChange={(value) => normalizeFilterValue('city', value)}
              />
            </Tooltip>
            <Tooltip
              size="sm"
              placement="bottom"
              color="foreground"
              content={translate('state_tooltip')}
            >
              <Input
                value={cityInputValue}
                label={translate('state')}
                id="venueStateFilter"
                name="venueStateFilter"
                classNames={{ label: 'z-0' }}
                onValueChange={(value) => normalizeFilterValue('state', value)}
              />
            </Tooltip>
            <CountrySelector
              multiSelect
              selectedValues={countryFilter}
              onMultiSelect={setCountryFilter}
            />
          </div>
          <div className="flex flex-wrap justify-center gap-3">
            <Button
              variant="solid"
              color="success"
              onClick={() =>
                onApplyFilter(
                  nameFilter,
                  streetFilter,
                  neighborhoodFilter,
                  postalCodeFilter,
                  cityFilter,
                  stateFilter,
                  countryFilter
                )
              }
              isDisabled={
                nameInputValue.length === 0 &&
                streetInputValue.length === 0 &&
                neighborhoodInputValue.length === 0 &&
                postalCodeInputValue.length === 0 &&
                cityInputValue.length === 0 &&
                stateInputValue.length === 0 &&
                countryFilter.length === 0
              }
              startContent={<FontAwesomeIcon icon={faFilter} />}
            >
              {translate('apply_filters')}
            </Button>
            <Button
              variant="solid"
              color="warning"
              onClick={clearFilter}
              isDisabled={
                nameInputValue.length === 0 &&
                streetInputValue.length === 0 &&
                neighborhoodInputValue.length === 0 &&
                postalCodeInputValue.length === 0 &&
                cityInputValue.length === 0 &&
                countryFilter.length === 0
              }
              startContent={<FontAwesomeIcon icon={faFilterCircleXmark} />}
            >
              {translate('clear_filters')}
            </Button>
          </div>
        </div>
      </AccordionItem>
    </Accordion>
  )
}

export default VenuesTableFilter
