import React, { useState } from 'react'

import useTranslate from '@hooks/useTranslate'
import {
  Card,
  CardBody,
  CardHeader,
  DatePicker,
  DateValue
} from '@nextui-org/react'
import { I18nProvider } from '@react-aria/i18n'
import { RootState } from '@store'
import { useSelector } from 'react-redux'

interface EditEventDatesProps {
  releaseDateInitialValue?: DateValue
  endDateInitialValue?: DateValue
  onChangeReleaseDate: (date: DateValue) => void
  onChangeEndDate: (date: DateValue) => void
}

const EditEventDates = ({
  releaseDateInitialValue,
  endDateInitialValue,
  onChangeReleaseDate,
  onChangeEndDate
}: EditEventDatesProps) => {
  const state = useSelector((state: RootState) => state.global)
  const translate = useTranslate('components.events.edit_event')

  const [releaseDate, setReleaseDate] = useState<DateValue | undefined | null>(
    releaseDateInitialValue
  )
  const [endDate, setEndDate] = useState<DateValue | undefined | null>(
    endDateInitialValue
  )

  const changeReleaseDate = (date: DateValue) => {
    setReleaseDate(date)

    if (endDate && endDate <= date) setEndDate(date.add({ days: 1 }))
    if (date === null) setEndDate(null)

    onChangeReleaseDate(date)
  }

  const changeFinalDate = (date: DateValue) => {
    setEndDate(date)
    onChangeEndDate(date)
  }

  return (
    <Card fullWidth className="h-full">
      <CardHeader>
        <div className="ml-1 justify-start font-medium text-foreground-500">
          {translate('dates')}
        </div>
      </CardHeader>
      <CardBody>
        <I18nProvider locale={state.language}>
          <div className="flex h-full flex-col justify-center gap-2">
            <DatePicker
              showMonthAndYearPickers
              value={releaseDate}
              onChange={changeReleaseDate}
              variant="flat"
              size="sm"
              label={translate('release_date')}
            />
            <DatePicker
              showMonthAndYearPickers
              value={endDate}
              onChange={changeFinalDate}
              variant="flat"
              size="sm"
              isDisabled={!releaseDate}
              label={translate('end_date')}
              minValue={releaseDate?.add({ days: 1 })}
              errorMessage={() => <p>{translate('end_date_error')}</p>}
            />
          </div>
        </I18nProvider>
      </CardBody>
    </Card>
  )
}

export default EditEventDates
