import React from 'react'

import LogoInline from '@assets/VisualElements/LogoInline'
import PartynerVisualElement from '@assets/VisualElements/PartynerVisualElement'
import DefaultAvatar from '@components/Shared/DefaultAvatar'
import PartynerIcon from '@components/Shared/PartynerIcon'
import { Modal, ModalContent, Spinner } from '@nextui-org/react'
import { RootState } from '@store'
import { useSelector } from 'react-redux'

const UserLoading = () => {
  const state = useSelector((store: RootState) => store.global)

  return (
    <Modal
      backdrop="transparent"
      size="full"
      isOpen={state.isLoadingUser}
      hideCloseButton
      classNames={{
        wrapper: 'default-bg'
      }}
    >
      <ModalContent className="border-none bg-transparent shadow-none">
        <div className="relative flex h-screen w-screen items-center justify-center partyner">
          <div className="absolute left-1/2 top-1/2 z-0 size-11/12 -translate-x-1/2 -translate-y-1/2 opacity-40 backdrop-blur">
            <PartynerVisualElement />
          </div>
          <div className="z-10 flex size-full -translate-y-16 flex-col items-center justify-center gap-3 sm:translate-y-0">
            <div className="flex w-1/2 items-center justify-center sm:w-2/12">
              <LogoInline />
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-3 sm:flex-row sm:gap-10">
              <div className="relative z-10 flex h-full w-[300px] flex-col items-center justify-center gap-3">
                <div className="flex aspect-square w-36 items-center justify-center">
                  <PartynerIcon>
                    {state.loggedUser?.avatarUrl ? (
                      <img
                        src={state.loggedUser?.avatarUrl}
                        alt={state.loggedUser?.displayName}
                        className="z-0 size-full object-cover"
                      />
                    ) : (
                      <DefaultAvatar
                        displayName={state.loggedUser?.displayName ?? ''}
                      />
                    )}
                  </PartynerIcon>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <span className="font-partyner text-2xl text-white">
                    {state.loggedUser?.displayName}
                  </span>
                  <span className="font-partyner text-tiny font-light text-white">
                    {state.loggedUser?.email}
                  </span>
                </div>
                <Spinner />
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default UserLoading
