import { LocalStorageConstants } from '@configuration/Constants'
import StoredUser from '@interfaces/StoredUser'

const useStoredUsers = () => {
  const getStoredUsers = (): StoredUser[] => {
    try {
      const storedUsersString =
        localStorage.getItem(LocalStorageConstants.STORED_USERS) ?? '[]'

      const users = JSON.parse(storedUsersString) as StoredUser[]

      return users.sort((userA, userB) =>
        userA.displayName.localeCompare(userB.displayName)
      )
    } catch {
      return []
    }
  }

  const setStoredUsers = (storedUsers: StoredUser[]) => {
    localStorage.setItem(
      LocalStorageConstants.STORED_USERS,
      JSON.stringify(storedUsers)
    )
  }

  return { getStoredUsers, setStoredUsers }
}

export default useStoredUsers
