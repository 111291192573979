import React, { useState } from 'react'

import {
  faFilter,
  faFilterCircleXmark
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import {
  Accordion,
  AccordionItem,
  Button,
  Input,
  Tooltip
} from '@nextui-org/react'
import { RootState } from '@store'
import { useSelector } from 'react-redux'

interface GenresTableFilterProps {
  onApplyFilter: (names: string[]) => void
  onClearFilter: () => void
}

const GenresTableFilter = ({
  onApplyFilter,
  onClearFilter
}: GenresTableFilterProps) => {
  const genreFilterInMemory = useSelector(
    (state: RootState) => state.genresFilter
  )
  const translate = useTranslate('components.genres.genres_table.filter')
  const [nameInputValue, setNameInputValue] = useState(
    genreFilterInMemory.namesFilter
      ? genreFilterInMemory.namesFilter.join(';')
      : ''
  )
  const [namesFilter, setNamesFilter] = useState<string[]>(
    genreFilterInMemory.namesFilter ?? []
  )

  const normalizeNamesFilter = (value: string) => {
    setNameInputValue(value)
    if (value === '') setNamesFilter([])
    else setNamesFilter(value.split(';'))
  }

  const clearFilter = () => {
    setNamesFilter([])
    setNameInputValue('')

    onClearFilter()
  }

  return (
    <Accordion variant="shadow" isCompact defaultExpandedKeys={['filters']}>
      <AccordionItem
        key="filters"
        title={translate('title')}
        startContent={
          <FontAwesomeIcon icon={faFilter} className="text-foreground-500" />
        }
        classNames={{
          title: 'text-sm font-semibold text-foreground-500'
        }}
      >
        <div className="flex flex-col items-center gap-3 pb-3">
          <div className="flex w-full flex-col justify-between gap-3 md:flex-row">
            <Tooltip
              size="sm"
              placement="bottom"
              color="foreground"
              content={translate('name_tooltip')}
            >
              <Input
                value={nameInputValue}
                label={translate('names')}
                id="genreNameFilter"
                name="genreNameFilter"
                classNames={{ label: 'z-0' }}
                onValueChange={normalizeNamesFilter}
              />
            </Tooltip>
          </div>
          <div className="flex flex-wrap justify-center gap-3">
            <Button
              variant="solid"
              color="success"
              onClick={() => onApplyFilter(namesFilter)}
              isDisabled={nameInputValue.length === 0}
              startContent={<FontAwesomeIcon icon={faFilter} />}
            >
              {translate('apply_filters')}
            </Button>
            <Button
              variant="solid"
              color="warning"
              onClick={clearFilter}
              isDisabled={nameInputValue.length === 0}
              startContent={<FontAwesomeIcon icon={faFilterCircleXmark} />}
            >
              {translate('clear_filters')}
            </Button>
          </div>
        </div>
      </AccordionItem>
    </Accordion>
  )
}

export default GenresTableFilter
