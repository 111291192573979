import React from 'react'

import { setIsLoadingAction } from '@actions/Global'
import ConfirmDeleteProducerModalBody from '@components/Producers/ConfirmDeleteProducerModal/ConfirmDeleteProducerModalBody'
import ConfirmDeleteProducerModalHeader from '@components/Producers/ConfirmDeleteProducerModal/ConfirmDeleteProducerModalHeader'
import { useTheme } from '@configuration/ThemeContext'
import ProducerDto from '@dtos/ProducerDto'
import useTranslate from '@hooks/useTranslate'
import { Modal, ModalContent } from '@nextui-org/react'
import useProducerService from '@services/Producer'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

interface ConfirmDeleteProducerModalProps {
  producer?: ProducerDto
  onClose: (reloadList: boolean) => void
}

const ConfirmDeleteProducerModal = ({
  producer,
  onClose
}: ConfirmDeleteProducerModalProps) => {
  const { isDarkMode } = useTheme()
  const { deleteProducer } = useProducerService()
  const dispatch = useDispatch()
  const translate = useTranslate('components.producers.remove_producer')

  const removeProducer = async () => {
    if (!producer) return

    dispatch(setIsLoadingAction(true))
    const response = await deleteProducer(producer.id)

    if (response) {
      toast.success(translate('producer_removed'))
    } else {
      toast.error(translate('error_on_remove'))
    }
    dispatch(setIsLoadingAction(false))

    onClose(true)
  }

  return (
    <Modal
      isOpen={!!producer}
      onClose={() => onClose(false)}
      backdrop={'blur'}
      isDismissable={true}
      size="sm"
      classNames={{
        header:
          'flex flex-col gap-1text-center justify-center font-semibold text-2xl text-danger',
        base: `${isDarkMode ? 'dark text-foreground' : ''} text-center`
      }}
    >
      <ModalContent>
        <ConfirmDeleteProducerModalHeader />
        <ConfirmDeleteProducerModalBody
          producer={producer}
          onConfirmDeleteProducer={async () => await removeProducer()}
        />
      </ModalContent>
    </Modal>
  )
}

export default ConfirmDeleteProducerModal
