import React from 'react'

import UserMenuLanguageSelector from '@components/MainMenu/UserMenu/UserMenuLanguageSelector'
import UserMenuLogout from '@components/MainMenu/UserMenu/UserMenuLogout'
import UserMenuThemeSelector from '@components/MainMenu/UserMenu/UserMenuThemeSelector'
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Image
} from '@nextui-org/react'
import { RootState } from '@store'
import { useSelector } from 'react-redux'

const UserMenu = () => {
  const translate = useTranslate('main_menu.user_menu')
  const loggedUser = useSelector((state: RootState) => state.global.loggedUser)

  if (!loggedUser) return <></>

  return (
    <div className="flex w-full items-center gap-3">
      <Dropdown>
        <DropdownTrigger>
          <div className="flex w-full cursor-pointer">
            <div
              className={`flex w-full cursor-pointer items-center justify-start gap-3 rounded-lg p-2 pr-4 text-white hover:bg-white/10`}
            >
              <div className="flex size-8 items-center justify-center gap-2">
                <Image
                  radius="full"
                  src={loggedUser.avatarUrl}
                  alt={loggedUser.displayName}
                />
              </div>
              <div className="font-partyner flex grow text-sm">
                {loggedUser.displayName}
              </div>
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </div>
          </div>
        </DropdownTrigger>
        <DropdownMenu>
          <DropdownSection showDivider title={translate('language')}>
            <DropdownItem key="language" isReadOnly>
              <UserMenuLanguageSelector />
            </DropdownItem>
          </DropdownSection>
          <DropdownSection showDivider title={translate('theme')}>
            <DropdownItem key="theme" isReadOnly>
              <UserMenuThemeSelector />
            </DropdownItem>
          </DropdownSection>
          <DropdownItem key="logout" isReadOnly>
            <UserMenuLogout />
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default UserMenu
