import CreateUpdateLineupArtistDto from '@dtos/CreateUpdateLineupArtistDto'
import CreateUpdateLineupDto from '@dtos/CreateUpdateLineupDto'
import LineupDto from '@dtos/LineupDto'
import useRootApiService from '@services/RootApi'

const useLineupService = () => {
  const { Get, Post, Put, Patch, Delete } = useRootApiService()

  const getLineupById = async (
    lineupId: string
  ): Promise<LineupDto | undefined> => {
    const response = await Get<LineupDto>(`/lineup/${lineupId}`)

    return response.data
  }

  const createLineup = async (
    mainInfoDto: CreateUpdateLineupDto
  ): Promise<LineupDto | undefined> => {
    const response = await Post<LineupDto, CreateUpdateLineupDto>(
      '/lineup',
      mainInfoDto
    )

    return response.data
  }

  const updateLineup = async (
    lineupId: string,
    mainInfoDto: CreateUpdateLineupDto
  ): Promise<LineupDto | undefined> => {
    const response = await Put<LineupDto, CreateUpdateLineupDto>(
      `/lineup/${lineupId}`,
      mainInfoDto
    )

    return response.data
  }

  const updateLineupArtists = async (
    lineupId: string,
    lineupArtists: CreateUpdateLineupArtistDto[]
  ): Promise<LineupDto | undefined> => {
    const response = await Patch<LineupDto, CreateUpdateLineupArtistDto[]>(
      `/lineup/${lineupId}/artist`,
      lineupArtists
    )

    return response.data
  }

  const deleteLineup = async (lineupId: string): Promise<boolean> => {
    const response = await Delete(`/lineup/${lineupId}`)

    return response.success
  }

  return {
    getLineupById,
    createLineup,
    updateLineup,
    deleteLineup,
    updateLineupArtists
  }
}

export default useLineupService
