import React from 'react'

import useTranslate from '@hooks/useTranslate'
import { Spinner } from '@nextui-org/react'

const PaginatedTableLoadingContent = () => {
  const translate = useTranslate('components.shared.paginated_table')
  return (
    <div className="z-10 flex size-full justify-center align-middle backdrop-blur-sm">
      <Spinner label={translate('loading')} />
    </div>
  )
}

export default PaginatedTableLoadingContent
