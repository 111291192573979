import React, { forwardRef, useImperativeHandle, useRef } from 'react'

import EditArtistSoundCloudPlayer, {
  EditArtistSoundCloudPlayerRef
} from '@components/Artists/EditArtist/SoundCloudTracks/EditArtistSoundCloudPlayer'
import { DraggableProvided } from 'react-beautiful-dnd'

interface EditArtistSoundCloudTrackProps {
  provided: DraggableProvided
  id: string
  trackId: string
  onRemoveLink: (id: string) => void
  onPlay: (id: string) => void
}

export type EditArtistSoundCloudTrackRef = {
  id: string
  pausePlayer: () => void
}

const EditArtistSoundCloudTrack = forwardRef<
  EditArtistSoundCloudTrackRef,
  EditArtistSoundCloudTrackProps
>(
  (
    {
      provided,
      id,
      trackId,
      onRemoveLink,
      onPlay
    }: EditArtistSoundCloudTrackProps,
    editArtistSoundCloudTrackRef
  ) => {
    const playerRef = useRef<EditArtistSoundCloudPlayerRef>(null)

    const pausePlayer = () => {
      if (playerRef.current?.pausePlayer) playerRef.current.pausePlayer()
    }

    useImperativeHandle(editArtistSoundCloudTrackRef, () => ({
      id: id,
      pausePlayer: pausePlayer
    }))

    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        style={{
          ...provided.draggableProps.style
        }}
        className="flex flex-col gap-2"
      >
        {trackId.trim().length > 0 && (
          <EditArtistSoundCloudPlayer
            ref={playerRef}
            trackId={trackId}
            draggableProvided={provided}
            onRemoveLink={() => onRemoveLink(id)}
            onPlay={() => onPlay(id)}
          />
        )}
      </div>
    )
  }
)

EditArtistSoundCloudTrack.displayName = 'EditArtistSoundCloudTrack'

export default EditArtistSoundCloudTrack
