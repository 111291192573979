import React from 'react'

import { AvailableCountries } from '@configuration/Constants'
import { useTheme } from '@configuration/ThemeContext'
import useTranslate from '@hooks/useTranslate'
import { Select, SelectItem } from '@nextui-org/react'

interface CountrySelectorProps {
  multiSelect: boolean
  selectedValues: string[]
  onSingleSelect?: (selectedCountry: string) => void
  onMultiSelect?: (selectedCountries: string[]) => void
}

const CountrySelector = ({
  multiSelect,
  selectedValues,
  onSingleSelect,
  onMultiSelect
}: CountrySelectorProps) => {
  const countriesTranslation = useTranslate('countries')
  const translate = useTranslate('components.shared.country_selector')
  const { isDarkMode } = useTheme()

  return (
    <Select
      fullWidth
      selectedKeys={selectedValues}
      label={translate('country')}
      selectionMode={multiSelect ? 'multiple' : 'single'}
      classNames={{
        label: 'z-0',
        popoverContent: `${isDarkMode ? 'text-foreground dark' : ''}`
      }}
      onChange={(e) =>
        multiSelect && onMultiSelect
          ? onMultiSelect(e.target.value.split(','))
          : onSingleSelect
            ? onSingleSelect(e.target.value)
            : null
      }
    >
      {AvailableCountries.sort((a, b) =>
        countriesTranslation(a.abbreviation).localeCompare(
          countriesTranslation(b.abbreviation)
        )
      ).map((country) => (
        <SelectItem
          key={country.databaseSelector}
          value={country.databaseSelector}
        >
          {countriesTranslation(country.abbreviation)}
        </SelectItem>
      ))}
    </Select>
  )
}

export default CountrySelector
