import React, { useState, useEffect } from 'react'

import { useTheme } from '@configuration/ThemeContext'
import { Spinner } from '@nextui-org/react'
import 'mapbox-gl/dist/mapbox-gl.css'
import Map, {
  Marker,
  MarkerDragEvent,
  ViewStateChangeEvent
} from 'react-map-gl'

interface MapBoxViewerProps {
  latitude?: number
  longitude?: number
  address?: string
  height?: number | string
  width?: number | string
  defaultZoom?: number
  onLocationSelect: (lat: number, lng: number) => void
  isLoading?: boolean
  reasonForBlock?: string
}

const MapBoxViewer = ({
  latitude = 37.7749,
  longitude = -122.4194,
  height = '500px',
  width = '100%',
  defaultZoom = 16,
  onLocationSelect,
  isLoading,
  reasonForBlock
}: MapBoxViewerProps) => {
  const { isDarkMode } = useTheme()
  const [marker, setMarker] = useState<[number, number]>([longitude, latitude])
  const [viewState, setViewState] = useState({
    longitude,
    latitude,
    zoom: defaultZoom
  })

  const selectLocation = (markerLocation: MarkerDragEvent) => {
    const newLat = markerLocation.lngLat.lat
    const newLng = markerLocation.lngLat.lng
    onLocationSelect(newLat, newLng)
    setMarker([newLng, newLat])
    setViewState((prev) => ({ ...prev, latitude: newLat, longitude: newLng }))
  }

  useEffect(() => {
    setViewState({
      longitude,
      latitude,
      zoom: defaultZoom
    })
    setMarker([longitude, latitude])
  }, [latitude, longitude])

  return (
    <div className="relative flex items-center" style={{ width, height }}>
      {(isLoading || reasonForBlock) && (
        <div className="absolute left-0 top-0 z-10 flex size-full flex-col items-center justify-center gap-3 overflow-hidden rounded-lg align-middle backdrop-blur">
          {isLoading && <Spinner size="lg" />}
          {reasonForBlock && (
            <span className="m-2 flex justify-center rounded-full bg-background/70 p-4 text-center align-middle text-small font-semibold text-foreground">
              {reasonForBlock}
            </span>
          )}
        </div>
      )}
      <Map
        {...viewState}
        onMove={(evt: ViewStateChangeEvent) => setViewState(evt.viewState)}
        style={{ width, height }}
        projection={{
          name: 'globe'
        }}
        mapStyle={
          isDarkMode
            ? 'mapbox://styles/partyner/cm1z2vtfi018f01qr5cmgfk3m'
            : 'mapbox://styles/partyner/cm1z2oo1m018d01qr9vbtapjh'
        }
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      >
        <Marker
          color="hsl(var(--nextui-foreground))"
          longitude={marker[0]}
          latitude={marker[1]}
          draggable={true}
          onDragEnd={selectLocation}
        />
      </Map>
    </div>
  )
}

export default MapBoxViewer
