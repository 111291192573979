import React, { useCallback } from 'react'

import PaginatedTable from '@components/Shared/PaginatedTable/PaginatedTable'
import VenuesTableColumnActions from '@components/Venues/VenuesTable/Columns/VenuesTableColumnActions'
import VenuesTableColumnAddress from '@components/Venues/VenuesTable/Columns/VenuesTableColumnAddress'
import VenuesTableColumnCountry from '@components/Venues/VenuesTable/Columns/VenuesTableColumnCountry'
import VenuesTableColumnName from '@components/Venues/VenuesTable/Columns/VenuesTableColumnName'
import VenuesTableFilter from '@components/Venues/VenuesTable/VenuesTableFilter'
import VenueDto from '@dtos/VenueDto'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMediaQueryHelpers from '@hooks/useMediaQueryHelpers'
import useTranslate from '@hooks/useTranslate'
import { Button, Tooltip } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'

enum columnUid {
  name = 'name',
  address = 'address',
  country = 'country',
  actions = 'actions'
}

interface VenuesTableProps {
  venueList: VenueDto[]
  totalPages: number
  selectedPage: number
  totalItems: number
  pageSize: number
  isLoading: boolean
  onSelectDeleteVenue: (venue: VenueDto) => void
  onChangePage: (page: number) => void
  onChangePageSize: (size: number) => void
  onApplyFilter: (
    name: string[],
    street: string[],
    neighborhood: string[],
    postalCode: string[],
    city: string[],
    state: string[],
    country: string[]
  ) => void
  onClearFilter: () => void
}

const VenuesTable = ({
  venueList,
  totalPages,
  selectedPage,
  totalItems,
  pageSize,
  isLoading,
  onSelectDeleteVenue,
  onChangePage,
  onChangePageSize,
  onApplyFilter,
  onClearFilter
}: VenuesTableProps) => {
  const translate = useTranslate('components.venues.venues_table')
  const navigate = useNavigate()
  const { isMedium } = useMediaQueryHelpers()

  const columns = [
    { name: translate('column_names.name'), uid: columnUid.name }
  ]

  if (!isMedium) {
    columns.push({
      name: translate('column_names.address'),
      uid: columnUid.address
    })
    columns.push({
      name: translate('column_names.country'),
      uid: columnUid.country
    })
  }

  columns.push({
    name: translate('column_names.actions'),
    uid: columnUid.actions
  })

  const renderCell = useCallback(
    (venue: VenueDto, columnKey: React.Key) => {
      switch (columnKey) {
        case columnUid.name:
          return (
            <VenuesTableColumnName
              name={venue.name}
              logoUrl={venue.logoUrl}
              venueId={venue.id}
              country={venue.address.country}
            />
          )
        case columnUid.country:
          return <VenuesTableColumnCountry country={venue.address.country} />
        case columnUid.address:
          return <VenuesTableColumnAddress address={venue.address} />
        case columnUid.actions:
          return (
            <VenuesTableColumnActions
              venueId={venue.id}
              onSelectDeleteVenue={() => onSelectDeleteVenue(venue)}
            />
          )
      }
    },
    [venueList]
  )

  return (
    <PaginatedTable
      items={venueList}
      columns={columns}
      cellRenderer={renderCell}
      totalPages={totalPages}
      selectedPage={selectedPage}
      pageSize={pageSize}
      totalItems={totalItems}
      isLoading={isLoading}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      emptyContent={translate('no_venues_found')}
      title={translate('title')}
      headerEndContent={
        <Tooltip color="success" content={translate('actions.add')}>
          <Button
            isIconOnly
            radius="full"
            color="success"
            size="sm"
            onClick={() => navigate('/venues/add')}
          >
            <FontAwesomeIcon icon={faPlus} size="lg" />
          </Button>
        </Tooltip>
      }
      filter={
        <VenuesTableFilter
          onApplyFilter={onApplyFilter}
          onClearFilter={onClearFilter}
        />
      }
    />
  )
}

export default VenuesTable
