import CreateUpdateGenreDto from '@dtos/CreateUpdateGenreDto'
import LocalizedStringDto from '@dtos/LocalizedStringDto'
import { LocalizedStringValidationSchema } from '@formSchemas/Shared'
import { array, ObjectSchema } from 'yup'
import * as yup from 'yup'

export const CreateUpdateGenreInitialValues: CreateUpdateGenreDto = {
  name: [
    {
      culture: 'en',
      value: ''
    }
  ]
}

export const GenreNameFormInitialValues = (
  culture: string
): LocalizedStringDto => ({
  culture: culture,
  value: ''
})

export const CreateUpdateGenreValidationSchema: ObjectSchema<CreateUpdateGenreDto> =
  yup.object({
    genreId: yup.string(),
    name: array()
      .of(LocalizedStringValidationSchema)

      .required()
  })
