import React, { useCallback, useEffect, useState } from 'react'

import { useTheme } from '@configuration/ThemeContext'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useCropImageHelpers from '@hooks/useCropImageHelpers'
import useTranslate from '@hooks/useTranslate'
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader
} from '@nextui-org/react'
import Cropper, { Area } from 'react-easy-crop'

interface CropImageModalProps {
  imageSrc?: string
  aspectRatio: number
  onClose: () => void
  onSave: (croppedImage: string) => void
}

const CropImageModal = ({
  imageSrc,
  aspectRatio,
  onClose,
  onSave
}: CropImageModalProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null)
  const [image, setImage] = useState<string | undefined>(imageSrc)
  const translate = useTranslate('components.artists.edit_artist')
  const { getCroppedImg } = useCropImageHelpers()
  const { isDarkMode } = useTheme()

  useEffect(() => {
    setImage(imageSrc)
  }, [imageSrc])

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels)
    },
    []
  )

  const handleSave = async () => {
    if (croppedAreaPixels && imageSrc) {
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels)
      onSave(croppedImage)
    }
  }

  return (
    <Modal
      size="full"
      closeButton
      backdrop="blur"
      aria-labelledby="modal-title"
      isOpen={!!image}
      onClose={onClose}
      className={isDarkMode ? 'text-foreground dark' : ''}
    >
      <ModalContent>
        <ModalHeader>
          <span className="pl-2 text-lg font-semibold text-foreground-500">
            {translate('adjust_image')}
          </span>
        </ModalHeader>
        <ModalBody>
          <div className="relative size-full">
            <Cropper
              classes={{ containerClassName: 'rounded-2xl' }}
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={aspectRatio}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button isIconOnly radius="full" color="success" onClick={handleSave}>
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CropImageModal
