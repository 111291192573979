import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react'

import {
  setIsLoadingUserAction,
  setLanguageAction,
  setLoggedUserAction,
  setThemeAction
} from '@actions/Global'
import { LocalStorageConstants } from '@configuration/Constants'
import SignInDto from '@dtos/SignInDto'
import useStoredUsers from '@hooks/useStoredUsers'
import useTranslate from '@hooks/useTranslate'
import PartynerJwtToken from '@interfaces/PartynerJwtToken'
import useAuthService from '@services/Auth'
import { RootState, store } from '@store'
import { jwtDecode } from 'jwt-decode'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

interface AuthContextType {
  isAuthenticated: boolean
  login: (rememberUser: boolean, signInDto: SignInDto) => Promise<void>
  logout: () => void
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  login: async () => {},
  logout: () => {}
})

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { signInUser, getUserProfile } = useAuthService()
  const { getStoredUsers, setStoredUsers } = useStoredUsers()
  const dispatch = useDispatch()
  const loggedUser = useSelector((state: RootState) => state.global.loggedUser)
  const translate = useTranslate('security')
  const { i18n } = useTranslation()

  const [token, setToken] = useState<string | null>(
    localStorage.getItem('auth-token')
  )

  useEffect(() => {
    if (token && !loggedUser) {
      const decodedToken = jwtDecode<PartynerJwtToken>(token)
      const users = getStoredUsers()
      const userFromToken = users.find(
        (user) => user.id === decodedToken.user.id
      )

      if (userFromToken)
        dispatch(
          setLoggedUserAction({
            id: userFromToken.id,
            displayName: userFromToken.displayName,
            email: userFromToken.email,
            avatarUrl: userFromToken.avatarUrl,
            name: ''
          })
        )

      dispatch(setIsLoadingUserAction(true))
      getUserProfile().then((userProfile) => {
        if (userProfile) {
          dispatch(setLoggedUserAction(userProfile))
          dispatch(
            setThemeAction(
              localStorage.getItem(LocalStorageConstants.THEME) ?? 'partyner'
            )
          )
          dispatch(setIsLoadingUserAction(false))
        } else {
          logout()
        }
      })
    }
  }, [loggedUser])

  const showErrorToast = () => {
    toast.error(translate('wrong_credentials'))
  }

  const login = async (rememberUser: boolean, signInDto: SignInDto) => {
    dispatch(setIsLoadingUserAction(true))
    const userToken = await signInUser(signInDto)
    if (userToken) {
      setToken(userToken.authToken)
      localStorage.setItem(
        LocalStorageConstants.AUTH_TOKEN,
        userToken.authToken
      )
      localStorage.setItem(
        LocalStorageConstants.REFRESH_TOKEN,
        userToken.refreshToken
      )

      const userProfile = await getUserProfile()

      if (userProfile) {
        dispatch(setLoggedUserAction(userProfile))
        store.dispatch(
          setThemeAction(
            localStorage.getItem(LocalStorageConstants.THEME) ?? 'partyner'
          )
        )
        const language =
          localStorage.getItem(LocalStorageConstants.LANGUAGE) ?? 'en'
        store.dispatch(setLanguageAction(language))
        i18n.changeLanguage(language)

        if (rememberUser) {
          const storedUsers = getStoredUsers()

          if (
            storedUsers.filter((user) => user.email == userProfile.email)
              .length <= 0
          ) {
            storedUsers.push({
              id: userProfile.id,
              displayName: userProfile.displayName,
              email: userProfile.email,
              avatarUrl: userProfile.avatarUrl
            })

            setStoredUsers(storedUsers)
          }
        }
      } else {
        logout()
        showErrorToast()
      }
    } else {
      showErrorToast()
    }
    dispatch(setIsLoadingUserAction(false))
  }

  const logout = () => {
    setToken(null)
    localStorage.removeItem(LocalStorageConstants.AUTH_TOKEN)
    localStorage.removeItem(LocalStorageConstants.REFRESH_TOKEN)
    dispatch(setLoggedUserAction(undefined))
  }

  const isAuthenticated = !!token

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) throw new Error('useAuth must be used within an AuthProvider')

  return context
}
