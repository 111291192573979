import React, { ReactNode } from 'react'

import { Radio } from '@nextui-org/react'

interface VenueRadioSelectorProps {
  value: string
  content?: string
  children?: ReactNode
  onSelect?: (value: string) => void
}

const VenueRadioSelector = ({
  value,
  content,
  children,
  onSelect
}: VenueRadioSelectorProps) => {
  return (
    <div
      className="flex cursor-pointer justify-center gap-0 rounded-xl border-2 border-content3 p-2 align-middle"
      onClick={() => (onSelect ? onSelect(value) : undefined)}
    >
      <Radio value={value} />
      {content && (
        <div className="flex flex-col justify-center gap-3 align-middle">
          <span className="text-sm text-foreground-400">{content}</span>
        </div>
      )}
      {children}
    </div>
  )
}

export default VenueRadioSelector
