import React, { useEffect, useState } from 'react'

import { setRootBreadcrumbAction } from '@actions/Breadcrumb'
import {
  clearProducersFilterAction,
  setProducersFilterAction
} from '@actions/ProducersFilter'
import ConfirmDeleteProducerModal from '@components/Producers/ConfirmDeleteProducerModal/ConfirmDeleteProducerModal'
import ProducersTable from '@components/Producers/ProducersTable/ProducersTable'
import { RootBreadcrumbConstants } from '@configuration/Constants'
import ProducerDto from '@dtos/ProducerDto'
import useProducerService from '@services/Producer'
import { RootState } from '@store'
import { useDispatch, useSelector } from 'react-redux'

const Producers = () => {
  const dispatch = useDispatch()
  const { getProducerList } = useProducerService()
  const producersFilterInMemory = useSelector(
    (state: RootState) => state.producersFilter
  )
  const [producerList, setProducerList] = useState<ProducerDto[]>([])
  const [producerToDelete, setProducerToDelete] = useState<
    ProducerDto | undefined
  >(undefined)

  const [totalPages, setTotalPages] = useState<number>(0)
  const [selectedPage, setSelectedPage] = useState<number>(
    producersFilterInMemory.page ?? 1
  )
  const [pageSize, setPageSize] = useState<number>(
    producersFilterInMemory.pageSize ?? 10
  )
  const [totalItems, setTotalItems] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)

  const [nameFilter, setNameFilter] = useState<string[]>(
    producersFilterInMemory.nameFilter ?? []
  )

  useEffect(() => {
    dispatch(setRootBreadcrumbAction(RootBreadcrumbConstants.PRODUCERS))
  }, [])

  useEffect(() => {
    loadTable()
  }, [selectedPage, pageSize, nameFilter])

  const loadTable = () => {
    setIsLoading(true)
    getProducerList(selectedPage, pageSize, nameFilter).then((result) => {
      if (result) {
        setProducerList(result.items)
        setTotalPages(result.totalPages)
        setTotalItems(result.totalItems)
      }
      setIsLoading(false)
    })
  }

  const closeDeleteProducerModal = (reloadTable: boolean) => {
    setProducerToDelete(undefined)
    if (reloadTable) loadTable()
  }

  const applyFilter = (nameFilter: string[]) => {
    setSelectedPage(1)
    setNameFilter(nameFilter)
    dispatch(setProducersFilterAction(1, pageSize, nameFilter))
  }

  const clearFilter = () => {
    if (nameFilter.length > 0) {
      setNameFilter([])
      setSelectedPage(1)
      dispatch(clearProducersFilterAction())
    }
  }

  const changePage = (page: number) => {
    dispatch(setProducersFilterAction(page, pageSize, nameFilter))
    setSelectedPage(page)
  }

  const changePageSize = (pageSize: number) => {
    dispatch(setProducersFilterAction(selectedPage, pageSize, nameFilter))
    setPageSize(pageSize)
  }

  return (
    <>
      <ConfirmDeleteProducerModal
        producer={producerToDelete}
        onClose={(reloadTable) => closeDeleteProducerModal(reloadTable)}
      />
      <ProducersTable
        producerList={producerList}
        totalPages={totalPages}
        selectedPage={selectedPage}
        totalItems={totalItems}
        pageSize={pageSize}
        isLoading={isLoading}
        onSelectDeleteProducer={setProducerToDelete}
        onChangePage={changePage}
        onChangePageSize={changePageSize}
        onApplyFilter={applyFilter}
        onClearFilter={clearFilter}
      />
    </>
  )
}

export default Producers
