import React, { useEffect, useState } from 'react'

import { useTheme } from '@configuration/ThemeContext'
import DropdownItemDto from '@dtos/DropdownItemDto'
import useTranslate from '@hooks/useTranslate'
import { Select, SelectItem } from '@nextui-org/react'
import useArtistService from '@services/Artist'

interface EditEventLineupArtistDropdownProps {
  addedArtists: string[]
  onSelectArtist: (artistId: string) => void
}

const EditEventLineupArtistDropdown = ({
  addedArtists,
  onSelectArtist
}: EditEventLineupArtistDropdownProps) => {
  const translate = useTranslate('components.events.edit_event')
  const [items, setItems] = useState<DropdownItemDto[]>([])
  const { isDarkMode } = useTheme()
  const { getArtistDropdownItems } = useArtistService()

  useEffect(() => {
    getArtistDropdownItems().then((response) => {
      if (response)
        setItems(
          response.filter(
            (artistDropdown) => !addedArtists.includes(artistDropdown.id)
          )
        )
    })
  }, [])

  return (
    <Select
      items={items}
      size="sm"
      label={translate('select_artist')}
      classNames={{
        label: 'z-0',
        popoverContent: `${isDarkMode ? 'text-foreground dark' : ''}`
      }}
      onChange={(e) => onSelectArtist(e.target.value)}
    >
      {(artist) => (
        <SelectItem key={`${artist.id}`}>{artist.displayName}</SelectItem>
      )}
    </Select>
  )
}

export default EditEventLineupArtistDropdown
