import React, { useState } from 'react'

import InputField from '@components/Shared/InputField'
import LocalizableTextAreaHeader from '@components/Shared/LocalizableTextArea/LocalizableTextAreaHeader'
import TextareaField from '@components/Shared/TextareaField'
import LocalizedStringDto from '@dtos/LocalizedStringDto'
import useTranslate from '@hooks/useTranslate'
import { Card, CardBody, Tab, Tabs } from '@nextui-org/react'

interface LocalizableTextAreaProps {
  fieldName: string
  label: string
  placeholder: string
  values: LocalizedStringDto[]
  onAddValue: (culture: string) => void
  onRemoveValue: (culture: string) => void
}

const LocalizableTextArea = ({
  fieldName,
  label,
  placeholder,
  values,
  onAddValue,
  onRemoveValue
}: LocalizableTextAreaProps) => {
  const languageTranslate = useTranslate('languages')
  const [selectedLanguage, setSelectedLanguage] = useState('')
  const [fieldsWithError, setFieldsWithError] = useState<string[]>([])

  const validate = (index: number, isValid: boolean) => {
    if (isValid) {
      setFieldsWithError((fields) => [
        ...fields.filter((value) => value !== values[index].culture)
      ])
    } else {
      setFieldsWithError((fields) => [...fields, values[index].culture])
    }
  }

  const languageFilter = values.map((language) => language.culture)

  return (
    <Card className="grow" fullWidth shadow="md">
      <LocalizableTextAreaHeader
        label={label}
        languageFilter={languageFilter}
        selectedLanguage={selectedLanguage}
        showRemoveButton={values.length > 1}
        onAddValue={(culture) => onAddValue(culture)}
        onRemoveValue={(culture) => onRemoveValue(culture)}
      />
      <CardBody>
        <Tabs
          classNames={{
            panel: 'grow px-0'
          }}
          items={values}
          onSelectionChange={(key) => setSelectedLanguage(key as string)}
        >
          {values.map((value, index) => (
            <Tab
              key={value.culture}
              title={
                <div
                  className={
                    fieldsWithError.includes(value.culture)
                      ? 'text-danger'
                      : 'text-foreground'
                  }
                >
                  {languageTranslate(value.culture)}
                </div>
              }
            >
              <InputField
                type="hidden"
                id={`${fieldName}[${index}].culture`}
                name={`${fieldName}[${index}].culture`}
                value={value.culture}
              />
              <TextareaField
                id={`${fieldName}[${index}].value`}
                name={`${fieldName}[${index}].value`}
                onValidate={(isValid) => validate(index, isValid)}
                maxRows={4}
                label={placeholder}
                className="w-full"
                classNames={{
                  base: 'h-full',
                  inputWrapper: '!h-full'
                }}
              />
            </Tab>
          ))}
        </Tabs>
      </CardBody>
    </Card>
  )
}

export default LocalizableTextArea
