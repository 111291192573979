import React from 'react'

import useTranslate from '@hooks/useTranslate'
import { ModalHeader } from '@nextui-org/react'

const ConfirmDeleteVenueModalHeader = () => {
  const translate = useTranslate('components.venues.remove_venue')
  return (
    <ModalHeader>
      <span>{translate('title')}</span>
      <span className="text-sm">{translate('subtitle')}</span>
    </ModalHeader>
  )
}

export default ConfirmDeleteVenueModalHeader
