import React from 'react'

import { Input, InputProps } from '@nextui-org/react'
import { useField } from 'formik'

interface FieldProps {
  id: string
  name: string
}

type SignInInputProps = FieldProps & InputProps

const SignInInput = (props: SignInInputProps) => {
  const { id, name, ...restProps } = props

  const [field, meta] = useField({ name, id })
  const hasError = meta.touched && !!meta.error

  return (
    <Input
      {...field}
      {...restProps}
      classNames={{
        input: [
          'bg-transparent',
          'text-black/90',
          'placeholder:text-default-700/50'
        ],
        innerWrapper: 'bg-transparent',
        inputWrapper: [
          'hover:bg-transparent',
          'shadow-xl',
          hasError ? 'border border-2 border-danger' : 'border-none',
          'bg-default-200/70',
          'backdrop-blur-xl',
          'backdrop-saturate-200',
          'focus-within:!bg-default-200/70',
          'hover:!bg-default-200/70',
          '!cursor-text'
        ]
      }}
    />
  )
}

export default SignInInput
