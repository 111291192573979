import AddressDto from '@dtos/AddressDto'
import LocalizedStringDto from '@dtos/LocalizedStringDto'
import PictureUploadDto from '@dtos/PictureUploadDto'
import UpdatePictureListFormValues from '@interfaces/Forms/Shared/UpdatePictureListFormValues'
import UpdateSocialNetworkListFormValues from '@interfaces/Forms/Shared/UpdateSocialNetworkListFormValues'
import * as yup from 'yup'
import { array, ObjectSchema } from 'yup'

export const LocalizedStringInitialValues = (
  culture: string
): LocalizedStringDto => ({
  culture: culture,
  value: ''
})

export const LocalizedStringValidationSchema: ObjectSchema<LocalizedStringDto> =
  yup.object({
    id: yup.string(),
    culture: yup.string().required(),
    value: yup.string().required('form_validation.field_required')
  })

export const AddressValidationSchema: ObjectSchema<AddressDto> = yup.object({
  street: yup.string().required('form_validation.field_required'),
  buildingIdentification: yup.string().notRequired(),
  buildingIdentificationAddons: yup.string().notRequired(),
  neighborhood: yup.string().notRequired(),
  postalCode: yup.string().notRequired(),
  city: yup.string().required('form_validation.field_required'),
  state: yup.string().notRequired(),
  country: yup.string().required('form_validation.field_required'),
  latitude: yup.number().required(),
  longitude: yup.number().required()
})

export const PictureUploadValidationSchema: ObjectSchema<PictureUploadDto> =
  yup.object({
    base64image: yup.string().required(),
    imageType: yup.string().required(),
    order: yup.number(),
    id: yup.string()
  })

export const UpdatePictureListFormValuesValidationSchema: ObjectSchema<UpdatePictureListFormValues> =
  yup.object({
    pictures: array()
      .of(PictureUploadValidationSchema)
      .required('form_validation.field_required'),
    entityId: yup.string()
  })

export const UpdatePictureListFormValuesInitialValues: UpdatePictureListFormValues =
  {
    pictures: [],
    entityId: undefined
  }

export const UpdateSocialNetworkListFormValuesValidationSchema: ObjectSchema<UpdateSocialNetworkListFormValues> =
  yup.object({
    entityId: yup.string(),
    links: array().of(
      yup.object({
        id: yup.string(),
        name: yup.string().required('form_validation.field_required'),
        link: yup.string().required('form_validation.field_required'),
        order: yup.number().required(),
        isNew: yup.boolean()
      })
    )
  })

export const UpdateSocialNetworkListFormValuesInitialValues: UpdateSocialNetworkListFormValues =
  {
    links: [],
    entityId: undefined
  }
