import React, { ReactNode, useState } from 'react'

import TicketFrame from '@assets/VisualElements/TicketFrame'

interface PartynerIconProps {
  children: ReactNode
  onClick?: () => void
  backgroundColor?: string
  idleOpacity?: number
  idleScale?: number
  roundSize?: string
  frameColor?: string
}

const PartynerIcon = ({
  children,
  onClick,
  backgroundColor,
  idleOpacity,
  idleScale,
  roundSize,
  frameColor
}: PartynerIconProps) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div
      onClick={() => (onClick ? onClick() : undefined)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`relative flex aspect-square size-full items-center justify-center overflow-hidden align-middle ${roundSize ?? 'rounded-xl'} ${onClick ? 'cursor-pointer' : ''}`}
      style={{
        opacity: isHovered ? 1 : idleOpacity ? idleOpacity / 100 : 1,
        transform: isHovered
          ? 'scale(1)'
          : `scale(${idleScale ? idleScale / 100 : 1})`,
        transition: 'opacity 0.3s ease, transform 0.3s ease'
      }}
    >
      <div className="absolute left-0 top-0 z-10 size-full">
        <TicketFrame stroke={frameColor ?? 'hsl(var(--nextui-white))'} />
      </div>
      <div
        className="z-0 flex size-full items-center justify-center"
        style={{
          backgroundColor: backgroundColor ?? 'hsl(var(--nextui-primary))'
        }}
      >
        <div className="z-0 flex size-full items-center justify-center">
          {children}
        </div>
      </div>
    </div>
  )
}

export default PartynerIcon
