import React from 'react'

import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import { Button, CardHeader } from '@nextui-org/react'

interface EditArtistSocialNetworksHeaderProps {
  onAddSoundCloudTrack: () => void
}

const EditArtistSoundCloudTracksHeader = ({
  onAddSoundCloudTrack
}: EditArtistSocialNetworksHeaderProps) => {
  const translate = useTranslate('components.artists.edit_artist')

  return (
    <CardHeader className="flex w-full justify-between">
      <div className="pl-2 text-lg font-semibold text-foreground-500">
        {translate('sound_cloud_tracks')}
      </div>
      <div className="flex justify-end gap-3">
        <Button
          isIconOnly
          size="sm"
          radius="full"
          color="success"
          onClick={onAddSoundCloudTrack}
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </div>
    </CardHeader>
  )
}

export default EditArtistSoundCloudTracksHeader
