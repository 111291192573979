import React, { ChangeEvent, useEffect, useRef, useState } from 'react'

import defaultAvatar from '@assets/Images/default_artist_avatar.png'
import defaultAvatarDark from '@assets/Images/default_artist_avatar_dark.png'
import CropImageModal from '@components/Shared/CropImageModal'
import FramedImage from '@components/Shared/FramedImage/FramedImage'
import { useTheme } from '@configuration/ThemeContext'
import PictureUploadDto from '@dtos/PictureUploadDto'
import {
  faArrowRotateBackward,
  faImage
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import { Button, Tooltip } from '@nextui-org/react'

interface ChangeableAvatarProps {
  name: string
  originalAvatarUrl: string
  onChangeAvatar: (newAvatar: PictureUploadDto | null) => void
}

const ChangeableAvatar = ({
  name,
  originalAvatarUrl,
  onChangeAvatar
}: ChangeableAvatarProps) => {
  const translate = useTranslate('components.shared.changeable_avatar')
  const [newAvatar, setNewAvatar] = useState<PictureUploadDto | null>(null)
  const [croppingImage, setCroppingImage] = useState<string | undefined>(
    undefined
  )
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const { isDarkMode } = useTheme()

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const image = reader.result as string
        setCroppingImage(image)
      }
      reader.readAsDataURL(file)
      fileInputRef.current!.value = ''
    }
  }

  const handleCropSave = (croppedImage: string) => {
    const match = croppedImage.match(/^data:image\/([a-zA-Z0-9]+);base64,/)
    const type = match ? match[1].toLowerCase() : ''
    setNewAvatar({ base64image: croppedImage, imageType: type })
    setCroppingImage(undefined)
  }

  useEffect(() => {
    onChangeAvatar(newAvatar)
  }, [newAvatar])

  return (
    <>
      <CropImageModal
        imageSrc={croppingImage}
        aspectRatio={1}
        onClose={() => setCroppingImage(undefined)}
        onSave={handleCropSave}
      />
      <div className="flex">
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleFileChange}
        />
        <FramedImage
          alt={name}
          classNames="shadow-lg shadow-foreground/10"
          src={
            newAvatar?.base64image
              ? newAvatar.base64image
              : originalAvatarUrl.trim().length > 0
                ? originalAvatarUrl
                : isDarkMode
                  ? defaultAvatarDark
                  : defaultAvatar
          }
          footer={
            <div className="flex gap-3">
              <Tooltip
                className={isDarkMode ? 'text-foreground dark' : ''}
                content={translate('change_avatar')}
              >
                <Button
                  isIconOnly
                  radius="full"
                  className="border-2 border-background/40 bg-background/60 shadow shadow-dark backdrop-blur"
                  onClick={handleButtonClick}
                >
                  <FontAwesomeIcon icon={faImage} size="lg" />
                </Button>
              </Tooltip>
              {newAvatar && (
                <Tooltip
                  className={isDarkMode ? 'text-foreground dark' : ''}
                  content={translate('restore_avatar')}
                >
                  <Button
                    className="border-2 border-background/40 bg-background/30 text-warning shadow shadow-dark backdrop-blur"
                    isIconOnly
                    radius="full"
                    onClick={() => setNewAvatar(null)}
                  >
                    <FontAwesomeIcon icon={faArrowRotateBackward} />
                  </Button>
                </Tooltip>
              )}
            </div>
          }
        />
      </div>
    </>
  )
}

export default ChangeableAvatar
