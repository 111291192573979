import CreateUpdateVenueDto from '@dtos/CreateUpdateVenueDto'
import DropdownItemDto from '@dtos/DropdownItemDto'
import PaginatedListDto from '@dtos/PaginatedListDto'
import PictureUploadDto from '@dtos/PictureUploadDto'
import SocialNetworkLinkDto from '@dtos/SocialNetworkLinkDto'
import UpdatePhotoBatchDto from '@dtos/UpdatePhotoBatchDto'
import VenueDto from '@dtos/VenueDto'
import useRootApiService from '@services/RootApi'

const useVenueService = () => {
  const { Get, Post, Put, Patch, Delete } = useRootApiService()

  const getVenueList = async (
    pageIndex: number,
    pageSize: number,
    nameFilter?: string[],
    streetFilter?: string[],
    neighborhoodFilter?: string[],
    postalCodeFilter?: string[],
    cityFilter?: string[],
    stateFilter?: string[],
    countryFilter?: string[]
  ): Promise<PaginatedListDto<VenueDto> | undefined> => {
    let params: any = { pageIndex, pageSize }

    if (nameFilter && nameFilter.length > 0)
      params = { ...params, name: nameFilter }

    if (streetFilter && streetFilter.length > 0)
      params = { ...params, street: streetFilter }

    if (neighborhoodFilter && neighborhoodFilter.length > 0)
      params = { ...params, neighborhood: neighborhoodFilter }

    if (postalCodeFilter && postalCodeFilter.length > 0)
      params = { ...params, postalCode: postalCodeFilter }

    if (cityFilter && cityFilter.length > 0)
      params = { ...params, city: cityFilter }

    if (stateFilter && stateFilter.length > 0)
      params = { ...params, city: stateFilter }

    if (countryFilter && countryFilter.length > 0)
      params = { ...params, country: countryFilter }

    const response = await Get<PaginatedListDto<VenueDto>>(`/venue`, params)

    return response.data
  }

  const getVenueById = async (
    venueId: string
  ): Promise<VenueDto | undefined> => {
    const response = await Get<VenueDto>(`/venue/${venueId}`)

    return response.data
  }

  const createVenue = async (
    mainInfoDto: CreateUpdateVenueDto
  ): Promise<VenueDto | undefined> => {
    const response = await Post<VenueDto, CreateUpdateVenueDto>(
      '/venue',
      mainInfoDto
    )

    return response.data
  }

  const updateVenue = async (
    venueId: string,
    mainInfoDto: CreateUpdateVenueDto
  ): Promise<VenueDto | undefined> => {
    const response = await Put<VenueDto, CreateUpdateVenueDto>(
      `/venue/${venueId}`,
      mainInfoDto
    )

    return response.data
  }

  const updateVenueLogo = async (
    venueId: string,
    logoDto: PictureUploadDto
  ): Promise<VenueDto | undefined> => {
    const response = await Put<VenueDto, PictureUploadDto>(
      `/venue/${venueId}/logo`,
      logoDto
    )

    return response.data
  }

  const deleteVenueLogo = async (
    venueId: string
  ): Promise<VenueDto | undefined> => {
    const response = await Delete<VenueDto, PictureUploadDto>(
      `/venue/${venueId}/logo`
    )

    return response.data
  }

  const uploadVenuePhoto = async (
    venueId: string,
    photoDto: PictureUploadDto
  ): Promise<VenueDto | undefined> => {
    const response = await Post<VenueDto, PictureUploadDto>(
      `/venue/${venueId}/photo`,
      photoDto
    )
    return response.data
  }

  const removeVenuePhoto = async (
    venueId: string,
    photoId: string
  ): Promise<VenueDto | undefined> => {
    const response = await Delete<VenueDto, undefined>(
      `/venue/${venueId}/photo/${photoId}`
    )
    return response.data
  }

  const updateVenuePhotoBatch = async (
    venueId: string,
    photos: UpdatePhotoBatchDto
  ): Promise<VenueDto | undefined> => {
    const response = await Patch<VenueDto, UpdatePhotoBatchDto>(
      `/venue/${venueId}/photo`,
      photos
    )
    return response.data
  }

  const deleteVenue = async (venueId: string): Promise<boolean> => {
    const response = await Delete(`/venue/${venueId}`)

    return response.success
  }

  const updateVenueSocialNetworkListBatch = async (
    venueId: string,
    socialNetworkLinks: SocialNetworkLinkDto[]
  ) => {
    socialNetworkLinks.forEach((snl) => (!snl.isNew ? snl.id : undefined))

    const response = await Patch<VenueDto, SocialNetworkLinkDto[]>(
      `/venue/${venueId}/social-network`,
      socialNetworkLinks
    )

    return response.data
  }

  const getVenueDropdownItems = async (): Promise<
    DropdownItemDto[] | undefined
  > => {
    const response = await Get<DropdownItemDto[]>('/venue/dropdown')

    return response.data
  }

  return {
    getVenueList,
    getVenueById,
    createVenue,
    updateVenue,
    updateVenueLogo,
    deleteVenueLogo,
    uploadVenuePhoto,
    removeVenuePhoto,
    updateVenuePhotoBatch,
    deleteVenue,
    updateVenueSocialNetworkListBatch,
    getVenueDropdownItems
  }
}

export default useVenueService
