import React, { Ref } from 'react'

import { setIsLoadingAction } from '@actions/Global'
import EditArtistGenres from '@components/Artists/EditArtist/Genres/EditArtistGenres'
import ArtistDto from '@dtos/ArtistDto'
import { ArtistGenresFormValidationSchema } from '@formSchemas/Artist'
import useTranslate from '@hooks/useTranslate'
import AddGenreToArtist from '@interfaces/Forms/Artist/AddGenreToArtist'
import { Card, CardBody, CardHeader } from '@nextui-org/react'
import useArtistService from '@services/Artist'
import { Formik, FormikProps } from 'formik'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

interface EditArtistGenresFormProps {
  values: AddGenreToArtist
  formRef: Ref<FormikProps<AddGenreToArtist>>
  formKey: number
  onSaveForm: (updatedArtist: ArtistDto) => void
}

const EditArtistGenresForm = ({
  values,
  formRef,
  formKey,
  onSaveForm
}: EditArtistGenresFormProps) => {
  const dispatch = useDispatch()
  const translate = useTranslate('components.artists.edit_artist')
  const errorsTranslate = useTranslate('errors')

  const { updateArtistGenreBatch } = useArtistService()

  const addGenre = (
    formikProps: FormikProps<AddGenreToArtist>,
    genreId: string,
    displayName: string
  ) => {
    formikProps.setFieldValue('genres', [
      ...formikProps.values.genres,
      { genreId, displayName }
    ])
  }

  const removeGenre = (
    formikProps: FormikProps<AddGenreToArtist>,
    genreId: string
  ) => {
    formikProps.setFieldValue(
      'genres',
      formikProps.values.genres.filter((g) => g.genreId != genreId)
    )
  }

  const handleError = () => {
    dispatch(setIsLoadingAction(false))
    toast.error(errorsTranslate('general'))
  }

  const saveForm = async (form: AddGenreToArtist) => {
    if (form.artistId) {
      const updatedArtist = await updateArtistGenreBatch(
        form.artistId,
        form.genres.map((genre) => genre.genreId)
      )

      if (!updatedArtist) return handleError()
      onSaveForm(updatedArtist)
    }
  }

  return (
    <Card fullWidth>
      <CardHeader>
        <div className="pl-2 text-lg font-semibold text-foreground-500">
          {translate('genres')}
        </div>
      </CardHeader>
      <Formik
        innerRef={formRef}
        key={formKey}
        initialValues={values}
        validationSchema={ArtistGenresFormValidationSchema}
        onSubmit={async (values) => await saveForm(values)}
      >
        {(props: FormikProps<AddGenreToArtist>) => (
          <form onSubmit={props.handleSubmit} noValidate>
            <CardBody>
              <EditArtistGenres
                genres={props}
                onRemoveGenre={(genre) => removeGenre(props, genre)}
                onAddGenre={(genreId, displayName) =>
                  addGenre(props, genreId, displayName)
                }
              />
            </CardBody>
          </form>
        )}
      </Formik>
    </Card>
  )
}

export default EditArtistGenresForm
