import React, { useEffect, useRef, useState } from 'react'

import FaceIcon from '@assets/Icons/FaceIcon'
import LogoInline from '@assets/VisualElements/LogoInline'
import PartynerVisualElement from '@assets/VisualElements/PartynerVisualElement'
import DefaultAvatar from '@components/Shared/DefaultAvatar'
import PartynerIcon from '@components/Shared/PartynerIcon'
import SignInNewUserCard, {
  SignInNewUserCardRef
} from '@components/SignIn/SignInNewUserCard'
import SignInUserCard, {
  SignInUserCardRef
} from '@components/SignIn/SignInUserCard'
import { LocalStorageConstants } from '@configuration/Constants'
import useMediaQueryHelpers from '@hooks/useMediaQueryHelpers'
import useStoredUsers from '@hooks/useStoredUsers'
import StoredUser from '@interfaces/StoredUser'
import { useAuth } from '@security/AuthContext'
import { useNavigate } from 'react-router-dom'

export default function App() {
  const { isAuthenticated } = useAuth()
  const { getStoredUsers } = useStoredUsers()
  const { isSmall } = useMediaQueryHelpers()

  const signInUserCardRefs = useRef<Array<SignInUserCardRef | null>>([])
  const signInNewUserCardRef = useRef<SignInNewUserCardRef>(null)

  const signInUserCardMobileRef = useRef<SignInUserCardRef>(null)
  const signInNewUserCardMobileRef = useRef<SignInNewUserCardRef>(null)

  const navigate = useNavigate()

  const [users, setUsers] = useState<StoredUser[]>([])
  const [selectedUserEmail, setSelectedUserEmail] = useState('')

  useEffect(() => {
    getUsers()
  }, [localStorage.getItem(LocalStorageConstants.STORED_USERS)])

  useEffect(() => {
    signInUserCardRefs.current = signInUserCardRefs.current.slice(
      0,
      users.length
    )
  }, [users])

  useEffect(() => {
    if (isAuthenticated) navigate('/')
  }, [isAuthenticated])

  useEffect(() => {
    if (selectedUserEmail !== '' && signInUserCardMobileRef.current)
      signInUserCardMobileRef.current.selectCard()

    if (selectedUserEmail === '' && signInNewUserCardMobileRef.current)
      signInNewUserCardMobileRef.current.selectCard()
  }, [selectedUserEmail])

  useEffect(() => {
    if (isSmall) {
      renderSmallScreen()
    }
  }, [selectedUserEmail, isSmall])

  const getUsers = () => {
    const users = getStoredUsers()
    setUsers(users)
    setSelectedUserEmail(users.length > 0 ? users[0].email : '')
  }

  const selectUser = (selectedUserEmail: string) => {
    if (signInUserCardRefs.current) {
      signInUserCardRefs.current
        .filter((user) => user?.email != selectedUserEmail)
        .forEach((user) => user?.deselectCard())
    }

    setSelectedUserEmail(selectedUserEmail)

    if (selectedUserEmail != '' && signInNewUserCardRef.current)
      signInNewUserCardRef.current.deselectCard()
  }

  const renderSmallScreen = () => {
    return selectedUserEmail === '' ? (
      <SignInNewUserCard
        ref={signInNewUserCardMobileRef}
        onSelectNewUser={() => selectUser('')}
      />
    ) : (
      <SignInUserCard
        ref={signInUserCardMobileRef}
        storedUser={users.find((user) => user.email === selectedUserEmail)!}
        onSelectUser={selectUser}
        onRemoveUser={getUsers}
      />
    )
  }

  const renderBigScreen = () => {
    return users.map((user, index) => (
      <SignInUserCard
        ref={(el) => (signInUserCardRefs.current[index] = el)}
        key={index}
        storedUser={user}
        onSelectUser={selectUser}
        onRemoveUser={getUsers}
      />
    ))
  }

  return (
    <div className="default-bg relative flex h-screen w-screen items-center justify-center partyner">
      <div className="absolute left-1/2 top-1/2 z-0 size-11/12 -translate-x-1/2 -translate-y-1/2 opacity-40 backdrop-blur">
        <PartynerVisualElement />
      </div>
      <div className="z-10 flex size-full -translate-y-16 flex-col items-center justify-center gap-3 sm:translate-y-0">
        <div className="flex w-1/2 items-center justify-center sm:w-2/12">
          <LogoInline />
        </div>
        <div className="flex w-full flex-col items-center justify-center gap-3 sm:flex-row sm:gap-10">
          {isSmall ? renderSmallScreen() : renderBigScreen()}
          {!isSmall && users.length <= 3 && (
            <SignInNewUserCard
              ref={signInNewUserCardRef}
              onSelectNewUser={() => selectUser('')}
            />
          )}
        </div>
      </div>
      {isSmall && (
        <div className="absolute bottom-0 z-10 flex w-full -translate-y-16 justify-center gap-2 align-middle">
          {users
            .filter((user) => user.email !== selectedUserEmail)
            .map((user) => (
              <div key={user.email} className="size-[64px]">
                <PartynerIcon onClick={() => selectUser(user.email)}>
                  {user.avatarUrl ? (
                    <img
                      src={user.avatarUrl}
                      alt={user.avatarUrl}
                      className="z-0 size-full object-cover"
                    />
                  ) : (
                    <DefaultAvatar displayName={user.displayName} />
                  )}
                </PartynerIcon>
              </div>
            ))}
          {users.length <= 3 && selectedUserEmail !== '' && (
            <div className="size-[64px]">
              <PartynerIcon onClick={() => selectUser('')}>
                <FaceIcon fill="hsl(var(--nextui-white))" />
              </PartynerIcon>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
