import React, { useCallback, useEffect, useState } from 'react'

import { setProducerAction } from '@actions/Global'
import { LocalStorageConstants } from '@configuration/Constants'
import AvailableProducerDto from '@dtos/AvailableProducerDto'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger
} from '@nextui-org/react'
import useAuthService from '@services/Auth'
import { RootState } from '@store'
import { useDispatch, useSelector } from 'react-redux'

const ProducerSelector = () => {
  const state = useSelector((state: RootState) => state.global)
  const dispatch = useDispatch()

  const [producers, setProducers] = useState<AvailableProducerDto[]>([])
  const [selectedProducer, setSelectedProducer] = useState<string | undefined>(
    undefined
  )

  const { getAvailableProducers, updateTokenWithProducer } = useAuthService()

  useEffect(() => {
    if (!state.loggedUser?.id) return

    getAvailableProducers().then((producers) => {
      if (producers && producers.length > 0) {
        setProducers(producers)

        const storageProducer = localStorage.getItem(
          `${LocalStorageConstants.PRODUCER}_${state.loggedUser!.id}`
        )

        if (
          storageProducer &&
          producers.filter((producer) => producer.id === storageProducer)
            .length > 0
        ) {
          setSelectedProducer(storageProducer)
          dispatch(setProducerAction(storageProducer))
        } else {
          setSelectedProducer(producers[0].id)
          dispatch(setProducerAction(producers[0].id))
        }
      }
    })
  }, [state.loggedUser])

  useEffect(() => {
    if (selectedProducer && state.loggedUser) {
      updateTokenWithProducer(selectedProducer).then((token) => {
        if (token) {
          dispatch(setProducerAction(selectedProducer))
          localStorage.setItem(
            `${LocalStorageConstants.PRODUCER}_${state.loggedUser!.id}`,
            selectedProducer
          )
          localStorage.setItem(
            LocalStorageConstants.AUTH_TOKEN,
            token.authToken
          )
          localStorage.setItem(
            LocalStorageConstants.REFRESH_TOKEN,
            token.refreshToken
          )
        }
      })
    }
  }, [selectedProducer])

  const producerName = useCallback(
    (producerId: string) => {
      return producers.find((producer) => producer.id === producerId)?.name
    },
    [producers]
  )

  if (producers.length === 0) return <></>

  return (
    <Dropdown>
      <DropdownTrigger>
        <div className="flex cursor-pointer gap-2 text-xs font-black uppercase text-white">
          {producerName(state.producer!)}
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </DropdownTrigger>
      <DropdownMenu
        selectionMode="single"
        onSelectionChange={(producer) =>
          setSelectedProducer(producer.currentKey)
        }
      >
        {producers.map((producer) => (
          <DropdownItem key={producer.id}>{producer.name}</DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default ProducerSelector
