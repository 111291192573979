import React, { ReactNode, useEffect, useState } from 'react'

import useTranslate from '@hooks/useTranslate'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger
} from '@nextui-org/react'
import i18n from 'i18next'

interface LocalizableTextAreaDropdownProps {
  onAddValue: (culture: string) => void
  languageFilter: string[]
  children: ReactNode
}

interface SupportedLanguages {
  code: string
  description: string
}

const LocalizableTextAreaDropdown = ({
  onAddValue,
  children,
  languageFilter
}: LocalizableTextAreaDropdownProps) => {
  const translate = useTranslate('languages')
  const supportedLanguages = i18n.options.supportedLngs
  const [languages, setLanguages] = useState<SupportedLanguages[]>([])

  useEffect(() => {
    if (supportedLanguages) {
      setLanguages([])
      supportedLanguages
        .filter((lng) => lng !== 'cimode' && !languageFilter.includes(lng))
        .map((lng) =>
          setLanguages((original) => [
            ...original,
            { code: lng, description: translate(lng) }
          ])
        )
    }
  }, [supportedLanguages, languageFilter])

  if (languages.length === 0) return <></>

  return (
    <Dropdown className="dark" placement="bottom-end">
      <DropdownTrigger>{children}</DropdownTrigger>
      <DropdownMenu variant="flat">
        {languages
          .sort((a, b) => a.description.localeCompare(b.description))
          .map((language) => (
            <DropdownItem
              key={language.code}
              className="text-foreground"
              color="default"
              onPress={() => onAddValue(language.code)}
            >
              {language.description}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  )
}

export default LocalizableTextAreaDropdown
