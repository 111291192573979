import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react'

import { setLoggedUserAction } from '@actions/Global'
import DefaultAvatar from '@components/Shared/DefaultAvatar'
import PartynerIcon from '@components/Shared/PartynerIcon'
import SignInInput from '@components/SignIn/SignInInput'
import { LocalStorageConstants } from '@configuration/Constants'
import SignInDto from '@dtos/SignInDto'
import {
  SignInFormInitialValues,
  SignInFormValidationSchema
} from '@formSchemas/SignIn'
import { faRemove, faSignIn } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import StoredUser from '@interfaces/StoredUser'
import { Button } from '@nextui-org/react'
import { useAuth } from '@security/AuthContext'
import { Formik, FormikProps } from 'formik'
import { useDispatch } from 'react-redux'

interface SignInUserCardProps {
  storedUser: StoredUser
  onSelectUser: (email: string) => void
  onRemoveUser: () => void
}

export type SignInUserCardRef = {
  selectCard: () => void
  deselectCard: () => void
  email: string
}

const SignInUserCard = forwardRef<SignInUserCardRef, SignInUserCardProps>(
  (
    { storedUser, onSelectUser, onRemoveUser }: SignInUserCardProps,
    signInUserCardRef
  ) => {
    const { login } = useAuth()
    const dispatch = useDispatch()
    const translate = useTranslate('components.sign_in')

    const [isUserSelected, setIsUserSelected] = useState(false)

    const formRef = useRef<FormikProps<SignInDto>>(null)

    useEffect(() => {
      if (formRef.current) {
        formRef.current.setValues({
          email: storedUser.email,
          password: ''
        })
      }
    }, [storedUser])

    const selectUser = (email: string) => {
      setIsUserSelected(true)
      onSelectUser(email)
    }

    const handleLogin = async (signInDto: SignInDto) => {
      dispatch(
        setLoggedUserAction({
          displayName: storedUser.displayName,
          email: storedUser.email,
          avatarUrl: storedUser.avatarUrl,
          id: '',
          name: ''
        })
      )
      await login(true, signInDto)
    }

    const removeUser = () => {
      const storedUsersString =
        localStorage.getItem(LocalStorageConstants.STORED_USERS) ?? '[]'

      const storedUsers = JSON.parse(storedUsersString) as StoredUser[]

      localStorage.setItem(
        LocalStorageConstants.STORED_USERS,
        JSON.stringify(
          storedUsers.filter((user) => user.email != storedUser.email)
        )
      )

      onRemoveUser()
    }

    useImperativeHandle(signInUserCardRef, () => ({
      email: storedUser.email,
      selectCard: () => setIsUserSelected(true),
      deselectCard: () => setIsUserSelected(false)
    }))

    return (
      <Formik
        innerRef={formRef}
        initialValues={SignInFormInitialValues}
        validationSchema={SignInFormValidationSchema}
        onSubmit={handleLogin}
      >
        {(props: FormikProps<SignInDto>) => (
          <form onSubmit={props.handleSubmit} noValidate>
            <div className="relative z-10 flex h-full w-[300px] flex-col items-center justify-center gap-3">
              <div className="flex aspect-square w-36 items-center justify-center">
                <PartynerIcon
                  idleOpacity={isUserSelected ? 100 : 70}
                  idleScale={isUserSelected ? 100 : 95}
                  onClick={() => selectUser(storedUser.email)}
                >
                  {storedUser.avatarUrl ? (
                    <img
                      src={storedUser.avatarUrl}
                      alt={storedUser.displayName}
                      className="z-0 size-full object-cover"
                    />
                  ) : (
                    <DefaultAvatar displayName={storedUser.displayName} />
                  )}
                </PartynerIcon>
              </div>
              <div className="flex flex-col items-center justify-center">
                <span className="font-partyner text-2xl text-white">
                  {storedUser.displayName}
                </span>
                <span className="font-partyner text-tiny font-light text-white">
                  {storedUser.email}
                </span>
              </div>
              <div
                className={`absolute top-full mt-3 flex w-full flex-col items-center justify-center gap-3 transition-all duration-500 ease-in-out ${
                  isUserSelected
                    ? 'translate-y-0 opacity-95'
                    : 'translate-y-8 opacity-0'
                }`}
              >
                <SignInInput
                  isDisabled={!isUserSelected}
                  id="password"
                  name="password"
                  fullWidth
                  type="password"
                  radius="full"
                  placeholder={translate('password')}
                  classNames={{
                    label: 'text-black/50',
                    input: [
                      'bg-transparent',
                      'text-black/90',
                      'placeholder:text-default-700/50'
                    ],
                    innerWrapper: 'bg-transparent',
                    inputWrapper: [
                      'hover:bg-transparent',
                      'shadow-xl',
                      'bg-default-200/70',
                      'backdrop-blur-xl',
                      'backdrop-saturate-200',
                      'focus-within:!bg-default-200/70',
                      'hover:!bg-default-200/70',
                      '!cursor-text'
                    ]
                  }}
                />
                <div className="flex items-center justify-center gap-3">
                  <Button
                    disabled={!isUserSelected}
                    className="bg-danger shadow-xl backdrop-blur-xl backdrop-saturate-200"
                    isIconOnly
                    radius="full"
                    onClick={removeUser}
                  >
                    <FontAwesomeIcon icon={faRemove} className="text-white" />
                  </Button>
                  <Button
                    disabled={!isUserSelected}
                    type="submit"
                    className="bg-primary shadow-xl backdrop-blur-xl backdrop-saturate-200"
                    isIconOnly
                    radius="full"
                  >
                    <FontAwesomeIcon icon={faSignIn} className="text-white" />
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    )
  }
)

SignInUserCard.displayName = 'SignInUserCard'

export default SignInUserCard
