import React from 'react'

import LogoInline from '@assets/VisualElements/LogoInline'
import LogoTicketP from '@assets/VisualElements/LogoTicketP'
import MainMenuItem from '@components/MainMenu/MainMenuItem'
import ProducerSelector from '@components/MainMenu/ProducerSelector'
import UserMenu from '@components/MainMenu/UserMenu/UserMenu'
import {
  MainMenuProducerItems,
  MainMenuGeneralItems
} from '@configuration/Constants'
import { Divider } from '@nextui-org/react'
import { RootState } from '@store'
import { useSelector } from 'react-redux'

const MainMenu = () => {
  const state = useSelector((state: RootState) => state.global)

  return (
    <div className="default-bg size-full">
      <div className="flex size-full flex-col justify-between gap-2 bg-black/40 p-2">
        <div>
          <div className="mb-10 mt-2 flex justify-between">
            <LogoTicketP
              fill="hsl(var(--nextui-white))"
              width={20}
              suppressHeight
            />
            <LogoInline
              fill="hsl(var(--nextui-white))"
              width={80}
              suppressHeight
            />
          </div>

          <div className="flex w-full flex-col gap-2">
            <ProducerSelector />
            {state.producer && (
              <>
                {MainMenuProducerItems.map((item, index) => (
                  <MainMenuItem key={index} menuOption={item} />
                ))}
                <div className="py-4">
                  <Divider className="bg-white/50" />
                </div>
              </>
            )}

            {MainMenuGeneralItems.map((item, index) => (
              <MainMenuItem key={index} menuOption={item} />
            ))}
          </div>
        </div>

        <div className="flex w-full flex-col gap-2">
          <div className="py-1">
            <Divider className="bg-white/50" />
          </div>
          <UserMenu />
        </div>
      </div>
    </div>
  )
}

export default MainMenu
