import ArtistDto from '@dtos/ArtistDto'
import CreateUpdateArtistDto from '@dtos/CreateUpdateArtistDto'
import CreateUpdateGenreDto from '@dtos/CreateUpdateGenreDto'
import DropdownItemDto from '@dtos/DropdownItemDto'
import PaginatedListDto from '@dtos/PaginatedListDto'
import PictureUploadDto from '@dtos/PictureUploadDto'
import SocialNetworkLinkDto from '@dtos/SocialNetworkLinkDto'
import SoundCloudTrackDto from '@dtos/SoundCloudTrackDto'
import UpdatePhotoBatchDto from '@dtos/UpdatePhotoBatchDto'
import useRootApiService from '@services/RootApi'

const useArtistService = () => {
  const { Get, Post, Put, Patch, Delete } = useRootApiService()

  const getArtistList = async (
    pageIndex: number,
    pageSize: number,
    namesFilter?: string[],
    genresFilter?: string[],
    countriesFilter?: string[]
  ): Promise<PaginatedListDto<ArtistDto> | undefined> => {
    let params: any = { pageIndex, pageSize }

    if (namesFilter && namesFilter.length > 0)
      params = { ...params, names: namesFilter }

    if (genresFilter && genresFilter.length > 0)
      params = { ...params, genres: genresFilter }

    if (countriesFilter && countriesFilter.length > 0)
      params = { ...params, countries: countriesFilter }

    const response = await Get<PaginatedListDto<ArtistDto>>(`/artist`, params)

    return response.data
  }

  const getArtistById = async (
    artistId: string
  ): Promise<ArtistDto | undefined> => {
    const response = await Get<ArtistDto>(`/artist/${artistId}`)

    return response.data
  }

  const createArtist = async (
    mainInfoDto: CreateUpdateArtistDto
  ): Promise<ArtistDto | undefined> => {
    const response = await Post<ArtistDto, CreateUpdateArtistDto>(
      '/artist',
      mainInfoDto
    )

    return response.data
  }

  const updateArtist = async (
    artistId: string,
    mainInfoDto: CreateUpdateArtistDto
  ): Promise<ArtistDto | undefined> => {
    const response = await Put<ArtistDto, CreateUpdateArtistDto>(
      `/artist/${artistId}`,
      mainInfoDto
    )

    return response.data
  }

  const updateArtistAvatar = async (
    artistId: string,
    avatarDto: PictureUploadDto
  ): Promise<ArtistDto | undefined> => {
    const response = await Put<ArtistDto, PictureUploadDto>(
      `/artist/${artistId}/avatar`,
      avatarDto
    )

    return response.data
  }

  const uploadArtistPhoto = async (
    artistId: string,
    photoDto: PictureUploadDto
  ): Promise<ArtistDto | undefined> => {
    const response = await Post<ArtistDto, PictureUploadDto>(
      `/artist/${artistId}/photo`,
      photoDto
    )
    return response.data
  }

  const removeArtistPhoto = async (
    artistId: string,
    photoId: string
  ): Promise<ArtistDto | undefined> => {
    const response = await Delete<ArtistDto, undefined>(
      `/artist/${artistId}/photo/${photoId}`
    )
    return response.data
  }

  const updateArtistPhotoBatch = async (
    artistId: string,
    photos: UpdatePhotoBatchDto
  ): Promise<ArtistDto | undefined> => {
    const response = await Patch<ArtistDto, UpdatePhotoBatchDto>(
      `/artist/${artistId}/photo`,
      photos
    )
    return response.data
  }

  const addGenreToArtist = async (
    artistId: string,
    genreDto: CreateUpdateGenreDto
  ): Promise<ArtistDto | undefined> => {
    const response = await Post<ArtistDto, CreateUpdateGenreDto>(
      `/artist/${artistId}/genre`,
      genreDto
    )

    return response.data
  }

  const removeGenreFromArtist = async (
    artistId: string,
    genreDto: CreateUpdateGenreDto
  ): Promise<ArtistDto | undefined> => {
    const response = await Delete<ArtistDto, CreateUpdateGenreDto>(
      `/artist/${artistId}/genre`,
      genreDto
    )
    return response.data
  }

  const updateArtistGenreBatch = async (
    artistId: string,
    genreIds: string[]
  ): Promise<ArtistDto | undefined> => {
    const response = await Patch<ArtistDto, string[]>(
      `/artist/${artistId}/genre`,
      genreIds
    )
    return response.data
  }

  const deleteArtist = async (artistId: string): Promise<boolean> => {
    const response = await Delete(`/artist/${artistId}`)

    return response.success
  }

  const updateArtistSocialNetworkListBatch = async (
    artistId: string,
    socialNetworkLinks: SocialNetworkLinkDto[]
  ) => {
    socialNetworkLinks.forEach((snl) => (!snl.isNew ? snl.id : undefined))

    const response = await Patch<ArtistDto, SocialNetworkLinkDto[]>(
      `/artist/${artistId}/social-network`,
      socialNetworkLinks
    )

    return response.data
  }

  const updateArtistSoundCloudTracksBatch = async (
    artistId: string,
    soundCloudTracks: SoundCloudTrackDto[]
  ) => {
    soundCloudTracks.forEach((sct) => (!sct.isNew ? sct.id : undefined))

    const response = await Patch<ArtistDto, SoundCloudTrackDto[]>(
      `/artist/${artistId}/sound-cloud`,
      soundCloudTracks
    )

    return response.data
  }

  const getArtistDropdownItems = async (): Promise<
    DropdownItemDto[] | undefined
  > => {
    const response = await Get<DropdownItemDto[]>('/artist/dropdown')

    return response.data
  }

  return {
    getArtistList,
    getArtistById,
    createArtist,
    updateArtist,
    updateArtistAvatar,
    uploadArtistPhoto,
    removeArtistPhoto,
    updateArtistPhotoBatch,
    addGenreToArtist,
    removeGenreFromArtist,
    updateArtistGenreBatch,
    deleteArtist,
    updateArtistSocialNetworkListBatch,
    updateArtistSoundCloudTracksBatch,
    getArtistDropdownItems
  }
}

export default useArtistService
