import React from 'react'

import { setThemeAction } from '@actions/Global'
import { LocalStorageConstants } from '@configuration/Constants'
import { useTheme } from '@configuration/ThemeContext'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import { Switch } from '@nextui-org/react'
import { useDispatch } from 'react-redux'

const UserMenuThemeSelector = () => {
  const { isDarkMode } = useTheme()
  const translate = useTranslate('main_menu.user_menu')
  const dispatch = useDispatch()

  const changeTheme = (setLightMode: boolean) => {
    const theme = setLightMode ? 'partyner' : 'dark'
    dispatch(setThemeAction(theme))
    localStorage.setItem(LocalStorageConstants.THEME, theme)
  }

  return (
    <div className="flex min-w-[200px] items-center justify-center gap-2 align-middle">
      <div
        onClick={() => changeTheme(false)}
        className={`cursor-pointer text-sm text-foreground-400 ${isDarkMode ? 'font-semibold' : ''}`}
      >
        {translate('dark')}
      </div>
      <Switch
        isSelected={!isDarkMode}
        onValueChange={changeTheme}
        color="warning"
        size="sm"
        classNames={{
          wrapper: 'mr-0'
        }}
        startContent={<FontAwesomeIcon icon={faSun} />}
        endContent={<FontAwesomeIcon icon={faMoon} />}
      />
      <div
        onClick={() => changeTheme(true)}
        className={`cursor-pointer text-sm text-foreground-400 ${!isDarkMode ? 'font-semibold' : ''}`}
      >
        {translate('light')}
      </div>
    </div>
  )
}

export default UserMenuThemeSelector
