import React from 'react'

import ArtistIcon from '@assets/Icons/ArtistIcon'
import EventIcon from '@assets/Icons/EventIcon'
import GenreIcon from '@assets/Icons/GenreIcon'
import ProducerIcon from '@assets/Icons/ProducerIcon'
import VenueIcon from '@assets/Icons/VenueIcon'
import CountryDto from '@dtos/CountryDto'
import {
  faInstagram,
  faSoundcloud,
  faSpotify,
  faSquareFacebook,
  faTiktok,
  faXTwitter,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import {
  faChartLine,
  faGear,
  faGlobe,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MainMenuOption from '@interfaces/MainMenuOption'
import SocialNetworkDefinition from '@interfaces/SocialNetworkDefinition'

export const LocalStorageConstants = {
  AUTH_TOKEN: 'auth-token',
  REFRESH_TOKEN: 'refresh-token',
  THEME: 'theme',
  LANGUAGE: 'language',
  STORED_USERS: 'stored-users',
  PRODUCER: 'producer'
}

export enum RootBreadcrumbConstants {
  DASHBOARD = 'dashboard',
  ARTISTS = 'artists',
  VENUES = 'venues',
  PRODUCERS = 'producers',
  EVENTS = 'events',
  USERS = 'users',
  SETTINGS = 'settings',
  GENRES = 'genres'
}

export const MainMenuProducerItems: MainMenuOption[] = [
  {
    text: 'dashboard',
    icon: <FontAwesomeIcon icon={faChartLine} />,
    destination: '/'
  },
  {
    text: 'events',
    icon: <EventIcon />,
    destination: '/events'
  },
  {
    text: 'users',
    icon: <FontAwesomeIcon icon={faUser} />,
    destination: '/users'
  },
  {
    text: 'settings',
    icon: <FontAwesomeIcon icon={faGear} />,
    destination: '/settings'
  }
]

export const MainMenuGeneralItems: MainMenuOption[] = [
  {
    text: 'artists',
    icon: <ArtistIcon />,
    destination: '/artists'
  },
  {
    text: 'venues',
    icon: <VenueIcon />,
    destination: '/venues'
  },
  {
    text: 'producers',
    icon: <ProducerIcon />,
    destination: '/producers'
  },
  {
    text: 'genres',
    icon: <GenreIcon />,
    destination: '/genres'
  },
  {
    text: 'users',
    icon: <FontAwesomeIcon icon={faUser} />,
    destination: '/users'
  },
  {
    text: 'settings',
    icon: <FontAwesomeIcon icon={faGear} />,
    destination: '/settings'
  }
]

export const AvailableSocialNetworks: SocialNetworkDefinition[] = [
  {
    name: 'instagram',
    icon: faInstagram
  },
  {
    name: 'x',
    icon: faXTwitter
  },
  {
    name: 'facebook',
    icon: faSquareFacebook
  },
  {
    name: 'tiktok',
    icon: faTiktok
  },
  {
    name: 'youtube',
    icon: faYoutube
  },
  {
    name: 'soundcloud',
    icon: faSoundcloud
  },
  {
    name: 'spotify',
    icon: faSpotify
  },
  {
    name: 'website',
    icon: faGlobe
  }
]

export enum VenueVisibility {
  VISIBLE = 'visible',
  HIDDEN = 'hidden',
  SCHEDULED = 'scheduled'
}

export const GetVenueVisibilityValue = (venueVisibility: string) => {
  switch (venueVisibility) {
    case VenueVisibility.VISIBLE:
      return 0
    case VenueVisibility.HIDDEN:
      return 1
    case VenueVisibility.SCHEDULED:
      return 2
  }
}

export const AvailableCountries: CountryDto[] = [
  { abbreviation: 'ad', databaseSelector: 'AND' },
  { abbreviation: 'ae', databaseSelector: 'ARE' },
  { abbreviation: 'af', databaseSelector: 'AFG' },
  { abbreviation: 'ag', databaseSelector: 'ATG' },
  { abbreviation: 'ai', databaseSelector: 'AGI' },
  { abbreviation: 'al', databaseSelector: 'ALB' },
  { abbreviation: 'am', databaseSelector: 'ARM' },
  { abbreviation: 'ao', databaseSelector: 'AGO' },
  { abbreviation: 'aq', databaseSelector: 'ANT' },
  { abbreviation: 'ar', databaseSelector: 'ARG' },
  { abbreviation: 'as', databaseSelector: 'ASA' },
  { abbreviation: 'at', databaseSelector: 'AUT' },
  { abbreviation: 'au', databaseSelector: 'AUS' },
  { abbreviation: 'aw', databaseSelector: 'ABW' },
  { abbreviation: 'ax', databaseSelector: 'AXI' },
  { abbreviation: 'az', databaseSelector: 'AZE' },
  { abbreviation: 'ba', databaseSelector: 'BIH' },
  { abbreviation: 'bb', databaseSelector: 'BRB' },
  { abbreviation: 'bd', databaseSelector: 'BGD' },
  { abbreviation: 'be', databaseSelector: 'BEL' },
  { abbreviation: 'bf', databaseSelector: 'BFA' },
  { abbreviation: 'bg', databaseSelector: 'BGR' },
  { abbreviation: 'bh', databaseSelector: 'BHR' },
  { abbreviation: 'bi', databaseSelector: 'BDI' },
  { abbreviation: 'bj', databaseSelector: 'BEN' },
  { abbreviation: 'bl', databaseSelector: 'BLY' },
  { abbreviation: 'bm', databaseSelector: 'BER' },
  { abbreviation: 'bn', databaseSelector: 'BRN' },
  { abbreviation: 'bo', databaseSelector: 'BOL' },
  { abbreviation: 'bq', databaseSelector: 'BQN' },
  { abbreviation: 'br', databaseSelector: 'BRA' },
  { abbreviation: 'bs', databaseSelector: 'BHS' },
  { abbreviation: 'bt', databaseSelector: 'BTN' },
  { abbreviation: 'bv', databaseSelector: 'BVT' },
  { abbreviation: 'bw', databaseSelector: 'BWA' },
  { abbreviation: 'by', databaseSelector: 'BLR' },
  { abbreviation: 'bz', databaseSelector: 'BLZ' },
  { abbreviation: 'ca', databaseSelector: 'CAN' },
  { abbreviation: 'cc', databaseSelector: 'CCI' },
  { abbreviation: 'cd', databaseSelector: 'CRC' },
  { abbreviation: 'cf', databaseSelector: 'CAF' },
  { abbreviation: 'cg', databaseSelector: 'CNG' },
  { abbreviation: 'ch', databaseSelector: 'CHE' },
  { abbreviation: 'ci', databaseSelector: 'CIV' },
  { abbreviation: 'ck', databaseSelector: 'COK' },
  { abbreviation: 'cl', databaseSelector: 'CHL' },
  { abbreviation: 'cm', databaseSelector: 'CMR' },
  { abbreviation: 'cn', databaseSelector: 'CHN' },
  { abbreviation: 'co', databaseSelector: 'COL' },
  { abbreviation: 'cr', databaseSelector: 'CRI' },
  { abbreviation: 'cu', databaseSelector: 'CUB' },
  { abbreviation: 'cv', databaseSelector: 'CPV' },
  { abbreviation: 'cw', databaseSelector: 'CUW' },
  { abbreviation: 'cx', databaseSelector: 'CXR' },
  { abbreviation: 'cy', databaseSelector: 'CYP' },
  { abbreviation: 'cz', databaseSelector: 'CZK' },
  { abbreviation: 'de', databaseSelector: 'DEU' },
  { abbreviation: 'dj', databaseSelector: 'DJI' },
  { abbreviation: 'dk', databaseSelector: 'DNK' },
  { abbreviation: 'dm', databaseSelector: 'DMA' },
  { abbreviation: 'do', databaseSelector: 'DOM' },
  { abbreviation: 'dz', databaseSelector: 'DZA' },
  { abbreviation: 'ec', databaseSelector: 'ECU' },
  { abbreviation: 'ee', databaseSelector: 'EST' },
  { abbreviation: 'eg', databaseSelector: 'EGY' },
  { abbreviation: 'eh', databaseSelector: 'WSH' },
  { abbreviation: 'er', databaseSelector: 'ERI' },
  { abbreviation: 'es', databaseSelector: 'ESP' },
  { abbreviation: 'et', databaseSelector: 'ETH' },
  { abbreviation: 'fi', databaseSelector: 'FIN' },
  { abbreviation: 'fj', databaseSelector: 'FJI' },
  { abbreviation: 'fk', databaseSelector: 'FLK' },
  { abbreviation: 'fm', databaseSelector: 'FSM' },
  { abbreviation: 'fo', databaseSelector: 'FRO' },
  { abbreviation: 'fr', databaseSelector: 'FRA' },
  { abbreviation: 'ga', databaseSelector: 'GAB' },
  { abbreviation: 'gb', databaseSelector: 'GBR' },
  { abbreviation: 'gb-eng', databaseSelector: 'ENG' },
  { abbreviation: 'gb-nir', databaseSelector: 'NIR' },
  { abbreviation: 'gb-sct', databaseSelector: 'SCT' },
  { abbreviation: 'gb-wls', databaseSelector: 'WLS' },
  { abbreviation: 'gd', databaseSelector: 'GRD' },
  { abbreviation: 'ge', databaseSelector: 'GEO' },
  { abbreviation: 'gf', databaseSelector: 'GFR' },
  { abbreviation: 'gg', databaseSelector: 'GGY' },
  { abbreviation: 'gh', databaseSelector: 'GHA' },
  { abbreviation: 'gi', databaseSelector: 'GIB' },
  { abbreviation: 'gl', databaseSelector: 'GRE' },
  { abbreviation: 'gm', databaseSelector: 'GMB' },
  { abbreviation: 'gn', databaseSelector: 'GIN' },
  { abbreviation: 'gp', databaseSelector: 'GDP' },
  { abbreviation: 'gq', databaseSelector: 'GNQ' },
  { abbreviation: 'gr', databaseSelector: 'GRC' },
  { abbreviation: 'gs', databaseSelector: 'SGG' },
  { abbreviation: 'gt', databaseSelector: 'GTM' },
  { abbreviation: 'gu', databaseSelector: 'GUA' },
  { abbreviation: 'gw', databaseSelector: 'GWB' },
  { abbreviation: 'gy', databaseSelector: 'GUY' },
  { abbreviation: 'hk', databaseSelector: 'HKG' },
  { abbreviation: 'hn', databaseSelector: 'HND' },
  { abbreviation: 'hr', databaseSelector: 'HRV' },
  { abbreviation: 'ht', databaseSelector: 'HTI' },
  { abbreviation: 'hu', databaseSelector: 'HUN' },
  { abbreviation: 'id', databaseSelector: 'IDN' },
  { abbreviation: 'ie', databaseSelector: 'IRL' },
  { abbreviation: 'il', databaseSelector: 'ISR' },
  { abbreviation: 'im', databaseSelector: 'IOM' },
  { abbreviation: 'in', databaseSelector: 'IND' },
  { abbreviation: 'io', databaseSelector: 'IOT' },
  { abbreviation: 'iq', databaseSelector: 'IRQ' },
  { abbreviation: 'ir', databaseSelector: 'IRN' },
  { abbreviation: 'is', databaseSelector: 'ISL' },
  { abbreviation: 'it', databaseSelector: 'ITA' },
  { abbreviation: 'je', databaseSelector: 'JER' },
  { abbreviation: 'jm', databaseSelector: 'JAM' },
  { abbreviation: 'jo', databaseSelector: 'JOR' },
  { abbreviation: 'jp', databaseSelector: 'JPN' },
  { abbreviation: 'ke', databaseSelector: 'KEN' },
  { abbreviation: 'kg', databaseSelector: 'KGZ' },
  { abbreviation: 'kh', databaseSelector: 'KHM' },
  { abbreviation: 'ki', databaseSelector: 'KIR' },
  { abbreviation: 'km', databaseSelector: 'COM' },
  { abbreviation: 'kn', databaseSelector: 'KNA' },
  { abbreviation: 'kp', databaseSelector: 'NKO' },
  { abbreviation: 'kr', databaseSelector: 'SKO' },
  { abbreviation: 'kw', databaseSelector: 'KWT' },
  { abbreviation: 'ky', databaseSelector: 'CYM' },
  { abbreviation: 'kz', databaseSelector: 'KAZ' },
  { abbreviation: 'la', databaseSelector: 'LAO' },
  { abbreviation: 'lb', databaseSelector: 'LBN' },
  { abbreviation: 'lc', databaseSelector: 'LCA' },
  { abbreviation: 'li', databaseSelector: 'LIE' },
  { abbreviation: 'lk', databaseSelector: 'LKA' },
  { abbreviation: 'lr', databaseSelector: 'LBR' },
  { abbreviation: 'ls', databaseSelector: 'LSO' },
  { abbreviation: 'lt', databaseSelector: 'LTU' },
  { abbreviation: 'lu', databaseSelector: 'LUX' },
  { abbreviation: 'lv', databaseSelector: 'LVA' },
  { abbreviation: 'ly', databaseSelector: 'LBY' },
  { abbreviation: 'ma', databaseSelector: 'MAR' },
  { abbreviation: 'mc', databaseSelector: 'MCO' },
  { abbreviation: 'md', databaseSelector: 'MDA' },
  { abbreviation: 'me', databaseSelector: 'MNE' },
  { abbreviation: 'mf', databaseSelector: 'SMF' },
  { abbreviation: 'mg', databaseSelector: 'MDG' },
  { abbreviation: 'mh', databaseSelector: 'MHL' },
  { abbreviation: 'mk', databaseSelector: 'MKD' },
  { abbreviation: 'ml', databaseSelector: 'MLI' },
  { abbreviation: 'mm', databaseSelector: 'MMR' },
  { abbreviation: 'mn', databaseSelector: 'MNG' },
  { abbreviation: 'mo', databaseSelector: 'MAC' },
  { abbreviation: 'mp', databaseSelector: 'NMI' },
  { abbreviation: 'mq', databaseSelector: 'MTN' },
  { abbreviation: 'mr', databaseSelector: 'MRT' },
  { abbreviation: 'ms', databaseSelector: 'MSR' },
  { abbreviation: 'mt', databaseSelector: 'MLT' },
  { abbreviation: 'mu', databaseSelector: 'MUS' },
  { abbreviation: 'mv', databaseSelector: 'MDV' },
  { abbreviation: 'mw', databaseSelector: 'MWI' },
  { abbreviation: 'mx', databaseSelector: 'MEX' },
  { abbreviation: 'my', databaseSelector: 'MYS' },
  { abbreviation: 'mz', databaseSelector: 'MOZ' },
  { abbreviation: 'na', databaseSelector: 'NAM' },
  { abbreviation: 'nc', databaseSelector: 'NCD' },
  { abbreviation: 'ne', databaseSelector: 'NER' },
  { abbreviation: 'nf', databaseSelector: 'NKI' },
  { abbreviation: 'ng', databaseSelector: 'NGA' },
  { abbreviation: 'ni', databaseSelector: 'NIC' },
  { abbreviation: 'nl', databaseSelector: 'NLD' },
  { abbreviation: 'no', databaseSelector: 'NOR' },
  { abbreviation: 'np', databaseSelector: 'NPL' },
  { abbreviation: 'nr', databaseSelector: 'NRU' },
  { abbreviation: 'nu', databaseSelector: 'NUE' },
  { abbreviation: 'nz', databaseSelector: 'NZL' },
  { abbreviation: 'om', databaseSelector: 'OMN' },
  { abbreviation: 'pa', databaseSelector: 'PAN' },
  { abbreviation: 'pe', databaseSelector: 'PER' },
  { abbreviation: 'pf', databaseSelector: 'FPY' },
  { abbreviation: 'pg', databaseSelector: 'PNG' },
  { abbreviation: 'ph', databaseSelector: 'PHL' },
  { abbreviation: 'pk', databaseSelector: 'PAK' },
  { abbreviation: 'pl', databaseSelector: 'POL' },
  { abbreviation: 'pm', databaseSelector: 'SPM' },
  { abbreviation: 'pn', databaseSelector: 'PCI' },
  { abbreviation: 'pr', databaseSelector: 'PUR' },
  { abbreviation: 'ps', databaseSelector: 'PAL' },
  { abbreviation: 'pt', databaseSelector: 'PRT' },
  { abbreviation: 'pw', databaseSelector: 'PLW' },
  { abbreviation: 'py', databaseSelector: 'PRY' },
  { abbreviation: 'qa', databaseSelector: 'QAT' },
  { abbreviation: 're', databaseSelector: 'RUN' },
  { abbreviation: 'ro', databaseSelector: 'ROU' },
  { abbreviation: 'rs', databaseSelector: 'SRB' },
  { abbreviation: 'ru', databaseSelector: 'RUS' },
  { abbreviation: 'rw', databaseSelector: 'RWA' },
  { abbreviation: 'sa', databaseSelector: 'SAU' },
  { abbreviation: 'sb', databaseSelector: 'SLB' },
  { abbreviation: 'sc', databaseSelector: 'SYC' },
  { abbreviation: 'sd', databaseSelector: 'SDN' },
  { abbreviation: 'se', databaseSelector: 'SWE' },
  { abbreviation: 'sg', databaseSelector: 'SGP' },
  { abbreviation: 'sh', databaseSelector: 'HAT' },
  { abbreviation: 'si', databaseSelector: 'SVN' },
  { abbreviation: 'sj', databaseSelector: 'SJM' },
  { abbreviation: 'sk', databaseSelector: 'SVK' },
  { abbreviation: 'sl', databaseSelector: 'SLE' },
  { abbreviation: 'sm', databaseSelector: 'SMR' },
  { abbreviation: 'sn', databaseSelector: 'SEN' },
  { abbreviation: 'so', databaseSelector: 'SOM' },
  { abbreviation: 'sr', databaseSelector: 'SUR' },
  { abbreviation: 'ss', databaseSelector: 'SSD' },
  { abbreviation: 'st', databaseSelector: 'STP' },
  { abbreviation: 'sv', databaseSelector: 'SLV' },
  { abbreviation: 'sx', databaseSelector: 'SXM' },
  { abbreviation: 'sy', databaseSelector: 'SYR' },
  { abbreviation: 'sz', databaseSelector: 'SZW' },
  { abbreviation: 'tc', databaseSelector: 'TCI' },
  { abbreviation: 'td', databaseSelector: 'TCD' },
  { abbreviation: 'tf', databaseSelector: 'FSA' },
  { abbreviation: 'tg', databaseSelector: 'TGO' },
  { abbreviation: 'th', databaseSelector: 'THA' },
  { abbreviation: 'tj', databaseSelector: 'TJK' },
  { abbreviation: 'tk', databaseSelector: 'TKL' },
  { abbreviation: 'tl', databaseSelector: 'TIL' },
  { abbreviation: 'tm', databaseSelector: 'TKM' },
  { abbreviation: 'tn', databaseSelector: 'TUN' },
  { abbreviation: 'to', databaseSelector: 'TON' },
  { abbreviation: 'tr', databaseSelector: 'TUR' },
  { abbreviation: 'tt', databaseSelector: 'TTO' },
  { abbreviation: 'tv', databaseSelector: 'TUV' },
  { abbreviation: 'tw', databaseSelector: 'TWN' },
  { abbreviation: 'tz', databaseSelector: 'TZA' },
  { abbreviation: 'ua', databaseSelector: 'UKR' },
  { abbreviation: 'ug', databaseSelector: 'UGA' },
  { abbreviation: 'us', databaseSelector: 'USA' },
  { abbreviation: 'uy', databaseSelector: 'URY' },
  { abbreviation: 'uz', databaseSelector: 'UZB' },
  { abbreviation: 'va', databaseSelector: 'VAT' },
  { abbreviation: 'vc', databaseSelector: 'VCT' },
  { abbreviation: 've', databaseSelector: 'VEN' },
  { abbreviation: 'vg', databaseSelector: 'BVI' },
  { abbreviation: 'vi', databaseSelector: 'UVI' },
  { abbreviation: 'vn', databaseSelector: 'VNM' },
  { abbreviation: 'vu', databaseSelector: 'VUT' },
  { abbreviation: 'wf', databaseSelector: 'WAF' },
  { abbreviation: 'ws', databaseSelector: 'SAM' },
  { abbreviation: 'xk', databaseSelector: 'XKO' },
  { abbreviation: 'ye', databaseSelector: 'YEM' },
  { abbreviation: 'yt', databaseSelector: 'MYT' },
  { abbreviation: 'za', databaseSelector: 'ZAF' },
  { abbreviation: 'zm', databaseSelector: 'ZMB' },
  { abbreviation: 'zw', databaseSelector: 'ZWE' }
]
