import { BreadcrumbActions, BreadcrumbsActionTypes } from '@actions/Breadcrumb'
import { RootBreadcrumbConstants } from '@configuration/Constants'
import Breadcrumb from '@interfaces/Breadcrumb'

import i18n from '../../i18n'

export interface BreadcrumbState {
  breadcrumbs: Breadcrumb[]
}

const initialBreadcrumbState: BreadcrumbState = {
  breadcrumbs: []
}

const BreadcrumbReducers = (
  state: BreadcrumbState = initialBreadcrumbState,
  action: BreadcrumbActions
) => {
  switch (action.type) {
    case BreadcrumbsActionTypes.ADD_BREADCRUMB:
      return {
        breadcrumbs:
          state.breadcrumbs.length > 0
            ? [...state.breadcrumbs, ...action.payload]
            : [...action.payload]
      }
    case BreadcrumbsActionTypes.CLEAR_BREADCRUMBS:
      return {
        breadcrumbs: []
      }
    case BreadcrumbsActionTypes.REMOVE_BREADCRUMB: {
      const newBreadcrumbs = [...state.breadcrumbs]

      for (let i = newBreadcrumbs.length - 1; i >= 0; i--) {
        const currentBreadcrumb = newBreadcrumbs[i]

        if (currentBreadcrumb.path === action.payload) {
          newBreadcrumbs.splice(i, 1)
          break
        }
      }
      return {
        breadcrumbs: newBreadcrumbs
      }
    }
    case BreadcrumbsActionTypes.SET_ROOT_BREADCRUMB: {
      const newBreadcrumbs: Breadcrumb[] = []

      switch (action.payload.root) {
        case RootBreadcrumbConstants.DASHBOARD:
          newBreadcrumbs.push({
            title: i18n.t(`pages.${RootBreadcrumbConstants.DASHBOARD}`),
            path: '/'
          })
          break
        case RootBreadcrumbConstants.ARTISTS:
          newBreadcrumbs.push({
            title: i18n.t(`pages.${RootBreadcrumbConstants.ARTISTS}`),
            path: '/artists'
          })
          break
        case RootBreadcrumbConstants.VENUES:
          newBreadcrumbs.push({
            title: i18n.t(`pages.${RootBreadcrumbConstants.VENUES}`),
            path: '/venues'
          })
          break
        case RootBreadcrumbConstants.PRODUCERS:
          newBreadcrumbs.push({
            title: i18n.t(`pages.${RootBreadcrumbConstants.PRODUCERS}`),
            path: '/producers'
          })
          break
        case RootBreadcrumbConstants.EVENTS:
          newBreadcrumbs.push({
            title: i18n.t(`pages.${RootBreadcrumbConstants.EVENTS}`),
            path: '/events'
          })
          break
        case RootBreadcrumbConstants.GENRES:
          newBreadcrumbs.push({
            title: i18n.t(`pages.${RootBreadcrumbConstants.GENRES}`),
            path: '/genres'
          })
          break
        case RootBreadcrumbConstants.USERS:
          newBreadcrumbs.push({
            title: i18n.t(`pages.${RootBreadcrumbConstants.USERS}`),
            path: '/users'
          })
          break
        case RootBreadcrumbConstants.SETTINGS:
          newBreadcrumbs.push({
            title: i18n.t(`pages.${RootBreadcrumbConstants.SETTINGS}`),
            path: '/settings'
          })
          break
        default:
          break
      }

      if (action.payload.complement)
        newBreadcrumbs.push(...action.payload.complement)

      return {
        breadcrumbs: newBreadcrumbs
      }
    }
    default:
      return state
  }
}

export default BreadcrumbReducers
