import CreateUpdateLineupArtistDto from '@dtos/CreateUpdateLineupArtistDto'
import {
  LocalizedStringValidationSchema,
  PictureUploadValidationSchema
} from '@formSchemas/Shared'
import CreateUpdateEventLineup from '@interfaces/Forms/Event/CreateUpdateEventLineup'
import CreateUpdateEventLineupDate from '@interfaces/Forms/Event/CreateUpdateEventLineupDate'
import CreateUpdateEventMainInfo from '@interfaces/Forms/Event/CreateUpdateEventMainInfo'
import UpdateEventVenue from '@interfaces/Forms/Event/UpdateEventVenue'
import * as yup from 'yup'
import { array, ObjectSchema } from 'yup'

export const EventMainInfoFormInitialValues = (
  mainProducerId: string
): CreateUpdateEventMainInfo => ({
  name: '',
  mainProducerId: mainProducerId,
  avatar: undefined,
  releaseDate: undefined,
  endDate: undefined,
  description: [
    {
      culture: 'en',
      value: ''
    }
  ]
})

export const EventVenueFormInitialValues: UpdateEventVenue = {
  venueId: '',
  venueVisibility: 0
}

export const EventLineupFormInitialValues: CreateUpdateEventLineup = {
  lineup: []
}

const EventLineupArtistValidationSchema: ObjectSchema<CreateUpdateLineupArtistDto> =
  yup.object({
    artistId: yup.string().required('form_validation.field_required'),
    time: yup.string().nullable(),
    order: yup.number().required()
  })

const EventLineupValidationSchema: ObjectSchema<CreateUpdateEventLineupDate> =
  yup.object({
    id: yup.string().required(),
    date: yup.date().required('form_validation.field_required'),
    isNew: yup.boolean().required(),
    artists: array()
      .of(EventLineupArtistValidationSchema)
      .required('form_validation.field_required')
  })

export const EventMainInfoFormValidationSchema: ObjectSchema<CreateUpdateEventMainInfo> =
  yup.object({
    name: yup.string().required('form_validation.field_required'),
    mainProducerId: yup.string().required('form_validation.field_required'),
    description: array().of(LocalizedStringValidationSchema).required(),
    avatar: PictureUploadValidationSchema.notRequired(),
    eventId: yup.string(),
    releaseDate: yup.date().required('form_validation.field_required'),
    endDate: yup.date().required('form_validation.field_required')
  })

export const EventVenueFormValidationSchema: ObjectSchema<UpdateEventVenue> =
  yup.object({
    venueId: yup.string().required('form_validation.field_required'),
    eventId: yup.string(),
    venueVisibility: yup.number().required('form_validation.field_required'),
    venueReleaseDate: yup.date().nullable('form_validation.field_required')
  })

export const EventLineupFormValidationSchema: ObjectSchema<CreateUpdateEventLineup> =
  yup.object({
    eventId: yup.string(),
    lineup: array()
      .of(EventLineupValidationSchema)
      .required('form_validation.field_required')
  })
