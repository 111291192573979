import React from 'react'

import ErrorPicture from '@assets/VisualElements/ErrorPicture'
import useTranslate from '@hooks/useTranslate'
import { Card, CardBody } from '@nextui-org/react'

interface ErrorPageProps {
  code?: number
}

const ErrorPage = ({ code }: ErrorPageProps) => {
  const translate = useTranslate('errors')
  return (
    <Card fullWidth>
      <CardBody className="m-3 flex w-full flex-col items-center gap-3 align-middle">
        <span className="text-xl font-semibold text-primary">
          {translate('title')}
        </span>
        <span className="text-foreground">
          {translate(code === 404 ? 'not_found' : 'general')}
        </span>
        <div className=" w-full items-center text-center md:w-1/2">
          <ErrorPicture
            primary="hsl(var(--nextui-primary-400))"
            secondary="hsl(var(--nextui-content1))"
            details="hsl(var(--nextui-foreground-200))"
          />
        </div>
      </CardBody>
    </Card>
  )
}

export default ErrorPage
