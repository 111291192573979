import { LocalStorageConstants } from '@configuration/Constants'
import ApiResponseDto from '@dtos/ApiResponseDto'
import PartynerApiError from '@interfaces/PartynerApiError'
import { appInsights } from '@services/ApplicationInsights'
import axios, {
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig
} from 'axios'
import qs from 'qs'

const useRootApiService = () => {
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json'
    }
  })

  async function refreshToken() {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/user/refresh-token`,
      {
        authToken: localStorage.getItem(LocalStorageConstants.AUTH_TOKEN),
        refreshToken: localStorage.getItem(LocalStorageConstants.REFRESH_TOKEN)
      }
    )

    const { authToken, refreshToken } = response.data

    localStorage.setItem(LocalStorageConstants.AUTH_TOKEN, authToken)
    localStorage.setItem(LocalStorageConstants.REFRESH_TOKEN, refreshToken)

    return authToken
  }

  axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const authToken = localStorage.getItem(LocalStorageConstants.AUTH_TOKEN)

      if (authToken) {
        config.headers['Authorization'] = `Bearer ${authToken}`
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        try {
          const newTokenResponse = await refreshToken()
          axiosInstance.defaults.headers.common['Authorization'] =
            `Bearer ${newTokenResponse}`
          return axiosInstance(originalRequest)
        } catch (refreshError) {
          localStorage.removeItem(LocalStorageConstants.AUTH_TOKEN)
          localStorage.removeItem(LocalStorageConstants.REFRESH_TOKEN)
          window.location.href = '/signin'
          return Promise.reject(refreshError)
        }
      }
      return Promise.reject(error)
    }
  )

  function handleError(error: unknown) {
    if (axios.isAxiosError(error)) {
      const partynerApiError = new PartynerApiError(
        error.message,
        error.response?.status,
        error.response?.data
      )

      if (error.status !== 401 && error.status !== 404)
        appInsights.trackException({
          exception: partynerApiError,
          properties: {
            status: partynerApiError.status,
            data: JSON.stringify(partynerApiError.data)
          }
        })

      return {
        data: undefined,
        success: false,
        code: error.response?.status ?? 400,
        error: error.response?.data
      }
    }

    return Promise.reject(error)
  }

  async function Get<T>(
    path: string,
    params?: any
  ): Promise<ApiResponseDto<T>> {
    try {
      const response: AxiosResponse<T> = await axiosInstance.get(path, {
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      })
      return {
        data: response.data,
        success: true,
        code: response.status,
        error: undefined
      }
    } catch (error: unknown) {
      return handleError(error)
    }
  }

  async function Post<T, TBody>(
    path: string,
    body: TBody,
    authorization?: string | undefined
  ): Promise<ApiResponseDto<T>> {
    try {
      const response: AxiosResponse<T> = await axiosInstance.post(path, body, {
        headers: {
          Authorization: authorization,
          ClientId: process.env.REACT_APP_API_CLIENT_ID,
          ClientSecret: process.env.REACT_APP_API_CLIENT_SECRET
        }
      })
      return {
        data: response.data,
        success: true,
        code: response.status,
        error: undefined
      }
    } catch (error: unknown) {
      return handleError(error)
    }
  }

  async function PostWithoutRefreshToken<T, TBody>(
    path: string,
    body: TBody
  ): Promise<ApiResponseDto<T>> {
    try {
      const tempInstance: AxiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        timeout: 30000,
        headers: {
          'Content-Type': 'application/json'
        }
      })

      const response: AxiosResponse<T> = await tempInstance.post(path, body, {
        headers: {
          ClientId: process.env.REACT_APP_API_CLIENT_ID,
          ClientSecret: process.env.REACT_APP_API_CLIENT_SECRET
        }
      })
      return {
        data: response.data,
        success: true,
        code: response.status,
        error: undefined
      }
    } catch (error: unknown) {
      return handleError(error)
    }
  }

  async function Put<T, TBody>(
    path: string,
    body: TBody
  ): Promise<ApiResponseDto<T>> {
    try {
      const response: AxiosResponse<T> = await axiosInstance.put(path, body)
      return {
        data: response.data,
        success: true,
        code: response.status,
        error: undefined
      }
    } catch (error: unknown) {
      return handleError(error)
    }
  }

  async function Patch<T, TBody>(
    path: string,
    body?: TBody
  ): Promise<ApiResponseDto<T>> {
    try {
      const response: AxiosResponse<T> = await axiosInstance.patch(path, body)
      return {
        data: response.data,
        success: true,
        code: response.status,
        error: undefined
      }
    } catch (error: unknown) {
      return handleError(error)
    }
  }

  async function Delete<T, TBody>(
    path: string,
    body?: TBody
  ): Promise<ApiResponseDto<T>> {
    try {
      const response: AxiosResponse<T> = await axiosInstance.delete(path, {
        data: body
      })
      return {
        data: response.data,
        success: true,
        code: response.status,
        error: undefined
      }
    } catch (error: unknown) {
      return handleError(error)
    }
  }

  return { Get, Post, Put, Patch, Delete, PostWithoutRefreshToken }
}

export default useRootApiService
