import CreateUpdateEventDto from '@dtos/CreateUpdateEventDto'
import EventDto from '@dtos/EventDto'
import PaginatedListDto from '@dtos/PaginatedListDto'
import PictureUploadDto from '@dtos/PictureUploadDto'
import SocialNetworkLinkDto from '@dtos/SocialNetworkLinkDto'
import UpdatePhotoBatchDto from '@dtos/UpdatePhotoBatchDto'
import useRootApiService from '@services/RootApi'

const useEventService = () => {
  const { Get, Post, Put, Patch, Delete } = useRootApiService()

  const getEventList = async (
    pageIndex: number,
    pageSize: number,
    nameFilter?: string[]
  ): Promise<PaginatedListDto<EventDto> | undefined> => {
    let params: any = { pageIndex, pageSize }

    if (nameFilter && nameFilter.length > 0)
      params = { ...params, name: nameFilter }

    const response = await Get<PaginatedListDto<EventDto>>(`/event`, params)

    return response.data
  }

  const getEventById = async (
    eventId: string
  ): Promise<EventDto | undefined> => {
    const response = await Get<EventDto>(`/event/${eventId}`)

    return response.data
  }

  const createEvent = async (
    mainInfoDto: CreateUpdateEventDto
  ): Promise<EventDto | undefined> => {
    const response = await Post<EventDto, CreateUpdateEventDto>(
      '/event',
      mainInfoDto
    )

    return response.data
  }

  const updateEvent = async (
    eventId: string,
    mainInfoDto: CreateUpdateEventDto
  ): Promise<EventDto | undefined> => {
    const response = await Put<EventDto, CreateUpdateEventDto>(
      `/event/${eventId}`,
      mainInfoDto
    )

    return response.data
  }

  const updateEventAvatar = async (
    eventId: string,
    avatarDto: PictureUploadDto
  ): Promise<EventDto | undefined> => {
    const response = await Put<EventDto, PictureUploadDto>(
      `/event/${eventId}/avatar`,
      avatarDto
    )

    return response.data
  }

  const deleteEventAvatar = async (
    eventId: string
  ): Promise<EventDto | undefined> => {
    const response = await Delete<EventDto, PictureUploadDto>(
      `/event/${eventId}/avatar`
    )

    return response.data
  }

  const updateEventDates = async (
    eventId: string,
    releaseDate: Date,
    endDate: Date
  ): Promise<EventDto | undefined> => {
    const response = await Patch<EventDto, any>(
      `/event/${eventId}/dates?releaseDate=${releaseDate}&endDate=${endDate}`
    )

    return response.data
  }

  const uploadEventPhoto = async (
    eventId: string,
    photoDto: PictureUploadDto
  ): Promise<EventDto | undefined> => {
    const response = await Post<EventDto, PictureUploadDto>(
      `/event/${eventId}/photo`,
      photoDto
    )
    return response.data
  }

  const removeEventPhoto = async (
    eventId: string,
    photoId: string
  ): Promise<EventDto | undefined> => {
    const response = await Delete<EventDto, undefined>(
      `/event/${eventId}/photo/${photoId}`
    )
    return response.data
  }

  const updateEventPhotoBatch = async (
    eventId: string,
    photos: UpdatePhotoBatchDto
  ): Promise<EventDto | undefined> => {
    const response = await Patch<EventDto, UpdatePhotoBatchDto>(
      `/event/${eventId}/photo`,
      photos
    )
    return response.data
  }

  const deleteEvent = async (eventId: string): Promise<boolean> => {
    const response = await Delete(`/event/${eventId}`)

    return response.success
  }

  const updateEventSocialNetworkListBatch = async (
    eventId: string,
    socialNetworkLinks: SocialNetworkLinkDto[]
  ) => {
    socialNetworkLinks.forEach((snl) => (!snl.isNew ? snl.id : undefined))

    const response = await Patch<EventDto, SocialNetworkLinkDto[]>(
      `/event/${eventId}/social-network`,
      socialNetworkLinks
    )

    return response.data
  }

  const updateEventVenue = async (eventId: string, venueId: string) => {
    const response = await Patch<EventDto, any>(
      `/event/${eventId}/venue?venueId=${venueId}`
    )

    return response.data
  }

  const updateEventVenueVisibility = async (
    eventId: string,
    venueVisibility: number
  ) => {
    const response = await Patch<EventDto, any>(
      `/event/${eventId}/venue/visibility?venueVisibility=${venueVisibility}`
    )

    return response.data
  }

  const updateEventVenueReleaseDate = async (
    eventId: string,
    releaseDate?: Date | null
  ) => {
    const dateParam = `${releaseDate ? `?releaseDate=${releaseDate}` : ''}`

    const response = await Patch<EventDto, any>(
      `/event/${eventId}/venue/release${dateParam}`
    )

    return response.data
  }

  const updateEventLineups = async (eventId: string, lineupIds: string[]) => {
    const response = await Patch<EventDto, any>(
      `/event/${eventId}/lineups`,
      lineupIds
    )

    return response.data
  }

  return {
    getEventList,
    getEventById,
    createEvent,
    updateEvent,
    updateEventAvatar,
    deleteEventAvatar,
    updateEventDates,
    uploadEventPhoto,
    removeEventPhoto,
    updateEventPhotoBatch,
    deleteEvent,
    updateEventSocialNetworkListBatch,
    updateEventVenue,
    updateEventVenueVisibility,
    updateEventVenueReleaseDate,
    updateEventLineups
  }
}

export default useEventService
