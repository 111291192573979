import React, { Ref } from 'react'

import { setIsLoadingAction } from '@actions/Global'
import EditSocialNetworkListDraggable from '@components/Shared/EditSocialNetworkList/EditSocialNetworkListDraggable'
import EditSocialNetworkListHeader from '@components/Shared/EditSocialNetworkList/EditSocialNetworkListHeader'
import { UpdateSocialNetworkListFormValuesValidationSchema } from '@formSchemas/Shared'
import useTranslate from '@hooks/useTranslate'
import UpdateSocialNetworkListFormValues from '@interfaces/Forms/Shared/UpdateSocialNetworkListFormValues'
import { Card, CardBody } from '@nextui-org/react'
import { Formik, FormikProps } from 'formik'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { v4 as uuidv4 } from 'uuid'

interface EditSocialNetworkListFormProps<TEntity> {
  values: UpdateSocialNetworkListFormValues
  formKey: number
  formRef: Ref<FormikProps<UpdateSocialNetworkListFormValues>>
  onSaveForm: (entity: TEntity) => void
  updateSocialNetworkService: (...params: any[]) => Promise<TEntity | undefined>
}

const EditSocialNetworkListForm = <TEntity,>({
  values,
  formKey,
  formRef,
  onSaveForm,
  updateSocialNetworkService
}: EditSocialNetworkListFormProps<TEntity>) => {
  const dispatch = useDispatch()
  const translate = useTranslate('components.shared.edit_social_network_list')
  const errorsTranslate = useTranslate('errors')

  const addSocialNetwork = (
    formikProps: FormikProps<UpdateSocialNetworkListFormValues>,
    name: string
  ) => {
    formikProps.setFieldValue('links', [
      ...formikProps.values.links!,
      {
        id: uuidv4(),
        name: name,
        link: '',
        order: formikProps.values.links!.length + 1,
        isNew: true
      }
    ])
  }

  const removeSocialNetwork = (
    formikProps: FormikProps<UpdateSocialNetworkListFormValues>,
    id: string
  ) => {
    formikProps.setFieldValue('links', [
      ...formikProps.values.links!.filter((values) => values.id !== id)
    ])
  }

  const reorderSocialNetworks = (
    formikProps: FormikProps<UpdateSocialNetworkListFormValues>,
    result: DropResult
  ) => {
    if (!result.destination) return

    const reorderedSocialNetworks = [...formikProps.values.links!]
    const [movedSocialNetwork] = reorderedSocialNetworks.splice(
      result.source.index,
      1
    )
    reorderedSocialNetworks.splice(
      result.destination.index,
      0,
      movedSocialNetwork
    )

    reorderedSocialNetworks.map(
      (socialNetwork, index) => (socialNetwork.order = index + 1)
    )

    formikProps.setFieldValue('links', [...reorderedSocialNetworks])
  }

  const handleError = () => {
    dispatch(setIsLoadingAction(false))
    toast.error(errorsTranslate('general'))
  }

  const saveForm = async (form: UpdateSocialNetworkListFormValues) => {
    if (form.entityId && form.links) {
      const updatedEntity = await updateSocialNetworkService(
        form.entityId,
        form.links
      )
      if (!updatedEntity) return handleError()
      onSaveForm(updatedEntity)
    }
  }

  return (
    <Formik
      innerRef={formRef}
      key={formKey}
      initialValues={values}
      validationSchema={UpdateSocialNetworkListFormValuesValidationSchema}
      onSubmit={async (values) => saveForm(values)}
    >
      {(props: FormikProps<UpdateSocialNetworkListFormValues>) => (
        <form onSubmit={props.handleSubmit} noValidate>
          <Card fullWidth>
            <EditSocialNetworkListHeader
              onAddSocialNetwork={(name) => addSocialNetwork(props, name)}
            />
            <CardBody>
              <DragDropContext
                onDragEnd={(result) => reorderSocialNetworks(props, result)}
              >
                <Droppable droppableId="socialNetworkList" direction="vertical">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {props.values.links!.length === 0 ? (
                        <div className="mb-3 flex w-full justify-center text-small opacity-40">
                          {translate('no_social_network_added')}
                        </div>
                      ) : (
                        <EditSocialNetworkListDraggable
                          values={props.values}
                          onRemoveLink={(id) => removeSocialNetwork(props, id)}
                        />
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </CardBody>
          </Card>
        </form>
      )}
    </Formik>
  )
}

export default EditSocialNetworkListForm
