import React from 'react'

import { Card, CardFooter, Image } from '@nextui-org/react'

interface ConfirmDeleteArtistModalBodyAvatarProps {
  avatarUrl?: string
  name?: string
}

const ConfirmDeleteArtistModalBodyAvatar = ({
  avatarUrl,
  name
}: ConfirmDeleteArtistModalBodyAvatarProps) => {
  return (
    <Card
      isFooterBlurred
      radius="lg"
      className="w-[250px] border-t-1 border-danger shadow-md shadow-danger"
      fullWidth={false}
    >
      <Image
        className="object-cover"
        height={250}
        src={avatarUrl}
        width={250}
      />
      <CardFooter className="absolute bottom-1 z-10 ml-1 w-[calc(100%_-_8px)] justify-center gap-3 overflow-hidden rounded-large border-1 border-gray-50/20 bg-gray-950/50 py-1 text-gray-50 shadow-small">
        <p>{name}</p>
      </CardFooter>
    </Card>
  )
}

export default ConfirmDeleteArtistModalBodyAvatar
