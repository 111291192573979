import { useMediaQuery } from 'react-responsive'

const useMediaQueryHelpers = () => {
  const isExtraSmall = useMediaQuery({ query: '(max-width: 480px)' })
  const isSmall = useMediaQuery({ query: '(max-width: 640px)' })
  const isMedium = useMediaQuery({ query: '(max-width: 768px)' })
  const isLarge = useMediaQuery({ query: '(max-width: 1024px)' })
  const isExtraLarge = useMediaQuery({ query: '(min-width: 1025px)' })

  return { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge }
}

export default useMediaQueryHelpers
