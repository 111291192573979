import React from 'react'

interface ArtistIconProps {
  fill?: string
}

const ArtistIcon = ({ fill }: ArtistIconProps) => {
  return (
    <div className="flex size-1/2 items-center justify-center align-middle">
      <svg
        fill={fill ?? 'currentColor'}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 170.45 163.74"
        width="100%"
        height="100%"
      >
        <circle cx="77.62" cy="35.9" r="35.9" />
        <path d="M95.39,129.76c3.15,0,6.46.96,10.01,2.92v-30.9c0-2.19,1.48-4.14,3.59-4.73l14.02-3.93c-12.79-9.16-28.46-14.55-45.4-14.55C37.48,78.56,4.42,108.86.05,147.83c-.53,4.7,3.22,8.79,7.94,8.79h74.25c-1.65-2.92-2.48-6.16-2.48-9.67,0-4.66,1.42-8.67,4.23-11.93,2.98-3.44,6.92-5.26,11.4-5.26Z" />
        <path d="M170.45,85.12c0-.35-.28-.62-.61-.62-.06,0-.11.01-.17.02l-42.4,11.86-17.12,4.79c-.26.07-.45.31-.45.59v37.84c0,.36-.29.61-.61.61-.13,0-.26-.04-.37-.14-5.07-4.02-9.52-6.03-13.32-6.03-3.26,0-5.97,1.25-8.15,3.77-2.12,2.46-3.18,5.5-3.18,9.12s1.12,6.86,3.37,9.67c.49.61,1.03,1.22,1.63,1.79,1.73,1.66,4.03,2.97,6.91,3.91,2.89.95,6.16,1.42,9.81,1.42,2.28,0,4.56-.23,6.86-.68,1.86-.36,3.74-.9,5.61-1.57.25-.09.41-.33.41-.58v-51.42c0-.28.18-.52.44-.6l16.48-4.55,25.15-6.94c.06-.01.11-.02.17-.02.33,0,.61.26.61.61v30.52c0,.36-.29.61-.61.61-.13,0-.26-.04-.37-.14-4.69-3.72-8.84-5.72-12.45-6-.29-.02-.58-.04-.87-.04-3.26,0-5.97,1.25-8.15,3.77-2.12,2.46-3.18,5.5-3.18,9.12,0,4.43,1.66,8.25,4.99,11.47,1.73,1.66,4.03,2.97,6.91,3.91,1.98.64,4.13,1.07,6.47,1.28,1.07.09,2.19.14,3.34.14,2.28,0,4.56-.23,6.86-.68,1.86-.36,3.74-.9,5.61-1.57.25-.09.41-.33.41-.58v-55.09s0-9.6,0-9.6Z" />
      </svg>
    </div>
  )
}

export default ArtistIcon
