import React, { Ref, useState } from 'react'

import { setIsLoadingAction } from '@actions/Global'
import EditArtistSoundCloudTrackModal from '@components/Artists/EditArtist/SoundCloudTracks/EditArtistSoundCloudTrackModal'
import EditArtistSoundCloudTracksDraggable from '@components/Artists/EditArtist/SoundCloudTracks/EditArtistSoundCloudTracksDraggable'
import EditArtistSoundCloudTracksHeader from '@components/Artists/EditArtist/SoundCloudTracks/EditArtistSoundCloudTracksHeader'
import ArtistDto from '@dtos/ArtistDto'
import { ArtistSoundCloudTracksFormValidationSchema } from '@formSchemas/Artist'
import useTranslate from '@hooks/useTranslate'
import UpdateSoundCloudTracks from '@interfaces/Forms/Artist/UpdateSoundCloudTracks'
import { Card, CardBody } from '@nextui-org/react'
import useArtistService from '@services/Artist'
import { Formik, FormikProps } from 'formik'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

interface EditArtistSoundCloudTracksFormProps {
  values: UpdateSoundCloudTracks
  formKey: number
  formRef: Ref<FormikProps<UpdateSoundCloudTracks>>
  onSaveForm: (updatedArtist: ArtistDto) => void
}

const EditArtistSoundCloudTracksForm = ({
  values,
  formKey,
  formRef,
  onSaveForm
}: EditArtistSoundCloudTracksFormProps) => {
  const dispatch = useDispatch()
  const translate = useTranslate('components.artists.edit_artist')
  const errorsTranslate = useTranslate('errors')
  const { updateArtistSoundCloudTracksBatch } = useArtistService()
  const [addTrackModalOpen, setAddTrackModalOpen] = useState(false)

  const removeSoundCloudTrack = (
    formikProps: FormikProps<UpdateSoundCloudTracks>,
    id: string
  ) => {
    formikProps.setFieldValue('tracks', [
      ...formikProps.values.tracks!.filter((values) => values.id !== id)
    ])
  }

  const reorderSoundCloudtracks = (
    formikProps: FormikProps<UpdateSoundCloudTracks>,
    result: DropResult
  ) => {
    if (!result.destination) return

    const reorderedSoundCloudTracks = [...formikProps.values.tracks!]
    const [movedSoundCloudTrack] = reorderedSoundCloudTracks.splice(
      result.source.index,
      1
    )
    reorderedSoundCloudTracks.splice(
      result.destination.index,
      0,
      movedSoundCloudTrack
    )

    reorderedSoundCloudTracks.map(
      (soundCloudTrack, index) => (soundCloudTrack.order = index + 1)
    )

    formikProps.setFieldValue('tracks', [...reorderedSoundCloudTracks])
  }

  const handleError = () => {
    dispatch(setIsLoadingAction(false))
    toast.error(errorsTranslate('general'))
  }

  const saveForm = async (form: UpdateSoundCloudTracks) => {
    if (form.artistId && form.tracks) {
      const updatedArtist = await updateArtistSoundCloudTracksBatch(
        form.artistId,
        form.tracks
      )
      if (!updatedArtist) return handleError()
      onSaveForm(updatedArtist)
    }
  }

  return (
    <Formik
      innerRef={formRef}
      key={formKey}
      initialValues={values}
      validationSchema={ArtistSoundCloudTracksFormValidationSchema}
      onSubmit={async (values) => saveForm(values)}
    >
      {(props: FormikProps<UpdateSoundCloudTracks>) => (
        <form onSubmit={props.handleSubmit} noValidate>
          <EditArtistSoundCloudTrackModal
            isOpen={addTrackModalOpen}
            form={props}
            onClose={() => setAddTrackModalOpen(false)}
          />
          <Card fullWidth>
            <EditArtistSoundCloudTracksHeader
              onAddSoundCloudTrack={() => setAddTrackModalOpen(true)}
            />
            <CardBody>
              <DragDropContext
                onDragEnd={(result) => reorderSoundCloudtracks(props, result)}
              >
                <Droppable droppableId="socialNetworkList" direction="vertical">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {props.values.tracks!.length === 0 ? (
                        <div className="mb-3 flex w-full justify-center text-small opacity-40">
                          {translate('no_sound_cloud_tracks_added')}
                        </div>
                      ) : (
                        <EditArtistSoundCloudTracksDraggable
                          form={props}
                          onRemoveLink={(id) =>
                            removeSoundCloudTrack(props, id)
                          }
                        />
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </CardBody>
          </Card>
        </form>
      )}
    </Formik>
  )
}

export default EditArtistSoundCloudTracksForm
