import React, { ReactNode } from 'react'

import TicketFrame from '@assets/VisualElements/TicketFrame'
import '@components/Shared/FramedImage/FramedImage.css'

interface ImageWithBorderProps {
  src?: string
  alt?: string
  footer?: ReactNode
  size?: number
  classNames?: string
  imageClassNames?: string
  onClick?: () => void
  showFrame?: boolean
  roundSize?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
}

const FramedImage = ({
  src,
  alt,
  footer,
  size,
  classNames,
  imageClassNames,
  onClick,
  roundSize = '3xl',
  showFrame = true
}: ImageWithBorderProps) => {
  const rounded = 'rounded'.replaceAll('rounded', 'rounded-' + roundSize)

  return (
    <div
      onClick={() => (onClick ? onClick() : undefined)}
      className={`image-container ${classNames} ${rounded} ${onClick ? 'cursor-pointer' : ''}`}
      style={{ width: size ?? 300, height: size ?? 300 }}
    >
      <img src={src} alt={alt} className={`base-image ${imageClassNames}`} />
      {showFrame && (
        <div className="overlay">
          <TicketFrame stroke="hsl(var(--nextui-background))" />
        </div>
      )}
      {footer && <div className="footer">{footer}</div>}
    </div>
  )
}

export default FramedImage
