import React, { useEffect, useRef, useState } from 'react'

import { setRootBreadcrumbAction } from '@actions/Breadcrumb'
import { setIsLoadingAction } from '@actions/Global'
import EditArtistGenresForm from '@components/Artists/EditArtist/Forms/EditArtistGenresForm'
import EditArtistMainInfoForm from '@components/Artists/EditArtist/Forms/EditArtistMainInfoForm'
import EditArtistSoundCloudTracksForm from '@components/Artists/EditArtist/Forms/EditArtistSoundCloudTracksForm'
import FormFooter from '@components/Shared/FormFooter'
import EditPicturesListForm from '@components/Shared/Forms/EditPicutreListForm'
import EditSocialNetworkListForm from '@components/Shared/Forms/EditSocialNetworkListForm'
import { RootBreadcrumbConstants } from '@configuration/Constants'
import ArtistDto from '@dtos/ArtistDto'
import {
  ArtistGenresFormInitialValues,
  ArtistMainInfoFormInitialValues,
  ArtistSoundCloudTracksFormInitialValues
} from '@formSchemas/Artist'
import {
  UpdatePictureListFormValuesInitialValues,
  UpdateSocialNetworkListFormValuesInitialValues
} from '@formSchemas/Shared'
import useLocalizedStringHelpers from '@hooks/useLocalizedStringHelpers'
import usePageTitleTranslation from '@hooks/usePageTitleTranslation'
import useTranslate from '@hooks/useTranslate'
import AddGenreToArtist from '@interfaces/Forms/Artist/AddGenreToArtist'
import CreateUpdateArtistMainInfo from '@interfaces/Forms/Artist/CreateUpdateArtistMainInfo'
import UpdateSoundCloudTracks from '@interfaces/Forms/Artist/UpdateSoundCloudTracks'
import UpdatePictureListFormValues from '@interfaces/Forms/Shared/UpdatePictureListFormValues'
import UpdateSocialNetworkListFormValues from '@interfaces/Forms/Shared/UpdateSocialNetworkListFormValues'
import useArtistService from '@services/Artist'
import { FormikProps } from 'formik'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const EditArtist = () => {
  const { getPageTitle } = usePageTitleTranslation()
  const translate = useTranslate('components.artists.edit_artist')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string | undefined }>()
  const {
    getArtistById,
    updateArtistPhotoBatch,
    updateArtistSocialNetworkListBatch
  } = useArtistService()

  const { getLocalizedStringValue } = useLocalizedStringHelpers()

  const [isSavingData, setIsSavingData] = useState(false)
  const [artist, setArtist] = useState<ArtistDto | undefined>(undefined)

  const [mainInfoFormKey, setMainInfoFormKey] = useState(0)
  const [genresFormKey, setGenresFormKey] = useState(0)
  const [socialNetworkLinksFormKey, setSocialNetworkLinksFormKey] = useState(0)
  const [soundCloudTracksFormKey, setSoundCloudTracksFormKey] = useState(0)
  const [picturesFormKey, setPicturesFormKey] = useState(0)

  const [mainInfoFormValues, setMainInfoFormValues] =
    useState<CreateUpdateArtistMainInfo>(ArtistMainInfoFormInitialValues)
  const [genresFormValues, setGenresFormValues] = useState<AddGenreToArtist>(
    ArtistGenresFormInitialValues
  )
  const [socialNetworkListFormValues, setSocialNetworkListFormValues] =
    useState<UpdateSocialNetworkListFormValues>(
      UpdateSocialNetworkListFormValuesInitialValues
    )
  const [soundCloudTracksFormValues, setSoundCloudTracksFormValues] =
    useState<UpdateSoundCloudTracks>(ArtistSoundCloudTracksFormInitialValues)
  const [pictureListFormValues, setPictureListFormValues] =
    useState<UpdatePictureListFormValues>(
      UpdatePictureListFormValuesInitialValues
    )

  const mainInfoFormRef = useRef<FormikProps<CreateUpdateArtistMainInfo>>(null)
  const genresFormRef = useRef<FormikProps<AddGenreToArtist>>(null)
  const socialNetworkLinksFormRef =
    useRef<FormikProps<UpdateSocialNetworkListFormValues>>(null)
  const soundCloudTracksFormRef =
    useRef<FormikProps<UpdateSoundCloudTracks>>(null)
  const picturesForm = useRef<FormikProps<UpdatePictureListFormValues>>(null)

  const setBreadcrumbs = (breadcrumbData?: ArtistDto) => {
    if (breadcrumbData) {
      dispatch(
        setRootBreadcrumbAction(RootBreadcrumbConstants.ARTISTS, [
          { path: `/artists/${breadcrumbData.id}`, title: breadcrumbData.name }
        ])
      )
    } else {
      dispatch(
        setRootBreadcrumbAction(RootBreadcrumbConstants.ARTISTS, [
          { path: '/artists/add', title: getPageTitle('add_artist') }
        ])
      )
    }
  }

  useEffect(() => {
    if (id) loadArtist(id)
    setBreadcrumbs()
  }, [id])

  useEffect(() => {
    if (!isSavingData && artist) {
      setMainInfoFormValues({
        name: artist.name,
        description: artist.description,
        avatar: { base64image: '', imageType: '' },
        country: artist.country
      })

      const newGenres: AddGenreToArtist = { artistId: artist.id, genres: [] }
      artist.genres.map((genre) =>
        newGenres.genres.push({
          genreId: genre.id,
          displayName: getLocalizedStringValue(genre.name)
        })
      )

      setGenresFormValues(newGenres)
      setPictureListFormValues(UpdatePictureListFormValuesInitialValues)
      setSocialNetworkListFormValues({
        entityId: artist.id,
        links: artist.socialNetworkLinks
      })
      setSoundCloudTracksFormValues({
        artistId: artist.id,
        tracks: artist.soundCloudTracks
      })
      setMainInfoFormKey((prevKey) => prevKey + 1)
      setGenresFormKey((prevKey) => prevKey + 1)
      setSocialNetworkLinksFormKey((prevKey) => prevKey + 1)
      setSoundCloudTracksFormKey((prevKey) => prevKey + 1)
      setPicturesFormKey((prevKey) => prevKey + 1)
      dispatch(setIsLoadingAction(false))
    }
  }, [artist])

  const loadArtist = async (id: string) => {
    dispatch(setIsLoadingAction(true))
    const response = await getArtistById(id)
    if (response) {
      setBreadcrumbs(response)
      setArtist(response)
    } else {
      navigate('/not-found')
      dispatch(setIsLoadingAction(false))
    }
  }

  const saveMainForm = async () => {
    if (socialNetworkLinksFormRef.current) {
      await socialNetworkLinksFormRef.current.setFieldValue('links', [
        ...socialNetworkLinksFormRef.current.values.links!.filter(
          (track) => track.link.trim().length > 0
        )
      ])
    }

    if (soundCloudTracksFormRef.current) {
      await soundCloudTracksFormRef.current.setFieldValue('tracks', [
        ...soundCloudTracksFormRef.current.values.tracks!.filter(
          (track) => track.trackId.length > 0
        )
      ])
    }

    if (mainInfoFormRef.current)
      await mainInfoFormRef.current.setFieldValue('artistId', id)

    setTimeout(async () => {
      if (
        mainInfoFormRef.current &&
        genresFormRef.current &&
        socialNetworkLinksFormRef.current &&
        soundCloudTracksFormRef.current &&
        picturesForm.current &&
        mainInfoFormRef.current.isValid &&
        genresFormRef.current.isValid &&
        socialNetworkLinksFormRef.current.isValid &&
        soundCloudTracksFormRef.current.isValid &&
        picturesForm.current.isValid
      ) {
        dispatch(setIsLoadingAction(true))
        setIsSavingData(true)
        await mainInfoFormRef.current.submitForm()
      }
    }, 0)
  }

  const saveGenreForm = async (updatedArtist: ArtistDto) => {
    if (genresFormRef.current) {
      await genresFormRef.current.setFieldValue('artistId', updatedArtist.id)
      setTimeout(async () => {
        await genresFormRef.current!.submitForm()
      }, 0)
    }
  }

  const saveSocialNetworksForm = async (updatedArtist: ArtistDto) => {
    if (socialNetworkLinksFormRef.current) {
      await socialNetworkLinksFormRef.current.setFieldValue(
        'entityId',
        updatedArtist.id
      )
      setTimeout(async () => {
        await socialNetworkLinksFormRef.current!.submitForm()
      }, 0)
    }
  }

  const saveSoundCloudTracksForm = async (updatedArtist: ArtistDto) => {
    if (soundCloudTracksFormRef.current) {
      await soundCloudTracksFormRef.current.setFieldValue(
        'artistId',
        updatedArtist.id
      )
      setTimeout(async () => {
        await soundCloudTracksFormRef.current!.submitForm()
      }, 0)
    }
  }

  const savePictureForm = async (updatedArtist: ArtistDto) => {
    if (picturesForm.current) {
      await picturesForm.current.setFieldValue('entityId', updatedArtist.id)
      setTimeout(async () => {
        await picturesForm.current!.submitForm()
      }, 0)
    }
  }

  const finishSavingForms = (updatedArtist: ArtistDto) => {
    if (updatedArtist) {
      setIsSavingData(false)
      setArtist(updatedArtist)
      navigate(`/artists/${updatedArtist.id}`, { replace: true })
      toast.success(translate('data_saved'))
    }
  }

  return (
    <div className="flex grow flex-col gap-3">
      <EditArtistMainInfoForm
        values={mainInfoFormValues}
        formRef={mainInfoFormRef}
        formKey={mainInfoFormKey}
        originalAvatarUrl={artist?.avatarUrl}
        onSaveForm={async (artist) => await saveGenreForm(artist)}
      />
      <EditArtistGenresForm
        values={genresFormValues}
        formRef={genresFormRef}
        formKey={genresFormKey}
        onSaveForm={async (artist) => await saveSocialNetworksForm(artist)}
      />
      <EditSocialNetworkListForm
        values={socialNetworkListFormValues}
        formRef={socialNetworkLinksFormRef}
        formKey={socialNetworkLinksFormKey}
        updateSocialNetworkService={updateArtistSocialNetworkListBatch}
        onSaveForm={async (artist) => await saveSoundCloudTracksForm(artist)}
      />
      <EditArtistSoundCloudTracksForm
        values={soundCloudTracksFormValues}
        formKey={soundCloudTracksFormKey}
        formRef={soundCloudTracksFormRef}
        onSaveForm={async (artist) => await savePictureForm(artist)}
      />
      <EditPicturesListForm
        values={pictureListFormValues}
        formRef={picturesForm}
        formKey={picturesFormKey}
        onSaveForm={(artist) => finishSavingForms(artist)}
        existingPictures={artist?.photos}
        updatePicturesService={updateArtistPhotoBatch}
      />
      <FormFooter
        showCancelButton={!!id}
        onSelectCancel={() => loadArtist(id!)}
        onSelectSave={async () => await saveMainForm()}
        onGoBack={() => navigate('/artists')}
      />
    </div>
  )
}

export default EditArtist
