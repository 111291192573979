import React from 'react'

interface VenueIconProps {
  fill?: string
}

const VenueIcon = ({ fill }: VenueIconProps) => {
  return (
    <div className="flex size-1/2 items-center justify-center align-middle">
      <svg
        fill={fill ?? 'currentColor'}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 76.37 136.74"
        width="100%"
        height="100%"
      >
        <path d="M68.48,38.62c.29-4.34,3.66-7.75,7.83-8.23C72.12,12.53,55.67-1.12,35.94.07,18.01,1.16,3.88,14.01.04,30.35c4.87.36,8.55,4.58,8.22,9.46-.3,4.49-3.89,7.98-8.27,8.27.91,3.86,2.46,7.38,4.3,10.91,9.96,19.21,21.42,37.71,31.46,56.89,1.08,2.31,3.76,2.31,4.84,0,10.03-19.14,21.44-37.6,31.4-56.77,1.87-3.59,3.45-7.15,4.37-11.07-4.71-.52-8.21-4.66-7.89-9.43ZM38.19,61.47c-10.7,0-19.38-8.68-19.38-19.38s8.68-19.38,19.38-19.38,19.38,8.68,19.38,19.38-8.68,19.38-19.38,19.38Z" />
      </svg>
    </div>
  )
}

export default VenueIcon
