import React, { useState } from 'react'

import ArtistIcon from '@assets/Icons/ArtistIcon'
import EditEventLineupArtistCard from '@components/Events/EditEvent/Lineup/EditEventLineupArtistCard'
import EditEventLineupArtistDropdown from '@components/Events/EditEvent/Lineup/EditEventLineupArtistDropdown'
import PartynerIcon from '@components/Shared/PartynerIcon'
import { faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalContent
} from '@nextui-org/react'

interface EditEventLineupAddArtistCardProps {
  addedArtists: string[]
  onSelectArtist: (artistId: string) => void
}

const EditEventLineupAddArtist = ({
  addedArtists,
  onSelectArtist
}: EditEventLineupAddArtistCardProps) => {
  const [showArtistModal, setShowArtistModal] = useState(false)
  const [selectedArtist, setSelectedArtist] = useState<string | undefined>(
    undefined
  )

  const closeModal = () => {
    setSelectedArtist(undefined)
    setShowArtistModal(false)
  }

  const selectArtist = (artistId: string) => {
    setShowArtistModal(false)
    setSelectedArtist(undefined)
    onSelectArtist(artistId)
  }

  return (
    <>
      <Modal isOpen={showArtistModal} onClose={closeModal} size="xs">
        <ModalContent>
          <ModalBody>
            <div className="flex size-full flex-col items-center justify-center gap-4 p-4 align-middle">
              <EditEventLineupArtistDropdown
                addedArtists={addedArtists}
                onSelectArtist={setSelectedArtist}
              />
              {!selectedArtist && (
                <div className="size-[200px]">
                  <PartynerIcon
                    roundSize="rounded-3xl"
                    backgroundColor="hsl(var(--nextui-content2))"
                    frameColor="transparent"
                  >
                    <ArtistIcon fill="hsl(var(--nextui-foreground-300))" />
                  </PartynerIcon>
                </div>
              )}
              {selectedArtist && (
                <EditEventLineupArtistCard
                  artistId={selectedArtist}
                  buttonAction={selectArtist}
                  buttonIcon={faPlus}
                />
              )}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Card
        isFooterBlurred
        radius="lg"
        className="size-[200px] min-h-[200px] min-w-[200px] border-none"
        fullWidth={false}
      >
        <CardBody className="flex items-center justify-center bg-success-100/20 align-middle">
          <PartynerIcon
            backgroundColor="transparent"
            frameColor="transparent"
            idleScale={90}
            onClick={() => setShowArtistModal(true)}
          >
            <FontAwesomeIcon
              role="button"
              icon={faPlusCircle}
              className="text-success-600"
              size="5x"
            />
          </PartynerIcon>
        </CardBody>
      </Card>
    </>
  )
}

export default EditEventLineupAddArtist
