import React, { useCallback } from 'react'

import GenresTableColumnActions from '@components/Genres/GenresTable/Columns/GenresTableColumnActions'
import GenresTableColumnName from '@components/Genres/GenresTable/Columns/GenresTableColumnName'
import GenresTableFilter from '@components/Genres/GenresTable/GenresTableFilter'
import PaginatedTable from '@components/Shared/PaginatedTable/PaginatedTable'
import GenreDto from '@dtos/GenreDto'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import { Button, Tooltip } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'

enum columnUid {
  name = 'name',
  actions = 'actions'
}

interface GenresTableProps {
  genreList: GenreDto[]
  totalPages: number
  selectedPage: number
  totalItems: number
  pageSize: number
  isLoading: boolean
  onSelectDeleteGenre: (genre: GenreDto) => void
  onChangePage: (page: number) => void
  onChangePageSize: (size: number) => void
  onApplyFilter: (names: string[]) => void
  onClearFilter: () => void
}

const GenresTable = ({
  genreList,
  totalPages,
  selectedPage,
  totalItems,
  pageSize,
  isLoading,
  onSelectDeleteGenre,
  onChangePage,
  onChangePageSize,
  onApplyFilter,
  onClearFilter
}: GenresTableProps) => {
  const translate = useTranslate('components.genres.genres_table')
  const navigate = useNavigate()

  const columns = [
    { name: translate('column_names.name'), uid: columnUid.name },
    { name: translate('column_names.actions'), uid: columnUid.actions }
  ]

  const renderCell = useCallback(
    (genre: GenreDto, columnKey: React.Key) => {
      switch (columnKey) {
        case columnUid.name:
          return <GenresTableColumnName name={genre.name} />
        case columnUid.actions:
          return (
            <GenresTableColumnActions
              genreId={genre.id}
              onSelectDeleteGenre={() => onSelectDeleteGenre(genre)}
            />
          )
      }
    },
    [genreList]
  )

  return (
    <PaginatedTable
      items={genreList}
      columns={columns}
      cellRenderer={renderCell}
      totalPages={totalPages}
      selectedPage={selectedPage}
      pageSize={pageSize}
      totalItems={totalItems}
      isLoading={isLoading}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      emptyContent={translate('no_genres_found')}
      title={translate('title')}
      headerEndContent={
        <Tooltip color="success" content={translate('actions.add')}>
          <Button
            isIconOnly
            radius="full"
            color="success"
            size="sm"
            onClick={() => navigate('/genres/add')}
          >
            <FontAwesomeIcon icon={faPlus} size="lg" />
          </Button>
        </Tooltip>
      }
      filter={
        <GenresTableFilter
          onApplyFilter={onApplyFilter}
          onClearFilter={onClearFilter}
        />
      }
    />
  )
}

export default GenresTable
