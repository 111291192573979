import React from 'react'

import EditArtistGenresDropdown from '@components/Artists/EditArtist/Genres/EditArtistGenresDropdown'
import AddGenreToArtist from '@interfaces/Forms/Artist/AddGenreToArtist'
import { Chip } from '@nextui-org/react'
import { FormikProps } from 'formik'

interface EditArtistGenresProps {
  genres: FormikProps<AddGenreToArtist>
  onRemoveGenre: (genreId: string) => void
  onAddGenre: (genreId: string, displayName: string) => void
}

const EditArtistGenres = ({
  genres,
  onRemoveGenre,
  onAddGenre
}: EditArtistGenresProps) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-wrap gap-3">
        {genres.values.genres.map((genre, index) => (
          <Chip
            key={index}
            size="lg"
            onClose={() => onRemoveGenre(genre.genreId)}
          >
            <span className="text-sm">{genre.displayName}</span>
          </Chip>
        ))}
      </div>
      <EditArtistGenresDropdown
        addedGenreIds={genres.values.genres.map((genre) => genre.genreId)}
        onAddGenre={onAddGenre}
      />
    </div>
  )
}

export default EditArtistGenres
