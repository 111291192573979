import React from 'react'

interface ProducerIconProps {
  fill?: string
}

const ProducerIcon = ({ fill }: ProducerIconProps) => {
  return (
    <div className="flex size-1/2 items-center justify-center align-middle">
      <svg
        fill={fill ?? 'currentColor'}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 96.28 126.59"
        width="100%"
        height="100%"
      >
        <path d="M78.93,8.38c2.09.1,4.25-.13,6.34,0,.75.04,2.16.14,2.84.26.83.14,1.49.81,1.75,1.59l.06,44.86c.08.72-1.51,2.02-2.04,2.02h-8.95v15.88c.18.24.21.26.49.26,2.27.02,5.42-1.32,7.78-1.55,9.77-.92,12.28,11.58,4.48,14.99-1.86.81-4.25.82-6.11,1.77-9.61,5.06-19.6,9.44-29.23,14.45-.58.3-1.58.76-2.05,1.05-.3.19-.5.22-.41.66l35.41,17.48c2.81,1.75.8,5.36-2.17,4.31l-38.86-19.18-1,.2-37.62,18.72c-2.97,1.66-5.68-2.12-2.63-4.06l35.41-17.48-.39-.68-28.56-14.18c-3.58-1-8.74-.68-11.39-3.65-3.15-3.54-2.69-9.17,1.21-11.92,4.28-3.02,9.35-.22,14.07-.14v-16.96h-8.95c-.55,0-2.27-1.2-2.03-2.03l.11-45.03c.08-.52,1.58-1.66,1.92-1.66h8.95V1.57C17.35.72,18.99-.06,19.85,0s2.27,1.21,2.27,2.04v6.21l.36.36h51.32l.36-.36V2.05c0-.83,1.39-1.98,2.27-2.04s2.5.72,2.5,1.57v6.81ZM74.16,13.39H22.12v38.57l.36.36h51.32l.36-.36V13.39ZM74.16,57.09H22.12v17.31c.54.43,1.73.51,2.51.6,12.2,1.41,26.9,1.22,39.14.24,3.47-.28,6.92-.89,10.39-1.19v-16.96ZM69.86,90.77c-3.95.08-7.88.73-11.82.95-8.26.45-17.03.37-25.3,0-1.42-.06-3.12-.39-4.54-.48-.47-.03-.37.05-.35.48l20.41,9.8,21.6-10.75Z" />
        <path d="M48.14,22.1c-.47,0-.93.27-1.11.8l-1.86,5.72c-.16.48-.6.8-1.11.8h-6.01c-1.13,0-1.59,1.44-.68,2.1l4.87,3.54c.41.3.58.82.42,1.3l-1.86,5.72c-.27.82.38,1.53,1.11,1.53.23,0,.46-.07.68-.23l4.87-3.54c.2-.15.44-.22.68-.22s.48.07.68.22l4.87,3.54c.22.16.45.23.68.23.73,0,1.37-.71,1.11-1.53l-1.86-5.72c-.16-.48.01-1,.42-1.3l4.87-3.54c.91-.66.44-2.1-.68-2.1h-6.01c-.5,0-.95-.32-1.11-.8l-1.86-5.72c-.17-.54-.64-.8-1.11-.8h0Z" />
      </svg>
    </div>
  )
}

export default ProducerIcon
