import React, { useEffect, useState } from 'react'

import EditEventLineupAddArtist from '@components/Events/EditEvent/Lineup/EditEventLineupAddArtist'
import EditEventLineupArtistCard from '@components/Events/EditEvent/Lineup/EditEventLineupArtistCard'
import CreateUpdateLineupArtistDto from '@dtos/CreateUpdateLineupArtistDto'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMediaQueryHelpers from '@hooks/useMediaQueryHelpers'
import CreateUpdateEventLineupDate from '@interfaces/Forms/Event/CreateUpdateEventLineupDate'
import { parseDate } from '@internationalized/date'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  DatePicker,
  DateValue
} from '@nextui-org/react'
import { I18nProvider } from '@react-aria/i18n'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

interface EditEventLineupItemProps {
  lineup: CreateUpdateEventLineupDate
  onRemoveArtist: (artistId: string) => void
  onAddArtist: (artistId: string, order: number) => void
  onChangeLineupDate: (date: string | undefined) => void
  onRemoveLineup: () => void
  onReorderArtists: (lineupArtists: CreateUpdateLineupArtistDto[]) => void
}

const EditEventLineupItem = ({
  lineup,
  onRemoveArtist,
  onAddArtist,
  onChangeLineupDate,
  onRemoveLineup,
  onReorderArtists
}: EditEventLineupItemProps) => {
  const [lineupArtists, setLineupArtists] = useState<
    CreateUpdateLineupArtistDto[]
  >(lineup.artists ? [...lineup.artists] : [])

  const [lineupDate, setLineupDate] = useState<DateValue | undefined | null>(
    lineup.date ? parseDate(lineup.date.toString().substring(0, 10)) : undefined
  )

  const { isSmall } = useMediaQueryHelpers()

  const changeLineupDate = (date: DateValue) => {
    setLineupDate(date)
    onChangeLineupDate(
      date === null
        ? undefined
        : new Date(Date.UTC(date.year, date.month - 1, date.day)).toISOString()
    )
  }

  const handleArtistDrag = (result: any) => {
    if (!result.destination) return

    const reorderedArtists = [...lineupArtists]
    const [movedArtist] = reorderedArtists.splice(result.source.index, 1)
    reorderedArtists.splice(result.destination.index, 0, movedArtist)

    reorderedArtists.map((artist, index) => (artist.order = index + 1))

    setLineupArtists(reorderedArtists)
    onReorderArtists(reorderedArtists)
  }

  useEffect(() => {
    setLineupArtists(lineup.artists ? [...lineup.artists] : [])
  }, [lineup.artists])

  return (
    <Card>
      <CardHeader>
        <I18nProvider locale={'en'}>
          <div className="flex size-full justify-center gap-2">
            <DatePicker
              showMonthAndYearPickers
              fullWidth
              value={lineupDate}
              onChange={changeLineupDate}
              variant="flat"
              size="sm"
              radius="lg"
              label={'Date'}
            />
            <Button
              isIconOnly
              color="danger"
              radius="lg"
              size="lg"
              onClick={onRemoveLineup}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </Button>
          </div>
        </I18nProvider>
      </CardHeader>
      <CardBody>
        <div className="flex w-full flex-wrap justify-center gap-3 lg:justify-start">
          <DragDropContext onDragEnd={handleArtistDrag}>
            <Droppable
              droppableId={`dnd-${lineup.id}`}
              direction={isSmall ? 'vertical' : 'horizontal'}
            >
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="flex flex-wrap justify-center gap-3 lg:justify-start"
                >
                  {lineupArtists.map((artist, index) => (
                    <Draggable
                      key={artist.artistId!}
                      draggableId={artist.artistId!}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style
                          }}
                        >
                          <EditEventLineupArtistCard
                            key={artist.artistId}
                            artistId={artist.artistId}
                            buttonAction={onRemoveArtist}
                            buttonIcon={faTrashCan}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <EditEventLineupAddArtist
                    addedArtists={lineup.artists.map(
                      (artist) => artist.artistId
                    )}
                    onSelectArtist={(artistId) =>
                      onAddArtist(artistId, lineup.artists.length + 1)
                    }
                  />
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </CardBody>
    </Card>
  )
}
export default EditEventLineupItem
