import React, { Ref, useEffect, useState } from 'react'

import { setIsLoadingAction } from '@actions/Global'
import EditPicturesList from '@components/Shared/EditPicturesList/EditPicturesList'
import PictureDto from '@dtos/PictureDto'
import PictureUploadDto from '@dtos/PictureUploadDto'
import UpdatePhotoBatchDto from '@dtos/UpdatePhotoBatchDto'
import { UpdatePictureListFormValuesValidationSchema } from '@formSchemas/Shared'
import useTranslate from '@hooks/useTranslate'
import UpdatePictureListFormValues from '@interfaces/Forms/Shared/UpdatePictureListFormValues'
import PictureOrder from '@interfaces/PictureOrder'
import { Card, CardBody, CardHeader } from '@nextui-org/react'
import { Formik, FormikProps } from 'formik'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

interface EditPicturesListFormProps<TEntity> {
  values: UpdatePictureListFormValues
  formRef: Ref<FormikProps<UpdatePictureListFormValues>>
  formKey: number
  existingPictures?: PictureDto[]
  onSaveForm: (updatedEntity: TEntity) => void
  updatePicturesService: (...params: any[]) => Promise<TEntity | undefined>
}

const EditPicturesListForm = <TEntity,>({
  values,
  formRef,
  formKey,
  existingPictures,
  onSaveForm,
  updatePicturesService
}: EditPicturesListFormProps<TEntity>) => {
  const dispatch = useDispatch()
  const translate = useTranslate('components.shared.forms')
  const errorsTranslate = useTranslate('errors')

  const [picturesToRemove, setPicturesToRemove] = useState<string[]>([])
  const [picturesOrder, setPicturesOrder] = useState<PictureOrder[]>([])

  values.pictures.map((picture) =>
    picturesOrder.push({ imageId: picture.id!, order: picture.order ?? 0 })
  )

  useEffect(() => {
    setPicturesToRemove([])
  }, [formKey])

  const addPictures = (
    formikProps: FormikProps<UpdatePictureListFormValues>,
    pictures: PictureUploadDto[]
  ) => {
    const newPictureOrder: PictureOrder[] = []
    pictures.map((picture) =>
      newPictureOrder.push({ imageId: picture.id!, order: picture.order ?? 0 })
    )
    setPicturesOrder((prevState) => [...prevState, ...newPictureOrder])

    formikProps.setFieldValue('pictures', [
      ...formikProps.values.pictures,
      ...pictures
    ])
  }

  const removeExistingPicture = (pictureId: string) => {
    if (pictureId) {
      setPicturesToRemove((pictures) => [...pictures, pictureId])
    }
  }

  const cancelRemoveExistingPicture = (pictureId: string) => {
    if (pictureId) {
      setPicturesToRemove((pictures) =>
        pictures.filter((pic) => pic !== pictureId)
      )
    }
  }

  const removeNewPicture = (
    formikProps: FormikProps<UpdatePictureListFormValues>,
    picture: string
  ) => {
    formikProps.setFieldValue(
      'pictures',
      formikProps.values.pictures.filter((pic) => pic.base64image !== picture)
    )
  }

  const reorderPictures = (pictures: PictureDto[]) => {
    const newOrder: PictureOrder[] = []
    pictures.map((pic) => newOrder.push({ imageId: pic.id!, order: pic.order }))
    setPicturesOrder(newOrder)
  }

  const handleError = () => {
    dispatch(setIsLoadingAction(false))
    toast.error(errorsTranslate('general'))
  }

  const saveForm = async (form: UpdatePictureListFormValues) => {
    if (form.entityId) {
      form.pictures.map(async (picture) => {
        const dataIndex = picture.base64image.indexOf(',')
        picture.base64image = picture.base64image.substring(dataIndex + 1)
        picture.order = picturesOrder.find(
          (pic) => pic.imageId === picture.id
        )!.order
      })

      const updateDto: UpdatePhotoBatchDto = {
        photosToUpload: form.pictures,
        photosToRemove: picturesToRemove,
        photosOrder: picturesOrder
      }

      const updatedEntity = await updatePicturesService(
        form.entityId,
        updateDto
      )

      if (!updatedEntity) return handleError()

      onSaveForm(updatedEntity)
    }
  }

  return (
    <Card fullWidth>
      <CardHeader>
        <div className="pl-2 text-lg font-semibold text-foreground-500">
          {translate('pictures')}
        </div>
      </CardHeader>
      <Formik
        key={formKey}
        innerRef={formRef}
        initialValues={values}
        validationSchema={UpdatePictureListFormValuesValidationSchema}
        onSubmit={async (values) => await saveForm(values)}
      >
        {(props: FormikProps<UpdatePictureListFormValues>) => {
          const pictureList = existingPictures ? [...existingPictures] : []

          props.values.pictures.map((pic) =>
            pictureList.push({
              id: pic.id,
              newPicture: true,
              url: pic.base64image,
              order: pic.order ?? 0
            })
          )

          if (picturesOrder.length > 0) {
            pictureList.map(
              (pic) =>
                (pic.order = picturesOrder.find(
                  (picOrder) => picOrder.imageId === pic.id
                )?.order as number)
            )
          }

          return (
            <form noValidate onSubmit={props.handleSubmit}>
              <CardBody>
                <EditPicturesList
                  showFrame={false}
                  pictureList={pictureList.sort((a, b) => a.order - b.order)}
                  onAddPictures={(pictures) => addPictures(props, pictures)}
                  onRemove={(picture) => removeExistingPicture(picture)}
                  onCancelRemove={(picture) =>
                    cancelRemoveExistingPicture(picture)
                  }
                  onRemoveNewPicture={(picture) =>
                    removeNewPicture(props, picture)
                  }
                  picturesToRemoveList={picturesToRemove}
                  onReorderPictures={reorderPictures}
                />
              </CardBody>
            </form>
          )
        }}
      </Formik>
    </Card>
  )
}

export default EditPicturesListForm
