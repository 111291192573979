import { MutableRefObject } from 'react'

import SoundCloudTrackInfoDto from '@dtos/SoundCloudTrackInfoDto'
import useSoundCloudService from '@services/SoundCloud'
import Hls from 'hls.js'

const useSoundCloudHelpers = () => {
  const { getTrackStream } = useSoundCloudService()

  const generateAudioBuffer = async (
    trackInfo: SoundCloudTrackInfoDto,
    audioRef: MutableRefObject<HTMLMediaElement | null>
  ) => {
    if (!audioRef?.current) return

    const transcoding = trackInfo.media.transcodings.find(
      (t) => t.format.mime_type === 'audio/mpeg'
    )
    if (transcoding) {
      const audioBuffer = await getTrackStream(transcoding.url)
      if (audioBuffer) {
        const blob = new Blob([audioBuffer], { type: 'audio/mpegurl' })
        const bufferUrl = URL.createObjectURL(blob)
        const hls = new Hls()
        hls.loadSource(bufferUrl)
        hls.attachMedia(audioRef.current)

        return () => {
          hls.destroy()
        }
      }
    }
  }

  return { generateAudioBuffer }
}

export default useSoundCloudHelpers
