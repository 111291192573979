import {
  GenresFilterAction,
  GenresFilterActionTypes
} from '@actions/GenresFilter'

export interface GenresFilterState {
  page?: number
  pageSize?: number
  namesFilter?: string[]
}

const initialGenresFilterState: GenresFilterState = {
  page: undefined,
  pageSize: undefined,
  namesFilter: undefined
}

const GenresFilterReducer = (
  state: GenresFilterState = initialGenresFilterState,
  action: GenresFilterAction
) => {
  switch (action.type) {
    case GenresFilterActionTypes.SET_GENRE_FILTER:
      return {
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        namesFilter: action.payload.namesFilter
      }
    case GenresFilterActionTypes.CLEAR_GENRE_FILTER:
      return initialGenresFilterState
    default:
      return state
  }
}

export default GenresFilterReducer
