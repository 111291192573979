const useCropImageHelpers = () => {
  const getCroppedImg = async (
    imageSrc: string,
    pixelCrop: any
  ): Promise<string> => {
    const image = new Image()
    image.src = imageSrc
    return new Promise((resolve) => {
      image.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        canvas.width = pixelCrop.width
        canvas.height = pixelCrop.height

        ctx?.drawImage(
          image,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height
        )

        canvas.toBlob((blob) => {
          const reader = new FileReader()
          reader.readAsDataURL(blob!)
          reader.onloadend = () => {
            resolve(reader.result as string)
          }
        }, 'image/jpeg')
      }
    })
  }

  return { getCroppedImg }
}

export default useCropImageHelpers
