import React, { ReactNode } from 'react'

import useTranslate from '@hooks/useTranslate'

interface PaginatedTableHeaderProps {
  title: string
  totalItems: number
  endContent?: ReactNode
  filter?: ReactNode
}

const PaginatedTableHeader = ({
  title,
  totalItems,
  endContent,
  filter
}: PaginatedTableHeaderProps) => {
  const translate = useTranslate('components.shared.paginated_table')

  return (
    <div className="flex flex-col items-center justify-center gap-3">
      <div className="flex w-full items-center">
        <div className="grow items-center text-center text-lg font-bold text-foreground-500">
          {title}
        </div>

        {endContent && <div className="self-end">{endContent}</div>}
      </div>
      {filter}
      <div className=" text-tiny text-foreground-500">
        {translate('items_found', { count: totalItems })}
      </div>
    </div>
  )
}

export default PaginatedTableHeader
