import React, { useState } from 'react'

import { setIsLoadingAction } from '@actions/Global'
import { useTheme } from '@configuration/ThemeContext'
import GenreDto from '@dtos/GenreDto'
import useLocalizedStringHelpers from '@hooks/useLocalizedStringHelpers'
import useTranslate from '@hooks/useTranslate'
import {
  Button,
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader
} from '@nextui-org/react'
import useGenreService from '@services/Genre'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

interface ConfirmDeleteGenreModalProps {
  genre: GenreDto | undefined
  onClose: (reloadTable: boolean) => void
}

const ConfirmDeleteGenreModal = ({
  genre,
  onClose
}: ConfirmDeleteGenreModalProps) => {
  const translate = useTranslate('components.genres.remove_genre')
  const dispatch = useDispatch()
  const { deleteGenre } = useGenreService()
  const { getLocalizedStringValue } = useLocalizedStringHelpers()
  const { isDarkMode } = useTheme()
  const [nameMatch, setNameMatch] = useState('')

  const removeGenre = async () => {
    if (!genre) return

    dispatch(setIsLoadingAction(true))
    const response = await deleteGenre(genre.id)

    if (response) {
      toast.success(translate('genre_removed'))
    } else {
      toast.error(translate('error_on_remove'))
    }
    dispatch(setIsLoadingAction(false))

    onClose(true)
  }

  if (!genre) return <></>

  const genreName = getLocalizedStringValue(genre.name)

  return (
    <Modal
      isOpen={!!genre}
      size="sm"
      onClose={() => onClose(false)}
      backdrop={'blur'}
      isDismissable={true}
      classNames={{
        header:
          'flex flex-col gap-1text-center justify-center font-semibold text-2xl text-danger',
        base: `${isDarkMode ? 'dark text-foreground' : ''} text-center`
      }}
    >
      <ModalContent>
        <ModalHeader>
          <div className="flex flex-col gap-3 text-center">
            <span>{translate('title')}</span>
            <span className="text-sm">{translate('subtitle')}</span>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="flex flex-col items-center gap-5">
            <div className="rounded-full bg-danger p-3 text-3xl font-semibold text-white">
              {genreName}
            </div>
            <Divider />
            <span className="text-sm">{translate('type_genre_name')}</span>
            <Input
              variant="bordered"
              onValueChange={(value) => setNameMatch(value)}
            />
            <Button
              variant="flat"
              color="danger"
              isDisabled={nameMatch !== genreName}
              onClick={removeGenre}
            >
              {translate('remove_genre')}
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmDeleteGenreModal
