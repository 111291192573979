import React from 'react'

import StarIcon from '@assets/Icons/StarIcon'

interface DefaultAvatarProps {
  displayName: string
}

const DefaultAvatar = ({ displayName }: DefaultAvatarProps) => {
  const nameParts = displayName.split(' ')

  const firstLetter = nameParts[0] ? nameParts[0][0] : ''
  const lastLetter = nameParts[1] ? nameParts[1][0] : nameParts[0][1]

  return (
    <div
      className="flex size-full items-center justify-center align-middle"
      style={{
        containerType: 'size'
      }}
    >
      <div
        className="z-10 flex size-full items-center justify-center align-middle font-black text-white"
        style={{
          fontSize: '40cqw'
        }}
      >
        {firstLetter}
        {lastLetter}
      </div>
      <div className="absolute z-0 size-3/4 opacity-20">
        <StarIcon fullSize fill="hsl(var(--nextui-white))" />
      </div>
    </div>
  )
}

export default DefaultAvatar
