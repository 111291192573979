import React, { useCallback } from 'react'

import EventsTableColumnActions from '@components/Events/EventsTable/Columns/EventsTableColumnActions'
import EventsTableColumnName from '@components/Events/EventsTable/Columns/EventsTableColumnName'
import EventsTableFilter from '@components/Events/EventsTable/EventsTableFilter'
import PaginatedTable from '@components/Shared/PaginatedTable/PaginatedTable'
import EventDto from '@dtos/EventDto'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import { Button, Tooltip } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'

enum columnUid {
  name = 'name',
  actions = 'actions'
}

interface EventsTableProps {
  eventList: EventDto[]
  totalPages: number
  selectedPage: number
  totalItems: number
  pageSize: number
  isLoading: boolean
  onSelectDeleteEvent: (event: EventDto) => void
  onChangePage: (page: number) => void
  onChangePageSize: (size: number) => void
  onApplyFilter: (name: string[]) => void
  onClearFilter: () => void
}

const EventsTable = ({
  eventList,
  totalPages,
  selectedPage,
  totalItems,
  pageSize,
  isLoading,
  onSelectDeleteEvent,
  onChangePage,
  onChangePageSize,
  onApplyFilter,
  onClearFilter
}: EventsTableProps) => {
  const translate = useTranslate('components.events.events_table')
  const navigate = useNavigate()
  const columns = [
    { name: translate('column_names.name'), uid: columnUid.name }
  ]

  columns.push({
    name: translate('column_names.actions'),
    uid: columnUid.actions
  })

  const renderCell = useCallback(
    (event: EventDto, columnKey: React.Key) => {
      switch (columnKey) {
        case columnUid.name:
          return (
            <EventsTableColumnName
              name={event.name}
              avatarUrl={event.avatarUrl}
              eventId={event.id}
            />
          )
        case columnUid.actions:
          return (
            <EventsTableColumnActions
              eventId={event.id}
              onSelectDeleteEvent={() => onSelectDeleteEvent(event)}
            />
          )
      }
    },
    [eventList]
  )

  return (
    <PaginatedTable
      items={eventList}
      columns={columns}
      cellRenderer={renderCell}
      totalPages={totalPages}
      selectedPage={selectedPage}
      pageSize={pageSize}
      totalItems={totalItems}
      isLoading={isLoading}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      emptyContent={translate('no_events_found')}
      title={translate('title')}
      headerEndContent={
        <Tooltip color="success" content={translate('actions.add')}>
          <Button
            isIconOnly
            radius="full"
            color="success"
            size="sm"
            onClick={() => navigate('/events/add')}
          >
            <FontAwesomeIcon icon={faPlus} size="lg" />
          </Button>
        </Tooltip>
      }
      filter={
        <EventsTableFilter
          onApplyFilter={onApplyFilter}
          onClearFilter={onClearFilter}
        />
      }
    />
  )
}

export default EventsTable
