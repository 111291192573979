import CreateUpdateProducerDto from '@dtos/CreateUpdateProducerDto'
import DropdownItemDto from '@dtos/DropdownItemDto'
import PaginatedListDto from '@dtos/PaginatedListDto'
import PictureUploadDto from '@dtos/PictureUploadDto'
import ProducerDto from '@dtos/ProducerDto'
import SocialNetworkLinkDto from '@dtos/SocialNetworkLinkDto'
import UpdatePhotoBatchDto from '@dtos/UpdatePhotoBatchDto'
import useRootApiService from '@services/RootApi'

const useProducerService = () => {
  const { Get, Post, Put, Patch, Delete } = useRootApiService()

  const getProducerList = async (
    pageIndex: number,
    pageSize: number,
    nameFilter?: string[]
  ): Promise<PaginatedListDto<ProducerDto> | undefined> => {
    let params: any = { pageIndex, pageSize }

    if (nameFilter && nameFilter.length > 0)
      params = { ...params, name: nameFilter }

    const response = await Get<PaginatedListDto<ProducerDto>>(
      `/producer`,
      params
    )

    return response.data
  }

  const getProducerById = async (
    producerId: string
  ): Promise<ProducerDto | undefined> => {
    const response = await Get<ProducerDto>(`/producer/${producerId}`)

    return response.data
  }

  const createProducer = async (
    mainInfoDto: CreateUpdateProducerDto
  ): Promise<ProducerDto | undefined> => {
    const response = await Post<ProducerDto, CreateUpdateProducerDto>(
      '/producer',
      mainInfoDto
    )

    return response.data
  }

  const updateProducer = async (
    producerId: string,
    mainInfoDto: CreateUpdateProducerDto
  ): Promise<ProducerDto | undefined> => {
    const response = await Put<ProducerDto, CreateUpdateProducerDto>(
      `/producer/${producerId}`,
      mainInfoDto
    )

    return response.data
  }

  const updateProducerLogo = async (
    producerId: string,
    logoDto: PictureUploadDto
  ): Promise<ProducerDto | undefined> => {
    const response = await Put<ProducerDto, PictureUploadDto>(
      `/producer/${producerId}/logo`,
      logoDto
    )

    return response.data
  }

  const deleteProducerLogo = async (
    producerId: string
  ): Promise<ProducerDto | undefined> => {
    const response = await Delete<ProducerDto, PictureUploadDto>(
      `/producer/${producerId}/logo`
    )

    return response.data
  }

  const uploadProducerPhoto = async (
    producerId: string,
    photoDto: PictureUploadDto
  ): Promise<ProducerDto | undefined> => {
    const response = await Post<ProducerDto, PictureUploadDto>(
      `/producer/${producerId}/photo`,
      photoDto
    )
    return response.data
  }

  const removeProducerPhoto = async (
    producerId: string,
    photoId: string
  ): Promise<ProducerDto | undefined> => {
    const response = await Delete<ProducerDto, undefined>(
      `/producer/${producerId}/photo/${photoId}`
    )
    return response.data
  }

  const updateProducerPhotoBatch = async (
    producerId: string,
    photos: UpdatePhotoBatchDto
  ): Promise<ProducerDto | undefined> => {
    const response = await Patch<ProducerDto, UpdatePhotoBatchDto>(
      `/producer/${producerId}/photo`,
      photos
    )
    return response.data
  }

  const deleteProducer = async (producerId: string): Promise<boolean> => {
    const response = await Delete(`/producer/${producerId}`)

    return response.success
  }

  const updateProducerSocialNetworkListBatch = async (
    producerId: string,
    socialNetworkLinks: SocialNetworkLinkDto[]
  ) => {
    socialNetworkLinks.forEach((snl) => (!snl.isNew ? snl.id : undefined))

    const response = await Patch<ProducerDto, SocialNetworkLinkDto[]>(
      `/producer/${producerId}/social-network`,
      socialNetworkLinks
    )

    return response.data
  }

  const getProducerDropdownItems = async (): Promise<
    DropdownItemDto[] | undefined
  > => {
    const response = await Get<DropdownItemDto[]>('/producer/dropdown')

    return response.data
  }

  return {
    getProducerList,
    getProducerById,
    createProducer,
    updateProducer,
    updateProducerLogo,
    deleteProducerLogo,
    uploadProducerPhoto,
    removeProducerPhoto,
    updateProducerPhotoBatch,
    deleteProducer,
    updateProducerSocialNetworkListBatch,
    getProducerDropdownItems
  }
}

export default useProducerService
