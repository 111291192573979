import React from 'react'

import { AvailableCountries } from '@configuration/Constants'
import useTranslate from '@hooks/useTranslate'
import { Image } from '@nextui-org/react'

interface VenuesTableColumnCountryProps {
  country: string
}

const VenuesTableColumnCountry = ({
  country
}: VenuesTableColumnCountryProps) => {
  const countryTranslations = useTranslate('countries')
  const countryAbbreviation = AvailableCountries.find(
    (ac) => ac.databaseSelector === country
  )?.abbreviation

  if (!countryAbbreviation) return <></>

  return (
    <div className="flex items-center gap-2">
      <Image
        classNames={{ wrapper: 'min-w-[32px]' }}
        className="object-cover"
        radius="full"
        src={`https://flagcdn.com/${countryAbbreviation}.svg`}
        height={32}
        width={32}
      />
      <div className="invisible hidden md:visible md:flex">
        {countryTranslations(countryAbbreviation)}
      </div>
    </div>
  )
}

export default VenuesTableColumnCountry
