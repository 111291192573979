import React from 'react'

import useTranslate from '@hooks/useTranslate'
import { ModalHeader } from '@nextui-org/react'

const ConfirmDeleteArtistModalHeader = () => {
  const translate = useTranslate('components.artists.remove_artist')
  return (
    <ModalHeader>
      <span>{translate('title')}</span>
      <span className="text-sm">{translate('subtitle')}</span>
    </ModalHeader>
  )
}

export default ConfirmDeleteArtistModalHeader
