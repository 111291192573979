import React from 'react'

import AddressDto from '@dtos/AddressDto'
import useStringHelpers from '@hooks/useStringHelpers'

interface VenuesTableColumnAddressProps {
  address: AddressDto
}

const VenuesTableColumnAddress = ({
  address
}: VenuesTableColumnAddressProps) => {
  const { getAddressMainInfo, getAddressAddons } = useStringHelpers()

  return (
    <div className="flex max-h-[52px] flex-col items-start gap-1 text-tiny">
      <span className="text-foreground subpixel-antialiased">
        {getAddressMainInfo(address)}
      </span>
      <span className="text-foreground subpixel-antialiased">
        {getAddressAddons(address)}
      </span>
    </div>
  )
}

export default VenuesTableColumnAddress
