import React from 'react'

interface LogoTicketPProps {
  fill?: string
  width?: number
  height?: number
  suppressWidth?: boolean
  suppressHeight?: boolean
}

const LogoTicketP = ({
  fill,
  width = 100,
  height = 100,
  suppressWidth,
  suppressHeight
}: LogoTicketPProps) => {
  const finalWidth = `${width}%`
  const finalHeight = `${height}%`

  return (
    <svg
      fill={fill ?? 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="34.77 55.44 290.1 297.54"
      width={suppressWidth ? undefined : finalWidth}
      height={suppressHeight ? undefined : finalHeight}
    >
      <path d="M314.54,216.63c-3.56-2.96-5.83-7.43-5.83-12.43s2.27-9.47,5.83-12.43c6.67-5.55,10.33-13.93,10.33-22.61v-83.56c0-16.66-13.5-30.16-30.16-30.16H64.93c-16.65,0-30.16,13.5-30.16,30.16v83.63c0,8.64,3.62,17.01,10.28,22.51,3.59,2.96,5.88,7.45,5.88,12.47,0,5.02-2.29,9.5-5.88,12.47-6.66,5.5-10.28,13.87-10.28,22.51v83.63c0,16.66,13.51,30.16,30.16,30.16h229.78c16.66,0,30.16-13.5,30.16-30.16v-83.56c0-8.68-3.66-17.06-10.33-22.61ZM254.68,227.02c-10.63,12.3-23.9,18.45-39.81,18.45-19.79,0-43.09-11.14-69.91-33.43v95.98h-43.69v-135.79c36.99,19.33,71.52,28.99,103.61,28.99,15.63,0,28.43-2.66,38.42-7.97,9.99-5.32,14.98-12.04,14.98-20.18s-4.83-14.68-14.49-19.9c-9.66-5.22-22.4-7.84-38.21-7.84-14.8,0-29.31,2.08-43.55,6.24-15.44,4.44-28.11,9.43-38,14.98l-23.3-54.23c21.17-7.95,42.3-11.93,63.39-11.93,17.84,0,33.82,2.31,47.92,6.94,14.1,4.62,25.36,11.01,33.77,19.14,16.27,15.72,24.41,34.4,24.41,56.04,0,17.66-5.18,32.51-15.53,44.52Z" />
    </svg>
  )
}

export default LogoTicketP
