import React, { useEffect, useState } from 'react'

import { clearArtistsFilterAction } from '@actions/ArtistsFilter'
import { clearGenresFilterAction } from '@actions/GenresFilter'
import { setLanguageAction, setThemeAction } from '@actions/Global'
import { clearProducersFilterAction } from '@actions/ProducersFilter'
import { clearVenuesFilterAction } from '@actions/VenuesFilter'
import LoadingModal from '@components/Shared/LoadingModal'
import UserLoading from '@components/SignIn/UserLoading'
import { LocalStorageConstants } from '@configuration/Constants'
import { ThemeProvider } from '@configuration/ThemeContext'
import { NextUIProvider } from '@nextui-org/react'
import Artists from '@pages/Artists/Artists'
import EditArtist from '@pages/Artists/EditArtist'
import ErrorPage from '@pages/Error'
import EditEvent from '@pages/Events/EditEvent'
import Events from '@pages/Events/Events'
import EditGenre from '@pages/Genres/EditGenre'
import Genres from '@pages/Genres/Genres'
import Home from '@pages/Home'
import EditProducer from '@pages/Producers/EditProducer'
import Producers from '@pages/Producers/Producers'
import SignIn from '@pages/SignIn'
import EditVenue from '@pages/Venues/EditVenue'
import Venues from '@pages/Venues/Venues'
import { AuthProvider } from '@security/AuthContext'
import PrivateRoutes from '@security/PrivateRoutes'
import { store } from '@store'
import { useTranslation } from 'react-i18next'
import { Provider } from 'react-redux'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './App.css'

function App() {
  const [lastLocation, setLastLocation] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const { i18n } = useTranslation()

  useEffect(() => {
    initApp()
  }, [])

  useEffect(() => {
    setLastLocation(location.pathname.split('/')[1])
  }, [location.pathname])

  useEffect(() => {
    store.dispatch(clearGenresFilterAction())
    store.dispatch(clearVenuesFilterAction())
    store.dispatch(clearArtistsFilterAction())
    store.dispatch(clearProducersFilterAction())
  }, [lastLocation])

  const initApp = () => {
    store.dispatch(
      setThemeAction(
        localStorage.getItem(LocalStorageConstants.THEME) ?? 'partyner'
      )
    )
    const language =
      localStorage.getItem(LocalStorageConstants.LANGUAGE) ?? 'en'
    store.dispatch(setLanguageAction(language))
    i18n.changeLanguage(language)
  }

  return (
    <NextUIProvider navigate={navigate}>
      <Provider store={store}>
        <UserLoading />
        <ThemeProvider>
          <LoadingModal />
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            pauseOnHover={false}
            theme="colored"
          />
          <AuthProvider>
            <Routes>
              <Route element={<PrivateRoutes />}>
                <Route path="/" Component={Home} />
                <Route path="/artists" Component={Artists} />
                <Route path="/artists/:id" Component={EditArtist} />
                <Route path="/artists/add" Component={EditArtist} />
                <Route path="/venues" Component={Venues} />
                <Route path="/venues/:id" Component={EditVenue} />
                <Route path="/venues/add" Component={EditVenue} />
                <Route path="/producers" Component={Producers} />
                <Route path="/producers/:id" Component={EditProducer} />
                <Route path="/producers/add" Component={EditProducer} />
                <Route path="/events" Component={Events} />
                <Route path="/events/:id" Component={EditEvent} />
                <Route path="/events/add" Component={EditEvent} />
                <Route path="/genres" Component={Genres} />
                <Route path="/genres/:id" Component={EditGenre} />
                <Route path="/genres/add" Component={EditGenre} />
                <Route path="*" element={<ErrorPage code={404} />} />
              </Route>
              <Route path="/signin" Component={SignIn} />
            </Routes>
          </AuthProvider>
        </ThemeProvider>
      </Provider>
    </NextUIProvider>
  )
}

export default App
