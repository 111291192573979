import React from 'react'

import LocalizableTextAreaDropdown from '@components/Shared/LocalizableTextArea/LocalizableTextAreaDropdown'
import { useTheme } from '@configuration/ThemeContext'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import {
  Button,
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger
} from '@nextui-org/react'

interface LocalizableTextAreaHeaderProps {
  selectedLanguage: string
  label: string
  showRemoveButton: boolean
  languageFilter: string[]
  onAddValue: (culture: string) => void
  onRemoveValue: (culture: string) => void
}

const LocalizableTextAreaHeader = ({
  selectedLanguage,
  label,
  showRemoveButton,
  languageFilter,
  onAddValue,
  onRemoveValue
}: LocalizableTextAreaHeaderProps) => {
  const translate = useTranslate('components.shared.localizable_text_area')
  const { isDarkMode } = useTheme()

  return (
    <CardHeader className="flex w-full justify-between">
      <div className="ml-1 justify-start font-medium text-foreground-500">
        {label}
      </div>
      <div className="flex justify-end gap-3">
        <LocalizableTextAreaDropdown
          languageFilter={languageFilter}
          onAddValue={(culture) => onAddValue(culture)}
        >
          <Button isIconOnly size="sm" radius="full" color="success">
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </LocalizableTextAreaDropdown>

        {showRemoveButton && (
          <Dropdown className={isDarkMode ? 'dark' : ''} placement="bottom-end">
            <DropdownTrigger>
              <Button
                isDisabled={selectedLanguage === 'en'}
                isIconOnly
                size="sm"
                radius="full"
                color="danger"
              >
                <FontAwesomeIcon icon={faMinus} />
              </Button>
            </DropdownTrigger>
            <DropdownMenu variant="flat">
              <DropdownSection
                title={translate('remove_description_confirmation')}
              >
                <DropdownItem key="no" className="text-success" color="success">
                  {translate('no')}
                </DropdownItem>
                <DropdownItem
                  key="yes"
                  className="text-danger"
                  color="danger"
                  onPress={() => onRemoveValue(selectedLanguage)}
                >
                  {translate('yes')}
                </DropdownItem>
              </DropdownSection>
            </DropdownMenu>
          </Dropdown>
        )}
      </div>
    </CardHeader>
  )
}

export default LocalizableTextAreaHeader
