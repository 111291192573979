import React, { useCallback } from 'react'

import ProducersTableColumnActions from '@components/Producers/ProducersTable/Columns/ProducersTableColumnActions'
import ProducersTableColumnName from '@components/Producers/ProducersTable/Columns/ProducersTableColumnName'
import ProducersTableFilter from '@components/Producers/ProducersTable/ProducersTableFilter'
import PaginatedTable from '@components/Shared/PaginatedTable/PaginatedTable'
import ProducerDto from '@dtos/ProducerDto'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import { Button, Tooltip } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'

enum columnUid {
  name = 'name',
  actions = 'actions'
}

interface ProducersTableProps {
  producerList: ProducerDto[]
  totalPages: number
  selectedPage: number
  totalItems: number
  pageSize: number
  isLoading: boolean
  onSelectDeleteProducer: (producer: ProducerDto) => void
  onChangePage: (page: number) => void
  onChangePageSize: (size: number) => void
  onApplyFilter: (name: string[]) => void
  onClearFilter: () => void
}

const ProducersTable = ({
  producerList,
  totalPages,
  selectedPage,
  totalItems,
  pageSize,
  isLoading,
  onSelectDeleteProducer,
  onChangePage,
  onChangePageSize,
  onApplyFilter,
  onClearFilter
}: ProducersTableProps) => {
  const translate = useTranslate('components.producers.producers_table')
  const navigate = useNavigate()
  const columns = [
    { name: translate('column_names.name'), uid: columnUid.name }
  ]

  columns.push({
    name: translate('column_names.actions'),
    uid: columnUid.actions
  })

  const renderCell = useCallback(
    (producer: ProducerDto, columnKey: React.Key) => {
      switch (columnKey) {
        case columnUid.name:
          return (
            <ProducersTableColumnName
              name={producer.name}
              logoUrl={producer.logoUrl}
              producerId={producer.id}
            />
          )
        case columnUid.actions:
          return (
            <ProducersTableColumnActions
              producerId={producer.id}
              onSelectDeleteProducer={() => onSelectDeleteProducer(producer)}
            />
          )
      }
    },
    [producerList]
  )

  return (
    <PaginatedTable
      items={producerList}
      columns={columns}
      cellRenderer={renderCell}
      totalPages={totalPages}
      selectedPage={selectedPage}
      pageSize={pageSize}
      totalItems={totalItems}
      isLoading={isLoading}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      emptyContent={translate('no_producers_found')}
      title={translate('title')}
      headerEndContent={
        <Tooltip color="success" content={translate('actions.add')}>
          <Button
            isIconOnly
            radius="full"
            color="success"
            size="sm"
            onClick={() => navigate('/producers/add')}
          >
            <FontAwesomeIcon icon={faPlus} size="lg" />
          </Button>
        </Tooltip>
      }
      filter={
        <ProducersTableFilter
          onApplyFilter={onApplyFilter}
          onClearFilter={onClearFilter}
        />
      }
    />
  )
}

export default ProducersTable
