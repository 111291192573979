import React from 'react'

import defaultAvatar from '@assets/Images/default_artist_avatar.png'
import defaultAvatarDark from '@assets/Images/default_artist_avatar_dark.png'
import FramedImage from '@components/Shared/FramedImage/FramedImage'
import { AvailableCountries } from '@configuration/Constants'
import { useTheme } from '@configuration/ThemeContext'
import useMediaQueryHelpers from '@hooks/useMediaQueryHelpers'
import { Image } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'

interface VenuesTableColumnNameProps {
  name: string
  logoUrl: string
  venueId: string
  country: string
}

const VenuesTableColumnName = ({
  name,
  logoUrl,
  venueId,
  country
}: VenuesTableColumnNameProps) => {
  const { isMedium } = useMediaQueryHelpers()
  const { isDarkMode } = useTheme()
  const navigate = useNavigate()
  const countryAbbreviation = AvailableCountries.find(
    (ac) => ac.databaseSelector === country
  )?.abbreviation

  return (
    <div className="flex max-h-[52px] items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        <FramedImage
          size={52}
          roundSize="lg"
          src={logoUrl ?? (isDarkMode ? defaultAvatarDark : defaultAvatar)}
          alt={name}
          onClick={() => navigate(`/venues/${venueId}`)}
        />
        <span className="text-foreground subpixel-antialiased">{name}</span>
      </div>

      {isMedium && (
        <Image
          className="object-cover"
          radius="full"
          src={`https://flagcdn.com/${countryAbbreviation}.svg`}
          height={32}
          width={32}
        />
      )}
    </div>
  )
}

export default VenuesTableColumnName
