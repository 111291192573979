import React, { useEffect } from 'react'

import useTranslate from '@hooks/useTranslate'
import { Input, InputProps } from '@nextui-org/react'
import { useField } from 'formik'

interface FieldProps {
  id: string
  name: string
  onValidate?: (isValid: boolean) => void
}

type InputFieldProps = FieldProps & InputProps

const InputField = (props: InputFieldProps) => {
  const translate = useTranslate()

  const { id, name, ...restProps } = props

  const [field, meta] = useField({ name, id })
  const hasError = meta.touched && !!meta.error

  useEffect(() => {
    if (props.onValidate) {
      props.onValidate(!hasError)
    }
  }, [hasError])

  return (
    <Input
      {...field}
      {...restProps}
      isInvalid={hasError}
      errorMessage={
        hasError && meta.error
          ? translate(meta.error, { min_length: '3', max_length: '255' })
          : ''
      }
    />
  )
}

export default InputField
