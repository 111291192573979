export enum ArtistsFilterActionTypes {
  SET_ARTISTS_FILTER = 'SET_ARTISTS_FILTER',
  CLEAR_ARTISTS_FILTER = 'CLEAR_ARTISTS_FILTER'
}

export const setArtistsFilterAction = (
  page: number,
  pageSize: number,
  namesFilter: string[],
  genresFilter: string[],
  countriesFilter: string[]
) => ({
  type: ArtistsFilterActionTypes.SET_ARTISTS_FILTER as const,
  payload: { page, pageSize, namesFilter, genresFilter, countriesFilter }
})

export const clearArtistsFilterAction = () => ({
  type: ArtistsFilterActionTypes.CLEAR_ARTISTS_FILTER as const
})

export type ArtistsFilterAction = ReturnType<
  typeof setArtistsFilterAction | typeof clearArtistsFilterAction
>
