import React from 'react'

import { Modal, ModalContent, Spinner } from '@nextui-org/react'
import { RootState } from '@store'
import { useSelector } from 'react-redux'

const LoadingModal = () => {
  const state = useSelector((state: RootState) => state.global)

  return (
    <Modal
      isOpen={state.isLoading}
      backdrop={'blur'}
      isDismissable={false}
      isKeyboardDismissDisabled
      hideCloseButton={true}
      classNames={{
        base: 'border-none shadow-none bg-transparent'
      }}
    >
      <ModalContent>
        <Spinner size="lg" color="default" />
      </ModalContent>
    </Modal>
  )
}

export default LoadingModal
