import React, { Dispatch, SetStateAction, useState } from 'react'

import {
  faFilter,
  faFilterCircleXmark
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import {
  Accordion,
  AccordionItem,
  Button,
  Input,
  Tooltip
} from '@nextui-org/react'
import { RootState } from '@store'
import { useSelector } from 'react-redux'

interface EventsTableFilterProps {
  onApplyFilter: (name: string[]) => void
  onClearFilter: () => void
}

interface StringFilterHelper {
  field: string
  setInputValue: Dispatch<SetStateAction<string>>
  setFilter: Dispatch<SetStateAction<string[]>>
}

const EventsTableFilter = ({
  onApplyFilter,
  onClearFilter
}: EventsTableFilterProps) => {
  const eventsFilterInMemory = useSelector(
    (state: RootState) => state.eventsFilter
  )
  const translate = useTranslate('components.events.events_table.filter')

  const [nameInputValue, setNameInputValue] = useState(
    eventsFilterInMemory.nameFilter
      ? eventsFilterInMemory.nameFilter.join(';')
      : ''
  )

  const [nameFilter, setNameFilter] = useState<string[]>(
    eventsFilterInMemory.nameFilter ?? []
  )

  const stringFilters: StringFilterHelper[] = [
    {
      field: 'name',
      setInputValue: setNameInputValue,
      setFilter: setNameFilter
    }
  ]

  const normalizeFilterValue = (field: string, value: string) => {
    const filter = stringFilters.find((f) => f.field === field)
    if (filter) {
      filter.setInputValue(value)
      filter.setFilter(value === '' ? [] : value.split(';'))
    }
  }

  const clearFilter = () => {
    Object.values(stringFilters).forEach(({ setInputValue, setFilter }) => {
      setInputValue('')
      setFilter([])
    })

    onClearFilter()
  }

  return (
    <Accordion variant="shadow" isCompact={false}>
      <AccordionItem
        key="filters"
        title={translate('title')}
        startContent={
          <FontAwesomeIcon icon={faFilter} className="text-foreground-500" />
        }
        classNames={{
          title: 'text-sm font-semibold text-foreground-500'
        }}
      >
        <div className="flex flex-col items-center gap-3 pb-3">
          <Tooltip
            size="sm"
            placement="bottom"
            color="foreground"
            content={translate('name_tooltip')}
          >
            <Input
              value={nameInputValue}
              label={translate('name')}
              id="eventNameFilter"
              name="eventNameFilter"
              classNames={{ label: 'z-0' }}
              onValueChange={(value) => normalizeFilterValue('name', value)}
            />
          </Tooltip>
          <div className="flex flex-wrap justify-center gap-3">
            <Button
              variant="solid"
              color="success"
              onClick={() => onApplyFilter(nameFilter)}
              isDisabled={nameInputValue.length === 0}
              startContent={<FontAwesomeIcon icon={faFilter} />}
            >
              {translate('apply_filters')}
            </Button>
            <Button
              variant="solid"
              color="warning"
              onClick={clearFilter}
              isDisabled={nameInputValue.length === 0}
              startContent={<FontAwesomeIcon icon={faFilterCircleXmark} />}
            >
              {translate('clear_filters')}
            </Button>
          </div>
        </div>
      </AccordionItem>
    </Accordion>
  )
}

export default EventsTableFilter
