import React from 'react'

interface FaceIconProps {
  fill?: string
}

const FaceIcon = ({ fill }: FaceIconProps) => {
  return (
    <div className="flex size-1/2 items-center justify-center align-middle">
      <svg
        fill={fill ?? 'currentColor'}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="30.8935 29.28 78.803 83.37"
        width="100%"
        height="100%"
      >
        <path
          d="M106.89,73.11c1.61,0,2.91,1.36,2.8,2.97-.63,9.69-4.41,18-11.33,24.92-7.77,7.76-17.11,11.65-28.03,11.65s-20.22-3.84-27.88-11.51c-7.05-6.92-10.9-15.17-11.55-24.77-.11-1.61,1.19-2.97,2.8-2.97h.08c1.48,0,2.69,1.16,2.79,2.64.56,8.27,3.8,15.3,9.74,21.1,6.55,6.55,14.56,9.83,24.02,9.83s17.16-3.18,23.81-9.54c6.09-5.83,9.41-13.05,9.97-21.68.1-1.48,1.3-2.64,2.79-2.64h0Z"
          transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -3.552713678800501e-15)"
        />
        <path
          d="M62.54,40.28c-.99,2.35-1.49,3.94-1.49,4.76,0,.73.5,2.3,1.49,4.7,1,2.4,1.49,4.02,1.49,4.84s-.49,2.44-1.49,4.84c-.99,2.41-1.49,3.97-1.49,4.7,0,.53.5,2.07,1.49,4.62,1,2.55,1.49,4.14,1.49,4.77,0,1.89-.54,3.48-1.63,4.77-1.1,1.29-2.49,1.93-4.19,1.93s-2.97-.64-3.97-1.93c-.99-1.29-1.49-2.88-1.49-4.77,0-.82.48-2.44,1.46-4.84.97-2.4,1.45-3.92,1.45-4.55,0-1.17-.48-2.82-1.45-4.95-.98-2.14-1.46-3.71-1.46-4.73,0-.83.48-2.44,1.46-4.84.97-2.41,1.45-3.92,1.45-4.56,0-.82-.48-2.41-1.45-4.76-.98-2.36-1.46-3.85-1.46-4.48-.24-2.67.63-4.56,2.62-5.68,1.99-1.12,3.99-1.12,6.01,0,2.01,1.12,2.9,3.01,2.65,5.68,0,.63-.49,2.12-1.49,4.48Z"
          transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -3.552713678800501e-15)"
        />
        <path
          d="M88.64,40.28c-.99,2.35-1.49,3.94-1.49,4.76,0,.73.5,2.3,1.49,4.7,1,2.4,1.49,4.02,1.49,4.84s-.49,2.44-1.49,4.84c-.99,2.41-1.49,3.97-1.49,4.7,0,.53.5,2.07,1.49,4.62,1,2.55,1.49,4.14,1.49,4.77,0,1.89-.54,3.48-1.63,4.77-1.1,1.29-2.49,1.93-4.19,1.93s-2.97-.64-3.97-1.93c-.99-1.29-1.49-2.88-1.49-4.77,0-.82.48-2.44,1.46-4.84.97-2.4,1.45-3.92,1.45-4.55,0-1.17-.48-2.82-1.45-4.95-.98-2.14-1.46-3.71-1.46-4.73,0-.83.48-2.44,1.46-4.84.97-2.41,1.45-3.92,1.45-4.56,0-.82-.48-2.41-1.45-4.76-.98-2.36-1.46-3.85-1.46-4.48-.24-2.67.63-4.56,2.62-5.68,1.99-1.12,3.99-1.12,6.01,0,2.01,1.12,2.9,3.01,2.65,5.68,0,.63-.49,2.12-1.49,4.48Z"
          transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -3.552713678800501e-15)"
        />
      </svg>
    </div>
  )
}

export default FaceIcon
