import {
  LocalizedStringValidationSchema,
  PictureUploadValidationSchema
} from '@formSchemas/Shared'
import AddGenreToArtist from '@interfaces/Forms/Artist/AddGenreToArtist'
import CreateUpdateArtistMainInfo from '@interfaces/Forms/Artist/CreateUpdateArtistMainInfo'
import UpdateSoundCloudTracks from '@interfaces/Forms/Artist/UpdateSoundCloudTracks'
import * as yup from 'yup'
import { array, ObjectSchema } from 'yup'

export const ArtistMainInfoFormInitialValues: CreateUpdateArtistMainInfo = {
  name: '',
  description: [
    {
      culture: 'en',
      value: ''
    }
  ],
  country: '',
  avatar: undefined
}

export const ArtistGenresFormInitialValues: AddGenreToArtist = {
  genres: []
}

export const ArtistSoundCloudTracksFormInitialValues: UpdateSoundCloudTracks = {
  tracks: []
}

export const ArtistMainInfoFormValidationSchema: ObjectSchema<CreateUpdateArtistMainInfo> =
  yup.object({
    id: yup.string(),
    name: yup
      .string()
      .required('form_validation.field_required')
      .max(255, 'form_validation.max_length'),
    description: array().of(LocalizedStringValidationSchema).required(),
    country: yup.string().required(),
    avatar: PictureUploadValidationSchema.nullable().notRequired(),
    artistId: yup.string()
  })

export const ArtistGenresFormValidationSchema: ObjectSchema<AddGenreToArtist> =
  yup.object({
    genres: array()
      .of(
        yup.object({
          genreId: yup.string().required('form_validation.field_required'),
          displayName: yup.string()
        })
      )
      .required('form_validation.field_required'),
    artistId: yup.string()
  })

export const ArtistSoundCloudTracksFormValidationSchema: ObjectSchema<UpdateSoundCloudTracks> =
  yup.object({
    artistId: yup.string(),
    tracks: array().of(
      yup.object({
        id: yup.string(),
        trackId: yup.string().required('form_validation.field_required'),
        order: yup.number().required(),
        isNew: yup.boolean()
      })
    )
  })
