import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enUS from './Locales/enUS.json'
import ptBR from './Locales/ptBR.json'

const resources = {
  en: { translation: { ...enUS } },
  pt: { translation: { ...ptBR } }
}

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  supportedLngs: ['en', 'pt', 'es', 'de'],
  interpolation: { escapeValue: false }
})

export default i18n
