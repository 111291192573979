import React from 'react'

import useTranslate from '@hooks/useTranslate'
import { Button } from '@nextui-org/react'
import { useAuth } from '@security/AuthContext'

const UserMenuLogout = () => {
  const { logout } = useAuth()
  const translate = useTranslate('main_menu.user_menu')

  return (
    <Button color="danger" variant="light" fullWidth onClick={logout}>
      {translate('logout')}
    </Button>
  )
}

export default UserMenuLogout
