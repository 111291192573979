import React, { useEffect } from 'react'

import { setRootBreadcrumbAction } from '@actions/Breadcrumb'
import { RootBreadcrumbConstants } from '@configuration/Constants'
import { RootState } from '@store'
import { useDispatch, useSelector } from 'react-redux'

const Home = () => {
  const dispatch = useDispatch()
  const state = useSelector((state: RootState) => state.global)

  useEffect(() => {
    dispatch(setRootBreadcrumbAction(RootBreadcrumbConstants.DASHBOARD))
  }, [])

  return (
    <div className="text-amber-50">
      <h1>Hello from ControlP!</h1>
      <h2>{state.loggedUser?.id}</h2>
      <h2>{state.loggedUser?.displayName}</h2>
      <h2>{state.loggedUser?.name}</h2>
      <h2>{state.loggedUser?.email}</h2>
    </div>
  )
}

export default Home
