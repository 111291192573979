import React, { Ref } from 'react'

import { setIsLoadingAction } from '@actions/Global'
import ChangeableAvatar from '@components/Shared/ChangeableAvatar'
import InputField from '@components/Shared/InputField'
import LocalizableTextArea from '@components/Shared/LocalizableTextArea/LocalizableTextArea'
import CreateUpdateProducerDto from '@dtos/CreateUpdateProducerDto'
import PictureUploadDto from '@dtos/PictureUploadDto'
import ProducerDto from '@dtos/ProducerDto'
import { ProducerMainInfoFormValidationSchema } from '@formSchemas/Producer'
import { LocalizedStringInitialValues } from '@formSchemas/Shared'
import useTranslate from '@hooks/useTranslate'
import CreateUpdateProducerMainInfo from '@interfaces/Forms/Producer/CreateUpdateProducerMainInfo'
import { Card, CardBody, CardHeader } from '@nextui-org/react'
import useProducerService from '@services/Producer'
import { Formik, FormikProps } from 'formik'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

interface EditProducerMainInfoFormProps {
  values: CreateUpdateProducerMainInfo
  formRef: Ref<FormikProps<CreateUpdateProducerMainInfo>>
  formKey: number
  onSaveForm: (producer: ProducerDto) => void
  originalLogoUrl?: string
}

const EditProducerMainInfoForm = ({
  values,
  formRef,
  formKey,
  onSaveForm,
  originalLogoUrl
}: EditProducerMainInfoFormProps) => {
  const dispatch = useDispatch()
  const translate = useTranslate('components.producers.edit_producer')
  const errorsTranslate = useTranslate('errors')
  const { updateProducer, updateProducerLogo, createProducer } =
    useProducerService()

  const changeLogo = (
    form: FormikProps<CreateUpdateProducerMainInfo>,
    logo: PictureUploadDto | null
  ) => form.setFieldValue('logo', logo)

  const addDescription = (
    form: FormikProps<CreateUpdateProducerMainInfo>,
    culture: string
  ) => {
    if (
      !form.values.description
        .map((description) => description.culture)
        .includes(culture)
    ) {
      form.setFieldValue('description', [
        ...form.values.description,
        { ...LocalizedStringInitialValues(culture) }
      ])
    }
  }

  const removeDescription = (
    form: FormikProps<CreateUpdateProducerMainInfo>,
    culture: string
  ) => {
    if (
      form.values.description
        .map((description) => description.culture)
        .includes(culture)
    ) {
      form.setFieldValue('description', [
        ...form.values.description.filter(
          (description) => description.culture !== culture
        )
      ])
    }
  }
  const handleError = () => {
    dispatch(setIsLoadingAction(false))
    toast.error(errorsTranslate('general'))
  }

  const saveForm = async (form: CreateUpdateProducerMainInfo) => {
    const producerDto: CreateUpdateProducerDto = {
      name: form.name,
      description: form.description
    }

    let response

    if (form.producerId) {
      response = await updateProducer(form.producerId, producerDto)
    } else {
      response = await createProducer(producerDto)
    }

    if (!response) return handleError()

    if (form.logo) {
      const logoResponse = await saveLogo(response.id, form.logo)
      if (!logoResponse) return handleError()
      return onSaveForm(logoResponse)
    }

    return onSaveForm(response)
  }

  const saveLogo = async (
    producerId: string,
    logo: PictureUploadDto
  ): Promise<ProducerDto | undefined> => {
    const dataIndex = logo.base64image.indexOf(',')
    logo.base64image = logo.base64image.substring(dataIndex + 1)
    return await updateProducerLogo(producerId, logo)
  }

  return (
    <Card fullWidth>
      <CardHeader>
        <div className="pl-2 text-lg font-semibold text-foreground-500">
          {translate('main_info')}
        </div>
      </CardHeader>
      <Formik
        innerRef={formRef}
        key={formKey}
        initialValues={values}
        onSubmit={async (values) => await saveForm(values)}
        validationSchema={ProducerMainInfoFormValidationSchema}
      >
        {(props: FormikProps<CreateUpdateProducerMainInfo>) => (
          <form onSubmit={props.handleSubmit} noValidate>
            <CardBody>
              <div className="flex w-full flex-col items-center gap-4">
                <div className="flex w-full flex-col items-center gap-4 lg:flex-row">
                  <ChangeableAvatar
                    name={props.values.name}
                    originalAvatarUrl={originalLogoUrl ?? ''}
                    onChangeAvatar={(logo) => changeLogo(props, logo)}
                  />
                  <div className="flex w-full grow flex-col gap-3">
                    <InputField
                      id="name"
                      name="name"
                      type="text"
                      label={translate('name')}
                    />
                    <LocalizableTextArea
                      fieldName="description"
                      label={translate('description')}
                      placeholder={translate('description')}
                      values={props.values.description}
                      onAddValue={(culture) => addDescription(props, culture)}
                      onRemoveValue={(culture) =>
                        removeDescription(props, culture)
                      }
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          </form>
        )}
      </Formik>
    </Card>
  )
}

export default EditProducerMainInfoForm
