import React, { createContext, useContext } from 'react'

import { RootState } from '@store'
import { useSelector } from 'react-redux'

const ThemeContext = createContext<
  | {
      theme: string
      isDarkMode: boolean
      primaryColor: string
    }
  | undefined
>(undefined)

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const theme =
    useSelector((state: RootState) => state.global).theme ?? 'partyner'

  const isDarkMode: boolean = theme === 'dark'
  const primaryColor: string = `hsl(var(--nextui-primary)`

  return (
    <ThemeContext.Provider value={{ theme, isDarkMode, primaryColor }}>
      <main className={`h-full text-foreground ${theme}`}>{children}</main>
    </ThemeContext.Provider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error('useTheme must be used within an ThemeProvider')
  }
  return context
}
