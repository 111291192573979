import React, { useEffect, useRef, useState } from 'react'

import { setRootBreadcrumbAction } from '@actions/Breadcrumb'
import { setIsLoadingAction } from '@actions/Global'
import EditEventLineupForm from '@components/Events/EditEvent/Forms/EditEventLineupForm'
import EditEventMainInfoForm from '@components/Events/EditEvent/Forms/EditEventMainInfoForm'
import EditEventVenueForm from '@components/Events/EditEvent/Forms/EditEventVenueForm'
import FormFooter from '@components/Shared/FormFooter'
import EditPicturesListForm from '@components/Shared/Forms/EditPicutreListForm'
import EditSocialNetworkListForm from '@components/Shared/Forms/EditSocialNetworkListForm'
import { RootBreadcrumbConstants } from '@configuration/Constants'
import EventDto from '@dtos/EventDto'
import PictureDto from '@dtos/PictureDto'
import {
  EventLineupFormInitialValues,
  EventMainInfoFormInitialValues,
  EventVenueFormInitialValues
} from '@formSchemas/Event'
import {
  UpdatePictureListFormValuesInitialValues,
  UpdateSocialNetworkListFormValuesInitialValues
} from '@formSchemas/Shared'
import usePageTitleTranslation from '@hooks/usePageTitleTranslation'
import useTranslate from '@hooks/useTranslate'
import CreateUpdateEventLineup from '@interfaces/Forms/Event/CreateUpdateEventLineup'
import CreateUpdateEventMainInfo from '@interfaces/Forms/Event/CreateUpdateEventMainInfo'
import UpdateEventVenue from '@interfaces/Forms/Event/UpdateEventVenue'
import UpdatePictureListFormValues from '@interfaces/Forms/Shared/UpdatePictureListFormValues'
import UpdateSocialNetworkListFormValues from '@interfaces/Forms/Shared/UpdateSocialNetworkListFormValues'
import useEventService from '@services/Event'
import { RootState } from '@store'
import { FormikProps } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const EditEvent = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const translate = useTranslate('components.events.edit_event')
  const state = useSelector((state: RootState) => state.global)

  const { id } = useParams<{ id: string | undefined }>()
  const { getEventById } = useEventService()
  const { getPageTitle } = usePageTitleTranslation()
  const { updateEventPhotoBatch, updateEventSocialNetworkListBatch } =
    useEventService()

  const [isSavingData, setIsSavingData] = useState(false)
  const [event, setEvent] = useState<EventDto | undefined>(undefined)
  const [eventPictures, setEventPictures] = useState<PictureDto[] | undefined>(
    undefined
  )

  const [mainInfoFormKey, setMainInfoFormKey] = useState(0)
  const [venueFormKey, setVenueFormKey] = useState(0)
  const [lineupFormKey, setLineupFormKey] = useState(0)
  const [socialNetworkListFormKey, setSocialNetworkListFormKey] = useState(0)
  const [picturesFormKey, setPicturesFormKey] = useState(0)

  const [mainInfoFormValues, setMainInfoFormValues] =
    useState<CreateUpdateEventMainInfo>(
      EventMainInfoFormInitialValues(state.producer!)
    )
  const [lineupFormValues, setLineupFormValues] =
    useState<CreateUpdateEventLineup>(EventLineupFormInitialValues)

  const [venueFormValues, setVenueFormValues] = useState<UpdateEventVenue>(
    EventVenueFormInitialValues
  )
  const [socialNetworkListFormValues, setSocialNetworkListFormValues] =
    useState<UpdateSocialNetworkListFormValues>(
      UpdateSocialNetworkListFormValuesInitialValues
    )
  const [picturesFormValues, setPicturesFormValues] =
    useState<UpdatePictureListFormValues>(
      UpdatePictureListFormValuesInitialValues
    )

  const mainInfoFormRef = useRef<FormikProps<CreateUpdateEventMainInfo>>(null)
  const lineupFormRef = useRef<FormikProps<CreateUpdateEventLineup>>(null)
  const venueFormRef = useRef<FormikProps<UpdateEventVenue>>(null)
  const picturesFormRef = useRef<FormikProps<UpdatePictureListFormValues>>(null)
  const socialNetworkListFormRef =
    useRef<FormikProps<UpdateSocialNetworkListFormValues>>(null)

  const loadEvent = async (id: string) => {
    dispatch(setIsLoadingAction(true))
    const response = await getEventById(id)
    if (response) {
      setBreadcrumbs(response)
      setEvent(response)
    } else {
      navigate('/not-found')
      dispatch(setIsLoadingAction(false))
    }
  }

  const setBreadcrumbs = (breadcrumbData?: EventDto) => {
    if (breadcrumbData) {
      dispatch(
        setRootBreadcrumbAction(RootBreadcrumbConstants.EVENTS, [
          {
            path: `/events/${breadcrumbData.id}`,
            title: breadcrumbData.name
          }
        ])
      )
    } else {
      dispatch(
        setRootBreadcrumbAction(RootBreadcrumbConstants.EVENTS, [
          { path: '/events/add', title: getPageTitle('add_event') }
        ])
      )
    }
  }

  const saveMainForm = async () => {
    if (
      mainInfoFormRef.current &&
      lineupFormRef.current &&
      venueFormRef.current &&
      socialNetworkListFormRef.current &&
      picturesFormRef.current
    ) {
      await mainInfoFormRef.current.setFieldValue('eventId', id)
      await mainInfoFormRef.current.validateForm()
    }

    setTimeout(async () => {
      if (
        mainInfoFormRef.current!.isValid &&
        lineupFormRef.current!.isValid &&
        venueFormRef.current!.isValid &&
        socialNetworkListFormRef.current!.isValid &&
        picturesFormRef.current!.isValid
      ) {
        dispatch(setIsLoadingAction(true))
        setIsSavingData(true)
        await mainInfoFormRef.current!.submitForm()
      }
    }, 0)
  }

  const saveLineupForm = async (updatedEvent: EventDto) => {
    if (lineupFormRef.current) {
      await lineupFormRef.current.setFieldValue('eventId', updatedEvent.id)
      setTimeout(async () => {
        await lineupFormRef.current!.submitForm()
      }, 0)
    }
  }

  const saveVenueForm = async (updatedEvent: EventDto) => {
    if (venueFormRef.current) {
      await venueFormRef.current.setFieldValue('eventId', updatedEvent.id)
      setTimeout(async () => {
        await venueFormRef.current!.submitForm()
      }, 0)
    }
  }

  const saveSocialNetworksForm = async (eventId: string) => {
    if (socialNetworkListFormRef.current) {
      await socialNetworkListFormRef.current.setFieldValue('entityId', eventId)
      setTimeout(async () => {
        await socialNetworkListFormRef.current!.submitForm()
      }, 0)
    }
  }

  const savePictureForm = async (updatedEvent: EventDto) => {
    if (picturesFormRef.current) {
      await picturesFormRef.current.setFieldValue('entityId', updatedEvent.id)
      setTimeout(async () => {
        await picturesFormRef.current!.submitForm()
      }, 0)
    }
  }

  const finishSavingForms = (updatedEvent: EventDto) => {
    if (updatedEvent) {
      setIsSavingData(false)
      setEvent(updatedEvent)
      navigate(`/events/${updatedEvent.id}`, { replace: true })
      toast.success(translate('data_saved'))
    }
  }

  useEffect(() => {
    if (state.producer !== mainInfoFormValues.mainProducerId)
      navigate('/events')
  }, [state.producer])

  useEffect(() => {
    if (id) loadEvent(id)
    setBreadcrumbs()
  }, [id])

  useEffect(() => {
    if (!isSavingData && event) {
      setMainInfoFormValues({
        name: event.name,
        description: event.description,
        avatar: { base64image: '', imageType: '' },
        mainProducerId: event.mainProducerId,
        eventId: event.id,
        releaseDate: event.releaseDate,
        endDate: event.endDate
      })
      setVenueFormValues({
        venueId: event.venueId,
        eventId: event.id,
        venueVisibility: event.venueVisibility,
        venueReleaseDate: event.venueReleaseDate
      })
      setLineupFormValues({
        eventId: event.id,
        lineup: event.lineup.map((lineup) => ({
          id: lineup.id,
          date: lineup.date,
          isNew: false,
          artists: lineup.artists.map((artist) => ({
            artistId: artist.artistId,
            time: artist.time,
            order: artist.order
          }))
        }))
      })
      setPicturesFormValues({
        pictures: [],
        entityId: event.id
      })
      setSocialNetworkListFormValues({
        links: event.socialNetworkLinks,
        entityId: event.id
      })
      setEventPictures(event.photos)
    }
    setMainInfoFormKey((prevKey) => prevKey + 1)
    setVenueFormKey((prevKey) => prevKey + 1)
    setLineupFormKey((prevKey) => prevKey + 1)
    setSocialNetworkListFormKey((prevKey) => prevKey + 1)
    setPicturesFormKey((prevKey) => prevKey + 1)
    dispatch(setIsLoadingAction(false))
  }, [event])

  return (
    <div className="flex grow flex-col gap-3">
      <EditEventMainInfoForm
        values={mainInfoFormValues}
        formRef={mainInfoFormRef}
        formKey={mainInfoFormKey}
        originalLogoUrl={event?.avatarUrl}
        onSaveForm={async (values) => await saveVenueForm(values)}
      />
      <EditEventVenueForm
        values={venueFormValues}
        formRef={venueFormRef}
        formKey={venueFormKey}
        onSaveForm={async (values) => await saveLineupForm(values)}
      />
      <EditEventLineupForm
        values={lineupFormValues}
        formRef={lineupFormRef}
        formKey={lineupFormKey}
        onSaveForm={async (eventId) => {
          await saveSocialNetworksForm(eventId)
        }}
      />
      <EditSocialNetworkListForm
        values={socialNetworkListFormValues}
        formRef={socialNetworkListFormRef}
        formKey={socialNetworkListFormKey}
        updateSocialNetworkService={updateEventSocialNetworkListBatch}
        onSaveForm={async (values) => await savePictureForm(values)}
      />
      <EditPicturesListForm
        values={picturesFormValues}
        formRef={picturesFormRef}
        formKey={picturesFormKey}
        onSaveForm={finishSavingForms}
        existingPictures={eventPictures}
        updatePicturesService={updateEventPhotoBatch}
      />
      <FormFooter
        showCancelButton={!!id}
        onSelectCancel={() => loadEvent(id!)}
        onSelectSave={async () => await saveMainForm()}
        onGoBack={() => navigate('/events')}
      />
    </div>
  )
}

export default EditEvent
