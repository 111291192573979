import { RootBreadcrumbConstants } from '@configuration/Constants'
import Breadcrumb from '@interfaces/Breadcrumb'

export enum BreadcrumbsActionTypes {
  ADD_BREADCRUMB = 'ADD_BREADCRUMB',
  REMOVE_BREADCRUMB = 'REMOVE_BREADCRUMB',
  CLEAR_BREADCRUMBS = 'CLEAR_BREADCRUMBS',
  SET_ROOT_BREADCRUMB = 'SET_ROOT_BREADCRUMB'
}

export const setBreadcrumbAction = (breadcrumbs: Breadcrumb[]) => ({
  type: BreadcrumbsActionTypes.ADD_BREADCRUMB as const,
  payload: breadcrumbs
})

export const removeBreadcrumbAction = (path: string) => ({
  type: BreadcrumbsActionTypes.REMOVE_BREADCRUMB as const,
  payload: path
})

export const clearBreadcrumbsAction = () => ({
  type: BreadcrumbsActionTypes.CLEAR_BREADCRUMBS as const
})

export const setRootBreadcrumbAction = (
  root: RootBreadcrumbConstants,
  complement?: Breadcrumb[]
) => ({
  type: BreadcrumbsActionTypes.SET_ROOT_BREADCRUMB as const,
  payload: { root, complement }
})

export type BreadcrumbActions = ReturnType<
  | typeof setBreadcrumbAction
  | typeof removeBreadcrumbAction
  | typeof clearBreadcrumbsAction
  | typeof setRootBreadcrumbAction
>
