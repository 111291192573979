import React, { useState } from 'react'

import useTranslate from '@hooks/useTranslate'
import { Button, Input } from '@nextui-org/react'

interface ConfirmDeleteProducerModalBodyInputProps {
  name?: string
  onConfirmDeleteProducer: () => void
}

const ConfirmDeleteProducerModalBodyInput = ({
  name,
  onConfirmDeleteProducer
}: ConfirmDeleteProducerModalBodyInputProps) => {
  const translate = useTranslate('components.producers.remove_producer')
  const [nameMatch, setNameMatch] = useState('')
  return (
    <>
      <span className="text-sm">{translate('type_producer_name')}</span>
      <Input
        variant="bordered"
        onValueChange={(value) => setNameMatch(value)}
      />
      <Button
        variant="flat"
        color="danger"
        isDisabled={nameMatch !== name}
        onClick={onConfirmDeleteProducer}
      >
        {translate('remove_producer')}
      </Button>
    </>
  )
}

export default ConfirmDeleteProducerModalBodyInput
