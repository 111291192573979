import SignInDto from '@dtos/SignInDto'
import { ObjectSchema } from 'yup'
import * as yup from 'yup'

export const SignInFormInitialValues: SignInDto = {
  email: '',
  password: ''
}

export const SignInFormValidationSchema: ObjectSchema<SignInDto> = yup.object({
  email: yup
    .string()
    .required('form_validation.field_required')
    .max(255, 'form_validation.max_length'),
  password: yup
    .string()
    .required('form_validation.field_required')
    .max(255, 'form_validation.max_length')
})
