import React from 'react'

import { useTheme } from '@configuration/ThemeContext'
import { faBars, faPencil, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useMediaQueryHelpers from '@hooks/useMediaQueryHelpers'
import useTranslate from '@hooks/useTranslate'
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Tooltip
} from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'

interface ArtistsTableColumnActionsProps {
  artistId: string
  onSelectDeleteArtist: () => void
}

const ArtistsTableColumnActions = ({
  artistId,
  onSelectDeleteArtist
}: ArtistsTableColumnActionsProps) => {
  const navigate = useNavigate()
  const translate = useTranslate('components.artists.artists_table.actions')
  const { isMedium } = useMediaQueryHelpers()
  const { isDarkMode } = useTheme()

  if (isMedium) {
    return (
      <Dropdown className={isDarkMode ? 'text-foreground dark' : ''}>
        <DropdownTrigger>
          <Button isIconOnly radius="full" size="sm">
            <FontAwesomeIcon icon={faBars} />
          </Button>
        </DropdownTrigger>
        <DropdownMenu>
          <DropdownItem
            key="edit"
            variant="solid"
            onPress={() => navigate(`/artists/${artistId}`)}
            startContent={<FontAwesomeIcon icon={faPencil} />}
          >
            {translate('edit')}
          </DropdownItem>
          <DropdownItem
            key="delete"
            className="text-danger"
            color="danger"
            variant="solid"
            onPress={() => onSelectDeleteArtist()}
            startContent={<FontAwesomeIcon icon={faTrashCan} />}
          >
            {translate('delete')}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  } else {
    return (
      <div className="flex justify-end gap-5">
        <Tooltip color="danger" content={translate('delete')}>
          <span
            className="cursor-pointer text-danger active:opacity-50"
            onClick={() => onSelectDeleteArtist()}
          >
            <FontAwesomeIcon icon={faTrashCan} size="lg" />
          </span>
        </Tooltip>
        <Tooltip color="warning" content={translate('edit')}>
          <span
            className="cursor-pointer text-warning active:opacity-50"
            onClick={() => navigate(`/artists/${artistId}`)}
          >
            <FontAwesomeIcon icon={faPencil} size="lg" />
          </span>
        </Tooltip>
      </div>
    )
  }
}

export default ArtistsTableColumnActions
