import CreateUpdateProducerMainInfo from '@interfaces/Forms/Producer/CreateUpdateProducerMainInfo'
import * as yup from 'yup'
import { array, ObjectSchema } from 'yup'

import {
  LocalizedStringValidationSchema,
  PictureUploadValidationSchema
} from './Shared'

export const ProducerMainInfoFormInitialValues: CreateUpdateProducerMainInfo = {
  name: '',
  logo: undefined,
  description: [
    {
      culture: 'en',
      value: ''
    }
  ]
}

export const ProducerMainInfoFormValidationSchema: ObjectSchema<CreateUpdateProducerMainInfo> =
  yup.object({
    name: yup.string().required('form_validation.field_required'),
    description: array().of(LocalizedStringValidationSchema).required(),
    logo: PictureUploadValidationSchema.notRequired(),
    producerId: yup.string()
  })
