import i18n from '../i18n'

const usePageTitleTranslation = () => {
  const getPageTitle = (page: string, args?: any): string => {
    const result = i18n.t(`pages.${page}`, { ...args })
    return result.toString()
  }

  return { getPageTitle }
}

export default usePageTitleTranslation
