import SoundCloudTrackInfoDto from '@dtos/SoundCloudTrackInfoDto'
import useRootApiService from '@services/RootApi'

const useSoundCloudService = () => {
  const { Get } = useRootApiService()

  const validateTrack = async (trackId: string): Promise<boolean> => {
    const response = await Get<SoundCloudTrackInfoDto>(
      `/sound-cloud/info/${trackId}`
    )

    return response.success
  }

  const getTrackInfo = async (
    trackId: string
  ): Promise<SoundCloudTrackInfoDto | undefined> => {
    const response = await Get<SoundCloudTrackInfoDto>(
      `/sound-cloud/info/${trackId}`
    )

    return response.data
  }

  const getTrackStream = async (
    trackUrl: string
  ): Promise<ArrayBuffer | undefined> => {
    const response = await Get<ArrayBuffer>(
      `/sound-cloud/stream?trackUrl=${trackUrl}`
    )

    return response.data
  }

  return { validateTrack, getTrackInfo, getTrackStream }
}

export default useSoundCloudService
