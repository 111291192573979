import React from 'react'

import defaultAvatar from '@assets/Images/default_artist_avatar.png'
import defaultAvatarDark from '@assets/Images/default_artist_avatar_dark.png'
import FramedImage from '@components/Shared/FramedImage/FramedImage'
import { useTheme } from '@configuration/ThemeContext'
import { useNavigate } from 'react-router-dom'

interface ProducersTableColumnNameProps {
  name: string
  logoUrl: string
  producerId: string
}

const ProducersTableColumnName = ({
  name,
  logoUrl,
  producerId
}: ProducersTableColumnNameProps) => {
  const { isDarkMode } = useTheme()
  const navigate = useNavigate()

  return (
    <div className="flex max-h-[52px] items-center justify-between gap-2">
      <div className="flex items-center gap-2">
        <FramedImage
          size={52}
          roundSize="lg"
          src={logoUrl ?? (isDarkMode ? defaultAvatarDark : defaultAvatar)}
          alt={name}
          onClick={() => navigate(`/producers/${producerId}`)}
        />
        <span className="text-foreground subpixel-antialiased">{name}</span>
      </div>
    </div>
  )
}

export default ProducersTableColumnName
