import React from 'react'

interface LogoInlineProps {
  fill?: string
  width?: number
  height?: number
  suppressWidth?: boolean
  suppressHeight?: boolean
}

const LogoInline = ({
  fill,
  width = 100,
  height = 100,
  suppressWidth,
  suppressHeight
}: LogoInlineProps) => {
  const finalWidth = `${width}%`
  const finalHeight = `${height}%`

  return (
    <svg
      fill={fill ?? 'hsl(var(--nextui-primary))'}
      width={suppressWidth ? undefined : finalWidth}
      height={suppressHeight ? undefined : finalHeight}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1077.8 230.59"
    >
      <path d="M160.17,83.03c-1.09-3.81-2.67-7.32-4.73-10.53-2.06-3.22-4.56-6.1-7.5-8.67-2.94-2.56-6.25-4.73-9.91-6.52-3.68-1.79-7.69-3.16-12.05-4.11-4.36-.95-8.99-1.44-13.87-1.44h-61.55v130.02h28.72v-43.14h32.83c4.88,0,9.49-.48,13.83-1.44,4.36-.95,8.37-2.34,12.05-4.14,3.69-1.8,7-3.99,9.94-6.57,2.94-2.57,5.43-5.48,7.49-8.71,2.04-3.22,3.61-6.73,4.73-10.54,1.1-3.81,1.65-7.85,1.65-12.11s-.55-8.32-1.64-12.11ZM131.54,103.43c-1.04,2.44-2.52,4.51-4.46,6.21-1.94,1.69-4.31,2.99-7.08,3.91-2.79.9-5.93,1.35-9.42,1.35h-31.28v-39.41h31.51c3.46,0,6.57.45,9.32,1.35,2.76.89,5.1,2.19,7,3.88,1.9,1.7,3.38,3.76,4.41,6.18,1.02,2.42,1.54,5.18,1.54,8.24s-.52,5.85-1.54,8.29Z" />
      <path d="M285.97,153.99c-.17-.43-.33-.87-.52-1.3-3.73-9.56-8.65-17.93-14.75-25.06,4.39-10.73,7.79-21.77,10.18-33.15,2.92-13.75,4.38-27.97,4.38-42.67h-105.83v26.01h56.37l9.69-18.91c5.26,9.47,9.79,19.26,13.58,29.39.52,1.49,1.02,2.96,1.45,4.39,2.69,8.62,4.04,16.12,4.04,22.51,0,2.02-.17,4.14-.53,6.4-12.43-9.94-27.05-14.92-43.86-14.92-4.36,0-8.44.35-12.23,1.05-9.39,1.7-17.13,5.53-23.19,11.46-8.65,8.66-12.97,18.3-12.97,28.96,0,9.82,3.51,18.26,10.56,25.3,5.35,5.61,11.85,9.06,19.53,10.34,2.29.38,4.7.58,7.2.58,11.29,0,21.92-4.23,31.86-12.65,9.96-8.44,18.6-20.23,25.93-35.39.53,2.72.8,4.96.8,6.75,0,6.03-1.6,12.98-4.79,20.87-3.43,7.92-6.87,13.87-10.29,17.84h39.06c0-8.4-1.89-17.68-5.68-27.8ZM246.91,148.31c-7.4,4.98-15.15,7.47-23.27,7.47-3.49.12-6.82-.25-9.99-1.12-3.16-.85-5.96-2.47-8.39-4.83-1.74-1.7-2.87-3.68-3.43-5.95-.22-.9-.35-1.86-.38-2.84,0-1.8.27-3.51.84-5.13,1.35-4.04,4.41-7.55,9.19-10.51,6.52-4.14,13.85-6.22,22.02-6.22,5.21,0,10.58.97,16.07,2.89,5.5,1.92,9.71,4.19,12.61,6.78-2.97,8.12-8.05,14.6-15.27,19.45Z" />
      <path d="M406.23,152.94l-2.67-5.96c-2.86-6.4-6.25-11.31-10.21-14.75-3.96-3.44-8.52-5.35-13.7-5.71,5.48-.18,10.44-1.14,14.89-2.89,4.43-1.74,8.22-4.14,11.36-7.22,3.14-3.07,5.55-6.77,7.25-11.04,1.69-4.29,2.54-9.06,2.54-14.3,0-6-1.07-11.43-3.22-16.27-2.16-4.85-5.25-8.99-9.29-12.4-4.06-3.41-8.97-6.03-14.79-7.89-5.23-1.65-11.08-2.59-17.56-2.74-.69-.02-1.39-.03-2.11-.03h-64.33v130.07h28.74v-47.83h18.86c2.71,0,5.01.2,6.93.6,1.92.38,3.63,1.09,5.11,2.09,1.49,1,2.82,2.34,4.01,4.03,1.17,1.67,2.34,3.79,3.49,6.33l15.77,34.79h31.88l-12.97-28.85ZM365.42,110.78h-32.28v-35.32h32.23c6.97,0,12.31,1.47,16.01,4.44,3.69,2.97,5.55,7.28,5.55,12.92,0,2.84-.48,5.38-1.45,7.62-.97,2.22-2.37,4.11-4.21,5.63-1.84,1.54-4.08,2.71-6.75,3.51-2.67.8-5.7,1.2-9.09,1.2Z" />
      <path d="M422.88,51.77v24.46h40.37v105.56h28.74v-105.56h40.37v-24.46h-109.47Z" />
      <path d="M765.27,51.77v98.14l-16.21-33.1-31.85-65.04h-43.46v130.02h27.53v-104.29l34.99,71.89,15.76,32.4h40.78V51.77h-27.55Z" />
      <path d="M894.21,143.5c-4.91,2.41-9.97,4.43-15.2,6.06-9.59,3.01-19,4.86-28.27,5.53-2.76.22-5.5.32-8.22.32-8.4,0-15.09-.97-20.03-2.92-4.95-1.95-7.4-4.98-7.4-9.06,0-5.45,4.19-9.56,12.6-12.35.97-.32,1.95-.63,2.96-.92,7.62-2.16,16.54-3.24,26.8-3.24,11.93,0,23.31,1.35,34.1,4.06,1.45.37,2.91.75,4.34,1.17l8.69-35.87c-8.55,0-16.67.47-24.36,1.42-14.32,1.72-27.12,5.08-38.41,10.04v-29.92h71.66v-26.01h-99.63v50.51l21.49,8.7c-9.41,5.1-16.64,11.23-21.7,18.38-5.06,7.17-7.6,14.8-7.6,22.91,0,2.44.22,4.76.65,6.97,1.17,6.23,4.04,11.54,8.59,15.96,6.28,6.08,14.3,9.14,24.06,9.14s20.02-3.49,30.24-10.49c5.33-3.63,10.69-8.22,16.11-13.75v21.67h27.97v-50.34c-6.17,4.73-12.65,8.75-19.41,12.05Z" />
      <path d="M1032.64,146.97c-2.84-6.4-6.25-11.31-10.19-14.75-3.96-3.44-8.54-5.35-13.72-5.71,5.48-.18,10.46-1.14,14.89-2.89,4.44-1.74,8.24-4.14,11.38-7.22,3.12-3.07,5.55-6.77,7.25-11.04,1.69-4.29,2.54-9.06,2.54-14.3,0-6-1.07-11.43-3.22-16.27-2.16-4.85-5.26-8.99-9.31-12.4-4.04-3.41-8.97-6.03-14.77-7.89-5.8-1.84-12.36-2.77-19.67-2.77h-64.33v130.07h28.74v-47.83h18.86c2.69,0,5.01.2,6.93.6,1.92.38,3.63,1.09,5.11,2.09,1.49,1,2.82,2.34,3.99,4.03,1.19,1.67,2.36,3.79,3.51,6.33l15.77,34.79h31.86l-15.64-34.82ZM994.51,110.78h-32.28v-35.32h32.23c6.97,0,12.3,1.47,16.01,4.44,3.69,2.97,5.55,7.28,5.55,12.92,0,2.84-.48,5.38-1.47,7.62-.97,2.22-2.36,4.11-4.19,5.63-1.84,1.54-4.09,2.71-6.75,3.51-2.67.8-5.7,1.2-9.09,1.2Z" />
      <path d="M582.96,186.13c-8.1,0-15.7-1.29-22.81-3.87-7.11-2.58-12.52-5.97-16.24-10.17l17.91-29.51c9.24,9.6,23.6,14.4,43.1,14.4,23.09,0,34.64-9.96,34.64-29.87,0-5.16-.72-10.8-2.16-16.92l-12.96,19.52h-74.77V51.8h28.34v51.55h57.49c-4.02-13.91-11.64-31.1-22.85-51.55h43.28c.66,9,.99,16.17.99,21.5,0,22.61-3.39,43.07-10.17,61.36-3.42,9.48-7.98,18.27-13.68,26.36-5.76,7.74-12.96,13.86-21.59,18.35-8.64,4.5-18.15,6.75-28.52,6.75Z" />
    </svg>
  )
}

export default LogoInline
