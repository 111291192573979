export default class PartynerApiError extends Error {
  status?: number
  data?: any

  constructor(message: string, status?: number, data?: any) {
    super(message)
    this.name = 'PartynerApiError'
    this.status = status
    this.data = data

    Object.setPrototypeOf(this, PartynerApiError.prototype)
  }
}
