import React, { Ref, useEffect, useState } from 'react'

import { setIsLoadingAction } from '@actions/Global'
import ChangeableAvatar from '@components/Shared/ChangeableAvatar'
import CountrySelector from '@components/Shared/CountrySelector'
import InputField from '@components/Shared/InputField'
import LocalizableTextArea from '@components/Shared/LocalizableTextArea/LocalizableTextArea'
import MapBoxViewer from '@components/Shared/MapBoxViewer'
import CreateUpdateVenueDto from '@dtos/CreateUpdateVenueDto'
import PictureUploadDto from '@dtos/PictureUploadDto'
import VenueDto from '@dtos/VenueDto'
import { LocalizedStringInitialValues } from '@formSchemas/Shared'
import { VenueMainInfoFormValidationSchema } from '@formSchemas/Venue'
import useTranslate from '@hooks/useTranslate'
import CreateUpdateVenueMainInfo from '@interfaces/Forms/Venue/CreateUpdateVenueMainInfo'
import { Button, Card, CardBody, CardHeader } from '@nextui-org/react'
import useMapBoxGeocodeService from '@services/MapBoxGeocode'
import useVenueService from '@services/Venue'
import { Formik, FormikProps } from 'formik'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

interface EditVenueMainInfoFormProps {
  values: CreateUpdateVenueMainInfo
  formRef: Ref<FormikProps<CreateUpdateVenueMainInfo>>
  formKey: number
  onSaveForm: (venue: VenueDto) => void
  originalLogoUrl?: string
}

const EditVenueMainInfoForm = ({
  values,
  formRef,
  formKey,
  onSaveForm,
  originalLogoUrl
}: EditVenueMainInfoFormProps) => {
  const dispatch = useDispatch()
  const translate = useTranslate('components.venues.edit_venue')
  const errorsTranslate = useTranslate('errors')
  const { updateVenue, updateVenueLogo, createVenue } = useVenueService()
  const { getAddressCoordinates } = useMapBoxGeocodeService()

  const [blockMapText, setBlockMapText] = useState<string | undefined>(
    undefined
  )
  const [isLoadingAddress, setIsLoadingAddress] = useState(false)

  useEffect(() => {
    setBlockMapText(values.venueId ? undefined : translate('provide_address'))
  }, [values.venueId])

  const changeLogo = (
    form: FormikProps<CreateUpdateVenueMainInfo>,
    logo: PictureUploadDto | null
  ) => form.setFieldValue('logo', logo)

  const addDescription = (
    form: FormikProps<CreateUpdateVenueMainInfo>,
    culture: string
  ) => {
    if (
      !form.values.description
        .map((description) => description.culture)
        .includes(culture)
    ) {
      form.setFieldValue('description', [
        ...form.values.description,
        { ...LocalizedStringInitialValues(culture) }
      ])
    }
  }

  const removeDescription = (
    form: FormikProps<CreateUpdateVenueMainInfo>,
    culture: string
  ) => {
    if (
      form.values.description
        .map((description) => description.culture)
        .includes(culture)
    ) {
      form.setFieldValue('description', [
        ...form.values.description.filter(
          (description) => description.culture !== culture
        )
      ])
    }
  }

  const getCoordinates = async (
    form: FormikProps<CreateUpdateVenueMainInfo>
  ) => {
    setBlockMapText(undefined)
    setIsLoadingAddress(true)
    const coordinatesResponse = await getAddressCoordinates(form.values.address)
    if (
      coordinatesResponse.latitude != 0 &&
      coordinatesResponse.longitude != 0
    ) {
      await form.setFieldValue('address.latitude', coordinatesResponse.latitude)
      await form.setFieldValue(
        'address.longitude',
        coordinatesResponse.longitude
      )
    } else {
      toast.error(translate('unable_to_locate'))
    }
    setIsLoadingAddress(false)
  }

  const handleError = () => {
    dispatch(setIsLoadingAction(false))
    toast.error(errorsTranslate('general'))
  }

  const saveForm = async (form: CreateUpdateVenueMainInfo) => {
    const venueDto: CreateUpdateVenueDto = {
      name: form.name,
      description: form.description,
      street: form.address.street,
      buildingIdentification: form.address.buildingIdentification ?? undefined,
      buildingIdentificationAddons:
        form.address.buildingIdentificationAddons ?? undefined,
      neighborhood: form.address.neighborhood ?? undefined,
      postalCode: form.address.postalCode ?? undefined,
      city: form.address.city,
      state: form.address.state ?? undefined,
      country: form.address.country,
      latitude: form.address.latitude,
      longitude: form.address.longitude
    }

    let response

    if (form.venueId) {
      response = await updateVenue(form.venueId, venueDto)
    } else {
      response = await createVenue(venueDto)
    }

    if (!response) return handleError()

    if (form.logo) {
      const logoResponse = await saveLogo(response.id, form.logo)
      if (!logoResponse) return handleError()
      return onSaveForm(logoResponse)
    }

    return onSaveForm(response)
  }

  const saveLogo = async (
    venueId: string,
    logo: PictureUploadDto
  ): Promise<VenueDto | undefined> => {
    const dataIndex = logo.base64image.indexOf(',')
    logo.base64image = logo.base64image.substring(dataIndex + 1)
    return await updateVenueLogo(venueId, logo)
  }

  return (
    <Card fullWidth>
      <CardHeader>
        <div className="pl-2 text-lg font-semibold text-foreground-500">
          {translate('main_info')}
        </div>
      </CardHeader>
      <Formik
        innerRef={formRef}
        key={formKey}
        initialValues={values}
        onSubmit={async (values) => await saveForm(values)}
        validationSchema={VenueMainInfoFormValidationSchema}
      >
        {(props: FormikProps<CreateUpdateVenueMainInfo>) => (
          <form onSubmit={props.handleSubmit} noValidate>
            <CardBody>
              <div className="flex w-full flex-col items-center gap-4">
                <div className="flex w-full flex-col items-center gap-4 lg:flex-row">
                  <ChangeableAvatar
                    name={props.values.name}
                    originalAvatarUrl={originalLogoUrl ?? ''}
                    onChangeAvatar={(logo) => changeLogo(props, logo)}
                  />
                  <div className="flex w-full grow flex-col gap-3">
                    <InputField
                      id="name"
                      name="name"
                      type="text"
                      label={translate('name')}
                    />
                    <LocalizableTextArea
                      fieldName="description"
                      label={translate('description')}
                      placeholder={translate('description')}
                      values={props.values.description}
                      onAddValue={(culture) => addDescription(props, culture)}
                      onRemoveValue={(culture) =>
                        removeDescription(props, culture)
                      }
                    />
                  </div>
                </div>
                <Card fullWidth>
                  <CardHeader>
                    <div className="pl-2 text-lg font-semibold text-foreground-500">
                      {translate('address')}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="grid w-full grid-cols-12 gap-2">
                      <InputField
                        id="address.street"
                        name="address.street"
                        label={translate('street')}
                        className="col-span-12"
                      />
                      <InputField
                        id="address.buildingIdentification"
                        name="address.buildingIdentification"
                        label={translate('building_identification')}
                        className="col-span-12 lg:col-span-4"
                      />
                      <InputField
                        id="address.buildingIdentificationAddons"
                        name="address.buildingIdentificationAddons"
                        label={translate('building_identification_addons')}
                        className="col-span-12 lg:col-span-4"
                      />
                      <InputField
                        id="address.neighborhood"
                        name="address.neighborhood"
                        label={translate('neighborhood')}
                        className="col-span-12 lg:col-span-4"
                      />
                      <InputField
                        id="address.city"
                        name="address.city"
                        label={translate('city')}
                        className="col-span-12 lg:col-span-5"
                      />
                      <InputField
                        id="address.state"
                        name="address.state"
                        label={translate('state')}
                        className="col-span-12 lg:col-span-2"
                      />
                      <InputField
                        id="address.postalCode"
                        name="address.postalCode"
                        label={translate('postal_code')}
                        className="col-span-12 lg:col-span-2"
                      />
                      <div className="col-span-12 lg:col-span-3">
                        <CountrySelector
                          multiSelect={false}
                          selectedValues={[props.values.address.country]}
                          onSingleSelect={(value) =>
                            props.setFieldValue('address.country', value)
                          }
                        />
                      </div>
                      <div className="col-span-12 flex justify-center align-middle">
                        <Button
                          fullWidth
                          className="h-14"
                          onClick={async () => await getCoordinates(props)}
                        >
                          {translate('try_to_pin')}
                        </Button>
                      </div>
                      <div className="col-span-12 overflow-hidden rounded-lg border-1 border-content2 shadow-sm shadow-foreground/5">
                        <MapBoxViewer
                          reasonForBlock={blockMapText}
                          isLoading={isLoadingAddress}
                          latitude={props.values.address.latitude || -19.689071}
                          longitude={
                            props.values.address.longitude || -44.892563
                          }
                          onLocationSelect={async (lat, lng) => {
                            await props.setFieldValue('address.latitude', lat)
                            await props.setFieldValue('address.longitude', lng)
                          }}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </CardBody>
          </form>
        )}
      </Formik>
    </Card>
  )
}

export default EditVenueMainInfoForm
