import React, { useState } from 'react'

import useTranslate from '@hooks/useTranslate'
import { Button, Input } from '@nextui-org/react'

interface ConfirmDeleteVenueModalBodyInputProps {
  name?: string
  onConfirmDeleteVenue: () => void
}

const ConfirmDeleteVenueModalBodyInput = ({
  name,
  onConfirmDeleteVenue
}: ConfirmDeleteVenueModalBodyInputProps) => {
  const translate = useTranslate('components.venues.remove_venue')
  const [nameMatch, setNameMatch] = useState('')
  return (
    <>
      <span className="text-sm">{translate('type_venue_name')}</span>
      <Input
        variant="bordered"
        onValueChange={(value) => setNameMatch(value)}
      />
      <Button
        variant="flat"
        color="danger"
        isDisabled={nameMatch !== name}
        onClick={onConfirmDeleteVenue}
      >
        {translate('remove_venue')}
      </Button>
    </>
  )
}

export default ConfirmDeleteVenueModalBodyInput
