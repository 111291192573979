import CreateUpdateVenueMainInfo from '@interfaces/Forms/Venue/CreateUpdateVenueMainInfo'
import * as yup from 'yup'
import { array, ObjectSchema } from 'yup'

import {
  AddressValidationSchema,
  LocalizedStringValidationSchema,
  PictureUploadValidationSchema
} from './Shared'

export const VenueMainInfoFormInitialValues: CreateUpdateVenueMainInfo = {
  name: '',
  logo: undefined,
  description: [
    {
      culture: 'en',
      value: ''
    }
  ],
  address: {
    street: '',
    buildingIdentification: '',
    buildingIdentificationAddons: '',
    neighborhood: '',
    postalCode: '',
    city: '',
    country: '',
    latitude: 0,
    longitude: 0
  }
}

export const VenueMainInfoFormValidationSchema: ObjectSchema<CreateUpdateVenueMainInfo> =
  yup.object({
    name: yup.string().required('form_validation.field_required'),
    description: array().of(LocalizedStringValidationSchema).required(),
    logo: PictureUploadValidationSchema.notRequired(),
    address: AddressValidationSchema.required(),
    venueId: yup.string()
  })
