import React from 'react'

import { useTheme } from '@configuration/ThemeContext'
import GenreDto from '@dtos/GenreDto'
import useLocalizedStringHelpers from '@hooks/useLocalizedStringHelpers'
import {
  Chip,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger
} from '@nextui-org/react'

interface ArtistsTableColumnGenreProps {
  genres: GenreDto[]
}

const ArtistsTableColumnGenre = ({ genres }: ArtistsTableColumnGenreProps) => {
  const { isDarkMode } = useTheme()
  const { getLocalizedStringValue } = useLocalizedStringHelpers()

  return (
    <>
      <div className="invisible hidden gap-2 md:visible md:flex">
        {genres.map((genre) => (
          <Chip key={genre.id} size="sm">
            {getLocalizedStringValue(genre.name)}
          </Chip>
        ))}
      </div>
      <div className="visible flex justify-center md:invisible md:hidden">
        <Dropdown className={isDarkMode ? 'text-foreground dark' : ''}>
          <DropdownTrigger>
            <Chip size="sm">{genres.length.toString()}</Chip>
          </DropdownTrigger>
          <DropdownMenu>
            {genres.map((genre) => (
              <DropdownItem isReadOnly key={genre.id}>
                {getLocalizedStringValue(genre.name)}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    </>
  )
}

export default ArtistsTableColumnGenre
