export enum EventsFilterActionTypes {
  SET_EVENTS_FILTER = 'SET_EVENTS_FILTER',
  CLEAR_EVENTS_FILTER = 'CLEAR_EVENTS_FILTER'
}

export const setEventsFilterAction = (
  page: number,
  pageSize: number,
  nameFilter: string[]
) => ({
  type: EventsFilterActionTypes.SET_EVENTS_FILTER as const,
  payload: {
    page,
    pageSize,
    nameFilter
  }
})

export const clearEventsFilterAction = () => ({
  type: EventsFilterActionTypes.CLEAR_EVENTS_FILTER as const
})

export type EventsFilterAction = ReturnType<
  typeof setEventsFilterAction | typeof clearEventsFilterAction
>
