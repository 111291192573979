import React, { Ref } from 'react'

import { setIsLoadingAction } from '@actions/Global'
import LocalizableTextArea from '@components/Shared/LocalizableTextArea/LocalizableTextArea'
import CreateUpdateGenreDto from '@dtos/CreateUpdateGenreDto'
import GenreDto from '@dtos/GenreDto'
import {
  CreateUpdateGenreValidationSchema,
  GenreNameFormInitialValues
} from '@formSchemas/Genre'
import useTranslate from '@hooks/useTranslate'
import useGenreService from '@services/Genre'
import { Formik, FormikProps } from 'formik'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

interface EditGenreFormProps {
  values: CreateUpdateGenreDto
  formRef: Ref<FormikProps<CreateUpdateGenreDto>>
  formKey: number
  onSaveForm: (genre: GenreDto) => void
}

const EditGenreForm = ({
  values,
  formRef,
  formKey,
  onSaveForm
}: EditGenreFormProps) => {
  const dispatch = useDispatch()
  const translate = useTranslate('components.genres.edit_genre')
  const errorsTranslate = useTranslate('errors')
  const { createGenre, updateGenre } = useGenreService()

  const addGenreName = (
    formikProps: FormikProps<CreateUpdateGenreDto>,
    culture: string
  ) => {
    if (
      !formikProps.values.name.map((name) => name.culture).includes(culture)
    ) {
      formikProps.setFieldValue('name', [
        ...formikProps.values.name,
        { ...GenreNameFormInitialValues(culture) }
      ])
    }
  }

  const removeGenreName = (
    formikProps: FormikProps<CreateUpdateGenreDto>,
    culture: string
  ) => {
    if (formikProps.values.name.map((name) => name.culture).includes(culture)) {
      formikProps.setFieldValue('name', [
        ...formikProps.values.name.filter((name) => name.culture !== culture)
      ])
    }
  }

  const handleError = () => {
    dispatch(setIsLoadingAction(false))
    toast.error(errorsTranslate('general'))
  }

  const saveForm = async (form: CreateUpdateGenreDto) => {
    const response = form.genreId
      ? await updateGenre(form.genreId, form)
      : await createGenre(form)

    if (!response) return handleError()
    onSaveForm(response)
  }

  return (
    <Formik
      innerRef={formRef}
      key={formKey}
      initialValues={values}
      onSubmit={async (values) => await saveForm(values)}
      validationSchema={CreateUpdateGenreValidationSchema}
    >
      {(props: FormikProps<CreateUpdateGenreDto>) => (
        <form onSubmit={props.handleSubmit} noValidate>
          <div className="flex flex-col items-center gap-2 lg:flex-row">
            <LocalizableTextArea
              fieldName={'name'}
              label={translate('name')}
              placeholder={translate('name')}
              values={props.values.name}
              onAddValue={(culture) => addGenreName(props, culture)}
              onRemoveValue={(culture) => removeGenreName(props, culture)}
            />
          </div>
        </form>
      )}
    </Formik>
  )
}

export default EditGenreForm
