import { AvailableCountries } from '@configuration/Constants'
import AddressDto from '@dtos/AddressDto'
import MapBoxGeocodeResponseDto from '@dtos/MapBoxGeocodeResponseDto'
import AddressCoordinates from '@interfaces/AddressCoordinates'
import countries from '@locales/enUS.json'
import axios, { AxiosInstance } from 'axios'

const useMapBoxGeocodeService = () => {
  const axiosInstance: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_MAPBOX_GEOCODE_API_BASE_URL,
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json'
    }
  })
  axiosInstance.defaults.params = {
    access_token: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
  }

  const countryList = countries.countries as { [key: string]: string }

  const getAddressCoordinates = async (
    address: AddressDto
  ): Promise<AddressCoordinates> => {
    let query = address.street
    if (address.buildingIdentification)
      query = `${query} ${address.buildingIdentification}`

    if (address.buildingIdentificationAddons)
      query = `${query} ${address.buildingIdentificationAddons}`

    if (address.neighborhood) query = `${query} ${address.neighborhood}`

    query = `${query} ${address.city}`

    if (address.state) query = `${query} ${address.state}`

    if (address.postalCode) query = `${query} ${address.postalCode}`

    const country = AvailableCountries.find(
      (c) => c.databaseSelector === address.country
    )
    if (country) query = `${query} ${countryList[country.abbreviation] ?? ''}`

    try {
      const response = await axiosInstance.get<MapBoxGeocodeResponseDto>(
        '/forward',
        {
          params: {
            q: query,
            autocomplete: false,
            limit: 1
          }
        }
      )

      return {
        latitude: response.data.features[0].properties.coordinates.latitude,
        longitude: response.data.features[0].properties.coordinates.longitude
      }
    } catch {
      return {
        latitude: 0,
        longitude: 0
      }
    }
  }

  return { getAddressCoordinates }
}

export default useMapBoxGeocodeService
