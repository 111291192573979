import React, { useEffect, useRef } from 'react'

import EditArtistSoundCloudTrack, {
  EditArtistSoundCloudTrackRef
} from '@components/Artists/EditArtist/SoundCloudTracks/EditArtistSoundCloudTrack'
import UpdateSoundCloudTracks from '@interfaces/Forms/Artist/UpdateSoundCloudTracks'
import { FormikProps } from 'formik'
import { Draggable } from 'react-beautiful-dnd'

interface EditArtistSoundCloudTracksDraggableProps {
  form: FormikProps<UpdateSoundCloudTracks>
  onRemoveLink: (id: string) => void
}

const EditArtistSoundCloudTracksDraggable = ({
  form,
  onRemoveLink
}: EditArtistSoundCloudTracksDraggableProps) => {
  const playersRefs = useRef<Array<EditArtistSoundCloudTrackRef | null>>([])

  useEffect(() => {
    playersRefs.current = playersRefs.current.slice(
      0,
      form.values.tracks!.length
    )
  }, [form.values.tracks])

  const muteOtherPlayers = (idPlaying: string) => {
    if (playersRefs.current) {
      playersRefs.current
        .filter((player) => player?.id != idPlaying)
        .forEach((player) => player?.pausePlayer())
    }
  }

  return (
    <div className="flex flex-col gap-2">
      {form.values
        .tracks!.sort((a, b) => a.order - b.order)
        .map((value, index) => (
          <Draggable key={value.id} draggableId={value.id!} index={index}>
            {(provided) => (
              <EditArtistSoundCloudTrack
                ref={(el) => (playersRefs.current[index] = el)}
                provided={provided}
                id={value.id!}
                trackId={value.trackId}
                onRemoveLink={onRemoveLink}
                onPlay={muteOtherPlayers}
              />
            )}
          </Draggable>
        ))}
    </div>
  )
}

export default EditArtistSoundCloudTracksDraggable
