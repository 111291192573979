import React, { useEffect, useRef, useState } from 'react'

import { setRootBreadcrumbAction } from '@actions/Breadcrumb'
import { setIsLoadingAction } from '@actions/Global'
import EditGenreForm from '@components/Genres/EditGenre/EditGenreForm'
import { RootBreadcrumbConstants } from '@configuration/Constants'
import CreateUpdateGenreDto from '@dtos/CreateUpdateGenreDto'
import GenreDto from '@dtos/GenreDto'
import { CreateUpdateGenreInitialValues } from '@formSchemas/Genre'
import useLocalizedStringHelpers from '@hooks/useLocalizedStringHelpers'
import usePageTitleTranslation from '@hooks/usePageTitleTranslation'
import useTranslate from '@hooks/useTranslate'
import {
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger
} from '@nextui-org/react'
import useGenreService from '@services/Genre'
import { FormikProps } from 'formik'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const EditGenre = () => {
  const translate = useTranslate('components.genres.edit_genre')
  const { getPageTitle } = usePageTitleTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string | undefined }>()
  const { getGenreById } = useGenreService()
  const { getLocalizedStringValue } = useLocalizedStringHelpers()

  const [isSavingData, setIsSavingData] = useState(false)
  const [genreFormKey, setGenreFormKey] = useState(0)
  const [genre, setGenre] = useState<GenreDto | undefined>(undefined)
  const [genreData, setGenreData] = useState<CreateUpdateGenreDto>(
    CreateUpdateGenreInitialValues
  )
  const genreForm = useRef<FormikProps<CreateUpdateGenreDto> | null>(null)

  const setBreadcrumbs = (breadcrumbData?: GenreDto) => {
    if (breadcrumbData) {
      dispatch(
        setRootBreadcrumbAction(RootBreadcrumbConstants.GENRES, [
          {
            path: `/genres/${breadcrumbData.id}`,
            title: getLocalizedStringValue(breadcrumbData.name)
          }
        ])
      )
    } else {
      dispatch(
        setRootBreadcrumbAction(RootBreadcrumbConstants.GENRES, [
          { path: '/genres/add', title: getPageTitle('add_genre') }
        ])
      )
    }
  }

  useEffect(() => {
    if (id) loadGenre(id)
    setBreadcrumbs()
  }, [id])

  useEffect(() => {
    if (!isSavingData && genre) {
      setGenreData({
        genreId: genre.id,
        name: genre.name
      })
      setGenreFormKey((prevKey) => prevKey + 1)
      dispatch(setIsLoadingAction(false))
    }
  }, [genre])

  const loadGenre = async (id: string) => {
    dispatch(setIsLoadingAction(true))
    const response = await getGenreById(id)
    if (response) {
      setBreadcrumbs(response)
      setGenre(response)
    } else {
      navigate('/not-found')
      dispatch(setIsLoadingAction(false))
    }
  }

  const saveForm = async () => {
    if (genreForm.current) {
      await genreForm.current.setFieldValue('artistId', id)

      setTimeout(async () => {
        if (genreForm.current && genreForm.current.isValid) {
          dispatch(setIsLoadingAction(true))
          setIsSavingData(true)
          await genreForm.current.submitForm()
        }
      }, 0)
    }
  }

  const finishSavingForms = (updatedGenre: GenreDto) => {
    if (updatedGenre) {
      setIsSavingData(false)
      setGenre(updatedGenre)
      navigate(`/genres/${updatedGenre.id}`, { replace: true })
      toast.success(translate('data_saved'))
    }
  }

  return (
    <div className="flex grow flex-col gap-3">
      <EditGenreForm
        values={genreData}
        formRef={genreForm}
        formKey={genreFormKey}
        onSaveForm={(genre) => finishSavingForms(genre)}
      />
      <Card fullWidth>
        <CardBody className="flex flex-col justify-end gap-3 align-middle lg:flex-row">
          {id && (
            <Dropdown className="dark" placement="top">
              <DropdownTrigger>
                <Button size="lg" color="danger" variant="flat">
                  {translate('cancel_changes')}
                </Button>
              </DropdownTrigger>
              <DropdownMenu variant="flat">
                <DropdownSection
                  title={translate('cancel_changes_confirmation')}
                >
                  <DropdownItem
                    key="no"
                    className="text-success"
                    color="success"
                  >
                    {translate('no')}
                  </DropdownItem>
                  <DropdownItem
                    key="yes"
                    className="text-danger"
                    color="danger"
                    onPress={() => loadGenre(id)}
                  >
                    {translate('yes')}
                  </DropdownItem>
                </DropdownSection>
              </DropdownMenu>
            </Dropdown>
          )}
          <Button
            size="lg"
            color="success"
            variant="flat"
            onClick={async () => await saveForm()}
          >
            {translate('save_changes')}
          </Button>
          <div className="flex lg:absolute lg:left-3">
            <Button
              className="w-full"
              size="lg"
              variant="flat"
              onClick={() => navigate('/genres/')}
            >
              {translate('go_back')}
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default EditGenre
