import { AvailableCountries } from '@configuration/Constants'
import AddressDto from '@dtos/AddressDto'
import useTranslate from '@hooks/useTranslate'

const useStringHelpers = () => {
  const countryTranslation = useTranslate('countries')

  const renderIfValueExists = (
    value?: string | null | undefined,
    prepend?: string,
    append?: string
  ) => {
    if (!value || value.trim().length === 0) return ''
    return `${prepend ?? ''}${value}${append ?? ''}`
  }

  const getAddressMainInfo = (address: AddressDto) => {
    return `${address.street}${renderIfValueExists(address.buildingIdentification, ', ')}${renderIfValueExists(address.buildingIdentificationAddons, ', ')}`
  }

  const getAddressAddons = (address: AddressDto) => {
    return `${renderIfValueExists(address.neighborhood, undefined, ' - ')}${address.city}${renderIfValueExists(address.state, ' - ')}${renderIfValueExists(address.postalCode, ' - ')}`
  }

  const getAddressCountry = (address: AddressDto) => {
    const countrySelector = AvailableCountries.find(
      (country) => country.databaseSelector === address.country
    )

    if (!countrySelector) return ''

    return countryTranslation(countrySelector.abbreviation)
  }

  return { getAddressMainInfo, getAddressAddons, getAddressCountry }
}

export default useStringHelpers
