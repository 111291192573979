import React, { useEffect, useState } from 'react'

import { setRootBreadcrumbAction } from '@actions/Breadcrumb'
import {
  clearGenresFilterAction,
  setGenresFilterAction
} from '@actions/GenresFilter'
import ConfirmDeleteGenreModal from '@components/Genres/ConfirmDeleteGenreModal'
import GenresTable from '@components/Genres/GenresTable/GenresTable'
import { RootBreadcrumbConstants } from '@configuration/Constants'
import GenreDto from '@dtos/GenreDto'
import useLocalizedStringHelpers from '@hooks/useLocalizedStringHelpers'
import useGenreService from '@services/Genre'
import { RootState } from '@store'
import { useDispatch, useSelector } from 'react-redux'

const Genres = () => {
  const dispatch = useDispatch()
  const { getGenreList } = useGenreService()
  const genreFilterInMemory = useSelector(
    (state: RootState) => state.genresFilter
  )
  const { selectedLanguage } = useLocalizedStringHelpers()
  const [genreList, setGenreList] = useState<GenreDto[]>([])
  const [totalPages, setTotalPages] = useState<number>(0)
  const [selectedPage, setSelectedPage] = useState<number>(
    genreFilterInMemory.page ?? 1
  )
  const [pageSize, setPageSize] = useState<number>(
    genreFilterInMemory.pageSize ?? 10
  )
  const [totalItems, setTotalItems] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(true)
  const [namesFilter, setNamesFilter] = useState<string[]>(
    genreFilterInMemory.namesFilter ?? []
  )
  const [genreToDelete, setGenreToDelete] = useState<GenreDto | undefined>(
    undefined
  )

  useEffect(() => {
    dispatch(setRootBreadcrumbAction(RootBreadcrumbConstants.GENRES))
  }, [])

  useEffect(() => {
    loadTable()
  }, [selectedPage, pageSize, namesFilter])

  const loadTable = () => {
    setIsLoading(true)
    getGenreList(selectedPage, pageSize, selectedLanguage, namesFilter).then(
      (result) => {
        if (result) {
          setGenreList(result.items)
          setTotalPages(result.totalPages)
          setTotalItems(result.totalItems)
        }
        setIsLoading(false)
      }
    )
  }

  const closeDeleteGenreModal = (reloadTable: boolean) => {
    setGenreToDelete(undefined)
    if (reloadTable) loadTable()
  }

  const applyFilter = (namesFilter: string[]) => {
    setSelectedPage(1)
    setNamesFilter(namesFilter)
    dispatch(setGenresFilterAction(1, pageSize, namesFilter))
  }

  const clearFilter = () => {
    if (namesFilter.length > 0) {
      setNamesFilter([])
      setSelectedPage(1)
      dispatch(clearGenresFilterAction())
    }
  }

  const changePage = (page: number) => {
    dispatch(setGenresFilterAction(page, pageSize, namesFilter))
    setSelectedPage(page)
  }

  const changePageSize = (pageSize: number) => {
    dispatch(setGenresFilterAction(selectedPage, pageSize, namesFilter))
    setPageSize(pageSize)
  }

  return (
    <>
      <ConfirmDeleteGenreModal
        genre={genreToDelete}
        onClose={(reloadTable) => closeDeleteGenreModal(reloadTable)}
      />
      <GenresTable
        genreList={genreList}
        totalPages={totalPages}
        selectedPage={selectedPage}
        totalItems={totalItems}
        pageSize={pageSize}
        isLoading={isLoading}
        onSelectDeleteGenre={setGenreToDelete}
        onChangePage={changePage}
        onChangePageSize={changePageSize}
        onApplyFilter={applyFilter}
        onClearFilter={clearFilter}
      />
    </>
  )
}

export default Genres
