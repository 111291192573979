import React, { useState } from 'react'

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useTranslate from '@hooks/useTranslate'
import { Button, Input } from '@nextui-org/react'
import useSoundCloudService from '@services/SoundCloud'
import { toast } from 'react-toastify'

interface EditArtistSoundCloudTrackValidationProps {
  onValidate: (isValid: boolean, trackId: string | undefined) => void
}

const EditArtistSoundCloudTrackValidation = ({
  onValidate
}: EditArtistSoundCloudTrackValidationProps) => {
  const { validateTrack } = useSoundCloudService()
  const translate = useTranslate('components.artists.edit_artist')
  const [embedCode, setEmbedCode] = useState('')

  const validate = () => {
    const trackId = extractTrackId(embedCode)

    if (!trackId) {
      onValidate(false, undefined)
      toast.error(translate('sound_cloud_invalid_track'))
      return
    }

    try {
      validateTrack(trackId).then((response) => {
        onValidate(response, trackId)
        if (!response) toast.error(translate('sound_cloud_invalid_track'))
      })
    } catch {
      onValidate(false, undefined)
      toast.error(translate('sound_cloud_invalid_track'))
    }
  }

  function extractTrackId(embedString: string): string | null {
    const trackIdMatch = embedString.match(/tracks\/(\d+)/)
    return trackIdMatch ? trackIdMatch[1] : null
  }

  return (
    <div className="flex w-full flex-col gap-2 p-4">
      <div className="flex w-full flex-col text-tiny">
        <span>{translate('sound_cloud_validate_message')}</span>
        <div className="my-2 flex w-full flex-col">
          <span>
            <strong>1: </strong>
            {translate('sound_cloud_step_one')}
          </span>
          <span>
            <strong>2: </strong>
            {translate('sound_cloud_step_two')}
          </span>
          <span>
            <strong>3: </strong>
            {translate('sound_cloud_step_three')}
          </span>
          <span>
            <strong>4: </strong>
            {translate('sound_cloud_step_four')}
          </span>
        </div>
      </div>
      <div className="flex gap-2">
        <Input
          label={translate('sound_cloud_embed_code')}
          placeholder="<iframe width ... >"
          fullWidth
          variant="bordered"
          value={embedCode}
          onValueChange={setEmbedCode}
          size="sm"
        />
        <Button
          variant="flat"
          onClick={() => validate()}
          className="h-full px-6"
          startContent={<FontAwesomeIcon icon={faMagnifyingGlass} />}
        >
          {translate('validate_sound_cloud_link')}
        </Button>
      </div>
    </div>
  )
}

export default EditArtistSoundCloudTrackValidation
