import ArtistsFilterReducer from '@reducers/ArtistsFilter'
import BreadcrumbReducers from '@reducers/Breadcrumb'
import EventsFilterReducer from '@reducers/EventsFilter'
import GenresFilterReducer from '@reducers/GenresFilter'
import GlobalReducers from '@reducers/Global'
import ProducersFilterReducer from '@reducers/ProducersFilter'
import VenuesFilterReducer from '@reducers/VenuesFilter'
import { combineReducers } from '@reduxjs/toolkit'

const Reducers = combineReducers({
  global: GlobalReducers,
  breadcrumbs: BreadcrumbReducers,
  artistsFilter: ArtistsFilterReducer,
  venuesFilter: VenuesFilterReducer,
  genresFilter: GenresFilterReducer,
  producersFilter: ProducersFilterReducer,
  eventsFilter: EventsFilterReducer
})

export default Reducers
