import {
  ArtistsFilterAction,
  ArtistsFilterActionTypes
} from '@actions/ArtistsFilter'

export interface ArtistsFilterState {
  page?: number
  pageSize?: number
  namesFilter?: string[]
  genresFilter?: string[]
  countriesFilter?: string[]
}

const initialArtistsFilterState: ArtistsFilterState = {
  page: undefined,
  pageSize: undefined,
  namesFilter: undefined,
  genresFilter: undefined,
  countriesFilter: undefined
}

const ArtistsFilterReducer = (
  state: ArtistsFilterState = initialArtistsFilterState,
  action: ArtistsFilterAction
) => {
  switch (action.type) {
    case ArtistsFilterActionTypes.SET_ARTISTS_FILTER:
      return {
        page: action.payload.page,
        pageSize: action.payload.pageSize,
        namesFilter: action.payload.namesFilter,
        genresFilter: action.payload.genresFilter,
        countriesFilter: action.payload.countriesFilter
      }
    case ArtistsFilterActionTypes.CLEAR_ARTISTS_FILTER:
      return initialArtistsFilterState
    default:
      return state
  }
}

export default ArtistsFilterReducer
