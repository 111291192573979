import React, { useEffect, useState } from 'react'

import { useTheme } from '@configuration/ThemeContext'
import DropdownItemDto from '@dtos/DropdownItemDto'
import useTranslate from '@hooks/useTranslate'
import { Select, SelectItem } from '@nextui-org/react'
import useVenueService from '@services/Venue'

interface EditEventVenueDropdownProps {
  onSelectVenue: (venueId: string) => void
  selectedVenue: string
}

const EditEventVenueDropdown = ({
  onSelectVenue,
  selectedVenue
}: EditEventVenueDropdownProps) => {
  const translate = useTranslate('components.events.edit_event')
  const [items, setItems] = useState<DropdownItemDto[]>([])
  const { isDarkMode } = useTheme()
  const { getVenueDropdownItems } = useVenueService()

  useEffect(() => {
    getVenueDropdownItems().then((response) => {
      if (response) setItems(response)
    })
  }, [])

  return (
    <Select
      defaultSelectedKeys={[selectedVenue]}
      items={items}
      label={translate('venue')}
      classNames={{
        label: 'z-0',
        popoverContent: `${isDarkMode ? 'text-foreground dark' : ''}`
      }}
      onChange={(e) => onSelectVenue(e.target.value)}
    >
      {(venue) => (
        <SelectItem key={`${venue.id}`}>{venue.displayName}</SelectItem>
      )}
    </Select>
  )
}

export default EditEventVenueDropdown
