import React from 'react'

import useTranslate from '@hooks/useTranslate'
import { ModalHeader } from '@nextui-org/react'

const ConfirmDeleteProducerModalHeader = () => {
  const translate = useTranslate('components.producer.remove_producer')
  return (
    <ModalHeader>
      <span>{translate('title')}</span>
      <span className="text-sm">{translate('subtitle')}</span>
    </ModalHeader>
  )
}

export default ConfirmDeleteProducerModalHeader
