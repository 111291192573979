import React from 'react'

import FramedImage from '@components/Shared/FramedImage/FramedImage'
import {
  faArrowRotateBackward,
  faTrashCan
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@nextui-org/react'

interface EditPicturesListPreviewProps {
  picture: string
  pictureId: string
  isNewPicture: boolean
  setToRemove: boolean
  onRemove: (picture: string) => void
  onCancelRemove?: (picture: string) => void
  showFrame?: boolean
}

const EditPicturesListPreview = ({
  picture,
  pictureId,
  isNewPicture,
  setToRemove,
  onRemove,
  onCancelRemove,
  showFrame = true
}: EditPicturesListPreviewProps) => {
  return (
    <FramedImage
      showFrame={showFrame}
      src={picture}
      size={200}
      classNames={`w-[200px] ${isNewPicture ? 'border-t-1 border-solid border-success-500 shadow-md shadow-success-500' : setToRemove ? 'border-t-1 border-solid border-danger-500 shadow-md shadow-danger-500' : 'border-none'}`}
      imageClassNames={`${setToRemove ? 'blur-sm' : ''}`}
      footer={
        <div className="flex grow justify-center">
          <Button
            className="bg-black/40 text-tiny text-white backdrop-blur"
            variant="flat"
            color={setToRemove ? 'warning' : 'danger'}
            radius="lg"
            size="sm"
            onClick={() =>
              setToRemove && onCancelRemove
                ? onCancelRemove(pictureId)
                : onRemove(pictureId)
            }
            isIconOnly
          >
            <FontAwesomeIcon
              icon={setToRemove ? faArrowRotateBackward : faTrashCan}
            />
          </Button>
        </div>
      }
    />
  )
}

export default EditPicturesListPreview
