import React, { Ref } from 'react'

import { setIsLoadingAction } from '@actions/Global'
import EditEventDates from '@components/Events/EditEvent/EditEventDates'
import ChangeableAvatar from '@components/Shared/ChangeableAvatar'
import InputField from '@components/Shared/InputField'
import LocalizableTextArea from '@components/Shared/LocalizableTextArea/LocalizableTextArea'
import CreateUpdateEventDto from '@dtos/CreateUpdateEventDto'
import EventDto from '@dtos/EventDto'
import PictureUploadDto from '@dtos/PictureUploadDto'
import { EventMainInfoFormValidationSchema } from '@formSchemas/Event'
import { LocalizedStringInitialValues } from '@formSchemas/Shared'
import useTranslate from '@hooks/useTranslate'
import CreateUpdateEventMainInfo from '@interfaces/Forms/Event/CreateUpdateEventMainInfo'
import { parseDate } from '@internationalized/date'
import { Card, CardBody, CardHeader, DateValue } from '@nextui-org/react'
import useEventService from '@services/Event'
import { Formik, FormikProps } from 'formik'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

interface EditEventMainInfoFormProps {
  values: CreateUpdateEventMainInfo
  formRef: Ref<FormikProps<CreateUpdateEventMainInfo>>
  formKey: number
  onSaveForm: (event: EventDto) => void
  originalLogoUrl?: string
}

const EditEventMainInfoForm = ({
  values,
  formRef,
  formKey,
  onSaveForm,
  originalLogoUrl
}: EditEventMainInfoFormProps) => {
  const dispatch = useDispatch()
  const translate = useTranslate('components.events.edit_event')
  const errorsTranslate = useTranslate('errors')
  const { updateEvent, updateEventAvatar, updateEventDates, createEvent } =
    useEventService()

  const changeLogo = (
    form: FormikProps<CreateUpdateEventMainInfo>,
    avatar: PictureUploadDto | null
  ) => form.setFieldValue('avatar', avatar)

  const addDescription = (
    form: FormikProps<CreateUpdateEventMainInfo>,
    culture: string
  ) => {
    if (
      !form.values.description
        .map((description) => description.culture)
        .includes(culture)
    ) {
      form.setFieldValue('description', [
        ...form.values.description,
        { ...LocalizedStringInitialValues(culture) }
      ])
    }
  }

  const removeDescription = (
    form: FormikProps<CreateUpdateEventMainInfo>,
    culture: string
  ) => {
    if (
      form.values.description
        .map((description) => description.culture)
        .includes(culture)
    ) {
      form.setFieldValue('description', [
        ...form.values.description.filter(
          (description) => description.culture !== culture
        )
      ])
    }
  }

  const changeReleaseDate = (
    form: FormikProps<CreateUpdateEventMainInfo>,
    date: DateValue
  ) => {
    form.setFieldValue(
      'releaseDate',
      date === null
        ? undefined
        : new Date(Date.UTC(date.year, date.month - 1, date.day)).toISOString()
    )

    if (date === null) form.setFieldValue('endDate', null)
  }

  const changeEndDate = (
    form: FormikProps<CreateUpdateEventMainInfo>,
    date: DateValue
  ) =>
    form.setFieldValue(
      'endDate',
      date === null
        ? undefined
        : new Date(Date.UTC(date.year, date.month - 1, date.day)).toISOString()
    )

  const handleError = () => {
    dispatch(setIsLoadingAction(false))
    toast.error(errorsTranslate('general'))
  }

  const saveForm = async (form: CreateUpdateEventMainInfo) => {
    const eventDto: CreateUpdateEventDto = {
      name: form.name,
      description: form.description,
      mainProducerId: form.mainProducerId
    }

    let response

    if (form.eventId) {
      response = await updateEvent(form.eventId, eventDto)
    } else {
      response = await createEvent(eventDto)
    }

    if (!response) return handleError()

    response = await updateEventDates(
      response.id,
      form.releaseDate!,
      form.endDate!
    )

    if (!response) return handleError()

    if (form.avatar) {
      const avatarResponse = await saveAvatar(response.id, form.avatar)
      if (!avatarResponse) return handleError()
      return onSaveForm(avatarResponse)
    }

    return onSaveForm(response)
  }

  const saveAvatar = async (
    eventId: string,
    avatar: PictureUploadDto
  ): Promise<EventDto | undefined> => {
    const dataIndex = avatar.base64image.indexOf(',')
    avatar.base64image = avatar.base64image.substring(dataIndex + 1)
    return await updateEventAvatar(eventId, avatar)
  }

  return (
    <Card fullWidth>
      <CardHeader>
        <div className="pl-2 text-lg font-bold text-foreground-500">
          {translate('main_info')}
        </div>
      </CardHeader>
      <Formik
        innerRef={formRef}
        key={formKey}
        initialValues={values}
        onSubmit={async (values) => await saveForm(values)}
        validationSchema={EventMainInfoFormValidationSchema}
      >
        {(props: FormikProps<CreateUpdateEventMainInfo>) => (
          <form onSubmit={props.handleSubmit} noValidate>
            <CardBody>
              <div className="flex w-full flex-col items-center gap-4">
                <div className="flex w-full flex-col items-center gap-4 lg:flex-row">
                  <ChangeableAvatar
                    name={props.values.name}
                    originalAvatarUrl={originalLogoUrl ?? ''}
                    onChangeAvatar={(logo) => changeLogo(props, logo)}
                  />
                  <div className="flex w-full grow flex-col gap-3">
                    <InputField
                      id="name"
                      name="name"
                      type="text"
                      label={translate('name')}
                    />
                    <div className="grid grid-cols-12 gap-2">
                      <div className="col-span-12 lg:col-span-9">
                        <LocalizableTextArea
                          fieldName="description"
                          label={translate('description')}
                          placeholder={translate('description')}
                          values={props.values.description}
                          onAddValue={(culture) =>
                            addDescription(props, culture)
                          }
                          onRemoveValue={(culture) =>
                            removeDescription(props, culture)
                          }
                        />
                      </div>
                      <div className="col-span-12 lg:col-span-3">
                        <EditEventDates
                          releaseDateInitialValue={
                            props.values.releaseDate
                              ? parseDate(
                                  props.values.releaseDate
                                    .toString()
                                    .substring(0, 10)
                                )
                              : undefined
                          }
                          endDateInitialValue={
                            props.values.endDate
                              ? parseDate(
                                  props.values.endDate
                                    .toString()
                                    .substring(0, 10)
                                )
                              : undefined
                          }
                          onChangeReleaseDate={(date) =>
                            changeReleaseDate(props, date)
                          }
                          onChangeEndDate={(date) => changeEndDate(props, date)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </form>
        )}
      </Formik>
    </Card>
  )
}

export default EditEventMainInfoForm
