import React from 'react'

interface GenreIconProps {
  fill?: string
}

const GenreIcon = ({ fill }: GenreIconProps) => {
  return (
    <div className="flex size-1/2 items-center justify-center align-middle">
      <svg
        fill={fill ?? 'currentColor'}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 142.27 130.5"
        width="100%"
        height="100%"
      >
        <path d="M84.83,32.67l-27.14,7.5c-.44.12-.74.53-.74.98v77.63s-51.4,0-51.4,0c-3.7-4.64-5.54-9.95-5.54-15.93s1.74-10.96,5.23-15.01c3.59-4.15,8.06-6.21,13.42-6.21,6.26,0,13.58,3.32,21.93,9.93.19.15.4.22.62.22.53,0,1.01-.41,1.01-1.01V28.44c0-.46.3-.85.74-.97l28.2-7.89c4.88,4.03,9.45,8.4,13.68,13.09Z" />
        <path d="M142.26,16.82v90.73c.01.42-.25.81-.66.96-3.08,1.1-6.17,1.99-9.24,2.59-3.77.75-7.54,1.12-11.29,1.12-1.9,0-3.73-.08-5.5-.23-3.84-.33-7.4-1.04-10.65-2.1-4.74-1.56-8.54-3.7-11.38-6.44-5.48-5.3-8.22-11.59-8.22-18.89,0-5.96,1.74-10.96,5.23-15.01,3.59-4.15,8.06-6.21,13.42-6.21.48,0,.95.02,1.44.06,5.94.46,12.77,3.75,20.5,9.87.19.15.4.22.62.22.53,0,1.01-.41,1.01-1.01V22.22c0-.58-.47-1.01-1-1.01-.09,0-.18.01-.27.03l-41.43,11.43-27.14,7.5c-.44.12-.74.53-.74.98v77.63s.01,7.06.01,7.06c0,.42-.26.81-.67.96-3.08,1.1-6.17,1.99-9.24,2.59-3.77.75-7.54,1.12-11.29,1.12-6.02,0-11.4-.78-16.16-2.34-4.74-1.56-8.54-3.7-11.38-6.44-.98-.95-1.88-1.94-2.68-2.95-3.7-4.64-5.54-9.95-5.54-15.93s1.74-10.96,5.23-15.01c3.59-4.15,8.06-6.21,13.42-6.21,6.26,0,13.58,3.32,21.93,9.93.19.15.4.22.62.22.53,0,1.01-.41,1.01-1.01v-50.8c0-7.26,4.83-13.63,11.82-15.59l17.11-4.79L140.99.04c.09-.02.18-.04.27-.04.55,0,1.01.45,1.01,1.02v15.8Z" />
      </svg>
    </div>
  )
}

export default GenreIcon
